import React from "react";
import {
  BorderButton,
  // style
  StyledCard,
  StyledWrapper,
} from "components";

export const ComponentHeader = ({
  firstClick,
  secondClick,
  firstTitle,
  secontTitle,
  firstIcon,
  secondIcon,
  thirdClick,
  thirdTitle,
  thirdIcon,
  disabled=false
}) => {
  return (
    <StyledWrapper>
      <StyledCard>
        <BorderButton
          onClick={firstClick}
          icon={firstIcon}
          title={firstTitle}
        />
        {secondIcon && (
          <BorderButton
            icon={secondIcon}
            template
            title={secontTitle}
            type="button"
            onClick={secondClick}
            disabled={disabled}
          />
        )}

        {thirdIcon && (
          <BorderButton
            onClick={thirdClick}
            icon={thirdIcon}
            title={thirdTitle}
          
          />
        )}
      </StyledCard>
    </StyledWrapper>
  );
};
