// email validation
export const checkEmailisValid = (value) =>
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/g.test(value);

// max & min length check
export const checkLengthisValid = (value, min = 6, max = 250) =>
  value.length >= min && value.length <= max;

// space check helper
export const checkSpaceinValue = (value) => /\s/g.test(value);

// check start with space ex.: "__some word"
export const checkStartWithSpace = (value) => /^\s+/g.test(value);

export const checkValueIsOnlyAzSymbolAndOtherSymbols = (value) =>
  /^[A-Za-züöıəöğşçiZÜÖIİƏÖĞŞÇ -]+$/.test(value) && !/[wW]/.test(value);

export const isValidNumber = (value) => {
  const reg = /^-?(0|[1-9][0-9]*)$/;
  if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
    return true;
  }
  return false;
};

export const isValidFullname = (value) =>
  /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/.test(value);

// universal letter check ru/az/etc..
export const isNotValidLetter = (value) =>
  /[0-9\s~!@#$%^&*()_+{}[\]:;'"<>?|`\-=\\/.,№]/g.test(value);

export const amountValidation = (value) =>
  !/^([\d]{1,3})(\.[\d]{3})*?(,[\d]{1,2})*?$/.test(value);

export const numberFormat = (value) => {
  if (value === null || value === undefined) value = 0;
  let amountString = parseFloat(value)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .toString();

  amountString = amountString.replace(/\./g, '@');
  amountString = amountString.replace(/,/g, '.');
  amountString = amountString.replace(/@/g, ',');

  return amountString;
};

export const numberFormatConvertion = (value) => {
  let amountString = parseFloat(value)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .toString();

  amountString = amountString.replace(/\./g, '@');
  amountString = amountString.replace(/,/g, '');
  amountString = amountString.replace(/@/g, '.');

  return amountString;
};

export const onlyNumber = (e, dotKeyCode) =>
  [69, 187, 189, 107, 109, 110, 111, 191, 188, dotKeyCode].includes(e.keyCode) &&
  e.preventDefault();

export const onlyNumberAmount = (e) =>
  [69, 187, 189, 107].includes(e.keyCode) && e.preventDefault();

export const regexMax6 = (value) => {
  return /^\d{0,6}$/.test(value);
};
export const regexMax10 = (value) => {
  return /^\d{0,10}$/.test(value);
};

export const passwordOnlyNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const regexEmailCharacter = (value) => {
  return /^([a-zA-Z0-9._%+-@])*$/.test(value);
};
export const numberLimitAfterDot = (e) =>
  e.target.value.split('.')[1]?.length === 2 &&
  e.code !== 'Backspace' &&
  e.preventDefault();

export const maxNumber = (e, num) =>
  e.target.value.length === num && e.preventDefault();

export const purposeValidation = (e) =>
  e.target.value.replace(/[^A-Z0-9.\.\-\/]*$/gm, '');

export const amountToFixedVal = (value) => /^(([0-9])+(\.[0-9]{1,2})?)$/.test(value);

export const datePickerFormat = (e) => {
  // Allow only certain key codes: numbers, dot, backspace, and delete
  if (
    (e.keyCode < 48 || e.keyCode > 57) && // Numbers
    e.keyCode !== 190 && // Dot
    e.keyCode !== 8 && // Backspace
    e.keyCode !== 46 // Delete
  ) {
    e.preventDefault();
  }
};
export const checkStringWithEmpty = (value) => {
  return /^[^\s].*/.test(value);
};

export const checkStringWithEmptyAllCharacter = (value) => {
  return /^\S*$/.test(value);
};

//============== Payments validations ===========

// only english and ./- sybols
export const onlyEnglishSymbol = (value) => /^([A-Za-z0-9./\s-])*$/.test(value);
export const englishAzSymbol = (value) =>
  /^([A-Za-z0-9./\s-ğşçİÜÖIƏÖĞŞÇzüöıəöğşçZÜÖIİƏÖĞŞÇ])*$/.test(value);

// only english and Only dots
export const onlyEnglishSymbolOnlyDot = (value) => /^([A-Za-z0-9.\s])*$/.test(value);

// only english and Not symbol
export const onlyEnglishNotSymbol = (value) => /^[A-Za-z0-9\s]*$/.test(value);

export const onlySymbolForPin = (value) => /^[A-NP-Za-np-z0-9]*$/g.test(value);

// only english, rus and Only dots == for Swift
export const onlyEnglishAndRussianSymbolOnlyDot = (value) =>
  /^([A-Za-z0-9А-Яа-я.\s])*$/.test(value);

// Password
export const passwordValidation = (value) =>
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[\w!@#$%^&*]+$/.test(value);

// specialSymbol for past event Receiver Bank code
export const specialSymbol = (value) => /[_+e\.\-]/g.test(value);
