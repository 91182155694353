import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'client';
import { setOtpData } from '../authInfo';

//Asan Sign
export const AsanAuthenticate = createAsyncThunk(
  'AsanAuthenticate',
  async (info, thunkAPI) => {
    try {
      const data = await client({
        url: `/users/v1/auth/asan/authenticate`,
        data: info,
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Get user info in Asan Sign in',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//Asan Sign
export const AsanSign = createAsyncThunk(
  'AsanSign',
  async (info, thunkAPI) => {
    const { sessionId } = info;

    try {
      const data = await client({
        url: `/users/v1/auth/asan/sign-in`,
        data: JSON.stringify(sessionId),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: false,
        hasChannel: true,
        // succesMessage: "Successfully logged in",
        token: false,
        // errorCallback: () => history.push("/"),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);
// =========================AND ASAN ========================================

//OTP Login SMS
export const OtpLoginSMS = createAsyncThunk(
  'OtpLoginSMS',
  async (info, thunkAPI) => {
    const { history, otpInfo } = info;
    try {
      const data = await client({
        url: `/users/v1/auth/otp/sign-in`,
        data: JSON.stringify(otpInfo),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Send SMS was successfully',
        token: false,
        successCallback: () => history.push('/'),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

// OTP verification
export const OtpVerification = createAsyncThunk(
  'OtpVerification',
  async (info, thunkAPI) => {
    const { otpData, otpInfo } = info
    try {
      const data = await client({
        url: `users/v1/auth/otp/sign-in/check-otp`,
        data: JSON.stringify(otpInfo),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Verification was successfully',
        token: false,
        successCallback: () => thunkAPI.dispatch(setOtpData(otpData)),
        hasChannel: true,
      })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//OTP Sign
export const OtpSign = createAsyncThunk(
  'OtpSign',
  async (info, thunkAPI) => {
    const { signInfo } = info;
    try {
      const data = await client({
        url: `/users/v1/otp-login/sign-in`,
        data: JSON.stringify(signInfo),
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Successfully logged in',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//OTP confirmation
export const OtpConfirmation = createAsyncThunk(
  'OtpConfirmation',
  async (info, thunkAPI) => {
    const { history, userInfo, token, email } = info;
    try {
      const data = await client({
        url: `/users/v1/auth/otp/first-login`,
        data: JSON.stringify(userInfo),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Confirmation was successfully',
        successCallback: () => history.push(
          {
            pathname: '/set-new-password/success',
            state: email,
          },
        ),
        errorCallback: () => history.push('/'),
        stateInToken: token,

      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//first login email confirmation

export const EmailConfirmation = createAsyncThunk(
  'EmailConfirmation',
  async (token, thunkAPI) => {
    try {
      const data = await client({
        url: `/users/v1/operator/confirmation`,
        data: JSON.stringify({ token }),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//Get questions
export const GetQuestions = createAsyncThunk(
  'GetQuestions',
  async (thunkAPI) => {
    try {
      const data = await client({
        url: `/users/v1/questions`,
        showSuccessToast: false,
        showErrorToast: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//Get Company List
export const GetCompanyList = createAsyncThunk(
  'getCompanyList',
  async (stateInToken, thunkAPI) => {
    try {
      const data = await client({
        url: `/users/v1/auth/companyList`,
        showSuccessToast: false,
        showErrorToast: true,
        stateInToken,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//OTP forgot password
export const OtpForgotPassword = createAsyncThunk(
  'OtpForgotPassword',
  async (otpInfo, thunkAPI) => {
    try {
      const data = await client({
        url: '/users/v1/forgot-password/otp',
        data: otpInfo,
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Otp was successfully sent',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

// OTP forgot password verification
export const OtpForgotPasswordVerification = createAsyncThunk(
  'OtpForgotPasswordVerification',
  async (info, thunkAPI) => {
    const { otpData, otpInfo } = info
    const toMethods = () => {
      thunkAPI.dispatch(setOtpData(otpData));
    }
    try {
      const data = await client({
        url: `/users/v1/forgot-password/verification`,
        data: otpInfo,
        method: 'PUT',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Verification was successfully',
        token: false,
        successCallback: () => toMethods(),
        // errorCallback: () => history.push("/"),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);


//Forgot password Check answer
export const ForgotPasswordCheckAnswer = createAsyncThunk(
  'ForgotPasswordCheckAnswer',
  async (info, thunkAPI) => {
    const { body, history } = info;
    try {
      const data = await client({
        url: '/users/v1/forgot-password/verify-answer',
        data: JSON.stringify(body),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Check was successfully',
        token: false,
        successCallback: () => history.push('/forget-password/set-newpassword'),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//Forgot password Email
export const ForgotPasswordEmail = createAsyncThunk(
  'ForgotPasswordEmail',
  async (info, thunkAPI) => {
    const { body } = info;
    try {
      const data = await client({
        url: '/users/v1/forgot-password/email',
        data: JSON.stringify(body),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Please check your email address',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

// check token when set new pasword page (forget password)

export const CheckToken = createAsyncThunk(
  'CheckToken',
  async (token, thunkApi) => {
    try {
      const data = await client({
        url: `/users/v1/forgot-password/new-password?token=${token}`,
        method: 'POST',
        data: { token },
        showSuccessToast: false,
        showErrorToast: false,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  },
);

export const SetNewPasswordForgetPassword = createAsyncThunk(
  'SetNewPasswordForgetPassword',
  async ({ body, history }, thunkApi) => {
    try {
      const data = await client({
        url: `/users/v1/forgot-password/set-new-password`,
        method: 'PUT',
        data: body,
        showSuccessToast: false,
        showErrorToast: true,
        token: false,
        successCallback: () => history.push('/forgetpassword/success'),
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  },
);


//Validation token
export const ValidationToken = createAsyncThunk(
  'ValidationToken',
  async (validationToken, thunkAPI) => {
    const { history, token } = validationToken;
    try {
      const data = await client({
        url: `/password-recovery/token-validation?token=${token}`,
        data: '',
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Validation token was successfully',
        token: false,
        errorCallback: () => history.push('/'),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

//Refresh token
export const RefreshToken = createAsyncThunk(
  'RefreshToken',
  async (tokenInfo, thunkAPI) => {
    const { history, refreshToken } = tokenInfo;
    try {
      const data = await client({
        url: `/auth/refresh-token?refreshToken=${refreshToken}`,
        data: '',
        method: 'PUT',
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: 'Refresh token was successfully updated',
        token: false,
        successCallback: () => history.push('/'),
        errorCallback: () => history.push('/'),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  },
);

// Permissions
export const GetPermissionsList = createAsyncThunk(
  'GetPermissionsList',
  async (thunkAPI) => {
    try {
      const data = await client({
        url: `/users/v1/feature`,
        showSuccessToast: false,
        showErrorToast: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const RefreshTokenRemainingTime = async () => {
  try {
    const res = await client({
      url: `/users/v1/auth/refresh-token/remaining-time`,
      showSuccessToast: false,
      showErrorToast: false,
      method: 'GET',
      hasChannel: true,
      hasRefreshToken: true
    });
    if(res?.data.remainingTimeInSeconds) localStorage.setItem('remainingTimeInSeconds', res.data.remainingTimeInSeconds);
  } catch (err) {
    console.log(err);
  }
};