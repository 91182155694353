import React, { useState } from 'react';
import { Input as AntInput, DatePicker, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { Select } from 'components/Select';
import { calendar, clearIcon as icon, selectArrow } from 'static/svg';
import { AutoCompleteInput, TextArea } from 'components/Input';
import { useTranslation } from 'react-i18next';
import { datePickerFormat } from 'utils';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { Select as AntSelect } from 'antd';

import 'moment/locale/az';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import azAZ from 'antd/es/locale/az_AZ';
import enEn from 'antd/es/locale/en_GB';
import ruRu from 'antd/es/locale/ru_RU';
import { useMemo } from 'react';

export const CustomInput = React.forwardRef((props, ref) => {
  const [focus, setFocus] = useState(false);

  let {
    label,
    inputValue,
    placeholder,
    type,
    required,
    password,
    select,
    textare,
    date,
    quickSearch,
    positionSelect,
    disabled = false,
    maxLength,
    minLength,
    suffixIcon,
    isSuffix,
    onSuffixClick,
    addonBefore,
    readOnly,
    isResponsive,
    isLabel,
    responsive,
    inputmask,
    selectSearch,
    optionsData,
    allowClear = true,
    showLength,
    ...rest
  } = props;
  if (!placeholder) placeholder = label;
  const isOccupied =
    focus ||
    (rest.value && rest.value.length !== 0) ||
    (inputValue && inputValue.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const handleFilter = (input, option) => {
    return (option.value ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const { t } = useTranslation();

  const { lang } = useSelector((state) => state.setLang);

  const currentLang = useMemo(() => {
    if (lang === 'az') {
      return azAZ;
    } else if (lang === 'ru') {
      return ruRu;
    } else {
      return enEn;
    }
  }, [lang]);

  const charCount = useMemo(() => {
    if (maxLength) {
      return `${rest?.value?.length ? rest?.value?.length : 0}/${maxLength}`;
    }
  }, [rest?.value, maxLength]);

  return (
    <StyledInput isLabel={isLabel} isTextArea={textare}>
      <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        {password ? (
          <AntInput.Password ref={ref} {...rest} maxLength={maxLength} />
        ) : select ? (
          <Select
            clearIcon={<img src={icon} alt="clear" />}
            defaultValue={inputValue}
            disabled={disabled}
            placeholder=" "
            {...rest}
            positionSelect={positionSelect}
            allowClear={allowClear}
            responsive
          />
        ) : selectSearch ? (
          <AntSelect
            defaultValue={inputValue}
            disabled={disabled}
            placeholder=" "
            {...rest}
            suffixIcon={<img src={selectArrow} alt={selectArrow} />}
            positionSelect={positionSelect}
            allowClear={allowClear}
            responsive
            showSearch={true}
            filterOption={handleFilter}
            optionFilterProp="children"
          >
            {optionsData.map((option) => (
              <AntSelect.Option
                key={option.key}
                value={option.key}
                label={option.label}
              >
                {option.label}
              </AntSelect.Option>
            ))}
          </AntSelect>
        ) : textare ? (
          <TextArea
            clearIcon={<img src={icon} alt="clear" />}
            showCount
            defaultValue={inputValue}
            disabled={disabled}
            maxLength={maxLength}
            autoComplete="off"
            {...rest}
          />
        ) : date ? (
          <ConfigProvider locale={currentLang}>
            <DatePicker
              disabled={disabled}
              defaultValue={inputValue}
              suffixIcon={<img src={calendar} alt="datePicker" />}
              placeholder=" "
              {...rest}
              onKeyDown={datePickerFormat}
              format="DD.MM.YYYY"
            />
          </ConfigProvider>
        ) : quickSearch ? (
          <AutoCompleteInput autoComplete="off" {...rest} />
        ) : inputmask ? (
          <InputMask
            autoComplete="off"
            disabled={disabled}
            className="ant-input"
            style={{ backgroundColor: disabled ? '#ebebeb' : 'transparent' }}
            mask="+\9\94 (99) 999-99-99"
            {...rest}
          />
        ) : (
          <AntInput
            onChange={props.onChange}
            type={type}
            onWheel={(e) => e.target.blur()}
            defaultValue={inputValue}
            ref={ref}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            addonBefore={addonBefore}
            autoComplete="off"
            {...{ readOnly }}
            {...rest}
          />
        )}
        {isSuffix ? (
          <span className="suffixIcon" onClick={onSuffixClick}>
            {suffixIcon}
          </span>
        ) : null}

        <label className={labelClass}>
          {isOccupied ? t(label) : t(placeholder)} {requiredMark}
        </label>
      </div>
      {maxLength && showLength && <div className="char-count">{charCount}</div>}
    </StyledInput>
  );
});

const textStyle = `
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #393939;
background-color: transparent;
`;

const StyledInput = styled.div`
  .ant-select-multiple .ant-select-selection-item {
    padding-right: 0px !important;
    margin: 0;
  }
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
    .ant-select-selection-item {
    background-color: transparent !important;
  }

  .ant-select-multiple .ant-select-selection-item-remove > * {
    display: none;
  }
  .ant-select-multiple .ant-select-arrow {
    top: 45% !important;
    right: 10px;
    display: inline-block;
    position: absolute;
  }
  .ant-select-multiple .ant-select-selection-item {
    background: transparent;
    padding-left: 0;
  }
  .ant-select-multiple .ant-select-item {
    padding: 0px 10px;
  }
  .ant-select-clear {
    opacity: 1;
    margin-right: 20px;
    background-color: transparent;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #bebed0;
  }

  .ant-input,
  .ant-select-selector {
    height: 55px !important;
    ${textStyle}
  }
  /* #inside_country_receiverName,
  #currency_paymentPurpose,
  #state_treasury_receiverName,
  #state_treasury_additionalDetails,
  #international_swift_receiverName,
  #budget_additionalDetails,
  #aniPay_receiverName,
  #inside_country_additionalDetails,
  #international_swift_additionalDetails,
  #aniPay_additionalDetails {
    text-transform: uppercase;
  } */
  .ant-input {
    padding: 13px 54px 0 10px;
  }

  .float-label {
    display: ${(props) => (props.isSuffix ? 'flex' : null)};
    .suffixIcon {
      position: absolute;
      right: 12px;
      top: 17px;
      cursor: pointer;
    }
  }
  .ant-select-selector {
    padding-top: 14px !important;
    background-color: transparent !important;
  }
  .ant-select-selection-item {
    color: #393939;
    padding-right: 50px !important;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-picker-input input {
    height: 27px !important;
    border-radius: 0;
    ${textStyle}
  }
  .ant-input-textarea {
    textarea {
      height: 86px !important;
      resize: none;
      padding: 25px 10px;
      font-weight: 600 !important;
      font-size: 18px !important;
    }
    margin-bottom: 0;
  }

  .ant-input-textarea-show-count::after {
    margin-bottom: -20px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .ant-input-disabled {
    background-color: #ebebeb;
    border-color: #ebebeb;
  }
  .float-label {
    position: relative;
  }

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 17px;
    transition: 0.2s ease all;
  }

  .as-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #63666a;
  }

  .as-label {
    top: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #8e8e8e;
    background: ${(props) => (props.isTextArea ? '#fff' : 'transparent')};
    padding: 0 4px;
    margin-left: -4px;
    width: ${(props) => (props.isTextArea ? '95%' : 'unset')};
    margin-top: ${(props) => (props.isTextArea ? '-9px' : 'unset')};
    padding-top: ${(props) => (props.isTextArea ? '10px' : 'unset')};
  }

  .ant-select-selection-item {
    flex-wrap: wrap;
    line-height: 20px !important;
    margin-top:8px;

    span {
      width: fit-content !important;
      text-align: end;

      @media screen and (max-width: 1803px) {
        text-align: start;
      }
    }
  }

  .positionSelect {
    .ant-select-item-option-content {
      span {
        &:nth-child(1) {
          padding: 10px !important;
        }
      }
    }
    .ant-select-item {
      background-color: transparent;
      span {
        &:nth-child(1) {
          border-radius: 4px;
          background: #f2f2f2 !important;
          display: flex;
          justify-content: center;
          img {
            margin-right: 5px;
          }
        }
      }
    }

    .selected_span {
      padding: 1px 8px;
      background: #f2f2f2;
      border-radius: 4px;
      img {
        margin-bottom: 6px;
      }
    }
    .ant-select-item-option-active {
      background-color: #f2f2f2 !important;
    }
  }

  .simpleSelect {
    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background-color: #fafafa !important;
    }
  }
  input {
    padding-top: ${(props) => (props.isLabel ? '0px' : '13px')}!important;
  }
  .char-count {
    position: absolute;
    right: 0;
    display: inline-flex;
    opacity: 0.6;
  }
`;
