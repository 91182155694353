import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from 'client';


export const DownloadeEmployeList = createAsyncThunk(
    "DownloadeEmployeList",

    async (allValues = { 'data': 'employelist' }, thunkAPI) => {

        console.log('Action value: ', allValues)
        try {
            const data = await client({
                url: `/payment/salary/download-payroll`,
                data: allValues,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "download employe list",
                token: false,
            })
            return data
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)