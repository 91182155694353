import React from "react";
import styled from "styled-components";
import {
  ActionSubButtons,
  GroupButtons,
} from "components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { responsive } from "utils/responsiveUI";

export const DeleteModal = (props) => {
  const { cancelOnClick, mainOnClick, title, noText, yesText } = props;


  const loadingReject = useSelector((state) => state.loadings.DocumentSignReject);


  const {t} = useTranslation()
  return (
    <Container>
      <StyleContent>
        <h4>
        {t(title)}
        </h4>
      </StyleContent>
      <ContainerBtn>
      <GroupButtons
        firstProps={{isGrey: true, text: noText ? noText : "No, keep", onClick: cancelOnClick  }}
        secondProps={{isRed: true, text: yesText ? yesText : 'Yes, delete', onClick: mainOnClick, disabled: loadingReject,  loading: loadingReject}}
      />
      </ContainerBtn>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 32px;
`;

const ContainerBtn = styled.div`
    display: flex;
    justify-content: end;
    padding: 0 16px;
`;

const StyleContent = styled.div`
  width: 406px;
  padding: 16px;
  @media ${responsive.laptop} {
    width: auto;
  }
  h4 {
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #63666a;
    span {
      font-weight: 700;
    }
  }
`;
