import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

const voen = localStorage.getItem("voen")


export const OrdersList = createAsyncThunk(
  "OrdersList",
  async (body,thunkAPI) => {
    const {page, size}= body;
    try {
      const data = await client({
        url: `collect-order-ms/api/v1/collection-order/list/${voen}?size=${size}&page=${page}`,
        showErrorToast: true,
        token: false             
      });
      return data;

    }
    catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
    
  }
);


export const OrdersTotalDebt = createAsyncThunk(
  'OrdersTotalDebt',
  async (body, thunkAPI)=>{
      // const {} = body;
      try{
        const data = await client({
          url: `collect-order-ms/api/v1/collection-order/totalRateAmount/${voen}`,
          showErrorToast:false,
          token:false
        })
        return data;
      }
  catch (err){
    return thunkAPI.rejectWithValue(err.response)
  }
}
)
