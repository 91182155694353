import React from "react";
import { useState } from "react";
import { double_arrow_up } from "static/svg";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

export const MobileCards = ({
  children,
  childrenclose,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const openFn = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      {open ? (
        <>
          {children}

          <StyledArrowUp onClick={openFn}>
            <span style={{cursor: "pointer"}}>{t("TapToCollapse")}</span>{" "}
            <img src={double_arrow_up} alt="arrow" />
          </StyledArrowUp>
        </>
      ) : (
        <CardStyle onClick={openFn}>{childrenclose}</CardStyle>
      )}
    </>
  );
};

const bubble = keyframes`
    0%   { transform:scale(0.5);  opacity:0.3;}
    50%  { transform:scale(1.1); opacity:0.5; }
    100% { transform:scale(1.0); opacity:1.0; }
`;

const CardStyle = styled.div`
  padding: 22px 18px 22px 18px;
  width: 100%;
  height: 143px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  animation: ${bubble} 2s linear 0.5s 1 forwards;
  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 90%;
    top: 100%;
    background-color: #fff;
    left: 5%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 12px 12px;
  }
  &::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 80%;
    background-color: #fff;
    left: 10%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 12px 12px;
    bottom: -10%;
  }
`;

const StyledArrowUp = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: #ffa300;
  }
`;
