import { createSlice } from "@reduxjs/toolkit";
import { GetTemplates, SaveTemplate, DeleteTemplate,GetTemplateId,EditTemplate, RenameTemplate, SearchTemplates } from "./api_action";

//export
export { GetTemplates, SaveTemplate, DeleteTemplate,GetTemplateId,EditTemplate, RenameTemplate, SearchTemplates};

const initialState = {
  templateData: null,
  template: null,
  templateRename: null,
  templateDelete: null,
  templateSearch: [],
  error: null,
}

export const TemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers:{
   reset:()=>initialState
  },
  extraReducers: {
    [GetTemplates.fulfilled]: (state, action) => {
      state.templateData = action.payload.data
    },
    [GetTemplates.rejected]: (state, action) => {
      state.error = action.payload
    },
    [SaveTemplate.fulfilled]: (state, action) => {
      state.templateData=action.payload.data
      state.error = null
    },
    [SaveTemplate.rejected]: (state, action) => {
      state.error = action.payload
    },
    [DeleteTemplate.fulfilled]: (state, {payload}) => {
      state.templateDelete = {result: payload}
      state.error = null
    },
    [DeleteTemplate.rejected]: (state, action) => {
      state.error = action.payload
    },
    [GetTemplateId.fulfilled]: (state, action) => {
      state.template = action.payload.data
      state.error = null
    },
    [GetTemplateId.rejected]: (state, action) => {
      state.error = action.payload
    },
    [RenameTemplate.fulfilled]: (state, {payload}) => {
      state.templateRename = {result: payload}
      state.error = null
    },
    [RenameTemplate.rejected]: (state, action) => {
      state.error = action.payload
    },
    [EditTemplate.fulfilled]: (state, action) => {
      // state.editTemplateInfo = action.payload.data
      state.error = null
    },
    [EditTemplate.rejected]: (state, action) => {
      state.error = action.payload
    },
    [SearchTemplates.fulfilled]: (state, action) => {
      state.templateData = action.payload.data
      state.templateSearch = action.payload.data

      state.error = null
    },
    [SearchTemplates.rejected]: (state, action) => {
      state.error = action.payload
    }
  }
})

export const templateReducer = TemplateSlice.reducer;

// //GETTEMPLATE
// const getTemplatesSlice = createSlice({
//   name: "getTemplates",
//   initialState: {
//     templateData: [],
//   },
//   extraReducers: {
//     [GetTemplates.fulfilled]: (state, action) => {
//       state.templateData = action.payload.data
//     },
//     [SaveTemplate.fulfilled]: (state, action) => {
//       state.templateData.push(action.payload.data)
//       state.error = null
//     },
//     [SaveTemplate.rejected]: (state, action) => {
//       state.error = action.payload
//     }
//   }
// });

// export const getTemplateReducer = getTemplatesSlice.reducer;


// //SAVETEMPLATE
// const saveTemplateSlice = createSlice({
//   name: "savetemplate",
//   initialState: {
//     saveTemplateInfo: [],
//     error: null
//   },
//   extraReducers: {
//     [SaveTemplate.fulfilled]: (state, action) => {
//       state.saveTemplateInfo = action.payload.data
//       state.error = null
//     },
//     [SaveTemplate.rejected]: (state, action) => {
//       state.error = action.payload
//     }
//   }
//   // extraReducers: (builder) => {
//   //   // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
//   //   builder.addCase(SaveTemplate.fulfilled, (state, { payload }) => {
//   //     state.saveTemplateInfo = payload
//   //   })
//   //   builder.addCase(SaveTemplate.rejected, (state, action) => {
//   //     if (action.payload) {
//   //       // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
//   //       state.error = action.payload.errorMessage
//   //     } else {
//   //       state.error = action.error.message
//   //     }
//   //   })
//   // },
// });

// export const saveTemplateReducer = saveTemplateSlice.reducer;


// //DELETETEMPLATE
// const deleteTemplateSlice = createSlice({
//   name: "deletetemplate",
//   initialState: {
//     deleteTemplateInfo: {},
//     error: null
//   },
//   extraReducers: {
//     [DeleteTemplate.fulfilled]: (state, action) => {
//       state.deleteTemplateInfo = action.payload?.data
//       state.error = null
//     },
//     [DeleteTemplate.rejected]: (state, action) => {
//       state.error = action.payload
//     }
//   }
// });

// export const deleteTemplateReducer = deleteTemplateSlice.reducer;


// //GETTEMPLATE_ID
// const getTemplateIdSlice = createSlice({
//   name: "gettemplateid",
//   initialState: {
//     getTemplateIdInfo: {},
//     error: null
//   },
//   extraReducers: {
//     [GetTemplateId.fulfilled]: (state, action) => {
//       state.getTemplateIdInfo = action.payload.data
//       state.error = null
//     },
//     [GetTemplateId.rejected]: (state, action) => {
//       state.error = action.payload
//     }
//   }
// });

// export const getTemplateIdReducer = getTemplateIdSlice.reducer;


// //RENAMETEMPLATE
// const renameTemplateSlice = createSlice({
//   name: "renametemplate",
//   initialState: {
//     renameTemplateInfo: [],
//     error: null
//   },
//   extraReducers: {
//     [RenameTemplate.fulfilled]: (state, action) => {
//       state.renameTemplateInfo = action.payload.data
//       state.error = null
//     },
//     [RenameTemplate.rejected]: (state, action) => {
//       state.error = action.payload
//     }
//   }
// });

// export const renameTemplateReducer = renameTemplateSlice.reducer;

// //EDITTEMPLATE
// const editTemplateSlice = createSlice({
//   name: "edittemplate",
//   initialState: {
//     editTemplateInfo: [],
//     error: null
//   },
//   extraReducers: {
//     [EditTemplate.fulfilled]: (state, action) => {
//       state.editTemplateInfo = action.payload.data
//       state.error = null
//     },
//     [EditTemplate.rejected]: (state, action) => {
//       state.error = action.payload
//     }
//   }
// });

// export const editTemplateReducer = editTemplateSlice.reducer;