import {
  // flag
  AZ,
  loan_azn,
  loan_usd,
  loan_eur,
  loan_rur,
  loan_gbp,
  deposit_dark_azn,
  deposit_light_azn,
  deposit_dark_eur,
  deposit_light_eur,
  deposit_dark_gbp,
  deposit_light_gbp,
  deposit_dark_rur,
  deposit_light_rur,
  deposit_dark_usd,
  deposit_light_usd,
  within_bank,
  inside_country,
  between_my_account,
  TR,
  UK,
  EUR,
  RU,
  JP,
  US,
  manat,
  dollar,
  frank,
  euro,
  rubl,
  yen,
  united,
  gdarkGray_azn,
  gdarkLight_azn,
  gdarkGray_euro,
  gdarkLight_euro,
  gdarkGray_gbp,
  gdarkLight_gbp,
  gdarkGray_rub,
  gdarkLight_rub,
  gdarkGray_usd,
  gdarkLight_usd,
  icon_budget,
  icon_tax,
  icon_salary,
  icon_internal,
  excellSuccess,
  jpegUpload,
  pdfUpload,
  documentUpload,
  icon_anipay,
  director_black,
  accounter_black,
  creator_black,
  icon_conversion,
  CHF,
} from 'static/svg';

export const paginationSize = [
  {
    id: 5,
    show: '5',
  },
  {
    id: 10,
    show: '10',
  },
  {
    id: 20,
    show: '20',
  },
  {
    id: 50,
    show: '50',
  },
  {
    id: 100,
    show: '100',
  },
];

export const allLanguages = {
  AZ,
  EN: UK,
  RU,
};

export const paymentType = {
  AZIPS: 'AZIPS',
  XOHKS: 'XOHKS',
  INSIDE_COUNTRY: ['AZIPS', 'XOHKS'],
  INTERNAL: 'INTERNAL',
  BETWEEN: 'BETWEEN',
  ANYPAY: 'ANYPAY',
  'SWIFT.MT103': 'SWIFT.MT103',
  'SWIFT.MT103.RU': 'SWIFT.MT103.RU',
  BUDGET: 'XOHKS.BUDGET',
  VAT: 'XOHKS.BDX',
  SALARY: 'SALARY',
  'CCY.BUY': 'CCY.BUY',
  'CCY.SELL': 'CCY.SELL',
};

export const paymentTypeSecond = {
  inside_country_azips: 'AZIPS',
  inside_country: 'XOHKS',
  within_bank: 'INTERNAL',
  between_my_account: 'BETWEEN',
  AniPay: 'ANYPAY',
  swift: 'SWIFT.MT103',
  swift_ru: 'SWIFT.MT103.RU',
  Budget: 'XOHKS.BUDGET',
  State_treasury: 'XOHKS.BDX',
  Salary: 'SALARY',
  Conversion: 'CCY.SELL',
  Conversion_buy: 'CCY.BUY',
};

export const paymentTypeTemplate = {
  inside_country_azips: 'AZIPS',
  inside_country: 'XOHKS',
  within_bank: 'INTERNAL',
  between_my_account: 'BETWEEN',
  AniPay: 'ANYPAY',
  swift: 'SWIFT.MT103',
  swift_ru: 'SWIFT.MT103.RU',
  Budget: 'XOHKS.BUDGET',
  State_treasury: 'XOHKS.BDX',
  Salary: 'SALARY',
  Conversion: 'CCY.SELL',
  Conversion_buy: 'CCY.BUY',
};

export const transferAndPaymentType = {
  INSIDE_COUNTRY: {
    title: 'inside_country',
    icon: inside_country,
  },
  WITHIN_BANK: {
    title: 'within_bank',
    icon: within_bank,
  },
  BETWEEN_MY_ACCOUNT: {
    title: 'between_my_account',
    icon: between_my_account,
  },
  ANYPAY: {
    title: 'AniPay',
    icon: icon_anipay,
  },
  SALARY: {
    title: 'Salary',
    icon: icon_salary,
  },
  BUDGET: {
    title: 'Budget',
    icon: icon_budget,
  },
  VAT: {
    title: 'State_treasury (VAT)',
    icon: icon_tax,
  },
  'SWIFT.MT103': {
    title: 'International',
    icon: icon_internal,
  },
  'CCY.BUY': {
    title: 'Conversion',
    icon: icon_conversion,
  },
  'CCY.SELL': {
    title: 'Conversion',
    icon: icon_conversion,
  },
};

export const paymentPurpose = {
  INSIDE_COUNTRY: 'INSIDE_COUNTRY',
  WITHIN_BANK: 'WITHIN_BANK',
  BUDGET_PAYMENT: 'BUDGET_PAYMENT',
  ANIPAY: 'ANIPAY',
  BETWEEN_MY_ACCOUNT: 'BETWEEN_MY_ACCOUNT',
  PAYMENTS_TO_STATE: 'PAYMENTS_TO_STATE',
  INTERNAL: 'INTERNAL',
  STANDARD: 'STANDARD',
  OTHER: 'OTHER',
};

export const paymentPurposeType = {
  [paymentPurpose.INTERNAL]: 0,
  [paymentPurpose.WITHIN_BANK]: 0,
  [paymentPurpose.BETWEEN_MY_ACCOUNT]: 0,
  [paymentPurpose.STANDARD]: 1,
  [paymentPurpose.INSIDE_COUNTRY]: 1,
  [paymentPurpose.ANIPAY]: 1,
  [paymentPurpose.BUDGET_PAYMENT]: 5,
  [paymentPurpose.PAYMENTS_TO_STATE]: 6,
  [paymentPurpose.OTHER]: 999,
};

export const userPositions = [
  {
    id: 1,
    name: 'Director',
    icon: director_black,
  },
  {
    id: 2,
    name: 'accountant',
    icon: accounter_black,
  },
  {
    id: 3,
    name: 'Operator',
    icon: creator_black,
  },
];

export const currencyInfos = {
  944: {
    AZN: 944,
    type: 'AZN',
    flag: AZ,
    loan_icon: loan_azn,
    deposit_icon: { dark: deposit_dark_azn, light: deposit_light_azn },
    guarantee_icon: { dark: gdarkGray_azn, light: gdarkLight_azn },
    symbol: '₼',
    symbolIcon: manat,
    beneficiary: `KOD:510891  VÖEN:1500031691 Müxbir hesab:AZ11NABZ01350100000000036944 S.W.I.F.T AZENAZ22`,
    correspondent: '',
  },
  756: {
    CHF: 756,
    type: 'CHF',
    flag: CHF,
    symbolIcon: frank,
    symbol: '₣',
  },
  949: { flag: TR, symbol: '₺', type: 'TRY', TRY: 949 },
  840: {
    USD: 840,
    type: 'USD',
    flag: US,
    loan_icon: loan_usd,
    deposit_icon: { dark: deposit_dark_usd, light: deposit_light_usd },
    guarantee_icon: { dark: gdarkGray_usd, light: gdarkLight_usd },
    symbol: '$',
    symbolIcon: dollar,
    beneficiary:
      'S.W.I.F.T.: AZENAZ22  Expressbank OJSC  Baku, Azerbaijan Corr. Acc.: 70-55.084.305',
    correspondent:
      'S.W.I.F.T.: RZBAATWW Raiffeisen Bank International AG Vienna, Austria',
  },
  978: {
    EUR: 978,
    type: 'EUR',
    flag: EUR,
    loan_icon: loan_eur,
    deposit_icon: { dark: deposit_dark_eur, light: deposit_light_eur },
    guarantee_icon: { dark: gdarkGray_euro, light: gdarkLight_euro },
    symbol: '€',
    symbolIcon: euro,
    beneficiary:
      'S.W.I.F.T.: AZENAZ22 Expressbank OJSC Baku, Azerbaijan Corr. Acc.: 1-55.084.305',
    correspondent:
      'S.W.I.F.T.: RZBAATWW Raiffeisen Bank International AG Vienna, Austria',
  },
  643: {
    RUB: 643,
    type: 'RUB',
    flag: RU,
    loan_icon: loan_rur,
    deposit_icon: { dark: deposit_dark_rur, light: deposit_light_rur },
    guarantee_icon: { dark: gdarkGray_rub, light: gdarkLight_rub },
    symbol: '₽',
    symbolIcon: rubl,
    beneficiary:
      'Счет: 30111810800000002822 Expressbank OJSC Baku, Azerbaijan S.W.I.F.T.: AZENAZ22',
    correspondent:
      'S.W.I.F.T.: IBAZRUMM “MBA-MOSKVA “ OAO Москва, Россия БИК:  044525502 Счет: 30101810000000000502 в Главном управлении Центрального банка Российской Федерации по Центральному федеральному округу г. Москва (ГУ Банка России по ЦФО)   ',
  },
  392: { flag: JP, symbol: '¥', type: 'JPY', JPY: 392, symbolIcon: yen },
  826: {
    GBP: 826,
    type: 'GBP',
    flag: UK,
    loan_icon: loan_gbp,
    deposit_icon: { dark: deposit_dark_gbp, light: deposit_light_gbp },
    guarantee_icon: { dark: gdarkGray_gbp, light: gdarkLight_gbp },
    symbol: '£',
    symbolIcon: united,
    beneficiary:
      'S.W.I.F.T.: AZENAZ22 Expressbank OJSC Baku, Azerbaijan Corr. Acc.: 83-55.084.305',
  },
};

export const exchangeRateType = [
  {
    id: 0,
    value: 'Non cash',
  },
  {
    id: 1,
    value: 'Cash',
  },
  {
    id: 2,
    value: 'CBA',
  },
];

export const balanceType = [
  {
    id: 0,
    value: 'In equality',
  },
  {
    id: 1,
    value: 'By currency',
  },
];

export const statuses = {
  1: {
    bgColor: '#FFF4E0',
    color: '#FFA000',
    status: 'To be sign',
  },
  2: {
    bgColor: '#E7F8F0',
    color: '#12B76A',
    status: 'Completed',
  },
  3: {
    bgColor: '#FEE8E7',
    color: '#F44336',
    status: 'Rejected',
  },
  4: {
    bgColor: '#FEE8E7',
    color: '#F44336',
    status: 'Rejected by bank',
  },
  5: {
    bgColor: '#E4F1FF',
    color: '#3D89FF',
    status: 'Checking in bank',
  },
};

export const multiLanguage = [
  {
    id: 944,
    value: 'az',
  },
  {
    id: 643,
    value: 'ru',
  },
  {
    id: 826,
    value: 'en',
  },
];

export const statusColors = {
  ACTIVE: {
    backgroundColor: '#E7F8F0',
    color: '#12B76A',
  },
  BLOCKED: {
    backgroundColor: '#FEE8E7',
    color: '#F44336',
  },
  OTP_VERIFY_WAITING: {
    backgroundColor: '#FFF4E0',
    color: '#FFA000',
  },
  LOGIN_WAITING: {
    backgroundColor: '#FFF4E0',
    color: '#FFA000',
  },
};

export const aliasType = [
  {
    id: 'other',
    name: '-',
  },
  {
    id: 'PHONE',
    name: 'TELEFON',
  },
  {
    id: 'EMAIL',
    name: 'E-POCT',
  },
  {
    id: 'PIN',
    name: 'FIN',
  },
  {
    id: 'VOEN',
    name: 'VOEN',
  },
];

export const aliasTypeCabinet = [
  {
    id: 1,
    name: 'MOBILE',
  },
  {
    id: 2,
    name: 'EMAIL',
  },
];

export const aliasTypeEnum = {
  MOBILE: 'MOBILE',
  EMAIL: 'EMAIL',
  TIN: 'TIN',
};

const commonAll = [
  'senderIban',
  'senderName',
  'receiverIban',
  'receiverName',
  'amount',
  'paymentCurrency',
];

const commonSome = ['senderFee', 'overdraftFee', 'senderFeeIban'];

const commonSomeTwo = ['paymentPurpose', 'additionalDetails'];

const covertionDetails = [
  'paymentDate',
  'paymentId',
  'paymentCurrency',
  'receiverCurrency',
  'amount',
  'amountConverter',
  'senderIban',
  'receiverIban',
  'senderFee',
  'overdraftFee',
  'senderFeeIban',
  'paymentPurpose',
];

export const transfers = {
  // Internationaly
  [paymentType['SWIFT.MT103']]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverAccountNo',
    'receiverName',
    'amount',
    'paymentCurrency',
    'receiverCountry',
    'receiverSwiftCode',
    'senderFee',
    'senderFeeIban',
    'paymentPurpose',
    'additionalDetails',
    'receiverAddress',
    'intermedAccount',
    'intermedBankSwift',
  ],
  [paymentType['SWIFT.MT103.RU']]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverAccountNo',
    'receiverName',
    'amount',
    'paymentCurrency',
    'receiverCountry',
    'receiverBank',
    'senderFee',
    'senderFeeIban',
    'paymentPurpose',
    'additionalDetails',
    'receiverAddress',
    'receiverBankAccount',
  ],
  // Anipay
  [paymentType.ANYPAY]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverIban',
    'receiverName',
    'amount',
    'paymentCurrency',
    'receiverVoen',
    'receiverBankVoen',
    'receiverBankCode',
    'receiverBankName',
    'senderFee',
    'overdraftFee',
    'paymentPurpose',
    'additionalDetails',
  ],
  // Between My Accounts
  [paymentType.BETWEEN]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'receiverIban',
    'amount',
    'paymentCurrency',
    'senderFee',
    'overdraftFee',
    'paymentPurpose',
  ],
  // Within Bank
  [paymentType.INTERNAL]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverIban',
    'receiverName',
    'amount',
    'paymentCurrency',
    'senderFee',
    'overdraftFee',
    'paymentPurpose',
  ],
  [paymentType.AZIPS]: [
    'paymentDate',
    'paymentId',
    ...commonAll,
    'receiverVoen',
    'receiverBankVoen',
    'receiverBankCode',
    'receiverBankName',
    ...commonSome,
    ...commonSomeTwo,
  ],
  // Inside Country
  [paymentType.XOHKS]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverIban',
    'receiverName',
    'amount',
    'paymentCurrency',
    'receiverVoen',
    'receiverBankVoen',
    'receiverBankCode',
    'receiverBankName',
    'senderFee',
    'overdraftFee',
    'senderFeeIban',
    'paymentPurpose',
    'additionalDetails',
  ],
  // Budget
  [paymentType.BUDGET]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverIban',
    'receiverName',
    'amount',
    'paymentCurrency',
    'receiverVoen',
    'receiverBankVoen',
    'receiverBankCode',
    'receiverBankName',
    'budgetLevel',
    'budgetType',
    'senderFee',
    'overdraftFee',
    'senderFeeIban',
    'paymentPurpose',
    // "additionalDetails",
  ],
  // State Treasury
  [paymentType.VAT]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderName',
    'receiverIban',
    'receiverName',
    'amount',
    'paymentCurrency',
    'receiverVoen',
    'receiverBankVoen',
    'receiverBankCode',
    'receiverBankName',
    'senderFee',
    'overdraftFee',
    'senderFeeIban',
    'paymentPurpose',
    'additionalDetails',
  ],
  // Salary
  [paymentType.SALARY]: [
    'paymentDate',
    'paymentId',
    'senderIban',
    'senderFeeIban',
    'amount',
    'paymentCurrency',
    'senderFee',
    'overdraftFee',
    'paymentPurpose',
  ],
  [paymentType['CCY.BUY']]: [...covertionDetails],
  [paymentType['CCY.SELL']]: [...covertionDetails],
};

export const currencies = [
  {
    id: 944,
    value: 'AZN',
  },
  {
    id: 840,
    value: 'USD',
  },
  {
    id: 978,
    value: 'EUR',
  },
  {
    id: 826,
    value: 'GBP',
  },
  {
    id: 643,
    value: 'RUB',
  },
  // {
  //   id: 949,
  //   value: "TRY",
  // },
];

export const VALID_PRODUCTS_CODES = {
  guarantee: ['944', '840', '978', '643', '826'],
};

export const FileImgType = {
  'image/jpeg': {
    title: 'image',
    icon: jpegUpload,
  },
  'application/vnd.ms-excel': {
    title: 'excell',
    icon: excellSuccess,
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    title: 'excell',
    icon: excellSuccess,
  },
  'application/pdf': {
    title: 'pdf',
    icon: pdfUpload,
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    title: 'word/document',
    icon: documentUpload,
  },
  'application/msword': {
    title: 'doc',
    icon: documentUpload,
  },
  // SALARY: {
  //   title: "Salary",
  //   icon: icon_salary
  // },
};
export const typeOfFile = [
  {
    id: 1,
    name: 'PDF',
    title: 'PDF',
  },
  {
    id: 2,
    name: 'EXCEL',
    title: 'EXCEL',
  },
];

export const blockingType = {
  NONE: 'NONE',
  OTP_30: 'OTP_30',
  OTP_120: 'OTP_120',
  OTP_PERMANENT: 'OTP_PERMANENT',
  QA_30: 'QA_30',
  QA_120: 'QA_120',
  QA_PERMANENT: 'QA_PERMANENT',
};
