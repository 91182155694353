import React from 'react';
import ChartComponent from './chartComponent';
import styled from 'styled-components';
import { percentDown, percentHigh } from 'static/svg'

//percent - obyekt tipindədir, value və status elementləri var
//title
//price
//moneyIcon - məs M və ya rub
//chartColor - chartın xəttinin rəngi

export const ChartPanel = ({ title, price, moneyIcon, percent, chartColor }) => {

    return (

        <Container percent={percent.status}>
            <div className='textContainer'>
                <span className='title'>{title}</span>
                <span className='priceContainer'>
                    <span className='price'>{price}</span>
                    <span className='moneyIcon'>{moneyIcon}</span>
                </span>
                <span className='percentContainer'>
                    <img src={percent.status === 'high' ? percentHigh : percentDown} alt='' />
                    <span className='percent'>{percent.value}</span>
                </span>
            </div>
            <div className='chartContainer'>
                <ChartComponent chartColor={chartColor} />
            </div>
        </Container>

    )
}

const Container = styled.div`
    background-color:white;
    border-radius: 10px;
    height:150px;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;

    .textContainer{
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        align-items:flex-start;
        width:50%;
        padding: 20px 0 20px 20px;
    }
    .chartContainer{
        width:50%;
        padding-right:10px;
    }
    .title{
        font-family: Proxima Nova;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: #8798AD;
        padding-bottom:15px;
    }
    .priceContainer{
        display:flex;
        align-items:center;
        flex-direction:row;
        .price{
            font-family: Proxima Nova;
            font-size: 48px;
            line-height: 57px;
            letter-spacing: -0.6px;
            color: #231F20;
            padding-right:5px;
            @media(max-width:1100px){
                font-size:36px;
            }
        }
        .moneyIcon{
            font-family: Proxima Nova;
            font-size: 36px;
            line-height: 32px;
            color: #616173;
        }
    }
    .percentContainer{
            display:flex;
            flex-direction:row;
            padding-top:10px;
            span{
                padding-left:8px;
                font-family: Proxima Nova;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: ${props=>props.percent === 'high' ? '#2DB744' : '#E84A50'};
            }

        }
`