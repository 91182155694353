import { createSlice } from "@reduxjs/toolkit";
import { CreateUser, GetUserDetails, GetOperatorsList, GetAuthorityUsersList, GetUserSettingsInfo } from "./api_actions";

const initialState = {
  operators: {},
  authorityUsers: {},
  userDetails: null,
  createdUserId: null,
  userSettingsInfo: null
};

export const usersSlice = createSlice({
  name: "usersIB",
  initialState: initialState,
  reducers: {
    resetCreatedUserId:(state) => {
      state.createdUserId = null;
    }
  },
  extraReducers: {
    [CreateUser.fulfilled]:(state, action)=>{
      state.createdUserId = action.payload.data.userId;
    },
    [CreateUser.rejected]:(state, action)=>{
      state.createdUserId = null;
    },
    [GetUserDetails.fulfilled]:(state, action)=>{
      state.userDetails = action.payload.data;
    },
    [GetUserDetails.rejected]:(state, action)=>{
      state.userDetails = null;
    },
    [GetOperatorsList.fulfilled]:(state, action)=>{
      state.operators = action.payload.data;
    },
    [GetOperatorsList.rejected]:(state, action)=>{
      state.operators = null;
    },
    [GetAuthorityUsersList.fulfilled]:(state, action)=>{
      state.authorityUsers = action.payload.data;
    },
    [GetAuthorityUsersList.rejected]:(state, action)=>{
      state.authorityUsers = null;
    },
    [GetUserSettingsInfo.fulfilled]: (state, action) => {
      state.userSettingsInfo = action.payload.data;
    },
    [GetUserSettingsInfo.rejected]: (state) => {
      state.userSettingsInfo = null
    }
  }
});


export default usersSlice.reducer;
