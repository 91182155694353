import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthorizedApp from 'AuthorizedApp';
import UnAuthorizedApp from 'UnAuthorizedApp';
import { Loading } from 'components';
import { resetAuthInfo, setAuthInfo } from './store/authInfo';
import { useHistory } from 'react-router-dom';

function App() {
  const lngState = useSelector(state => state.lang.lngdata);
  const isLogout = useSelector(state => state.logout.isLogout);
  const dispatch = useDispatch();
  const { token, voen } = useSelector(state => state.authInfo);

  const handleSetAuthInfoToState = useCallback((e) => {
    dispatch(setAuthInfo(e.detail));
  }, [dispatch])

  const handleResetAuthInfoOnState = useCallback(() => {
    dispatch(resetAuthInfo());
  }, [dispatch])

  useEffect(() => {
    document.addEventListener('resetAuthInfoOnState', handleResetAuthInfoOnState);

    return () => {
      document.removeEventListener('resetAuthInfoOnState', handleResetAuthInfoOnState)
    }
  }, [handleResetAuthInfoOnState]);

  useEffect(() => {
    document.addEventListener('setAuthInfoToState', handleSetAuthInfoToState);

    return () => {
      document.removeEventListener('setAuthInfoToState', handleSetAuthInfoToState)
    }
  }, [handleSetAuthInfoToState]);

  useEffect(() => {
    //Məlumat gəlibsə LocalStorage-a göndər
    lngState.length && LocalStorageLanguage();
  }, [lngState])

  useEffect(() => {
  }, [isLogout])

  //Məlumatın sort edilib localstorage-ə yazılması
  const LocalStorageLanguage = () => {

    let AZ = []
    let EN = []
    let RU = []
    let ID = []

    lngState.length && lngState.map((item) => {   //məlumatı mapləyib hər dil üçün yeni array yaradırıq
      return (
        AZ.push(item?.az),
          EN.push(item?.en),
          RU.push(item?.ru),
          ID.push(item?.id)
      )
    })

    const LangAz = {};
    ID.forEach((key, i) => LangAz[key] = AZ[i]);   //dil ilə id-ni birləşdiririk.İd-key dil value olur

    const LangEn = {}
    ID.forEach((key, i) => LangEn[key] = EN[i])

    const LangRu = {}
    ID.forEach((key, i) => LangRu[key] = RU[i])

    const LangStorage = {   //Localstorage-yə göndərəcəyimiz məlumat
      AZ: LangAz,
      EN: LangEn,
      RU: LangRu
    }

    localStorage.setItem('LangStorage', JSON.stringify(LangStorage));
  }

  const logged = !!token && !!voen;
  const history = useHistory();

  useEffect(() => {
      if(logged){
        if (history?.location?.pathname === '/login') {
          history.replace('/');
        }
      }
  }, [history, logged]);

  const AuthRoute = () => {
    return logged ? (
      <Suspense fallback={<Loading />}>
        <AuthorizedApp />
      </Suspense>
    ) : (
      <Suspense fallback={<Loading />}>
        <UnAuthorizedApp />
      </Suspense>
    );
  };

  return useMemo(AuthRoute, [logged]);
}

export default App;
