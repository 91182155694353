import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'client';
import { filterQueryResolver } from 'utils';
import { changePaymentTypeIds } from 'utils/changePaymentTypeIds';

export const DocumentToBeSignList = createAsyncThunk(
  'DocumentToBeSignList',
  async (filters, thunkAPI) => {
    const query = filterQueryResolver(
      changePaymentTypeIds(filters, 'payment-type-ids')
    );
    try {
      const data = await client({
        url: `/operation-ms/v1/operation/documentsToSign?${query}`,
        showSuccessToast: false,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

//Document to be Sign ASAN
export const DocumentSignSelectedAsan = createAsyncThunk(
  'DocumentSignSelectedAsan',
  async (signList, thunkAPI) => {
    try {
      const data = await client({
        url: `/operation-ms/v1/operation/sign/asan/send`,
        data: signList,
        method: 'POST',
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "successfully",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const DocumentSignCheckAsan = createAsyncThunk(
  'DocumentSignCheckAsan',
  async (info, thunkAPI) => {
    const { paymentIds, sessionId } = info;

    try {
      const data = await client({
        url: `/operation-ms/v1/operation/sign/asan/check`,
        data: { paymentIds, sessionId },
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        errorMessage: 'Sign failed',
        token: false,
        // errorCallback: () => history.push("/"),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

//Document to be Sign OTP
export const DocumentSignSelectedOtp = createAsyncThunk(
  'DocumentSignSelectedOtp',
  async (signList, thunkAPI) => {
    try {
      const data = await client({
        url: `/operation-ms/v1/operation/sign/otp/send`,
        data: signList,
        method: 'POST',
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "successfully",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const DocumentSignCheckOtp = createAsyncThunk(
  'DocumentSignCheckOtp',
  async (info, thunkAPI) => {
    const { paymentIds, verificationCode } = info;

    try {
      const data = await client({
        url: `/operation-ms/v1/operation/sign/otp/check`,
        data: { paymentIds, verificationCode },
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: true,
        errorMessage: 'Sign failed',
        token: false,
        // errorCallback: () => history.push("/"),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const DocumentSignReject = createAsyncThunk(
  'DocumentSignReject',
  async (info, thunkAPI) => {
    const { paymentIds } = info;

    try {
      const data = await client({
        url: `/operation-ms/v1/operation/sign/reject`,
        data: { paymentIds },
        method: 'POST',
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: 'The payment has been rejected successfully',
        token: false,
        // errorCallback: () => history.push("/"),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const GetDocumentToSignCount = async (setCount) => {
  try {
    const data = await client({
      url: `/operation-ms/v1/operation/documentsToSign/count`,
      showSuccessToast: false,
      showErrorToast: false,
      method: 'GET',
    });
    if(setCount) setCount(data?.data);
  } catch (err) {
    console.log(err);
  }
};
