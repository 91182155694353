import { createSlice } from "@reduxjs/toolkit";
import {
  BudgetValidate,
  BudgetProses,
  GetBudgetIban,
  GetBudgetOrgNumberList,
  GetBudgetClassificationCode,
} from "./api_action";

export {
  BudgetValidate,
  BudgetProses,
  GetBudgetIban,
  GetBudgetOrgNumberList,
  GetBudgetClassificationCode,
};

//budget
const initialState = {
  validate: null,
  process: null,
  error: null,
};
export const BudgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [BudgetValidate.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.validate = data;
    },
    [BudgetProses.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.process = data;
    },
    [BudgetValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [BudgetProses.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const BudgetReducer = BudgetSlice.reducer;

//Beneficiary Bank Code
const GetBudgetIbanSlice = createSlice({
  name: "getBudgetIban",
  initialState: {
    ibanCode: null,
    error: null,
  },
  extraReducers: {
    [GetBudgetIban.fulfilled]: (state, action) => {
      const data = action.payload;
      state.ibanCode = data.data;
      state.error = null;
    },
    [GetBudgetIban.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const GetBudgetIbanReducer = GetBudgetIbanSlice.reducer;

// Budget level code
const GetBudgetOrgSlice = createSlice({
  name: "getBudgetOrgNumberList",
  initialState: {
    levelCode: [],
    error: null,
  },
  extraReducers: {
    [GetBudgetOrgNumberList.fulfilled]: (state, action) => {
      const data = action.payload;
      state.levelCode = data.data;
      state.error = null;
    },
    [GetBudgetOrgNumberList.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const GetBudgetOrgReducer = GetBudgetOrgSlice.reducer;

// Budget level code
const GetBudgetClassificationCodeSlice = createSlice({
  name: "getBudgetClassificationCode",
  initialState: {
    classificationCode: [],
    error: null,
  },
  extraReducers: {
    [GetBudgetClassificationCode.fulfilled]: (state, action) => {
      const data = action.payload;
      state.classificationCode = data.data;
      state.error = null;
    },
    [GetBudgetClassificationCode.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const GetBudgetClassificationReducer =
  GetBudgetClassificationCodeSlice.reducer;
