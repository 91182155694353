import { center } from "components";
import { currencyInfos } from "constant";
import React from "react";
import styled from "styled-components";

const Flags = ({ currencyId, isFrame }) => {
  return isFrame ? (
    <StyledFlagFrame>
      <img src={currencyInfos[currencyId]?.flag} alt="img/flag" />
    </StyledFlagFrame>
  ) : (
    <img src={currencyInfos[currencyId]?.flag} alt="img/flag" />
  );
};

export default Flags;

const StyledFlagFrame = styled.div`
  background: #f6f6f6;
  height: 48px;
  width: 48px;
  border-radius: 12px;
  ${center}
`;
