import React from "react";
import { Checkbox as AntCheck } from "antd";
import styled from "styled-components";

export const Checkbox = ({ onlyCheck, title, ...rest }) => {
  return (
    <Container onlyCheck={onlyCheck}>
      <AntCheck {...rest}></AntCheck>
      <span className="title">{title}</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 62px;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #63666a;
    margin-left: 10px;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    border: 2px solid;
    width: 20px;
    height: 20px;
  }
`;
