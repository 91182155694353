import React from "react";
import styled from "styled-components";

const CarouselComponents = ({ item, title, spanText }) => {
  return (
    <CarouselComponent item={item}>
      <div className="back_image">
        <div className="text_container">
          <h1>{title}</h1>
          {spanText && (
            <h2>
              <span className="spanText">{spanText}</span>
            </h2>
          )}
        </div>
      </div>
    </CarouselComponent>
  );
};

export default CarouselComponents;

const CarouselComponent = styled.div`
  .back_image {
    height: 100vh;
    background: ${(props) =>
      `url(${require(`static/images/son${props.item}.jpg`)})`};
    background-size: cover;
    background-position: center;
    background-blend-mode: normal, multiply, normal, normal;
    display: flex;
    align-items: flex-end;
  }
  .text_container {
    position: relative;
    bottom: 80%;
    left: 10%;

    .spanText {
      background: #ffb500;
      padding: 0 10px;
      border-radius: 8px;
    }
  }
  h1,
  .spanText {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #ffffff;
    line-height: 1;
  }
`;
