import React from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { Translation } from 'react-i18next';
import { alert_error, alert_success, close } from 'static/svg';
import request from './request';
import moment from 'moment';

function getApiUrl() {
  if (process.env.REACT_APP_INTERNAL_ENV === 'prod') {
    return '/api/eg';
  } else if (process.env.REACT_APP_INTERNAL_ENV === 'qa') {
    return 'https://qa-ib.expressbank.az/eg';
  } else {
    return 'https://dev-ib.expressbank.az/eg';
  }
}

const voen = localStorage.getItem('voen');

export const globalHeader = {
  'Content-Type': 'application/json',
  voen,
  Accept: 'application/json',
};

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    description:
      type === 'error' ? (
        <Translation>
          {(t) => (
            <div style={{ wordBreak: 'break-word', paddingRight: '20px' }}>
              {t(message)}
            </div>
          )}
        </Translation>
      ) : (
        <Translation>
          {(t) => (
            <div style={{ wordBreak: 'break-word', paddingRight: '20px' }}>
              {t(message)}
            </div>
          )}
        </Translation>
      ),
    // description: "Given phone number or user ID is not correct.",
    duration: type === 'error' ? 5 : 3,
    // duration: null,
    icon: <img src={type === 'error' ? alert_error : alert_success} alt="alert" />,
    closeIcon: <img src={close} alt="close" />,
    style: {
      width: '392px',
      color: '#393939',
      background: '#fff',
      borderRadius: '14px',
      padding: '16px 20px',
    },
  });
};

let isRefreshing = false;
let refreshTokenPromise = null;
const pendingRequests = [];

function createHttpClient(baseURL) {
  const _axiosInstance = axios.create({
    baseURL,
    headers: globalHeader,
  });

  _axiosInstance.interceptors.request.use((config) => {
    const userLanguage = localStorage.getItem('lang');

    if (userLanguage) {
      config.headers['Accept-Language'] = userLanguage;
    }

    const token = localStorage.getItem('tokenInfo');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  });

  _axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          const refreshToken = localStorage.getItem('refreshToken');
          const refreshTokenExpiryDate = localStorage.getItem(
            'refreshTokenExpiryDate'
          );
          const voen = localStorage.getItem('voen');

          if (refreshToken) {
            const baseURL = getApiUrl();
            const endpoint =
              localStorage.getItem('loginStatus') === 'ASAN'
                ? '/users/v1/auth/asan/refresh-token'
                : '/users/v1/auth/otp/refresh-token';
            const url = baseURL + endpoint;
            const data = {
              token: refreshToken,
              expiryDate: refreshTokenExpiryDate,
            };
            const headers = {
              accept: '*/*',
              channel: 'WEB',
              voen: voen,
              'Content-Type': 'application/json',
              Authorization: ""
            };

            const createRequest = () => {
              return axios.post(url, data, { headers });
            }
            const requests = createRequest();

            Promise.resolve(requests)
              .then((responses) => {
                const {
                  accessToken,
                  refreshToken: newRefreshToken,
                  userStatus,
                } = responses.data;

                localStorage.setItem('tokenInfo', accessToken?.accessToken);
                localStorage.setItem('refreshToken', newRefreshToken.token);
                localStorage.setItem('remainingTimeInSeconds', newRefreshToken?.remainingTimeInSeconds);
                localStorage.setItem(
                  'refreshTokenExpiryDate',
                  newRefreshToken.expiryDate
                );

                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${accessToken?.accessToken}`;

                pendingRequests.forEach((callback) => callback(accessToken, newRefreshToken.token));
                pendingRequests.length = 0;

                isRefreshing = false;
                refreshTokenPromise = null;
              })
              .catch((error) => {
                isRefreshing = false;
                refreshTokenPromise = null;
                document.dispatchEvent(new CustomEvent('resetAuthInfoOnState'));
                return Promise.reject(error);
              });
          } else {
            isRefreshing = false;
            refreshTokenPromise = null;
            document.dispatchEvent(new CustomEvent('resetAuthInfoOnState'));
            return Promise.reject();
          }
        }

        return new Promise((resolve) => {
          pendingRequests.push((newToken, newRefreshToken) => {
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            originalRequest.headers['X-Session-Id'] = newRefreshToken;
            resolve(_axiosInstance.request(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    }
  );

  return (customConfig) => request(customConfig, _axiosInstance);
}

export const client = createHttpClient(getApiUrl());
