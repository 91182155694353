import React from 'react';
import styled from 'styled-components';


export const ComissionText = ({ text }) => {
    return (
        <CommissionTextContainer>{text}</CommissionTextContainer>
    )
}

const CommissionTextContainer = styled.div`
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.05em;
    color: #231F20;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:12px 50px;
    margin-top:40px;
    border-radius:8px;
    box-shadow: 0px 5px 10px #DADAE5;
`