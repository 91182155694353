import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";
import { GetBudgetIbanReducer } from "./index";

//Inside Country Propses
export const BudgetProses = createAsyncThunk(
  "BudgetProses",
  async (budgetBody, thunkAPI) => {
    try {
      const data = await client({
        url: "/budget-payment-ms/api/v1/process",
        data: JSON.stringify(budgetBody),
        method: "POST",
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "paymentSuccess",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

//Inside Country
export const BudgetValidate = createAsyncThunk(
  "BudgetValidate",
  async (budgetBody, thunkAPI) => {
    try {
      const data = await client({
        url: "/budget-payment-ms/api/v1/validate",
        data: JSON.stringify(budgetBody),
        method: "POST",
        showErrorToast: true,
        succesMessage: "paymentSuccess",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Beneficiary bank code
export const GetBudgetIban = createAsyncThunk(
  "GetBudgetIban",
  async (iban, error, thunkAPI) => {
    try {
      const data = await client({
        url: `/budget-payment-ms/api/v1/budget-details/${iban}`,
        showErrorToast: true,
        errorMessage: "Tam qeyd olunmayin",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Budget level code
export const GetBudgetOrgNumberList = createAsyncThunk(
  "GetBudgetOrgNumberList",
  async (iban, error, thunkAPI) => {
    try {
      const data = await client({
        url: `/budget-payment-ms/api/v1/budget-org-number/list`,
        showErrorToast: true,
        errorMessage: "Tam qeyd olunmayin",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Budgetary classification code
export const GetBudgetClassificationCode = createAsyncThunk(
  "getBudgetClassificationCode",
  async (thunkAPI) => {
    try {
      const data = await client({
        url: `/budget-payment-ms/api/v1/budget-code/list`,
        showErrorToast: true,
        errorMessage: "Tam qeyd olunmayin",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
