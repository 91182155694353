import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {  useHistory } from "react-router-dom";
import { Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GetQuestions, OtpConfirmation } from "store/auth";
import {
  FormItem,
  LoginButton,
  Loading,
  CustomInput,
  LoginLayout,
  ErrorMessage,
} from "components";
import { responsive } from "utils/responsiveUI";
import {
  requiredRule,
  whitespaceRule,
  emailRule,
} from "utils/checkValidations";
import { blockGoBackBtn } from "utils/shortFunctions";
import { useValidate } from "hooks";
import { passwordValidation } from "utils";

const ForgotPassword = () => {
  const [form] = Form.useForm();

  const allLoading = useSelector((state) => state.loadings);
  const getAllQuestions = useSelector((state) => state.getQuestions.questions);

  const { handleBeginningEmptyInput } = useValidate(form);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const history = useHistory();


  const tokenInfo =
    localStorage.getItem("tokenInfo");

    const userInfo = useSelector((state) => state.otpVerification.userInfo);


  const [showAnswer, setShowAnswer] = useState(false);
  const [questionList, setQuestionList] = useState([]);


  const { lang } = useSelector((state) => state.setLang);

  blockGoBackBtn();



  useEffect(() => {
    lang && dispatch(GetQuestions());
    form.setFieldsValue({
      question: undefined,
    });
  }, [dispatch, lang]);

  useEffect(() => {
    const listData = getAllQuestions?.map((item) => {
      const { id, name } = item;
      return {
        id,
        name,
      };
    });
    setQuestionList(listData);
  }, [getAllQuestions]);

  const onFinish = (values) => {
    const token = tokenInfo || userInfo?.accessToken?.accessToken

      if(token){
        dispatch(
          OtpConfirmation({
            userInfo: {
              answer: values?.answer,
              confirmPassword: values?.confirmPassword,
              email: values?.email,
              password: values?.password,
              questionId: values?.question,
            },
            history,
            token,
            email: values?.email
          })
        );

      }


  };

  const onQuestionChange = () => {
    setShowAnswer(true);
  };


  const checkConfPass = () => {
    let passVal = form.getFieldValue("password");
    let confPassVal = form.getFieldValue("confirmPassword");
    if (!confPassVal || passVal === confPassVal) {
      return Promise.resolve();
    }
    return Promise.reject(<ErrorMessage text={"passwordMatchError"} />);
  };


  const SetNewPasswordForm = () => {
    return (
      <FormContainer>
        <div className="title_style">{t("setPass")}</div>
        <Form
          name="Set-New-Password"
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values)}
          initialValues={{ remember: true }}
          scrollToFirstError={true}
          hideRequiredMark={true}
        >
          <Col span={24} className="general">
            <FormItem
              name="password"
              autoheight
              rules={[
                requiredRule,
                whitespaceRule,
                ...[
                  {

                    validator: (rule, value, callback) => {
                      if (value) {
                        const valueLength = value.split("");
                        if (
                          !passwordValidation(value) ||
                          valueLength.length < 8
                        ) {
                          callback(true);
                          return Promise.reject(
                            <ErrorMessage text="pass_info" />
                          );
                        } else {
                          callback();
                          return Promise.resolve();
                        }
                      }else{
                        callback(true);
                      }
                    },
                  },

                ]
              ]}
            >
              <CustomInput
                password
                label={t("password")}
                maxLength={30}
                onChange={(e) => {
                  handleBeginningEmptyInput(e, "password");
                  form.setFieldsValue({
                    confirmPassword: undefined,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col span={24} className="general">
            <FormItem
              name="confirmPassword"
              autoheight
              rules={[
                requiredRule,
                whitespaceRule,
                ...[
                  {
                    validator: checkConfPass,
                    message: <ErrorMessage text={"passwordMatchError"} />,
                  },
                ],
              ]}

            >
              <CustomInput
                password
                label={t("confirmPassword")}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </FormItem>
          </Col>

          <Col span={24} className="general">
            <FormItem
              name="email"
              rules={[requiredRule, whitespaceRule, emailRule]}
              autoheight
            >
              <CustomInput autoheight label={t("emailAddress")} />
            </FormItem>
          </Col>

          <Col span={24} className="general">
            <FormItem name="question" rules={[requiredRule]} autoheight>
              <CustomInput
                select
                label={t("questions")}
                data={questionList}
                onChange={onQuestionChange}
              />
            </FormItem>
          </Col>

          <Col span={24} className="general">
            <FormItem
              name="answer"
              autoheight
              info={{
                header: t("Q&A_header"),
                body: t("Q&A_info"),
              }}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (value) {
                      const valueLength = value.split("");
                      if (valueLength.length < 2) {
                        return Promise.reject(
                          <ErrorMessage text={"min2max30"} />
                        );
                      } else {
                        return Promise.resolve();
                      }
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <CustomInput
                autoheight
                disabled={!showAnswer}
                label={t("answer")}
                maxLength={30}
              />
            </FormItem>
          </Col>

          <Form.Item>
            <LoginButton title={t("save")} htmlType="submit" />
          </Form.Item>
        </Form>
      </FormContainer>
    );
  };

  return (
    <>
      <Loading loading={allLoading?.isLoading} />

      <LoginLayout>
        {SetNewPasswordForm()}
      </LoginLayout>
    </>
  );
};

export default ForgotPassword;

const FormContainer = styled.div`
  height: 100%;
  margin-top: 76px;
  overflow: auto;
  @media ${responsive.laptop} {
    margin-top: 59px;
  }
  .title_style {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
    @media ${responsive.laptop} {
      font-size: 20px;
    }
  }
`;
