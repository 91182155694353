import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Radio } from "antd";
import { AccountRequisiteDownload, DownloadFilesSlice } from "store/allFile";
import { useDispatch, useSelector } from "react-redux";

import {  GroupButtons } from "components";
import fileDownload from "js-file-download";
import { responsive } from "utils/responsiveUI";
import { downloadWhite } from "static/svg";

export const ExportModal = (props) => {
  const { cancelOnClick, children, style } = props;

  const [value, setValue] = useState(1);
  const requisiteFile = useSelector(
    (state) => state.downloadFiles.requisiteFile
  );

  const allLoading = useSelector((state) => state.loadings);
  const loadingFile = allLoading?.AccountRequisiteDownload;

  const requisites = useSelector((state) => state.getRequisites.requisites);
  const resetValidate = DownloadFilesSlice.actions.reset();

  const dispatch = useDispatch();

  useEffect(() => {
    requisiteFile &&
      fileDownload(
        requisiteFile,
        value === 1 ? "requisite.pdf" : "requisite.xlsx"
      );
  }, [requisiteFile]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const downloadFile = async () => {
   
    await dispatch(
      AccountRequisiteDownload({
        accountNo: requisites.accountNo,
        type: value === 1 ? "pdf" : "excel",
      })
    );
    await dispatch(resetValidate);
  };

  return (
    <Container style={style}>
      {children}

      <StyledRadio>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={1}>PDF</Radio>
          <Radio value={2}>MS Excel</Radio>
        </Radio.Group>
      </StyledRadio>
      <GroupButtons
       firstProps={{text: "Cancel", isGrey: true, onClick: cancelOnClick}}
       secondProps={{text: "Save", onClick: () => downloadFile(), icon: downloadWhite, loading: loadingFile, }}
      />
    </Container>
  );
};

const Container = styled.div`
 padding: 30px;
 max-height: 90vh;;
  overflow: auto;
  @media ${responsive.laptop} {
    .buttons{
      padding: 0;
    }
  }
`;

const StyledRadio = styled.div`
.ant-radio-group {
  display: flex;
}
  .ant-radio-wrapper {
    padding: 20px 0;
    margin-right: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #393939;
  }
  @media ${responsive.laptop} {
    .ant-radio-group {
      label.ant-radio-wrapper {
        width: 50%;
      }
    }
  }
`;
