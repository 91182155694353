import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'client';
import fileDownload from 'js-file-download';

export const DepositePdfDownload = createAsyncThunk(
  'DepositePdfDownload',
  async (dealId, thunkAPI) => {
    try {
      const data = await client({
        url: `/deposit-ms/api/v1/deposit/file/${dealId}`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "DownLoaded successfully",
        responseType: 'blob',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const LoanPdfDownload = createAsyncThunk(
  'LoanPdfDownload',
  async (dealId, thunkAPI) => {
    try {
      const data = await client({
        url: `/loan-ms/loan/schedule/${dealId}/file`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "DownLoaded successfully",
        responseType: 'blob',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const SalaryPdfDownload = createAsyncThunk(
  'SalaryPdfDownload',
  async (voen, thunkAPI) => {
    try {
      const data = await client({
        url: `salary-ms/salary/voen/${voen}/employees/download`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "DownLoaded successfully",
        responseType: 'blob',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const SalaryDownloadUploadFile = createAsyncThunk(
  'SalaryDownloadUploadFile',
  async (voen, batchId, thunkAPI) => {
    try {
      const data = await client({
        url: `salary-ms/salary/${voen}/payroll/${batchId}/download`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "DownLoaded successfully",
        responseType: 'blob',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const paymentOrderFile = createAsyncThunk(
  'paymentOrderFile',
  async ({ paymentId }, thunkAPI) => {
    try {
      const data = await client({
        url: `/operation-ms/v1/payment-order/download`,
        paymentId: paymentId,
        showErrorToast: true,
        responseType: 'blob',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const paymentPayrollFile = createAsyncThunk(
  'paymentPayrollFile',
  async ({ paymentId }, thunkAPI) => {
    try {
      const data = await client({
        url: `/salary-ms/salary/payroll`,
        paymentId: paymentId,
        showErrorToast: true,
        responseType: 'blob',
        token: true,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const OperationAsanDocFile = createAsyncThunk(
  'OperationAsanDocFile',
  async ({ paymentId }, thunkAPI) => {
    try {
      const response = await client({
        url: `/asan-imza/v1/files?payment-id=${paymentId}`,
        responseType: 'blob', // Ensure the response is treated as a blob
        showErrorToast: true,
        errorMessage: 'ERROR',
        token: false,
      });

      // Assuming the response contains the file data as a blob
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${response?.config?.headers?.['payment-id']}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return response.data; // You can return whatever you want from the API response
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const AccountRequisiteDownload = createAsyncThunk(
  'AccountRequisiteDownload',
  async ({ accountNo, type }, thunkAPI) => {
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/requisites/files/${accountNo}?file-format=${type}`,
        showErrorToast: true,
        responseType: 'blob',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const GetStatementDownloadFile = createAsyncThunk(
  'GetStatementDownloadFile',
  async (row, thunkAPI) => {
    try {
      client({
        url: `/account-ms/v1/accounts/statement/files/${row?.id}`,
        method: 'GET',
        responseType: 'blob',
        validateStatus: function (status) {
          return status === 200;
        },
      })
        .then((response) => {
          fileDownload(
            response.data,
            `application.${
              response.headers['content-type'] ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ? 'xlsx'
                : 'pdf'
            }`
          );
        })
        .catch((error) => {
          console.log('Error', error);
        });
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const GetCardStatementDownloadFile = createAsyncThunk(
  'GetCardStatementDownloadFile',
  async (row, thunkAPI) => {
    try {
      console.log(row);
      client({
        url: `/card-ms/api/v1/card/statement/files/${row?.id}`,
        method: 'GET',
        responseType: 'blob',
        validateStatus: function (status) {
          return status === 200;
        },
      })
        .then((response) => {
          fileDownload(
            response.data,
            `application.${
              response.headers['content-type'] ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ? 'xlsx'
                : 'pdf'
            }`
          );
        })
        .catch((error) => {
          console.log('Error', error);
        });
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
