import React from 'react';
import styled from "styled-components";
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
// components
import OTPForm from './OtpForm';
import AsanForm from './AsanForm';
import BankingInfo from '../Footer/BankingInfo';
import { blockGoBackBtn } from 'utils/shortFunctions';
import { responsive } from 'utils/responsiveUI';

const { TabPane } = Tabs;

const LogIn = () => {

  const { t } = useTranslation();

  function callback(key) {
    // console.log('key', key);
  }

  blockGoBackBtn();

  return (
    <>
      <CenterContainer>

        <ContainerTab>
          <AntTabs
            defaultActiveKey="1"
            onChange={callback}
          >
            <TabPane tab={t('viaASAN')} key="1">
              <FormContainer>
                <AsanForm />
              </FormContainer>
            </TabPane>
            <TabPane tab={t('viaOTP')} key="2">
              <FormContainer>
                <OTPForm />
              </FormContainer>
            </TabPane>
          </AntTabs>
        </ContainerTab>
        <BankingInfo />
      </CenterContainer>

    </>
  )
}

export default LogIn;

const ContainerTab = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
`

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  height: 100%;
  margin-top: 76px;
  overflow: auto;
  @media ${responsive.laptop} {
    margin-top: 59px;
  }
`

const AntTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-tab,
  .ant-tabs-nav-container {
    font-family: Proxima Nova;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    height: 100% !important;
    z-index: -10;
    background: #393939;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #393939;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  div.ant-tabs-nav::before {
    border-bottom: 1px solid transparent;
  }

  .ant-tabs-nav {
    height: 60px;
    padding: 6px;
    background-color: #F8F8F8;
    border-radius: 10px;
    color: #75787B;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFFFFF;
  }

  .ant-tabs-tab {
    width: 50%;
    display: flex;
    margin: 0;
    justify-content: center;

    &:hover {
      color: #616173
    }
  ;
  }
`

const FormContainer = styled.div`

`

