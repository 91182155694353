import React from "react";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";

export const ResponsiveComponent = ({ children, isMobile }) => {
  return (
    <ResponsiveComponentStyle isMobile={isMobile}>
      {children}
    </ResponsiveComponentStyle>
  );
};

const ResponsiveComponentStyle = styled.div`
  display: ${(props) => (props.isMobile ? "none" : "block")};

  @media ${responsive.laptop} {
    display: ${(props) => (props.isMobile ? "block" : "none")};
  }
`;
