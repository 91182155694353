import React from 'react';
import styled from 'styled-components';
import {CountdownCircle} from '../'

export const AsanSignTimer = ({apiActivation, duration, resendEvent, code}) => {

    return (
        <StyledContainer>
            <p>Description text description text Description text description text</p>
            <span className='codeTextStyle'>Validation Code: {code}</span>
            <CountdownCircle
                apiActivation={apiActivation}
                resendEvent={resendEvent}
                duration={duration}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  p {
    font-size: 14px;
    text-align: center;
    color: #231F20;
  }

  .codeTextStyle {
    margin-bottom: 30px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #231F20;
  }

`
