import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

export const LanguageDictonary = createAsyncThunk(
  "LanguageDictonary",
  async (config, thunkAPI) => {
    const data = await client({
      url: "common/lang",
      showSuccessToast: true,
      showErrorToast: true,
      succesMessage: "Language info fetched",
      token: false             //header-da token olmasın
    });
    return data;
  }
);