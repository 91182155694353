import React from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';

//expandPadding - expan-a padding veririrk.Default olaraq kənarlarında padding yoxdur
export const Table = (props) => {
  const {
    columns,
    dataSource,
    expandPadding,
    shadow = true, //tabel-ın ətrafında kölgənin olması
    borderRadius,
    isOverFlow = true,
    ...rest
  } = props;
  return (
    <Container {...{ borderRadius, shadow, expandPadding, isOverFlow }}>
      <AntTable {...rest} {...{ columns, dataSource }} />
    </Container>
  );
};

const Container = styled.div`
  border-radius: ${(props) => (props.borderRadius ? '16px' : '16px 16px 0 0')};
  overflow-x: ${(props) => (props.isOverFlow ? 'auto' : 'hidden')};
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1); */
  .ant-table-cell:nth-child(1) {
    padding: 0;
    padding-left: ${({ expandPadding }) => (expandPadding ? expandPadding : '0')};
  }
  .ant-table {
    border-radius: ${(props) => (props.borderRadius ? '16px' : '16px 16px 0 0')};
  }
  .ant-table-tbody {
    min-height: 140px;
  }
  .ant-table-tbody > tr:last-child > td {
    border: none !important;
  }
  .ant-table-thead > tr > th {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #a1a1aa;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 16px 16px 0 0;
  }
  tr {
    border-bottom: none !important;
  }
`;
