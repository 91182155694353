
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

export function useCanceled() {

const history=useHistory()
  const [isCancelled, setIsCancelled] = useState(false);
  const location = useLocation();

  const handleCancel = () => {
    setIsCancelled(true);
    history.push({
      pathname: "/",
      prevPath: location.pathname,
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !isCancelled) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isCancelled]);

  return { handleCancel };
}