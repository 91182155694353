import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";


const voen = localStorage.getItem("voen")


//all loans
export const GetLoans = createAsyncThunk(
  "GetLoans",
  async (config, thunkAPI) => {
    try {
      //VOEN 8300955032 || 2000185312 || 2303475831 || 2000285592
      const data = await client({
        url: `/loan-ms/loan/list/${voen}`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: 'Loans were successfully loaded',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

//get loan info
export const GetLoanInfo = createAsyncThunk(
  "GetLoanInfo",
  async (dealId, thunkAPI) => {
    try {
      const data = await client({
        url: `/loan-ms/loan/detail/${dealId}`,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

//loan payment schedule
export const PaymentScheduleLoan = createAsyncThunk(
  "PaymentScheduleLoan",
  async (info, thunkAPI) => {
    const { dealId, paymentStatus } = info;
    try {
      const data = await client({
        url: `/loan-ms/loan/schedule/${dealId}?paymentStatus=${paymentStatus}`,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
