

import { AuthLayout, LoginContainer, ScrollStyle } from 'components'
import React from 'react'
import VideoContent from '../../containers/Auth/VideoContent'
import styled from 'styled-components'
import { responsive } from 'utils/responsiveUI'
import Footer from '../../containers/Auth/Login/Footer'
import LoginHeader from '../../containers/Auth/Login/Header'

export const LoginLayout = ({children}) => {
  return (
    <AuthLayout>
    <div className="carousel_container">
      <VideoContent />
    </div>
    <LoginContainer>
      <LoginStyled>
        <LoginHeader />
       
       {children}

        <Footer />
      </LoginStyled>
    </LoginContainer>
  </AuthLayout>
  )
}

export default LoginLayout;

const LoginStyled = styled.div`
  position: relative;
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 100vh;
  ${ScrollStyle}
   @media ${responsive.laptop} {
    padding: 33px 18px;
  }
`;