import {createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "client";
import { ErrorMessage } from "utils/errorMessage";



//Inside Country Propses
export const InsideCountryProses = createAsyncThunk(
    "InsideCountryProses",
    async (insideCountryBody, thunkAPI) => {
        try {
            const data = await client({
                url: '/standard-transfers/v1/payment/process', 
                data: JSON.stringify(insideCountryBody),
                method: 'POST',
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: 'paymentSuccess',
                token: false,
            })
            return data
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

//Inside Country 
export const InsideCountryValidate = createAsyncThunk(
    "InsideCountryValidate",
    async (insideCountryBody, thunkAPI) => {
   
        try {
            const data = await client({
                url: '/standard-transfers/v1/payment/validate', 
                data: JSON.stringify(insideCountryBody),
                method: 'POST',
                showErrorToast: true,
                succesMessage: 'paymentSuccess',
                token: false,
            })
            return data
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

// Receiver bank code
export const GetBankCode = createAsyncThunk(
    "GetBankCode",
    async (code, error, thunkAPI) => {
        try {
            const data = await client({
                url: `/common-ms/bank-info/find?bankCode=${code}`, 
                showErrorToast: true,
                errorMessage: ErrorMessage(error),
                token: false,
            })
            return data
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);