import React, {forwardRef} from 'react';
import styled from 'styled-components';
import {Table} from "antd";
import {table_th_action} from "static/svg";
import  {ScrollStyle}  from 'components';

//info - listde görünəcək məlumatlar
//borderBottom - əyər true olsa ümumi listin sonunda border görünəcək
export const InfoList = forwardRef((props, ref) => {
    const {info, borderBottom, mHeight, scrollY} = props;
    return (
        <Container>
            <List
                ref={ref}
                borderBottom={borderBottom}
                mHeight={mHeight}
                scrollY={scrollY}
            >
                {info && info.map((item, index) => {
                    const {title, value, image} = item
                    return (
                        <li key={index}>
                            <Title>{title}</Title>
                            <Value>{image && <img src={image} alt=''/>}{value}</Value>
                        </li>
                    )
                })}
            </List>
        </Container>
    )
})

export const InfoListObj = forwardRef((props, ref) => {
    const {info, borderBottom} = props;
    const renderedListObj = () => (Object.entries(info).map(([key, value]) => (
        <li key={key}>
            <Title>{key}</Title>
            <Value>{value}</Value>
        </li>
    )))
    return (
        <Container>
            <List
                ref={ref}
                borderBottom={borderBottom}
            >
                {info && renderedListObj()}
            </List>
        </Container>
    )
})

export const InfoListTable = forwardRef((props, ref) => {
    const {info, borderBottom, columns, listData, source} = props;
   
    const renderedListHtml = (
        <>
            {
                listData?.map((item, idx) => (
                    <li key={idx}>
                        <Title>{item?.title}</Title>
                        <Value>{item?.value}</Value>
                    </li>)
                )
            }
            <StyledTable
                columns={columns}
                dataSource={source}
                pagination={false}
                // pagination={{hideOnSinglePage: true}}
                // scroll={{y: 325}}
                showHeader={true}
            />
        </>
    )
    return (
        <Container>
            <List
                ref={ref}
                borderBottom={borderBottom}
            >
                {info && renderedListHtml}
            </List>
        </Container>
    )
});

const StyledTable = styled(Table)`
  margin-top: 40px;
`

const Container = styled.div`
  //height: 100%;
  overflow-y: scroll;
  max-height: 380px;
  ${ScrollStyle}
    //max-height: ${props => props.mHeight};
    //overflow-y: ${props => props.scrollY && 'scroll'};
`
const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:nth-child(even) {
      background: #F7F8FD;
    }

    &:last-child {
      border-bottom: ${props => props.borderBottom && '1px solid #D7D8D6'};
    }
  }
`
const Title = styled.div`
  width: 245px;
  padding-left: 23px;
  font-family: Proxima Nova;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #9696AA;
`
const Value = styled.span`
  font-family: Proxima Nova;
  padding-left: 5px;
  padding-right: 23px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;

  img {
    padding-right: 10px;
  }
`