import { AutoComplete } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { close, mapnavigation } from "static/svg";
import { useDispatch } from "react-redux";
import { getBranchById, getTerminalById, getAtmById } from "store/maps";
import styled from "styled-components";

const AutoCompleteInput = ({ autoComplete, acitveKey, allowClear, newClearIcon }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (search === "") {
      setOptions(createList(autoComplete));
    } else {
      setOptions(createList(autoComplete?.filter(item => {
        return (acitveKey === "3"
          ? item?.title?.toLowerCase()?.includes(search)
          : item?.name?.toLowerCase()?.includes(search)) || item?.address?.toLowerCase()?.includes(search);
      })));
    }

  }, [autoComplete, search, acitveKey])

  let closeSearch = () => {
    setSearch('');
  };


  const createList = (list) => {

    return list?.slice(0, 15)?.map((item, index) => {
      return {
        value: item?.id,
        label: (
          <div
            style={{
              width: "100%",
            }}
            key={item.id}
            onClick={() =>
              acitveKey === "1"
                ? dispatch(getBranchById(item.id))
                : acitveKey === "2"
                  ? dispatch(getTerminalById(item.id))
                  : dispatch(getAtmById(item.id))
            }
          >
            <div style={{ color: "#393939", fontWeight: "700" }}>
              {item?.name}
            </div>
            <div

              style={{
                width: "100%",
                marginTop: "20px",
                borderBottom: "1px solid #E3E3E3",
                paddingBottom: "20px",
                display: "flex",
                alignItems: "start",
                whiteSpace: "pre-line",
              }}
            >
              <img src={mapnavigation} alt="map" style={{ marginRight: "10px", marginTop: "3px" }}></img>
              {item?.address}
            </div>
          </div>
        ),
        name: item?.name?.toLowerCase(),
      }
    });
  }

  return (
    <>
      <AutoComplete
        style={{
          minWidth: 300,
        }}
        className="auto-complete-container"
        options={options}
        placeholder={t('Search')}
        listHeight="700px"
        value={search}
        onChange={(value) => {
          if (typeof value === 'string') {
            setSearch(value?.toLowerCase());
          }
        }}
        allowClear={false}
      />
      {allowClear && newClearIcon ? (
        <CloseIcon onClick={closeSearch}>
          <div className="closeds_icon">
            <img src={close} />
          </div>
        </CloseIcon>
      ) : null}
    </>
  );
};

const CloseIcon = styled.div`
  .closeds_icon {
    position: absolute;
    top: 12px;
    right: 0;
    @media (max-width: 390px) {
      right: 30px
    }
  }
`;

export default AutoCompleteInput;
