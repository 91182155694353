import React from 'react';
import styled from 'styled-components';
import {Result} from 'antd'
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    //for translation
    const {t} = useTranslation();

    return (
        <StyledNotFound>
            <Result
                status="404"
                title="404"
                subTitle={t('notFoundContent')}
            />
        </StyledNotFound>
    )
}

const StyledNotFound = styled.div`
  height: 100%;
  width: 100%;
  background-color: 'orange';
  color: 'black';
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`
