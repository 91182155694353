import { StyledLine } from "components/StyleComponent";
import React from "react";
import styled from "styled-components";

export const MobileCardBordered = ({ topChild, bottomChild, onClick, noLine }) => {
  return (
    <StyledContainer onClick={onClick} >
      <StyledContent>
        <StyledTop>
          {topChild}
        </StyledTop>
      {noLine ? null : <StyledLine />}  
        <StyledBottom>
          {bottomChild}
        </StyledBottom>
      </StyledContent>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
cursor: pointer;
margin-bottom: 14px;
`;
const StyledContent = styled.div`


padding:0 16px;
background: #FFFFFF;
border: 1px solid #E3E3E3;
border-radius: 12px;
`;
const StyledTop = styled.div``;
const StyledBottom = styled.div``;
