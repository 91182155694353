import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Modal } from 'antd';
import { responsive } from 'utils/responsiveUI';
import { close, small_logo, header_drop_down, Exit } from 'static/svg';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { GetCompanyList, logout } from 'store/auth';
import { setVoen } from '../../store/authInfo';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

export const DropdownMenu = () => {
  const dispatch = useDispatch();
  const { companyList } = useSelector((state) => state.getCompanyList);

  const { t } = useTranslation();
  const { token, voen } = useSelector((state) => state.authInfo);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState({
    id: null,
    img: '-',
    companyName: null,
    name: null,
    voen: voen,
  });

  const [selectedId, setSelectedId] = useState();
  const [randomColors, setRandomColor] = useState([
    '#BA68C8',
    '#BA68C8',
    '#FF8A65',
    '#F06292',
    '#FFD54F',
    '#64B5F6',
  ]);

  useEffect(() => {
    token && dispatch(GetCompanyList(token));
  }, [dispatch, token]);

  const [companyListCustom, setCompanyListCustom] = useState();
  useEffect(() => {
    if (companyList) {
      const listData = companyList?.map(
        ({ companyName, voen, positionDescription }, index) => ({
          id: index,
          name: companyName || 'Company',
          img: '',
          companyName: t(`user_position_${positionDescription}`),
          voen: voen,
        })
      );
      setCompanyListCustom(listData);
    }
  }, [companyList, t]);

  useEffect(() => {
    if (companyListCustom) {
      companyListCustom.forEach((item) => {
        if (item?.voen === voen) {
          setDefaultSelected({
            id: item?.id,
            img: item?.img,
            companyName: item?.companyName,
            name: item?.name,
            voen: voen,
          });
        }
      });
    }
  }, [companyListCustom]);

  useEffect(() => {
    defaultSelected && dispatch(setVoen({ voen: defaultSelected?.voen }));
  }, [defaultSelected, voen]);

  useEffect(() => {
    const color = randomColors[Math.floor(Math.random() * randomColors.length)];
    setRandomColor(color);
  }, []);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // web
  const handleItemSelectModal = ({ key }) => {
    window.location.reload();
    for (let k in companyListCustom) {
      if (k === key) {
        setDefaultSelected({
          id: companyListCustom[key].id,
          img: '',
          companyName: companyListCustom[key].companyName,
          name: companyListCustom[key].name,
          voen: companyListCustom[key].voen,
        });
      }
    }
  };
  // Mobile
  const handleModalItem = (key) => {
    window.location.reload();
    for (let k in companyListCustom) {
      if (Number(k) === key) {
        setDefaultSelected({
          id: companyListCustom[key].id,
          img: '',
          companyName: companyListCustom[key].companyName,
          name: companyListCustom[key].name,
          voen: companyListCustom[key].voen,
        });
      }
    }
    setIsModalVisible(false);
  };

  const handleLogOut = () => {
    dispatch(logout());
    window.location.reload();
  };

  const logOutMenu = (
    <NavLink to="/" onClick={handleLogOut}>
      <StyledItem>
        <Icon component={() => <img src={Exit} alt="exit" />} />
        <span>{t('Log out')}</span>
      </StyledItem>
    </NavLink>
  );

  const menu = (
    <Menu selectable onClick={handleItemSelectModal}>
      {companyListCustom &&
        companyListCustom?.map((item) => (
          <Menu.Item key={item.id}>
            <StyleImg style={{ backgroundColor: randomColors }}>
              {item?.img || `${item?.name.split('')[0].split('')[0] || ''}`}
            </StyleImg>
            <StyledText>
              <label htmlFor="x" title={item?.name}>
                <h3>{item?.name}</h3>
              </label>
              <p> {item?.companyName}</p>
            </StyledText>
          </Menu.Item>
        ))}
      {logOutMenu}
    </Menu>
  );
  return (
    <>
      <StyledMobile isMobile>
        <Dropdown overlay={menu} trigger={['click']}>
          <a href="#" className="ant-dropdown-link">
            <StyleImg style={{ backgroundColor: randomColors }}>
              {defaultSelected?.img ||
                `${defaultSelected?.name?.split('')[0].split('')[0]}`}
            </StyleImg>
            <StyledText>
              <label title={defaultSelected?.name}>
                <h3>{defaultSelected?.name}</h3>
              </label>
              <p> {defaultSelected?.companyName}</p>
            </StyledText>
            <img src={header_drop_down} className="down" alt="header_drop_down" />
          </a>
        </Dropdown>
      </StyledMobile>
      <StyledMobile>
        <a onClick={showModal} href="#" className="ant-dropdown-link">
          <StyleImg style={{ backgroundColor: randomColors }}>
            {defaultSelected?.img ||
              `${defaultSelected?.name?.split('')[0].split('')[0]}`}
          </StyleImg>
        </a>
      </StyledMobile>
      <Modal
        title={<img src={small_logo} alt="expert" />}
        visible={isModalVisible}
        footer={null}
        closeIcon={<img src={close} alt="close" />}
        onCancel={handleCancel}
      >
        {companyListCustom &&
          companyListCustom?.map((item, index) => (
            <StyledModal
              style={{ background: selectedId === item.id ? '#F2F2F2' : '' }}
              isModalMobile
              onClick={() => handleModalItem(index)}
              key={item.voen}
            >
              <StyleImg style={{ backgroundColor: randomColors }}>
                {item?.img || `${item?.name.split('')[0].split('')[0] || ''}`}
              </StyleImg>
              <StyledText isModalMobile>
                <label title={item.name}>
                  <h3>{item.name}</h3>
                </label>
                <p> {item.companyName}</p>
              </StyledText>
            </StyledModal>
          ))}
        {logOutMenu}
      </Modal>
    </>
  );
};
const StyledMobile = styled.div`
  display: ${(props) => (props.isMobile ? 'block' : 'none')};
  @media ${responsive.laptop} {
    display: ${(props) => (props.isMobile ? 'none' : 'block')};
  }
`;
const StyleImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;

  img {
    width: 100%;
    height: 100%;
  }

  font-family: 'Proxima Nova';
  font-size: 16px;
`;
const StyledText = styled.div`
  display: block;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;

  p {
    line-height: 0;
    font-weight: 500;
    font-size: 14px;
    color: #8e8e8e;
    font-family: 'Proxima Nova';
    cursor: pointer;
  }

  h3 {
    line-height: 1.2;
    font-weight: 600;
    font-size: 18px;
    color: #1c1c1c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Proxima Nova';
    max-width: ${(props) => (props.isModalMobile ? '150px' : '370px')};
  }
`;
// Company Menu Component Responsive
const StyledModal = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isModalMobile ? '10px' : '6px 10px')};
  margin: 10px 0;
  border-radius: 8px;

  &:hover {
    background: #f2f2f2;
  }

  /* &:nth-child(1) {
    &::after {
      position: relative;
      content: '';
      position: absolute;
      width: 85%;
      height: 1px;
      background-color: #e3e3e3;
      left: 7%;
      top: 143px;
    }
  } */

  &:nth-child(2) {
    margin-top: 16px;
  }
`;

const StyledItem = styled.div`
  border-top: 2px solid #d9d9d9;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    color: #1c1c1c;
    font-weight: 600;
    font-size: 15px;
  }
`;
