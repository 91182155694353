import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducers from "./reducer";

const middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
];

const preloadedState = {};

const store = configureStore({
  reducer: rootReducers,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
  enhancers: []
});

export const dispatch = store.dispatch;
export const getState = store.getState;

export default store;

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were automatically composed together
