import { createSlice } from '@reduxjs/toolkit';
import { customersRegistr, getCustomerCheck, getCustomersData } from './api_action';

export { getCustomerCheck, getCustomersData };

const initialState = {
  error: null,
  customerCheckId: {},
  customersData: [],
  customersRegistrId: {},
};

const anipayCabinetSlice = createSlice({
  name: 'getCustomer',
  initialState,
  reducers: {},
  extraReducers: {
    [getCustomerCheck.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.customerCheckId = data;
    },
    [getCustomerCheck.rejected]: (state, action) => {
      const error = action.payload;
      state.error = error;
    },
    [getCustomersData.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.customersData = data;
    },

    [customersRegistr.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.customersRegistrId = data;
    },
    [customersRegistr.rejected]: (state, action) => {
      const error = action.payload;
      state.error = error;
    },
  },
});

export const anipayCabinetReducer = anipayCabinetSlice.reducer;
