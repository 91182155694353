import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";


export const GetBudgetCodeAndOrgList = createAsyncThunk(
    "GetBudgetCodeAndOrgList",
    async (config, thunkAPI) => {
        try {
            const budgetCodeList = await client({
                url: "/payment/budget/codes",
                showErrorToast: true,
                token: false

            });

            const budgetOrgList = await client({
                url: `/payment/budget/organizations`,
                showErrorToast: true,
                token: false
            });


            return { budgetCodeList, budgetOrgList };

        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }

    }
);
