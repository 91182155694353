import React from "react";
import styled from "styled-components";
import {responsive} from "utils/responsiveUI";
import {successCheckIcon} from "static/svg";
import {LoginButton, LoginLayout} from "components";
import {useTranslation} from "react-i18next";

const SuccessPassword = ({text}) => {
    const { t } = useTranslation();
    const to = () => {
      window.location.href = "/login";
    }

    return (
      <LoginLayout>
        <Container>
            <StyledCheckContnet>
                <img src={successCheckIcon} alt="checkIcon"/>
                <h4>{t(text)}</h4>

                <LoginButton onClick={() => to()} title={t("Go to Login")}/>
            </StyledCheckContnet>
        </Container>
      </LoginLayout>
        


    );
};

export default SuccessPassword;

const Container = styled.div`
  margin-top: 76px;
  height: 100%;
  background-color: #fcfcfc;
  text-align: left;
  @media ${responsive.laptop} {
      margin-top: 59px;
      text-align: center;
    }
`;

const StyledCheckContnet = styled.div`
 img{
  margin-bottom: 32px;
 }
  h4 {
    font-weight: 700;
    font-size: 28px;
    color: #1c1c1c;
    margin-bottom: 32px;
  }

  
  @media ${responsive.laptop} {
    padding: 30px;
    h4 {
      font-size: 32px;
    }
  }
`;
