import React from 'react';
import styled from 'styled-components';
import { GroupButtons, Loading } from 'components';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

export const AgreementModal = (props) => {
  const { cancelOnClick, mainOnClick, text, cofrimLabel } = props;

  //loading
  const allLoading = useSelector((state) => state.loadings);

  return (
    <Container>
      <Loading loading={allLoading?.isLoading} />
      <InputContainer>
        <Row>
          <Col span={24} className="general">
            {text}
          </Col>
        </Row>
      </InputContainer>

      <GroupButtons
        firstProps={{ text: 'No', isGrey: true, onClick: cancelOnClick }}
        secondProps={{ text: cofrimLabel || 'Yes', onClick: () => mainOnClick() }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 25px;
`;
const InputContainer = styled.div`
  padding: 0 0 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #63666a;
`;
