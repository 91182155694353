import { createSlice } from "@reduxjs/toolkit";
import { SalaryProcess, UploadFileSalary, ValidationSalary } from "./api_action";
export { SalaryProcess, UploadFileSalary, ValidationSalary};

const initialState={
    uploaded: null,
    validation:null,
    process:null,
    error:null,
    
}
export const SalarySlicer = createSlice({
    name:'SalarySlicer',
    initialState,
    reducers:{
        reset:()=>initialState
    },
    extraReducers:{
    
        [ValidationSalary.fulfilled]:(state, action)=>{
            state.validation=action.payload;
        },
        [ValidationSalary.rejected]:(state, action)=>{
            state.error=action.payload;
        },
        [SalaryProcess.fulfilled]:(state, action)=>{
            state.process=action.payload;
        },
        [SalaryProcess.rejected]:(state,action)=>{
            state.error=action.payload;
        },
       
    }
})

export const SalaryReducer = SalarySlicer.reducer;


export const SalaryUploadSlicer = createSlice({
    name:'SalaryUploadSlicer',
    initialState,
    reducers:{
        reset:()=>initialState
    },
    extraReducers:{
        [UploadFileSalary.fulfilled]:(state, action)=>{
            state.uploaded=action.payload.data;
        },
        [UploadFileSalary.rejected]:(state, action)=>{
            state.error=action.payload;
        },
      
    }
})

export const SalaryUploadReducer = SalaryUploadSlicer.reducer;