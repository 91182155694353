//reverse func for date
import React from 'react';
import JSpdf from 'jspdf';
import h2c from 'html2canvas';
import * as fs from 'fs';

export const reverseDate = (date, splitSimvol) =>
  date.toString().split(splitSimvol).reverse().join(splitSimvol);

export const mobileNumberParser = (number) => {
  const numberPattern = /\d+/g;
  return `+${number.match(numberPattern).join('').toString().substring(0)}`;
};

export const PdfExport = (elem, pdfName) =>
  h2c(elem).then((canvas) => {
    elem.scrollIntoView();
    const img = canvas.toDataURL('image/png', 1);

    let imgWidth = 150;
    let pageHeight = 150;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    const pdf = new JSpdf('p', 'mm');
    let position = 10;

    pdf.addImage(img, 'PNG', 30, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(img, 'PNG', 30, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    pdf.save(pdfName);
  });

export const isSameOrigin = (url) =>
  new URL(url, window.location.href).origin === window.location.origin;

export const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;
export const isMobile = navigator.userAgent.includes('Mobi');

export const capitalize = (str) => str.charAt(0)?.toUpperCase() + str.slice(1);

export const decodeBase64 = (base64) =>
  new TextDecoder().decode(
    new Uint8Array(
      atob(base64)
        .split('')
        .map((char) => char.charCodeAt(0))
    )
  );

export const encodeBase64 = (str) =>
  btoa(
    Array.from(new TextEncoder().encode(str))
      .map((n) => String.fromCharCode(n))
      .join('')
  );

export const getStatus = (user) => {
  return user?.userCompanyStatus === 'ACTIVE'
    ? user?.userStatus
    : user?.userCompanyStatus;
};

export const base64_encode = (fileOrigin) => {
  /** @type {Buffer} */
  const file = fs.readFileSync(fileOrigin);
  return Buffer.from(file).toString('base64');
};

export const xor = (...args) => args.filter(Boolean).length === 1;

export const hideEmail = (email) => {
  const [name, domain] = email.split('@');
  return `${name[0]}${new Array(name.length - 1).join('*')}${
    name[name.length - 1]
  }@${domain}`;
};

export const objectIsEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] != '') return false;
  }
  return true;
};

export const renderTimer = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const seconds_ = seconds % 60;
  return (
    (mins < 10 && '0' + mins.toString()) +
    ':' +
    (seconds_ < 10 ? '0' + seconds_.toString() : seconds_.toString())
  );
};

export const blockGoBackBtn = () => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };
};

export const starredIBAN = (cardNumber) => {
  const starredCardNumber =
    cardNumber.length === 16
      ? cardNumber.replace(/^(\d{4})(\d{2})(\d{6})(\d{4})/, '$1 $2** **** $4')
      : null;
  return starredCardNumber;
};

export const cardFormat = (value) => {
  if (value) {
    const valueSplit = value.match(/.{1,4}/g);
    const valueJoin = valueSplit.map((grup) => grup + '\u00A0').join(' ');

    return valueJoin;
  }
};

export const negativeNum = (num) => {
  const sign = Math.sign(num);

  const signText =
    sign === -1 ? (
      <span style={{ color: '#FF5252' }}>-</span>
    ) : (
      <span style={{ color: '#108850' }}>+</span>
    );
  return signText;
};

export const ListOfPayment = (paymentTypeSecond, t) => {
  return Object.entries(paymentTypeSecond)
    .filter(
      (item) =>
        item[0] !== 'inside_country_azips' &&
        item[0] !== 'Conversion_buy' &&
        item[0] !== 'swift_ru'
    )
    .map((item, index) => ({
      id:
        item[0] === 'inside_country'
          ? `${'XOHKS'}, ${'AZIPS'}`
          : item[0] === 'Conversion'
          ? `${'CCY.BUY'}, ${'CCY.SELL'}`
          : item[0] === 'swift'
          ? `${'SWIFT.MT103.RU'}, ${'SWIFT.MT103'}`
          : item[1],
      name: t(`${item[0]}`),
    }));
};

export const groupByDate = (dataList, dateKey) => {
  const groupedDate = {};
  dataList.forEach((statement) => {
    const date = statement[dateKey];
    if (!groupedDate[date]) {
      groupedDate[date] = [];
    }
    groupedDate[date].push(statement);
  });
  const newList = Object.keys(groupedDate).map((createDate, index) => ({
    createDate,
    dataList: groupedDate[createDate],
    id: index,
  }));
  return newList;
};

export const customValidations = (validations, value) =>
  validations?.some((validationFn) => !validationFn(value));
