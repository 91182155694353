import loadingsReducer from './loadings';
import usersReducer from './users';
import langReducer, { languageReducer } from './language';
import authInfoReducer from './authInfo';
import {
  getAniPayListReducer,
  getCheckVoenReducer,
  getCountryListReducer,
  getLegalTypeListReducer,
  getPaymentPurposeListReducer,
} from './common';
import {
  getLoanInfoReducer,
  getLoansReducer,
  paymentScheduleLoanReducer,
} from './loans';
import {
  getDepositInfoReducer,
  getDepositsReducer,
  paymentScheduleDepositReducer,
} from './deposits';
import {
  changeCardStatusReducer,
  getCardsReducer,
  renameCardReducer,
  statementCardReducer,
} from './cards';
import {
  getAccountNameReducer,
  getAccountsPaymentsReducer,
  getAccountsReducer,
  GetRequisitesReducer,
  renameAccountReducer,
  statementAccountReducer,
} from './accounts';
import { autoComPaymentTyprReducer } from './autoComPaymentType';
import { GetBudgetCodeAndOrgListReducer } from './getBudgetCodeAndOrgList';
import { getBankInfoByCodeReducer } from './getBancInfoByCode';
import { getAniPayTypeInfoReducer } from './getAniPayTypeInfo';
import {
  asanAuthenticateReducer,
  asanSignReducer,
  forgotPasswordCheckAnswerReducer,
  forgotPasswordEmailReducer,
  getCompanyListReducer,
  GetPermissionsListReducer,
  getQuestionsReducer,
  logOutReducer,
  otpConfirmationReducer,
  otpForgotPasswordReducer,
  otpForgotPasswordVerificationReducer,
  otpLoginSMSReducer,
  otpSignReducer,
  otpVerificationReducer,
  refreshTokenReducer,
  validationTokenReducer,
} from './auth';
import { getExchangeInfoReducer } from './getExchange';
import { checkPayrollFileReducer } from './checkPayrollFile';
import { downloadeEmployeListReducer } from './downloadEmployeList';
import { getBankCodeReducer, insideCountryReducer } from './insideCountry';
import { GetBtwAccountsReducer } from './btwAccounts';
import { withinBankReducer } from './withinBank';
import { anyPayReducer } from './anypay';
import { operationHistoryReducer } from './History';
import {
  DocumentSignSelectedReducer,
  DocumentToBeSignReducer,
} from './documentToBeSign';
import { downloadFilesReducer } from './allFile';
import { templateReducer } from './templates';
import { getBalanceReducer } from './balance';
import { internationalReducer } from './international';
import { OrderReducer } from './orders';
import { conversionReducer } from './conversion';
import {
  BudgetReducer,
  GetBudgetClassificationReducer,
  GetBudgetIbanReducer,
  GetBudgetOrgReducer,
} from './budget';
import { StateTreasuryReducer } from './stateTreasury';
import { SalaryReducer, SalaryUploadReducer } from './salary';
import { paymentListReducer } from './payments';
import { guaranteeReducer } from './guarantee';
import { mapBranch } from './maps';
import { StatementReducer } from './statement';
import { anipayCabinetReducer } from "./anipayCabinet";

const rootReducers = {
  loadings: loadingsReducer,
  usersIB: usersReducer,
  lang: langReducer,
  authInfo: authInfoReducer,
  setLang: languageReducer,
  // commonInfo: commonInfoReducer,
  // mapInfo: mapInfoReducer,
  paymentPurpose: getPaymentPurposeListReducer,
  legalType: getLegalTypeListReducer,
  countryList: getCountryListReducer,
  getCheckVoen: getCheckVoenReducer,
  getAniPayList: getAniPayListReducer,
  getLoans: getLoansReducer,
  getLoanInfo: getLoanInfoReducer,
  paymentScheduleLoan: paymentScheduleLoanReducer,
  getDeposits: getDepositsReducer,
  getDepositInfo: getDepositInfoReducer,
  paymentScheduleDeposit: paymentScheduleDepositReducer,
  getCards: getCardsReducer,
  renameCard: renameCardReducer,
  changeCardStatus: changeCardStatusReducer,
  statementCards: statementCardReducer,
  getAccounts: getAccountsReducer,
  getAccountsPayments: getAccountsPaymentsReducer,
  getRequisites: GetRequisitesReducer,
  getAccountDetails: getAccountNameReducer,
  renameAccount: renameAccountReducer,
  statementAccount: statementAccountReducer,
  autoComPaymentType: autoComPaymentTyprReducer,
  getBudgetCodeAndOrgList: GetBudgetCodeAndOrgListReducer,
  getBankInfoByCode: getBankInfoByCodeReducer,
  getAniPayTypeInfo: getAniPayTypeInfoReducer,
  asanSign: asanSignReducer,
  asanAuthenticate: asanAuthenticateReducer,
  validationToken: validationTokenReducer,
  otpLoginSMS: otpLoginSMSReducer,
  otpVerification: otpVerificationReducer,
  otpSign: otpSignReducer,
  otpConfirmation: otpConfirmationReducer,
  otpForgotPassword: otpForgotPasswordReducer,
  otpForgotPasswordVerification: otpForgotPasswordVerificationReducer,
  getQuestions: getQuestionsReducer,
  GetPermissionsList: GetPermissionsListReducer,
  getCompanyList: getCompanyListReducer,
  forgotPasswordCheckAnswer: forgotPasswordCheckAnswerReducer,
  forgotPasswordEmail: forgotPasswordEmailReducer,
  refreshToken: refreshTokenReducer,
  logout: logOutReducer,
  getExchange: getExchangeInfoReducer,
  checkPayrollFile: checkPayrollFileReducer,
  downloadeEmployeList: downloadeEmployeListReducer,
  insideCountry: insideCountryReducer,
  getBankCode: getBankCodeReducer,
  betweenMyAccounts: GetBtwAccountsReducer,
  withinBank: withinBankReducer,
  anypay: anyPayReducer,
  anipayCabinet: anipayCabinetReducer,
  operationHistory: operationHistoryReducer,
  documentToBeSign: DocumentToBeSignReducer,
  documentSignSelected: DocumentSignSelectedReducer,
  downloadFiles: downloadFilesReducer,
  templates: templateReducer,
  getBalance: getBalanceReducer,
  international: internationalReducer,
  orders: OrderReducer,
  conversion: conversionReducer,
  budget: BudgetReducer,
  getBudgetIban: GetBudgetIbanReducer,
  getBudgetOrg: GetBudgetOrgReducer,
  getBudgetClassification: GetBudgetClassificationReducer,
  stateTreasury: StateTreasuryReducer,
  salaryupload: SalaryUploadReducer,
  salary: SalaryReducer,
  paymentList: paymentListReducer,
  guarantee: guaranteeReducer,
  mapreducer: mapBranch,
  statement: StatementReducer,
  documentSignSelect: DocumentSignSelectedReducer,
};

export default rootReducers;
