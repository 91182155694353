import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LngSelect from './lngSelect';
import { useDispatch } from "react-redux";
import { setLanguage } from "store/language";

const ChangeLang = () => {
  //local lang
  const localLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'az';
  //translate
  const { i18n } = useTranslation();
  //states
  const [lang, setLang] = useState(localLang); //initialize
  //services
  const dispatch = useDispatch();

  useEffect(() => {
    setLang(localLang);
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  }, [])

  const handleButton = (e) => {
    const lang = e
    // const lang = e.target.value; in LngButton
    setLang(lang);
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  }

  return (
    <LngSelect handleButton={handleButton} lang={lang} />
  )
}

export default ChangeLang;
