import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

export const GetGuaranteesList = createAsyncThunk(
  "GetGuaranteesList",
  async ({ voen }, thunkAPI) => {
    try {
      const data = await client({
        url: `/guarantee-ms/api/v1/md-deal/list/${voen}`,
        showSuccessToast: false,
        showErrorToast: false,
        succesMessage: "Get Guarantee List",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const GetGuaranteeInfo = createAsyncThunk(
  "GetGuaranteeInfo",
  async ( dealId , thunkAPI) => {
    try {
      const data = await client({
        url: `/guarantee-ms/api/v1/md-deal/details/${dealId}`,
        showSuccessToast: false,
        showErrorToast: false,
        succesMessage: "Get Selected Guarantee",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
