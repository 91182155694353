import { Button } from "antd";
import React, { useState, useEffect } from "react";

export const MoreLoadPage = ({ children, fetchData, lastPage }) => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchMoreListItems = async () => {
    setIsFetching(true);
    await fetchData();
    setIsFetching(false);
  };

  useEffect(() => {
    if (isFetching) {
      fetchMoreListItems();
    }
  }, [isFetching]);

  return (
    <>
      {children}
      <div style={{ textAlign: "center", margin: "0 0 20px", width: "100%" }}>
        {lastPage ? null : <Button onClick={() => setIsFetching(true)} loading={isFetching}>
          Load More
        </Button>}
      </div>
    </>
  );
};

export default MoreLoadPage;
