import React from "react";
import { center } from "components/StyleComponent";
import styled from "styled-components";
import { lock, unlock } from "static/svg";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

export const LockButton = ({ locked, disable, onClick }) => {

  const {t}= useTranslation();


  return (
    <StyledContainer>
      <Tooltip
        title={
          locked
            ? t('unlock')
            : disable
            ? t('Contact the bank')
            : t("lock")
        }
        placement="bottom"
      >
        <StyledLock locked={locked} onClick={onClick} disabled={disable}>
          <img src={locked ? lock : unlock} alt="lock/unlock" />
        </StyledLock>
      </Tooltip>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;
const StyledLock = styled.span`
  background: transparent;
  border: 1.5px solid ${({ locked }) => (locked ? " #F44336" : "#12B76A")};
  border-radius: 8px;
  padding: 10px 18px;
  ${center}
  cursor: ${(disable) => (!disable ? "not-allowed" : "pointer")};
  img {
    width: 24px !important;
    height: 24px !important;
  }
`;
