import React from "react";
import styled from "styled-components";
import {
  ActionSubButtons,
} from "components";
import { useTranslation } from "react-i18next";

export const SignLaterModal = (props) => {
  
  const { cancelOnClick, mainOnClick } = props;

  const {t} = useTranslation()

  return (
    <Container>
      <StyleContent>
        <h4>
          {t("Operation directed to be sign")}
        </h4>
      </StyleContent>
      <ActionSubButtons
        cancelOnClick={cancelOnClick}
        mainOnClick={mainOnClick}
        blockBtnNameCancel={"Go to sign"}
        blockBtnName={"New payment"}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 32px;
`;

const StyleContent = styled.div`
  width: 340px;
  display: flex;
  padding: 16px;
  h4 {
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #63666a;
  }
`;
