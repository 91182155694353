import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ThreeDots } from '../../static/svg/svgs/three-dots.svg';
import { useTranslation } from 'react-i18next';

export const DetailDots = (props) => {
  const { children, placement = 'bottom', component } = props;
  return (
    <Popover
      placement={placement}
      trigger="click"
      getPopupContainer={(trigger) => trigger.parentNode}
      content={<DetailContent content={children} />}
    >
      {component ? (
        component
      ) : (
        <div style={{ padding: '8px', display: 'flex', cursor: 'pointer' }}>
          <ThreeDots />
        </div>
      )}
    </Popover>
  );
};

export const DetailContent = (props) => {
  const { content, ...rest } = props;
  return <StyledPopoverContent {...rest}>{content}</StyledPopoverContent>;
};

export const DetailItems = (props) => {
  const { label, icon = '', count = null, disabled = false, ...rest } = props;
  const { t } = useTranslation();
  return (
    <StyledPopoverItems {...rest} count={count} disabled={disabled}>
      <img src={icon} alt="edit_icon" />
      <span>{t(label)}</span>
      {count && <span>({count})</span>}
    </StyledPopoverItems>
  );
};

const StyledPopoverContent = styled.div`
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  a {
    border-radius: 8px;
  }
`;
const StyledPopoverItems = styled.div`
  padding: 13px 17px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;

  border-radius: 10px;

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  color: ${(props) => (props.disabled ? '#999' : '#555555')};
  cursor: ${(props) => (props.disabled ? 'not-allow' : 'pointer')};
  img {
    margin-right: ${({ count }) => (count ? '13px' : ' 5px')};
  }
  &:hover {
    background-color: #f3f2ef;
  }
  span {
    white-space: nowrap;
  }
`;
