import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CustomInput,
  FormItem,
  Loading,
  ErrorMessage,
  GroupButtons,
} from 'components';
import { Row, Col, Form } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'hooks';
import { responsive } from 'utils/responsiveUI';

export const ChangeNameForm = (props) => {
  const {
    cancelOnClick,
    mainOnClick,
    title,
    errorMsj,
    value,
    maxLength,
    errorMessage,
  } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputValue, setValue] = useState(value);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const allLoading = useSelector((state) => state.loadings);
  const { handleBeginningEmptyInput } = useValidate(form);

  // İlk yükləndikdə value-nu form sahəsinə təyin etmək
  useEffect(() => {
    form.setFieldsValue({
      templateName: value,
    });
  }, [value, form]);

  // errorMessage varsa error qurmaq üçün
  useEffect(() => {
    if (errorMessage) {
      form.setFields([
        {
          name: 'templateName',
          errors: [<ErrorMessage text={errorMessage} />],
        },
      ]);
    }
  }, [errorMessage, form]);

  // Input dəyişəndə error-ları təmizləmək üçün
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    form.setFields([
      {
        name: 'templateName',
        errors: [], // Errorları təmizləyir
      },
    ]);
    handleBeginningEmptyInput(e, 'templateName');

    if (newValue.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Loading loading={allLoading?.isLoading} />
      <Form
        form={form}
        layout="vertical"
        name="Template_Name"
        scrollToFirstError={true}
        hideRequiredMark={true}
      >
        <InputContainer>
          <Row>
            <Col span={24} className="general">
              <FormItem
                name="templateName"
                autoheight
                rules={[
                  {
                    required: true,
                    validator: (rule, value) => {
                      if (value) {
                        const valueLength = value.trim().replace(/\s/g, '');

                        if (valueLength?.length < 3) {
                          setIsDisabled(true);
                          return Promise.reject(<ErrorMessage text={t(errorMsj)} />);
                        } else {
                          setIsDisabled(false);
                          return Promise.resolve();
                        }
                      } else if (rule.required) {
                        return Promise.reject(
                          <ErrorMessage text="mandatoryField" />
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <CustomInput
                  label={title}
                  maxLength={maxLength || 35}
                  onChange={handleInputChange} // Input dəyişəndə
                />
              </FormItem>
            </Col>
          </Row>
        </InputContainer>
        <div style={{ padding: '0 30px' }}>
          <GroupButtons
            firstProps={{
              text: 'cancel',
              isGrey: true,
              onClick: cancelOnClick,
            }}
            secondProps={{
              text: 'Save',
              disabled: isDisabled || value === inputValue,
              onClick: () => mainOnClick(form.getFieldValue('templateName')),
            }}
          />
        </div>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 32px;
`;

const InputContainer = styled.div`
  padding: 30px 30px 0;
  width: 406px;
  @media ${responsive.laptop} {
    width: auto;
  }
  & > span {
    font-family: Proxima Nova;
    font-size: 16px;
    line-height: 19px;
    color: #616173;
  }

  & > :last-child {
    margin-top: 7px;
  }
`;
