import React, {useEffect, useRef, useState} from 'react';
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import styled from 'styled-components';
import {resend} from "store/auth";
import {useDispatch, useSelector} from "react-redux";
import { SingleButton } from 'components/CustomButtons/SingleButton';

export const CountdownCircle = ({
                                    resendEvent,
                                    duration = 60,
                                    endTimeAction = <div>End Time</div>,
                                    ...rest
                                }) => {
    //events
    const dispatch = useDispatch();
    //global state
    const isClicked = useSelector(state => state.asanAuthenticate.isClicked);
    //resend event
    const [key, setKey] = useState(0);

    const renderTime = ({remainingTime}) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const currentTime = useRef(remainingTime);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const prevTime = useRef(null);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const isNewTimeFirstTick = useRef(false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // const [, setOneLastRerender] = useState(0);

        if (currentTime.current !== remainingTime) {
            isNewTimeFirstTick.current = true;
            prevTime.current = currentTime.current;
            currentTime.current = remainingTime;
        } else {
            isNewTimeFirstTick.current = false;
        }

        // force one last re-render when the time is over to tirgger the last animation
        // if (remainingTime === 0) {
        //     setTimeout(() => {
        //         setOneLastRerender((val) => val + 1);
        //     }, 20);
        // }
        //
        // const isTimeUp = isNewTimeFirstTick.current;
        //
        // console.log('isTimeUp', isTimeUp);

        const handleReset = () => {
            dispatch(resend(true));
            resendEvent();
            setKey(prevKey => prevKey + 1);
        }

        return (
            remainingTime > 0
                ?
                <TimerStyle>
                    {minutes}:{seconds < 10 ? '0' + seconds : seconds}
                </TimerStyle>
                :
                <div>
                    <SingleButton
                        onClick={handleReset}
                        text='Resend'
                    />
                </div>

            // <div className="time-wrapper">
            //     <div key={remainingTime}>
            //         {remainingTime}
            //     </div>
            //     {prevTime.current !== null && (
            //         <div
            //             key={prevTime.current}
            //         >
            //             {prevTime.current}
            //         </div>
            //     )}
            // </div>
        );
    };

    useEffect(() => {
        console.log('isClicked', isClicked);
    }, [isClicked]);

    return (
        <CountdownCircleTimer
            key={key}
            isPlaying
            duration={parseInt(duration)}
            strokeWidth={8}
            size={240}
            trailColor='#F2F2F2'
            colors={[["#F9C20A"]]}
            onComplete={() => {
                // dispatch(resend(false));
                return [false, 0]
            }}
            {...rest}
        >
            {renderTime}
        </CountdownCircleTimer>
    )
}

const TimerStyle = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #231F20;
`
