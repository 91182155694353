import { createSlice } from '@reduxjs/toolkit';

export const authInfoSlice = createSlice({
  name: 'authInfo',
  initialState: {
    token: localStorage.getItem('tokenInfo') || null,
    refreshToken: localStorage.getItem('refreshToken'),
    voen: localStorage.getItem('voen') ?? null,
    otpData: null,
    remainingTimeInSeconds: null
  },
  reducers: {
    setVoen: (state, action) => {
      if (action.payload.voen) {
        localStorage.setItem('voen', action.payload.voen);
      }
      state.authInfo = {
        ...state.authInfo,
        voen: action.payload.voen || state.authInfo.voen,
      };
    },
    setAuthInfo: (state, action) => {
      localStorage.setItem('tokenInfo', action.payload.token);
      if (action.payload.refreshToken) {
        localStorage.setItem('refreshToken', action.payload.refreshToken);
      }
      if (action.payload.voen) {
        localStorage.setItem('voen', action.payload.voen);
      }
      if (action.payload.refreshTokenExpiryDate) {
        localStorage.setItem('refreshTokenExpiryDate', action.payload.refreshTokenExpiryDate);
      }
      if (action.payload.remainingTimeInSeconds) {
        localStorage.setItem('remainingTimeInSeconds', action.payload.remainingTimeInSeconds);
      }
      state.authInfo = {
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        voen: action.payload.voen || state.authInfo.voen,
        refreshTokenExpiryDate: action.payload.refreshTokenExpiryDate,
        remainingTimeInSeconds: action.payload.remainingTimeInSeconds,
      };
    },
    setOtpData: (state, action) => {
      state.otpData = action.payload;
    },
    resetAuthInfo: () => {
      localStorage.clear();
      window.location.reload();
    },
  },
});

const authInfoReducer = authInfoSlice.reducer;

export const {
  setAuthInfo,
  resetAuthInfo,
  setVoen,
  setOtpData,
} = authInfoSlice.actions;

export default authInfoReducer;
