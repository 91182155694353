import React from "react";
import styled from "styled-components";
import {responsive} from "utils/responsiveUI";
import {checkIcon} from "static/svg";
import {LoginButton, LoginLayout} from "components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { hideEmail } from "utils/shortFunctions";

const CheckEmailSuccess = () => {
  
  const historyState = useLocation();
  const { t } = useTranslation()

  const to = () => {
        const replacedUrl = window.location.href.replace("/set-new-password/success", "/")
        window.location.href = replacedUrl;
    }
  
    const hiddenEmailAdress = hideEmail(historyState.state || "");
    return (
      <LoginLayout>
        <Container>
            <StyledCheckContnet>
                <img src={checkIcon} alt="checkIcon"/>
                <h4>{t("Check your e-mail")}</h4>
                <h6>
                  {t("Сonfirmation link has been sent to {{hiddenEmailAdress}}",  {hiddenEmailAdress})}
               </h6>

                <LoginButton onClick={() => to()} title={t("Go to Login")}/>
            </StyledCheckContnet>
        </Container>
      </LoginLayout>
        

        
    );
};

export default CheckEmailSuccess;

const Container = styled.div`
  margin-top: 76px;
  height: 100%;
  background-color: #fcfcfc;
  text-align: left;
  @media ${responsive.laptop} {
      margin-top: 59px;
      text-align: center;
    }
`;

const StyledCheckContnet = styled.div`
  h4 {
    font-weight: 700;
    font-size: 28px;
    color: #1c1c1c;
  }
  h6 {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #383838;

    margin-bottom: 20px;
  }

  
  @media ${responsive.laptop} {
    h4 {
      font-size: 32px;
    }
  }
`;
