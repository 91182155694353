import React from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";

export const SectionStyle = ({
  header,
  body,
  isStyle,
  mediumFont,
  isMobile,
  cardAction,
  inlineText
}) => {
  const { t } = useTranslation();

  if (Array.isArray(body)) {
    let guaranters = body.map((item) => {
      return (
        <Col xl={12} xs={24}>
          <StyledTextCard
            className="rightStyle"
            isStyle={isStyle}
            mediumFont={mediumFont}
            inlineText={inlineText}
          >
            <h4>{t(header)}</h4>
            <p>{typeof item == "number" && !item ? 0 : item}</p>
          </StyledTextCard>
          {isMobile ? <div>{cardAction}</div> : null}
        </Col>
      );
    });
    return guaranters
  }
  return (
    <Col xl={12} xs={24}>
      <StyledTextCard
        className="rightStyle"
        isStyle={isStyle}
        mediumFont={mediumFont}
        inlineText={inlineText}
      >
        <h4>{t(header)}</h4>
        <p>{typeof body == "number" && !body ? 0 : body}</p>
      </StyledTextCard>
      {isMobile ? <div>{cardAction}</div> : null}
    </Col>
  );
};

const StyledTextCard = styled.div`
  margin-bottom: 24px;
  word-wrap: break-word;
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #aaaaaa;
    font-family: "Proxima Nova";
  }
  p {
    font-weight: 500;
    font-size: ${({ mediumFont }) => (mediumFont ? "15px" : "17px")};
    line-height: 20px;
    color: ${(props) => (props.isStyle ? "#FFA300" : "#393939")};
    font-family: "Proxima Nova";
    word-break:  ${(props) => (props.inlineText ? null : "word-wrap")};
    word-wrap:   ${(props) => (props.inlineText ? 'normal' : null)};
    max-width: 80%;
    display: inline;
    
  }
  @media ${responsive.laptop} {
    p {
      word-break: break-all;
    }
  }
`;
