export const signStatusColor = (statusCode) => {
  let bgColor, statusColor, color;

  switch (statusCode) {
    case 'INPUTTED':
      statusColor = '#FFF4E0';
      color = '#FFA000';
      break;
    case 'AUTH':
      statusColor = '#E7F8F0';
      color = '#12B76A';
      break;
    case 'DELETED':
      statusColor = '#FEE8E7';
      color = '#F44336';
      break;

    case 'REJECTBANK':
      statusColor = '#FEE8E7';
      color = '#F44336;';
      break;

    case 'CHECKINGBANK':
    case 'CHECKINGBRANCH':
      statusColor = 'rgba(228, 241, 255, 1)';
      color = 'rgba(61, 137, 255, 1)';
      break;
    default:
      statusColor = '#E3E3E3';
      color = '#555555';
  }

  bgColor = `background-color: ${statusColor}; color: ${color};`;
  return bgColor;
};

export const statementStatusCode = (statusCode) => {
  let bgColor, statusColor, color;

  switch (statusCode) {
    case 'IN_PROGRESS':
      statusColor = '#FFF4E0';
      color = '#FFA000';
      break;
    case 'READY':
      statusColor = '#E7F8F0';
      color = '#12B76A';
      break;
    case 'ERROR':
      statusColor = '#FEE8E7';
      color = '#F44336';
      break;
    default:
      statusColor = '#E3E3E3';
      color = '#555555';
  }

  bgColor = `background-color: ${statusColor}; color: ${color};`;
  return bgColor;
};
