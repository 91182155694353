import {createSlice} from '@reduxjs/toolkit';
import {
    CommonInfo,
    MapInfo,
    GetPaymentPurposeList,
    GetLegalTypeList,
    GetCountryList,
    GetCheckVoen,
    GetAniPayTypeList
} from './api_action';

export {
    CommonInfo,
    MapInfo,
    GetPaymentPurposeList,
    GetLegalTypeList,
    GetCountryList,
    GetCheckVoen,
    GetAniPayTypeList
}

const commonInfoSlice = createSlice({
    name: "commoninfo",
    initialState: {
        commonInfo: null,
        error: null
    },
    extraReducers: {
        [CommonInfo.fulfilled]: (state, action) => {
            const {date, paymentNumber, currencies} = action.payload
            state.commonInfo = {
                date: date.data,
                paymentNumber: paymentNumber.data,
                currencies: currencies.data
            }
            state.error = null
        },
        [CommonInfo.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const commonInfoReducer = commonInfoSlice.reducer;

const mapInfoSlice = createSlice({
    name: 'mapInfo',
    initialState: {
        branches: [],
        terminals: [],
        atms: [],
        error: null
    },
    extraReducers: {
        [MapInfo.fulfilled]: (state, action) => {
            state.branches = action.payload.branches.data;
            state.terminals = action.payload.terminalATM.data.filter(item => item.type === 1);
            state.atms = action.payload.terminalATM.data.filter(item => item.type === 0);
            // state.mapInfo = action.terminalATM.data;
            state.error = null;
        },
        [MapInfo.rejected]: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const mapInfoReducer = mapInfoSlice.reducer;

const getPaymentPurposeListSlice = createSlice({
    name: 'getPaymentPurposeList',
    initialState: {
        paymentPurposeData:[],
        error: null
    },
    extraReducers: {
        [GetPaymentPurposeList.fulfilled]: (state, action) => {
            state.paymentPurposeData = action.payload.data;
            state.error = null;
        },
        [GetPaymentPurposeList.rejected]: (state, action) => {
            state.error = action.payload;
        }
    }
})

export const getPaymentPurposeListReducer = getPaymentPurposeListSlice.reducer;


const getLegalTypeSlice = createSlice({
    name: 'getLegalTypeList',
    initialState: {
        legalTypeData:[],
        error: null
    },
    extraReducers: {
        [GetLegalTypeList.fulfilled]: (state, action) => {
            state.legalTypeData = action.payload.data;
            state.error = null;
        },
        [GetLegalTypeList.rejected]: (state, action) => {
            state.error = action.payload;
        }
    }
})

export const getLegalTypeListReducer = getLegalTypeSlice.reducer;


const getCountrySlice = createSlice({
    name: 'getCountryList',
    initialState: {
        countryList:[],
        error: null
    },
    extraReducers: {
        [GetCountryList.fulfilled]: (state, action) => {
            state.countryList = action.payload.data;
            state.error = null;
        },
        [GetCountryList.rejected]: (state, action) => {
            state.error = action.payload;
        }
    }
})

export const getCountryListReducer = getCountrySlice.reducer;


//Beneficiary Bank Code
const GetCheckVoenSlice = createSlice({
    name: "getCheckVoen",
    initialState: {
      checkVoen: null,
      error: null,
    },
    extraReducers: {
      [GetCheckVoen.fulfilled]: (state, action) => {
        const data = action.payload;
        state.checkVoen = data.data;
        state.error = null;
      },
      [GetCheckVoen.rejected]: (state, action) => {
        state.error = action.error.message;
      },
    },
  });
  
  export const getCheckVoenReducer = GetCheckVoenSlice.reducer;

  const getAniPaySlice = createSlice({
    name: 'getAniPayTypeList',
    initialState: {
        aniPayTypeData:[],
        error: null
    },
    extraReducers: {
        [GetAniPayTypeList.fulfilled]: (state, action) => {
            state.aniPayTypeData = action.payload.data;
            state.error = null;
        },
        [GetAniPayTypeList.rejected]: (state, action) => {
            state.error = action.payload;
        }
    }
})

export const getAniPayListReducer = getAniPaySlice.reducer;