
import { Select, center } from 'components'
import React from 'react'
import styled from 'styled-components'

export const BorderSelect = ({data, onChange, translate}) => {
  return (
    <StyledContainer>
        <Select
            allowClear={false}
            data={data}
            keys={["value"]}
            defaultValue={0}
            onChange={onChange}
            translate
          />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .ant-select-dropdown{
    width: auto !important;
  }
   .ant-select-selector{
    border-color: #63666A !important;
    color: #63666A !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    height: 44px !important;
    box-shadow: none !important;
    background-color: transparent !important;
      &:focus{
        box-shadow: none !important;
      }
    .ant-select-selection-item{
      ${center}
    }
   }
   .ant-select-item-option{
    ${center}
   }
`