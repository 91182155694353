import { createAsyncThunk } from "@reduxjs/toolkit";
import { client} from 'client'

export const AnyPayProcess = createAsyncThunk(
  "AnyPayProcess", 
  async (body, thunkAPI) =>  {
    try {
      const data = await client({
        url :'/ips-ms/v1/payment/process',
        data:JSON.stringify(body),
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "paymentSuccess",
        token: false
      })
      return data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

export const AnyPayValidate = createAsyncThunk(
  "AnyPayValidate",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url :'/ips-ms/v1/payment/validate',
        data:JSON.stringify(body),
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "paymentSuccess",
        token: false  
      })
      return data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

export const PaymentAlias = createAsyncThunk(
  "PaymentAlias",
  async (body, thunkAPI) => {
    try {
      const result = await client({
        url :'/ips-ms/v1/payment/alias',
        data:body,
        showSuccessToast: false,
        showErrorToast: true,
        token: false  
      })
      return result?.data?.data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)