import {createSlice} from '@reduxjs/toolkit';
import {
    GetLoans,
    GetLoanInfo,
    PaymentScheduleLoan
} from './api_actions';

export {
    GetLoans,
    GetLoanInfo,
    PaymentScheduleLoan
}

//get all loans
const GetLoansSlice = createSlice({
    name: 'getLoans',
    initialState: {
        loans: [],
        isSuccess: false,
        error: null
    },
    extraReducers: {
        [GetLoans.fulfilled]: (state, action) => {
            state.loans = action.payload.data;
            state.isSuccess = true;
            state.error = null
        },
        [GetLoans.rejected]: (state, action) => {
            state.error = action.payload;
            state.isSuccess = false;
        }
    }
});

export const getLoansReducer = GetLoansSlice.reducer;

//get loan info
const GetLoanInfoSlice = createSlice({
    name: 'getLoanInfo',
    initialState: {
        loanInfo: [],
        error: null
    },
    extraReducers: {
        [GetLoanInfo.fulfilled]: (state, action) => {
            state.loanInfo = action.payload.data;
            state.error = null
        },
        [GetLoanInfo.rejected]: (state, action) => {
            state.error = action.payload.data;
        }
    }
});

export const getLoanInfoReducer = GetLoanInfoSlice.reducer;

//loan payment schedule
const PaymentScheduleLoanSlice = createSlice({
    name: 'paymentScheduleLoan',
    initialState: {
        paymentSchedule: null,
        error: null
    },
    extraReducers: {
        [PaymentScheduleLoan.fulfilled]: (state, action) => {
            state.paymentSchedule = action.payload.data;
            state.error = null;
        },
        [PaymentScheduleLoan.rejected]: (state, action) => {
            state.error = action.payload;
            state.paymentSchedule = null;
        },
    }
});

export const paymentScheduleLoanReducer = PaymentScheduleLoanSlice.reducer;