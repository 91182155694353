import { createSlice } from '@reduxjs/toolkit'
import { GetExchangeInfo } from './api_action';


const GetExchangeInfoSlice = createSlice({
    name: 'getExchangeInfo',
    initialState: {
        exchangeInfo: null,
        error: null
    },
    extraReducers: {
        [GetExchangeInfo.fulfilled]: (state, action) => {
            state.exchangeInfo = action.payload.data
        },
        [GetExchangeInfo.rejected]: (state, action) => {
            state.error = action.payload
            state.exchangeInfo = null
        }
    }
});

export { GetExchangeInfo }

export const getExchangeInfoReducer = GetExchangeInfoSlice.reducer;
