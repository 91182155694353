import React from "react";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";
import { express_logo } from "static/svg";
import { LanguageSelect } from "components/LanguageSelect";

const LoginHeader = () => {
  const to = () => {
    window.location.href = "/login";
  };

  return (
    <HeaderContainer>
      <StyledImg onClick={to} className="logo" src={express_logo} alt="logo" />
      <CardsWrap>
        <LanguageSelect/>
      </CardsWrap>
    </HeaderContainer>
  );
};

export default LoginHeader;

const StyledImg = styled.img`
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 146px;
  }


  @media ${responsive.laptop} {
    .logo {
      width: 100px;
    }
  }
`;
const CardsWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
