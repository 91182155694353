import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { stepCheck, error_icon } from 'static/svg';
import { responsive } from 'utils/responsiveUI';

const { Panel } = Collapse;

export const CollapsePanel = ({
  header,
  children,
  status,
  id,
  success,
  errors,
  noLeftBorder,
  accountPadding,
  ...rest
}) => {
  return (
    <StyledCollapsePanel noLeftBorder={noLeftBorder} accountPadding={accountPadding}>
      <Panel
        header={
          <StyledHeaderContainer>
            {success ? (
              <img src={stepCheck} alt="stepCheck" />
            ) : errors ? (
              <img src={error_icon} alt="error_icon" />
            ) : (
              <span className="status">{status}</span>
            )}
            <StyledHeader>{header}</StyledHeader>
          </StyledHeaderContainer>
        }
        forceRender
        {...rest}
      >
        <span className="collapseRow">
          <div className="collapseRowContent">{children}</div>
        </span>
      </Panel>
    </StyledCollapsePanel>
  );
};

export function CollapseContent({
  children,
  accordion = true,
  defaultActiveKey = ['1'],
  expandIconPosition = 'right',
  activeKey,
  onChange,
  ...rest
}) {
  return (
    <StyledCollapseContent>
      <Collapse
        accordion={accordion}
        bordered={false}
        defaultActiveKey={defaultActiveKey}
        expandIconPosition={expandIconPosition}
        {...(activeKey ? { activeKey } : {})} 
        {...(onChange ? { onChange } : {})}  
      
        
        expandIcon={({ isActive }) =>
          isActive ? (
            <MdKeyboardArrowDown size={24} />
          ) : (
            <MdKeyboardArrowRight size={24} />
          )
        }
        {...rest}
      >
        {children}
      </Collapse>
    </StyledCollapseContent>
  );
}

const StyledCollapsePanel = styled.div`
  background: #ffffff;
  border-radius: 4px;
  min-height: 88px;
  border: 0;
  .ant-collapse-item-active .ant-collapse-arrow {
    transform: rotate(180deg);
  }
  .ant-collapse-item-active .ant-collapse-header {
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .ant-collapse-item-active .ant-collapse-header {
    .status {
      background-color: #393939;
    }
  }
  .ant-collapse-header {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
      border-radius: 8px;
    }
    svg {
      position: absolute;
      left: 95%;
      transform: rotate(90deg);
    }
    @media ${responsive.laptop} {
      svg {
        left: 87%;
      }
    }
  }
  :last-of-type {
    padding-bottom: 22px;
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse-content-box {
    padding: ${(props) =>
      props.noLeftBorder
        ? '36px 0 36px 19px'
        : props.accountPadding
        ? '36px 0 36px 0'
        : ' 36px 0 36px 30px'};

    .collapseRow {
      position: relative;
      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0%;
        width: 10px;
        height: 100%;
        border-left: ${(props) =>
          props.noLeftBorder ? 'none' : '1px solid #e6e5e2'};
        @media ${responsive.laptop} {
          display: none;
        }
      }
      .collapseRowContent {
        padding: ${(props) => (props.noLeftBorder ? '0' : '0 0 0 44px')};
      }
      @media ${responsive.laptop} {
        .collapseRowContent {
          padding: 0;
        }
        .collapseRowContent > .ant-row > .ant-col {
          max-width: 100% !important;
        }
      }
    }

    @media ${responsive.laptop} {
      /* padding: 16px; */
      padding: 16px 0 0 0;
    }
  }

  .collapseRowContent > .ant-row {
    flex-flow: row wrap;
    @media ${responsive.laptop} {
      flex-flow: column wrap;
    }
  }
`;

const StyledCollapseContent = styled.div`
  background-color: #fff;
  width: 100% !important;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 18px;
  &:hover {
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .status {
    background: #aaaa;
    color: #fff;
    padding: 2px 9px;
    border-radius: 50%;
  }
`;

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  color: #1c1c1c;
  padding-left: 30px;
`;
