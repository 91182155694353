import { createSlice } from '@reduxjs/toolkit';
import {
  DepositePdfDownload,
  LoanPdfDownload,
  SalaryPdfDownload,
  SalaryDownloadUploadFile,
  paymentOrderFile,
  AccountRequisiteDownload,
  OperationAsanDocFile,
  paymentPayrollFile,
} from './api_action';

export {
  DepositePdfDownload,
  LoanPdfDownload,
  SalaryPdfDownload,
  SalaryDownloadUploadFile,
  paymentOrderFile,
  AccountRequisiteDownload,
};

const initialState = {
  depositeFile: null,
  loanFile: null,
  salaryFile: null,
  salaryFileError: null,
  orderFile: null,
  requisiteFile: null,
  asanDocFile: null,
  payrollFile: null,
};

export const DownloadFilesSlice = createSlice({
  name: 'DownloadFilesSlice',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [DepositePdfDownload.fulfilled]: (state, action) => {
      state.depositeFile = action.payload.data;
    },
    [DepositePdfDownload.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [LoanPdfDownload.fulfilled]: (state, action) => {
      state.loanFile = action.payload.data;
    },
    [LoanPdfDownload.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [SalaryPdfDownload.fulfilled]: (state, action) => {
      state.salaryFile = action.payload.data;
    },
    [SalaryPdfDownload.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [SalaryDownloadUploadFile.fulfilled]: (state, action) => {
      state.salaryFileError = action.payload.data;
    },
    [SalaryDownloadUploadFile.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [paymentOrderFile.fulfilled]: (state, action) => {
      state.orderFile = action.payload.data;
    },
    [paymentOrderFile.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [AccountRequisiteDownload.fulfilled]: (state, action) => {
      state.requisiteFile = action.payload.data;
    },
    [AccountRequisiteDownload.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [OperationAsanDocFile.fulfilled]: (state, action) => {
      state.asanDocFile = action.payload.data;
    },
    [OperationAsanDocFile.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [paymentPayrollFile.fulfilled]: (state, action) => {
      state.payrollFile = action.payload.data;
    },
    [paymentPayrollFile.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const downloadFilesReducer = DownloadFilesSlice.reducer;
