import { createSlice } from "@reduxjs/toolkit";
import {
  DocumentSignCheckAsan,
  DocumentSignSelectedAsan,
  DocumentSignSelectedOtp,
  DocumentSignCheckOtp,
  DocumentToBeSignList  ,
} from "./api_action";

export {
  DocumentSignCheckAsan,
  DocumentSignSelectedAsan,
  DocumentSignSelectedOtp,
  DocumentSignCheckOtp,
  DocumentToBeSignList,
  
};



const DocumentToBeSignSlice = createSlice({
  name: "DocumentToBeSignSlice",
  initialState: {
    signList: null,
  },
  extraReducers: {
    [DocumentToBeSignList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.signList = {...data, content: data?.content?.map(item => ({
          ...item,
          key: item.paymentId
        })) || []};
    },
    [DocumentToBeSignList.rejected]: (state, action) => {
      state.error = action.payload;
    },
   
  },
});

export const DocumentToBeSignReducer = DocumentToBeSignSlice.reducer;


const initialState= {
  signSelectedOtp: null,
  signCheckOtp: null,
  signSelectedAsan: null,
  signCheckAsan: null,
  error: null
}

export const DocumentSignSelectSlice = createSlice({
  name: "DocumentSignSelect",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [DocumentSignSelectedOtp.fulfilled]: (state, action) => {
      const { status } = action.payload;
     
      state.signSelectedOtp = status;
    },
    [DocumentSignSelectedOtp.rejected]: (state, action) => {
        state.error = action.payload
    },
    [DocumentSignCheckOtp.fulfilled]: (state, action) => {
      const { status } = action.payload;
     
      state.signCheckOtp = status;
    },
    [DocumentSignCheckOtp.rejected]: (state, action) => {
        state.error = action.payload
    },

    [DocumentSignSelectedAsan.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.signSelectedAsan = data;
    },
    [DocumentSignSelectedAsan.rejected]: (state, action) => {
        state.error = action.payload
    },
    [DocumentSignCheckAsan.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.signCheckAsan = data;
    },
    [DocumentSignCheckAsan.rejected]: (state, action) => {
        state.error = action.payload
    }
}
});

export const DocumentSignSelectedReducer = DocumentSignSelectSlice.reducer;
