import React from 'react';
import styled from 'styled-components';
import { Select } from 'components';


export const PageSizeSelect = ({click}) => {
    return (
        <Container>
            <span className='show_text'>Show</span>
            <Select
                data={[
                    {
                        id: 10,
                        show: '10'
                    },
                    {
                        id: 20,
                        show: '20'
                    },
                    {
                        id: 50,
                        show: '50'
                    },
                    {
                        id: 100,
                        show: '100'
                    },
                ]}
                keys={['show']}
                defaultValue={10}
                bordered={false}
                simple
                style={{ fontWeight: 600 }}
                onChange={click}
            />
        </Container>
    )
}

const Container = styled.div`
    width:200px;
    position:absolute;
    bottom:0px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
    pointer-events:auto;
    .show_text{
        font-family: Proxima Nova;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.015em;
        color: #616173;
    }
`