import { createAsyncThunk } from "@reduxjs/toolkit";
import { client} from 'client'

export const WithinBankProcess = createAsyncThunk(
  "WithinBankProcess", 
  async (body, thunkAPI) =>  {
    try {
      const data = await client({
        url :'/internal-payment-ms/internal-payment/process',
        data: JSON.stringify(body),
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "paymentSuccess",
        token: false   
      })
      return data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

export const WithinBankValidate = createAsyncThunk(
  "withinBank",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url :'/internal-payment-ms/internal-payment/validate',
        data: JSON.stringify(body),
        showErrorToast: true,
        succesMessage: "paymentSuccess",
        token: false ,
        hasErrorsHiddenToast: true,
      })
      
      return data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)