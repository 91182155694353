import { oneDot, downArrow, upArrow } from "static/svg";

export const delta = (val) =>
  val === 0 ? oneDot : val > 0 ? upArrow : downArrow;

const filterCurrency = (exchangeInfo) =>
  exchangeInfo?.filter((item) =>
    ["USD", "EUR", "RUB", "GBP", "TRY"].includes(item.isoCode)
  );

// CASH
const cash_include = (item) =>
  filterCurrency(item)?.map((item) =>
    item.rates.filter((item) => ["CASH"].includes(item.marketName))
  );

export const cash = (item) =>
  cash_include(item) &&
  cash_include(item).filter((item) => (item.length !== 0 ? item : null));

// NonCash
const nonCach_include = (item) =>
  filterCurrency(item)?.map((item) =>
    item.rates.filter((item) => ["NON-CASH"].includes(item.marketName))
  );

export const nonCach = (item) =>
  nonCach_include(item) &&
  nonCach_include(item).filter((item) => (item.length !== 0 ? item : null));

// CBA
const cba_include = (item) =>
  filterCurrency(item)?.map((item) =>
    item.rates.filter((item) => ["CBA"].includes(item.marketName))
  );
export const cba = (item) =>
  cba_include(item) &&
  cba_include(item).filter((item) => (item.length !== 0 ? item : null));


export  const transformData = (exchangeInfo, type="NON-CASH") => {
    const filteredData = exchangeInfo?.filter((item) =>
      ["USD", "EUR", "RUB", "GBP", "TRY"].includes(item.isoCode)
    );
    let newDataArray = []
    if(filteredData){
      newDataArray = filteredData?.map((item) => {
        const nonCashRate = item.rates.find(
          (rate) => rate.marketName === type
        );
        const cbaRate = item.rates.find((rate) => rate.marketName === "CBA");
    
        return {
          isoCode: item.isoCode,
          currency: nonCashRate ? nonCashRate.currency : item.rates[0].currency,
          data: {
            cba: {
              cbar: cbaRate ? cbaRate.cbar : { rate: "", delta: "" },
            },
            filtered: nonCashRate
              ? {
                  marketNameType: nonCashRate.marketName,
                  sell: nonCashRate.sell,
                  buy: nonCashRate.buy,
                }
              : { marketNameType: "", sell: "", buy: "" },
          },
        };
      });
    }
  
    return newDataArray;
  };
  