import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";
import { changePaymentTypeIds } from "utils/changePaymentTypeIds";

const voen = localStorage.getItem("voen")

export const GetTemplates = createAsyncThunk(
  "GetTemplates",
  async (info, thunkAPI) => {
    const { page = 0, size = 12 } = info;

    const data = await client({
      url: `/template-ms/templates/list?page=${page}&size=${size}&senderPhone=009008&senderVoen=${voen}`,
      showSuccessToast: false,
      showErrorToast: true,
      succesMessage: "Template info fetched",
      token: false,
    });
    return data;
  }
);

export const SaveTemplate = createAsyncThunk(
  "SaveTemplate",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: `/template-ms/templates`,
        data: JSON.stringify(body),
        showSuccessToast: false,
        showErrorToast: false,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const DeleteTemplate = createAsyncThunk(
  "DeleteTemplate",
  async ({ id, currentPage }, thunkAPI) => {
    try {
      await client({
        url: `/template-ms/templates/delete/${id}`,
        method: "DELETE",
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "TemplateDeleted",
        token: false,
      });
      // const filteredData = templates.templateData.result.filter((item) => item.id !== id)
      thunkAPI.dispatch(GetTemplates({ page: currentPage, size: 12 }));
      // return filteredData
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const GetTemplateId = createAsyncThunk(
  "GetTemplateId",
  async (id, thunkAPI) => {
    try {
      const data = await client({
        url: `/template-ms/templates/${id}`,
        showSuccessToast: false,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const EditTemplate = createAsyncThunk(
  "EditTemplate",
  async ({ id, template }, thunkAPI) => {
    try {
      const data = await client({
        url: `template-ms/templates/update/${id}`,
        method: "PUT",
        data: JSON.stringify(template),
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "TemplateUpdated",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
export const RenameTemplate = createAsyncThunk(
  "RenameTemplate",
  async (accountInfo, thunkAPI) => {
    const { setModalFunc, id, templateName } = accountInfo;
    try {
      const data = await client({
        url: `template-ms/templates/rename/${id}/${templateName}`,
        method: "PUT",
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "RenameTemplate",
        token: false,
        successCallback: () => setModalFunc({ visible: false }),
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const SearchTemplates = createAsyncThunk(
  "SearchTemplates",
  async (body, thunkAPI) => {
    const { page = 0, size = 12 } = body;
    body = changePaymentTypeIds(body, "paymentTypeId");
    try {
      const data = await client({
        url: `template-ms/templates/search?page=${page}&size=${size}`,
        method: "POST",
        data: JSON.stringify(body),
        showSuccessToast: false,
        showErrorToast: true,
        // succesMessage: "Template filtered",
        token: false,
      });

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
