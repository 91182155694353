import React from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { responsive } from "utils/responsiveUI";
import { close } from "static/svg";
import { useTranslation } from "react-i18next";

//width--content width
//headerHeight - header height

const ReactModalAdapter = ({
  title,
  className,
  children,
  cancel,
  open,
  secondTitle,
  headerColor,
  hideCloseIcon,
  isSuccess,
  ...rest
}) => {
  ReactModal.setAppElement("#root");
  const { t } = useTranslation();
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  const cancelImg = (
    <img
      onClick={cancel}
      className="cancel_icon"
      src={close}
      alt="cancel icon"
    />
  );

  return (
    <ReactModal
      id="modalContainer"
      isOpen={open}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      onRequestClose={cancel}
      closeTimeoutMS={200}
      {...rest}
    >
      <ModalContent secondTitle={secondTitle} headerColor={headerColor}>
        <div className="modal_header">
          <ModalTitle isSuccess={isSuccess}>
            <span>{t(title)}</span>
            {/* <div>{secondTitle}</div> */}
          </ModalTitle>
          {!hideCloseIcon && cancelImg}
        </div>
        {children}
      </ModalContent>
    </ReactModal>
  );
};

export const Modal = styled(ReactModalAdapter)`
  .cancel_icon {
    cursor: pointer;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999999;

    &.ReactModal__Overlay {
      opacity: 0;
      overflow: auto;
      /* transition: opacity 100ms ease-in-out; */
    }

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
      transition: opacity 100ms ease-in-out;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    transform: translate(-50%, -50%) scale(0.5, 0.5);
    overflow: hidden;
    padding: 0;
    margin: 0;
    max-width: ${(props) => props.maxWidth && props.maxWidth};
    /* min-height: 300px; */
    min-width: ${(props) => props.width || "300px"};
    border: none;
    @media ${responsive.laptop} {
      min-width: 300px;
    }
    
    &.ReactModal__Content {
      transform: translate(-50%, -50%) scale(0.5, 0.5);
      transition: transform 200ms ease-in-out;
    }

    &.ReactModal__Content--after-open {
      transform: translate(-50%, -50%)
        scale(${(props) => props.scale || "1, 1"});
    }

    &.ReactModal__Content--before-close {
      transform: translate(-50%, -50%) scale(0.1, 0.1);
      transition: transform 100ms ease-in-out;
    }
  }
`;

const ModalContent = styled.div`
  .modal_header {
    /* height: ${(props) => (props.secondTitle ? "97px" : "80px")}; */
    /* background-color: ${(props) => props.headerColor || "#F7F8FD"}; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 25px 0px 25px;
  }
`;
const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Proxima Nova;
  font-style: normal;

  span {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    color: ${(props) => (props.isSuccess ? "#12B76A" : "#393939")};
    margin-right: 30px;
  }

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #8e8e8e;
    margin-top: 8px;
  }
`;
