import {createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "client";
import { ErrorMessage } from "utils/errorMessage";
// import {GetAccounts, GetAccountsByCurrency} from 'store/accounts';

export const CommonInfo = createAsyncThunk(
    "commonInfo",
    async (paymentType, thunkAPI) => {
        try {
            const date = await client({
                url: "/common/payment/date",
                showErrorToast: true,
                token: false

            });

            const paymentNumber = await client({
                url: `/common/payment/number`,
                showErrorToast: true,
                token: false
            });

            const currencies = await client({
                url: `/common/currency/by-customer/`,
                showErrorToast: true,
                token: false
            });

            //ilk currency-ə görə account çağrılır
            // currencies && thunkAPI.dispatch(GetAccountsByCurrency(currencies?.data[0].id))

            //Əyər ödəniş İnternal tipindədirsə bu zaman AZN accountlarını çağır əks halda ümumi accountları çağır 
            // currencies && paymentType === 'internal' ? thunkAPI.dispatch(GetAccountsByCurrency(currencies?.data[0].id)) : thunkAPI.dispatch(GetAccounts())

            return {date, paymentNumber, currencies};

        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }

    }
);

//get branches
export const MapInfo = createAsyncThunk(
    'MapInfo',
    async (config, thunkAPI) => {
        try {
            const branches = await client({
                url: `/common/map/branch/coordinates`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: 'Branches were successfully loaded',
                token: false
            });
            const terminalATM = await client({
                url: `/common/map/terminal-atm/coordinates`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: 'Terminal and ATM were successfully loaded',
                token: false
            });
            return {branches, terminalATM};
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

//get payment purpose
export const GetPaymentPurposeList = createAsyncThunk(
    "GetPaymentPurposeList",
    async({lang, paymentType}, thunkAPI) => {
        try {
            const data = await client({
                url: `common-ms/payment-purpose/list?paymentType=${paymentType}`,
                showSuccessToast: false,
                showErrorToast: true,
                token: false 
            })
            return data
        }catch(err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

//get legal type
export const GetLegalTypeList = createAsyncThunk(
    "GetLegalTypeList",
    async(thunkAPI) => {
        try {
            const data = await client({
                url: `common-ms/legal-type/list`,
                showSuccessToast: false,
                showErrorToast: true,
                token: false 
            })
            return data
        }catch(err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

//get country
export const GetCountryList = createAsyncThunk(
    "getCountryList",
    async(thunkAPI) => {
        try {
            const data = await client({
                url: `common-ms/country/list`,
                showSuccessToast: false,
                showErrorToast: true,
                token: false 
            })
            return data
        }catch(err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)

// Receiver bank code
export const GetCheckVoen= createAsyncThunk(
    "GetCheckVoen",
    async (voen, error, thunkAPI) => {
        try {
            const data = await client({
                url: `/common-ms/check-voen/${voen}`, 
                showErrorToast: true,
                errorMessage: ErrorMessage(error),
                token: false,
            })
            return data
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

// ANipay list
export const GetAniPayTypeList= createAsyncThunk(
    "GetAniPayTypeList",
    async (thunkAPI) => {
        try {
            const data = await client({
                url: `common-ms/ani-pay/types`, 
                showErrorToast: true,
                token: false,
            })
            return data
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

