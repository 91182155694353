import React, { useEffect } from "react";
import { center, Select } from "components";
import { FlagSelect } from "components/FlagSelect";
import { multiLanguage } from "constant/consts";
import i18next from "i18next";

import { useDispatch, useSelector } from "react-redux";
import { getLanguage, setLanguage } from "store/language";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";

export const LanguageSelect = () => {
  const dispatch = useDispatch();
  const handleChange = (value) => {
    const langValue = multiLanguage.filter((element) => element.id === value);
    dispatch(setLanguage(langValue[0].value));
    i18next.changeLanguage(langValue[0].value);
  };

  const { lang } = useSelector((state) => state.setLang);
  
  const htmlElement = document.getElementsByTagName("html")[0];

  const storageLang = multiLanguage.filter(
    (element) => element.value === localStorage.getItem("lang")
  );

  useEffect(() => {
    dispatch(getLanguage());
  }, [dispatch]);

  useEffect(() => {
    if(lang){
      dispatch(setLanguage(lang));

      htmlElement.setAttribute("lang", lang);
    }
  }, [lang]);


  return (
    <StyledContainer>
      <Select
        allowClear={false}
        data={multiLanguage?.map(({ id, value }) => {
          return {
            id: id,
            name: <FlagSelect {...{ value, id }} />,
          };
        })}
        keys={["name"]}
        defaultValue={storageLang[0]?.id || 944}
        onChange={handleChange}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
 
 .ant-select-selection-item, .simpleSelect{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  }
  .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    font-size: ${(props) => props.fontSize || "16px"};
    font-family: Proxima Nova;
  }
  .ant-select-item-option-content {
    padding: 0 10px;
    :hover {
      background: #f8f8f8;
    }
  }
  .ant-select-selector {
    min-height: 30px !important;
    background: transparent !important;
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    top: 45% !important;
    right: 10px;
  }
  .ant-select {
    font-size: ${(props) => props.fontSize || "16px"};
    font-family: Proxima Nova;
    .ant-select-selection-placeholder {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #b1b3b3;
      text-align: left;
    }
    &:hover {
      transition: 0.2s all;
      box-sizing: border-box;
      border-radius: 8px;
    }
    &:focus {
      box-sizing: border-box;
    }
    .ant-select-selection-item {
      text-align: left;
      justify-content: flex-start!important;
    }
  }
  margin-left: 25px;
  .ant-select-single .ant-select-selector {
    border: none;
  }
`;
