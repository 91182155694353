import { createSlice } from "@reduxjs/toolkit";
import { BetweenMyAccountsValidate, BetweenMyAccountsProcess } from "./api_actions.js";

export {BetweenMyAccountsValidate, BetweenMyAccountsProcess}

//Between My Accounts
const initialState = {
  validate: null,
  process: null,
  error: null,
}
const BetweenMyAccountsSlice = createSlice({
  name: "getBtwAccounts",
  initialState,
  reducers: {
    reset:() => initialState
  },
  extraReducers: {
    [BetweenMyAccountsValidate.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.validate = data;
    },
    [BetweenMyAccountsValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [BetweenMyAccountsProcess.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.process = data;
    },
    [BetweenMyAccountsProcess.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const btwAccountsActions = BetweenMyAccountsSlice.actions;
export const GetBtwAccountsReducer = BetweenMyAccountsSlice.reducer;
