import React from "react";
import {
  // style
  StyledCard,
  StyledWrapper,
  StyledFlexBetween,
  StyledLine,
} from "components";

export const ComponentPaymentHeader = ({
  isPadding,
  icon,
  paymentName,
  buttons,
}) => {
  return (
    <>
      <StyledWrapper isPadding={isPadding}>
        <StyledCard>
          <img src={icon} alt={icon} />
          <p>
            {paymentName}
          </p>
        </StyledCard>
        <StyledFlexBetween>{buttons}</StyledFlexBetween>
      </StyledWrapper>
      <StyledLine></StyledLine>
    </>
  );
};
