import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

//ALL AutoComplite Payment Type
export const AutoComPaymentType = createAsyncThunk(
  "AutoComPaymentType",
  async (type, thunkAPI) => {

    try {
      const data = await client({
        url: `/autocomplete/all?payment-type=${type}`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "Get all payment type",
        token: false,
      })
      return data;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)