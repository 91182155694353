import React from 'react'
import {Form} from 'antd'
import styled from 'styled-components';
import {InfoCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {objectIsEmpty} from 'utils/shortFunctions'

const {Item} = Form;

//marginbottom -aşağıdan margin
//marginhorizontal-kənarlardan margin
export const  FormItem = ({
                             children,
                             // validateStatus,
                             // help,
                             auto = false,
                             info = {
                                 header: '',
                                 body: ''
                             },
                             autoheight = false,
                             ...rest
                         }) => {
    const {header, body} = info;
    // const status = help && !validateStatus ? 'error' : '';
    // console.log('Neticeeeler:',{...rest})
    return (
        <div>
            <StyledItem
                colon={true}
                // validateStatus={status}
                autoheight={autoheight ? "auto" : ''}  //TextAre varsa hight auto olsun(auto etməsək text area label-in üzərini örtür)
                // help={help}
                auto={auto ? "auto" : ''}
                tooltip={!objectIsEmpty(info) && {
                    title:
                        <StyledTooltip>
                            <h3>{header}</h3>
                            <p>{body}</p>
                        </StyledTooltip>,
                    icon: <InfoCircleOutlined/>,
                    color: '#fff'
                }}
                {...rest}
            >
                {children}
            </StyledItem>
        </div>
    );
}

FormItem.defaultProps = {
    autoheight: false,
};

FormItem.propTypes = {
    // children: PropTypes.element
}

const StyledItem = styled(Item)`
  position: relative;
  margin-bottom: ${({marginbottom}) => marginbottom || '20px'};
  margin-left: ${({marginhorizontal}) => marginhorizontal || '0'};
  margin-right: ${({marginhorizontal}) => marginhorizontal || '0'};
  .ant-form-item-label {
    padding-bottom: ${({labelheight}) => labelheight || '5px'}
  }

  .ant-form-item-control {
    height: ${({autoheight}) => autoheight || '50px'};
    line-height: unset;
  }
  .ant-form-item-label label {
    font-family: Proxima Nova;
    font-size: 14px;
    line-height: 19px;
    color: #75787B;
    height: 0;
    width: 100%;
    font-weight: 500;
  }

  label > div {
    display: flex;    
    justify-content: space-between;
    width: 100%;
  }

  .ant-form-item-label > label .ant-form-item-tooltip {
    color: #393939;
  }

`;

const StyledTooltip = styled.div`

  h3, p {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    padding: 3px;
  }

  h3 {
    color: #344054;
    font-weight: 500;
  }

  p {
    font-weight: 400;
    color: #555555;
  }
`
