import React, { Fragment, useState } from 'react';
import { Col, Form } from 'antd';

import { FormItem, LoginButton, CustomInput, Modal } from 'components';
import {
  emailRule,
  mobileRules,
  requiredRule,
  whitespaceRule,
} from 'utils/checkValidations';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  customersRegistr,
  customerOtp,
  getCustomerCheck,
} from 'store/anipayCabinet/api_action';
import OtpModal from '../component/OtpModal';

const UnRegistered = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState({
    visible: false,
    isSuccess: false,
    title: null,
    secondTitle: null,
    width: '406px',
    component: null,
  });

  const phoneFormat =(phoneNumber)=> '+' + phoneNumber.replace(/\D/g, '');


  const resendOtp = (customData) => {
    dispatch(customersRegistr(customData));
  };
  
  const verifyOtp = (code, recordId, phoneNumber) => {

    const body = {
      recordId: recordId,
      mobileNumber: phoneFormat(phoneNumber),
      confirmCode: code,
    };

    dispatch(customerOtp(body)).then((response) => {
      if (response?.payload?.status === 200 && response?.payload?.data?.recordId) {
        dispatch(getCustomerCheck());
        setModalState(null);
      }
    });
  };

  const onFinish = (values) => {

    const customData = {
      email: values.email,
      mobileNumber: phoneFormat(values.mobileNumber)
    };
    dispatch(customersRegistr(customData)).then((response) => {
      if (response.payload.status === 200) {
        setModalState({
          visible: true,
          width: '406px',
          title: 'Verification',
          component: (
            <OtpModal
              otpPhoneNumber={phoneFormat(values?.mobileNumber)}
              resendOtp={() => resendOtp(customData)}
              verifyOtp={verifyOtp}
            />
          ),
        });
      }
    });
  };

  return (
    <Fragment>
      <Form
        form={form}
        name="Register"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        hideRequiredMark={true}
        autoComplete="off"
      >
        <Col xl={10} sm={24} xs={24} className="general">
          <FormItem
            autoComplete="off"
            name="mobileNumber"
            autoheight
            rules={[mobileRules]}
          >
            <CustomInput label={t('mobile')} inputmask autoComplete="off" />
          </FormItem>
        </Col>
        <Col xl={10} sm={24} xs={24} className="general">
          <FormItem
            name="email"
            autoheight
            rules={[requiredRule, whitespaceRule, emailRule]}
          >
            <CustomInput label={t('mail')} placeholder={t('mail')} />
          </FormItem>
        </Col>

        <Col xl={10} sm={24} xs={24}>
          <FormItem>
            <LoginButton title={t('Registration')} htmlType="submit" />
          </FormItem>
        </Col>
      </Form>
      {!!modalState && (
        <Modal
          open={modalState.visible}
          cancel={() => setModalState({ visible: false })}
          title={modalState.title}
          secondTitle={modalState.secondTitle}
          width={modalState.width}
          isSuccess={modalState.isSuccess}
        >
          {modalState.component}
        </Modal>
      )}
    </Fragment>
  );
};

export default UnRegistered;
