

import {createSlice} from '@reduxjs/toolkit';
import {
    GetDeposits,
    GetDepositInfo,
    PaymentScheduleDeposit
} from './api_actions';

export {
    GetDeposits,
    GetDepositInfo,
    PaymentScheduleDeposit
}

//get all deposits
const GetDepositsSlice = createSlice({
    name: 'getDeposits',
    initialState: {
        deposits: [],
        isSuccess: false,
        error: null
    },
    extraReducers: {
        [GetDeposits.fulfilled]: (state, action) => {
            state.deposits = action.payload.data;
            state.isSuccess = true;
            state.error = null
        },
        [GetDeposits.rejected]: (state, action) => {
            state.error = action.payload;
            state.isSuccess = false;
        }
    }
});

export const getDepositsReducer = GetDepositsSlice.reducer;

//get deposit info
const GetDepositInfoSlice = createSlice({
    name: 'getDepositInfo',
    initialState: {
        depositInfo: null,
        error: null
    },
    extraReducers: {
        [GetDepositInfo.fulfilled]: (state, action) => {
            state.depositInfo = action.payload.data;
            state.error = null;
        },
        [GetDepositInfo.rejected]: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const getDepositInfoReducer = GetDepositInfoSlice.reducer;

//deposit payment schedule
const PaymentScheduleDepositSlice = createSlice({
    name: 'paymentScheduleDeposit',
    initialState: {
        paymentSchedule: null,
        pdfDownload: null,
        error: null
    },
    extraReducers: {
        [PaymentScheduleDeposit.fulfilled]: (state, action) => {
            state.paymentSchedule = action.payload.data;
            state.error = null;
        },
        [PaymentScheduleDeposit.rejected]: (state, action) => {
            state.error = action.payload;
        },
    }
});

export const paymentScheduleDepositReducer = PaymentScheduleDepositSlice.reducer;