import { createSlice } from '@reduxjs/toolkit'
import { DownloadeEmployeList } from './api_action';


export const DownloadeEmployeListSlice = createSlice({
    name: 'downloadeEmployeList',
    initialState: {
        empolyeListFile: null,
        error: null
    },
    reducers: {
        removeState: (state) => { state.empolyeListFile = null }
    },
    extraReducers: {
        [DownloadeEmployeList.fulfilled]: (state, action) => {
            const { data } = action.payload
            console.log('Data: ', data)
            state.empolyeListFile = 'success'

            let arr = data.content;
            let a = window.document.createElement('a');

            a.href = `data:application/xlsx;base64,${arr}`;
            a.download = data.name;

            // Append anchor to body.
            document.body.appendChild(a)
            a.click();

            // Remove anchor from body
            document.body.removeChild(a)

        },
        [DownloadeEmployeList.rejected]: (state, action) => {
            state.error = action.payload
            state.empolyeListFile = null
        }
    }
});

export { DownloadeEmployeList }

export const downloadeEmployeListReducer = DownloadeEmployeListSlice.reducer;
