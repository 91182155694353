import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { responsive, size } from 'utils/responsiveUI';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { express_logo, expressSmall, selectArrow, callHeader } from 'static/svg';
import { GetPermissionsList } from 'store/auth';
import styled from 'styled-components';
import Menus from './Menus';
import { LanguageSelect } from '../LanguageSelect';
import { ResponsiveComponent } from 'components/ResponsiveComponent';

export const SidebarIB = ({ collapsed, toggle, setToggle, setCollapsed }) => {
  //services
  const dispatch = useDispatch();
  //routing
  const history = useHistory();
  const componentRef = useRef();

  const { Sider } = Layout;

  function handleClick(e) {
    if (componentRef && componentRef.current) {
      const ref = componentRef.current;
      if (!ref.contains(e.target)) {
        if (toggle) {
          setToggle(false);
        }
      }
    }
  }

  useEffect(() => {
    dispatch(GetPermissionsList());
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [toggle]);

  const handleClicktoMainPage = () => {
    history.push('/');
  };

  return (
    <StyledLayout collapsed={collapsed} toggle={toggle}>
      <Sider collapsed={collapsed} ref={componentRef}>
        <StyledHeader>
          <StyledLogo collapsed={collapsed}>
            <div style={{ width: '100%' }} onClick={handleClicktoMainPage}>
              <img src={collapsed ? expressSmall : express_logo} alt="logo" />
            </div>
            <StyledLng>
              <ResponsiveComponent isMobile>
                <LanguageSelect />
              </ResponsiveComponent>

              <div style={{ display: 'none' }} className={'sidebarhead'}>
                <img src={callHeader} alt="express_logo" /> <span>132</span>
              </div>
            </StyledLng>
          </StyledLogo>
          <div className="cloneMenu" onClick={() => setToggle(!toggle)}>
            {/* <img src={close} alt="close" /> */}
          </div>
        </StyledHeader>

        <StyleLine collapsed={collapsed} />

        <StyleMenu collapsed={collapsed}>
          <Menus collapsed={collapsed} toggle={toggle} setToggle={setToggle} />
        </StyleMenu>
        <StyleTrigger
          collapsed={collapsed}
          toggle={toggle}
          onClick={() =>
            window.innerWidth > parseInt(size.laptop)
              ? setCollapsed(!collapsed)
              : setToggle(!toggle)
          }
        >
          <span className={collapsed ? 'trigger active' : 'trigger'}>
            <img src={selectArrow} alt="arrowIcon" />
          </span>
        </StyleTrigger>
      </Sider>
    </StyledLayout>
  );
};

const StyledLng = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div {
    margin-left: 0;
  }
  .ant-select-arrow {
    display: flex;
    align-items: center;
    right: 35px;
  }
  .ant-select-selector {
    display: flex;
    right: 30px;
  }
`;
const StyledLayout = styled.aside`
  background-color: #fff !important;
  transition: all 1s ease-in-out;
  border-right: 1px solid #e0e0e0;
  .ant-menu-item {
    padding: 0 12px;
  }
  .ant-menu-item {
    font-size: 14px !important;
  }
  .ant-menu-submenu {
    font-size: 14px !important;
  }
  .ant-layout-sider {
    background-color: #fff !important;
    flex: ${(props) => (props.collapsed ? '100px' : '290px')} !important;
    max-width: ${(props) => (props.collapsed ? '100px' : '290px')} !important;
    min-width: ${(props) => (props.collapsed ? '100px' : '290px')} !important;
    width: ${(props) => (props.collapsed ? '100px' : '290px')} !important;

    .cloneMenu {
      display: none;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  @media ${responsive.laptop} {
    margin-left: ${(props) => (props.toggle ? '0' : '-1500px')};
    position: absolute;
    z-index: 111;
    height: 100vh;
    transition: all 0.5s ease-in-out;
    .ant-layout-sider {
      .cloneMenu {
        display: block;
        cursor: pointer;
      }
    }
    .sidebarhead {
      display: flex !important;
      margin-top: -4px !important;
      align-items: center;
    }

    .ant-layout-sider {
      max-width: 100vw !important;
      min-width: 80vw !important;
      width: 70vw !important;
      max-width: ${(props) => (props.collapsed ? '0' : '80vw')} !important;
      min-width: ${(props) => (props.collapsed ? '0' : '80vw')} !important;
      width: ${(props) => (props.collapsed ? '0' : '70vw')} !important;
    }
  }
  @media ${responsive.desktop} {
    .ant-menu-item {
      font-size: 13px !important;
    }
    .ant-menu-submenu {
      font-size: 13px !important;
    }
    .ant-layout-sider {
      background-color: #fff !important;
      flex: ${(props) => (props.collapsed ? '100px' : '275px')} !important;
      max-width: ${(props) => (props.collapsed ? '100px' : '275px')} !important;
      min-width: ${(props) => (props.collapsed ? '100px' : '275px')} !important;
      width: ${(props) => (props.collapsed ? '100px' : '275px')} !important;
    }
  }
`;

const StyleMenu = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  @media (max-width: 450px) {
    padding-bottom: 110px;
  }
  .ant-menu-submenu-arrow {
    right: 40px;
  }

  .ant-menu-item .anticon,
  .ant-menu-submenu .anticon {
    margin-right: ${(props) => (props.collapsed ? ' 0' : '27px')};
  }

  ::-webkit-scrollbar-thumb {
    // width: 5px;
    background-color: #8e8e8e;
  }
  .ant-menu-submenu-open .active,
  .ant-menu-item-active .active {
    background-color: ${(props) => (props.collapsed ? '#F2F2F2' : 'none')};
  }

  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    .active {
      background-color: #f2f2f2;
      color: #393939;
      height: 50px;
    }

    /* .active img {
      filter: invert(70%) sepia(22%) saturate(4255%) hue-rotate(2deg)
        brightness(107%) contrast(103%);
    } */
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #323232;

    &:active {
      background-color: transparent;
    }

    .active {
      color: #393939;
      border-radius: 8px;

      &:hover {
        color: #393939;
        background-color: #f2f2f2;
      }
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      .active {
        margin-right: ${(props) => (props.collapsed ? '0' : '-18px')};
      }
    }
  }

  .ant-menu-submenu-title {
    &:active {
      background-color: transparent;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    display: ${(props) => (props.collapsed ? 'flex' : 'block')};
    justify-content: center;
    align-items: center;
  }

  .ant-menu-submenu span {
    &:hover {
      color: #323232 !important;
    }
  }

  .ant-menu-inline-collapsed {
    width: 100px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
  }

  .ant-menu-vertical .ant-menu-item {
    margin: 12px 0;
  }

  .ant-menu-inline .ant-menu-item-selected,
  .ant-menu-vertical .ant-menu-item-selected {
    &:after {
      right: ${(props) => (props.collapsed ? '95%' : '98%')} !important;
      border-right: 10px solid transparent;
      border-radius: 0px 3px 3px 0px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 10px !important;
      transform: scaleY(1);
      opacity: 1;
    }
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: #fff;
  }

  .ant-menu-root {
    .ant-menu-item,
    .ant-menu-submenu-title {
      height: 50px;
    }

    .ant-menu-item:not(.ant-menu-item-only-child) {
      padding-left: 20px !important;
    }
  }

  .ant-menu-sub {
    .ant-menu-item {
      padding-left: 24px !important;
    }
  }

  .ant-menu-item-active {
    .active {
      background-color: #f2f2f2;
      height: 50px;
    }
  }
`;
const StyledHeader = styled.div`
  @media ${responsive.laptop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0px;
    width: 100%;
  }
`;
const StyledLogo = styled.div`
  padding: ${(props) => (props.collapsed ? '32px 24px 15px' : '20px 24px 4px;')};
  display: flex;
  justify-content: ${(props) => (props.collapsed ? 'center' : 'left')};
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;

  @media ${responsive.laptop} {
    padding: 0;
  }

  span {
    font-size: 22px;
    font-weight: 700;
    font-family: Proxima Nova;
    justify-content: flex-end !important;
  }
`;
const StyleLine = styled.div`
  border-bottom: 1px solid #e3e3e3;
  margin: 16px 32px;
`;

const StyleTrigger = styled.span`
  padding: 18px 4px;
  width: 18px;
  height: 60px;
  background: #ffb500;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  vertical-align: sub;
  bottom: 32px;
  cursor: pointer;
  z-index: 1;
  left: ${(props) => (props.collapsed ? '82%' : '94%')};

  img {
    filter: invert(100%) sepia(0%) saturate(5%) hue-rotate(71deg) brightness(264%)
      contrast(105%) !important;
  }

  @media ${responsive.laptop} {
    // display: none;
  }
`;
