import React, { useEffect, useState } from 'react';
import Registered from './registered';
import { Content, SingleButton } from 'components';
import { useTranslation } from 'react-i18next';
import { Plus } from 'static/svg';

import AddAliasModal from './component/addAliasModal';
import styled from 'styled-components';
import { Form, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerCheck, getCustomersData } from 'store/anipayCabinet';
import UnRegistered from './unregistered';

const AniPayCabinet = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [aliasCkeck, setAliasCkeck] = useState(false);
  const [changeFormValues, setChangeFormValues] = useState();

  const [form] = Form.useForm();
  const { customerCheckId } = useSelector((state) => state.anipayCabinet);
  const allLoading = useSelector((state) => state.loadings);

  const dispatch = useDispatch();
  const handleAddAlias = () => {
    setModalVisible(true);
  };

  const handleCancel = (e) => {
    setModalVisible(false);
    setAliasCkeck(false);
    form.resetFields();
    setChangeFormValues();
  };

  useEffect(() => {
    dispatch(getCustomerCheck());
  }, [dispatch]);

  useEffect(() => {
    if (customerCheckId?.recordId) {
      dispatch(getCustomersData(customerCheckId?.recordId));
    }
  }, [customerCheckId, dispatch]);

  return (
    <Content
      title={t('anipaycabinet')}
      second_component={
        !customerCheckId?.isCustomerExist ? null : (
          <SingleButton text={'Add alias'} icon={Plus} onClick={handleAddAlias} />
        )
      }
    >
      {allLoading?.getCustomerCheck ? (
        <SpinWrapper>
          <Spin spinning={allLoading?.getCustomerCheck} />
        </SpinWrapper>
      ) : !customerCheckId?.isCustomerExist ? (
        <UnRegistered />
      ) : (
        <Registered />
      )}

      <StyledModal
        visible={modalVisible}
        title={t('Add alias')}
        onCancel={handleCancel}
        centered={true}
        footer={false}
        width={550}
      >
        <AddAliasModal
          aliasCkeck={aliasCkeck}
          setAliasCkeck={setAliasCkeck}
          setModalVisible={setModalVisible}
          changeFormValues={changeFormValues}
          setChangeFormValues={setChangeFormValues}
          form={form}
        />
      </StyledModal>
    </Content>
  );
};

export default AniPayCabinet;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 30px;
  }
`;
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
