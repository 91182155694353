import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Modal, ResponsiveComponent, StatusInfo } from 'components';
import { accounter_black, bank_black, director_black, infoblack } from 'static/svg';
import { useTranslation } from 'react-i18next';
import { responsive } from '../../utils/responsiveUI';

export const StepStatus = ({ operationData }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState({
    visible: false,
    isSuccess: false,
    title: null,
    secondTitle: null,
    width: '500px',
    component: null,
  });

  const statuses = [
    {
      id: 'Rejected',
      name: <StyledStatusItem status="Rejected">{t('Rejected')}</StyledStatusItem>,
    },
    {
      id: 'Completed',
      name: <StyledStatusItem status="Completed">{t('Completed')}</StyledStatusItem>,
    },
    {
      id: 'ToBeSign',
      name: <StyledStatusItem status="ToBeSign">{t('to_be_sign')}</StyledStatusItem>,
    },
    {
      id: 'InBank',
      name: <StyledStatusItem status="InBank">{t('CHECKINGBANK')}</StyledStatusItem>,
    },
    {
      id: 'MustBeChanged',
      name: (
        <StyledStatusItem status="MustBeChanged">
          {t('mustBeChanged')}
        </StyledStatusItem>
      ),
    },
  ];

  const renderStepCards = (data, imageSrc, together) => {
    const statusStyles = {
      NEED_SIGN:
        'invert(72%) sepia(39%) saturate(5037%) hue-rotate(0deg) brightness(104%) contrast(106%)',
      PASSED:
        'invert(91%) sepia(26%) saturate(342%) hue-rotate(66deg) brightness(98%) contrast(96%)',
      SIGNED:
        'invert(72%) sepia(63%) saturate(6138%) hue-rotate(118deg) brightness(97%) contrast(86%)',
      REJECTED:
        'invert(76%) sepia(72%) saturate(6198%) hue-rotate(328deg) brightness(100%) contrast(101%)',
      default:
        'invert(90%) sepia(5%) saturate(13%) hue-rotate(356deg) brightness(87%) contrast(94%)',
    };

    const borderColors = {
      NEED_SIGN: '#FFA300',
      REJECTED: '#FF5252',
      default: '#C6C6C6',
    };

    const getBorderStatusColor = (data = []) => {
      for (const item of data) {
        if (item.signStatus === 'NEED_SIGN') {
          return 'NEED_SIGN';
        }
        if (item.signStatus === 'REJECTED') {
          return 'REJECTED';
        }
      }
      return 'default';
    };

    return (
      <>
        <ResponsiveComponent>
          <StyledBox
            style={{ borderColor: borderColors[getBorderStatusColor(data)] }}
          >
            {data?.map((item) => (
              <Fragment key={item.fullName}>
                <StepCard>
                  <img
                    src={imageSrc}
                    alt=""
                    style={{
                      filter: statusStyles[item?.signStatus] || statusStyles.default,
                    }}
                  />
                  <h4>{item.fullName}</h4>
                  <p>{item.positionDescription}</p>
                </StepCard>

                <StepCardLine together={together}>
                  <StyleLine />
                </StepCardLine>
              </Fragment>
            ))}
          </StyledBox>
          <StepCardLine>
            <StyleLine />
          </StepCardLine>
        </ResponsiveComponent>

        <ResponsiveComponent isMobile>
          <StyledBoxMobile
            style={{ borderColor: borderColors[getBorderStatusColor(data)] }}
          >
            {data?.map((item) => (
              <>
                <StyleContent key={12}>
                  <StepImg>
                    <img
                      src={imageSrc}
                      alt=""
                      style={{
                        filter:
                          statusStyles[item?.signStatus] || statusStyles.default,
                      }}
                    />
                  </StepImg>
                  <StepText>
                    <h4>{item.fullName}</h4>
                    <p>{item.positionDescription}</p>
                  </StepText>
                </StyleContent>
                <StepCardLineMobile together={together}>
                  <StyleLineMobile />
                </StepCardLineMobile>
              </>
            ))}
          </StyledBoxMobile>
        </ResponsiveComponent>
      </>
    );
  };

  const renderBankCard = (operationStatus) => {
    const statusStyles = {
      AUTH: 'invert(54%) sepia(71%) saturate(515%) hue-rotate(100deg) brightness(90%) contrast(91%)',
      REJECTBANK:
        'invert(37%) sepia(57%) saturate(3893%) hue-rotate(337deg) brightness(124%) contrast(101%)',
      CHECKINGBRANCH:
        'invert(57%) sepia(98%) saturate(672%) hue-rotate(357deg) brightness(99%) contrast(110%)',
      CHECKINGBANK:
        'invert(57%) sepia(98%) saturate(672%) hue-rotate(357deg) brightness(99%) contrast(110%)',
      MUSTBECHANGE:
        'invert(71%) sepia(30%) saturate(7407%) hue-rotate(238deg) brightness(85%) contrast(81%)',
      default:
        'invert(90%) sepia(5%) saturate(13%) hue-rotate(356deg) brightness(87%) contrast(94%)',
    };

    const borderColors = {
      AUTH: '#12B76A',
      CHECKINGBRANCH: '#FFA300',
      CHECKINGBANK: '#FFA300',
      REJECTBANK: '#FF5252',
      MUSTBECHANGE: '#BA68C8',
      default: '#C6C6C6',
    };

    return (
      <>
        <ResponsiveComponent>
          <StyledBox
            style={{
              borderColor: borderColors[operationStatus] || borderColors.default,
            }}
          >
            <StepCard>
              <img
                src={bank_black}
                alt=""
                style={{
                  filter: statusStyles[operationStatus] || statusStyles.default,
                }}
              />
              <h4>{t('Expressbank')}</h4>
              <p>{t('Bank')}</p>
            </StepCard>
          </StyledBox>
        </ResponsiveComponent>
        <ResponsiveComponent isMobile>
          <StyledBoxMobile
            style={{
              borderColor: borderColors[operationStatus] || borderColors.default,
            }}
          >
            <StyleContent key={12}>
              <StepImg>
                <img
                  src={bank_black}
                  alt=""
                  style={{
                    filter: statusStyles[operationStatus] || statusStyles.default,
                  }}
                />
              </StepImg>
              <StepText>
                <h4>{t('Expressbank')}</h4>
                <p>{t('Bank')}</p>
              </StepText>
            </StyleContent>
          </StyledBoxMobile>
        </ResponsiveComponent>
      </>
    );
  };

  return (
    <>
      <StyleContainer>
        <StyledHeader>
          <span>{t('Status')}</span>
          <span
            onClick={() =>
              setModalState({
                visible: true,
                title: 'Status info',
                component: (
                  <StatusInfo
                    cancelOnClick={() => setModalState({ visible: false })}
                    statuses={statuses}
                    // mainOnClick={(value) => renameDepositName(value, contractNumber)}
                  />
                ),
              })
            }
          >
            <img src={infoblack} alt="info" />
          </span>
        </StyledHeader>
        <div>
          {t('Created by')}: <strong>{operationData?.creator}</strong>
        </div>
        <StyledSteps>
          {operationData?.signChainData?.accountants?.together?.length > 0 &&
            renderStepCards(
              operationData?.signChainData?.accountants?.together,
              accounter_black,
              false
            )}

          {operationData?.signChainData?.accountants?.separately?.length > 0 &&
            renderStepCards(
              operationData?.signChainData?.accountants?.separately,
              accounter_black,
              true
            )}

          {operationData?.signChainData?.directors?.together?.length > 0 &&
            renderStepCards(
              operationData?.signChainData?.directors?.together,
              director_black,
              false
            )}

          {operationData?.signChainData?.directors?.separately?.length > 0 &&
            renderStepCards(
              operationData?.signChainData?.directors?.separately,
              director_black,
              true
            )}

          {renderBankCard(operationData?.operationStatusCode)}
        </StyledSteps>
      </StyleContainer>

      <Modal
        open={modalState.visible}
        cancel={() => setModalState({ visible: false })}
        title={modalState.title}
        secondTitle={modalState.secondTitle}
        width={modalState.width}
        isSuccess={modalState.isSuccess}
      >
        {modalState.component}
      </Modal>
    </>
  );
};

const StyledStatusItem = styled.span`
  background-color: ${(props) =>
    props.status === 'ToBeSign'
      ? '#FFF4E0'
      : props.status === 'Completed'
      ? '#E7F8F0'
      : props.status === 'Rejected'
      ? '#FEE8E7'
      : props.status === 'InBank'
      ? '#E4F1FF'
      : props.status === 'MustBeChanged'
      ? '#F4EBFF'
      : '#E3E3E3'};
  color: ${(props) =>
    props.status === 'ToBeSign'
      ? '#FFA000'
      : props.status === 'Completed'
      ? '#12B76A'
      : props.status === 'Rejected'
      ? '#F44336'
      : props.status === 'InBank'
      ? '#3D89FF'
      : props.status === 'MustBeChanged'
      ? '#7F56D9'
      : '#555555'};
  padding: 0 7px;
  border-radius: 6px;
  font-size: 14px;
  width: max-content !important;
`;

const center = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyleContainer = styled.div`
  margin: 32px 0;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  span:nth-child(2) {
    cursor: pointer;
  }

  span {
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: #1c1c1c;
    margin-bottom: 5px;
    margin-right: 13px;
  }
`;

const StyledSteps = styled.div`
  display: flex;
  padding: 28px 0 40px;
  overflow-x: scroll;
  gap: 14px;

  &::-webkit-scrollbar {
    height: 7px;
    background: #aaaaaa;
  }

  @media ${responsive.laptop} {
    overflow-x: auto;
    display: block;
  }
`;

const StepCard = styled.div`
  min-width: 150px;
  text-align: center;
  height: 100%;

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 17px;
  }

  h4 {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #393939;
    margin-bottom: 6px;

    font-family: Proxima Nova;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #63666a;
    margin-bottom: 0;

    font-family: Proxima Nova;
    font-weight: 400;
  }
`;

const StepCardLine = styled.div`
  transform: ${(props) => (props.together ? 'rotate(90deg)' : 'rotate(0)')};

  &:nth-last-child(1) {
    display: none;
  }

  ${center}
`;
const StyleLine = styled.div`
  width: 62px;
  height: 1px;
  background-color: #c6c6c6;
`;

const StepCardLineMobile = styled.div`
  margin-left: 28px;
  width: 28px;
  transform: ${(props) => (props.together ? 'rotate(0)' : 'rotate(90deg)')};

  &:nth-last-child(1) {
    display: none;
  }

  ${center}
`;
const StyleLineMobile = styled.div`
  width: 28px;
  height: 1px;
  background-color: #c6c6c6;
`;

// Mobile Style

const StyledStepsMobile = styled.div``;

const StyleContent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 20px 26px;
  gap: 15px;
`;

const StyledBox = styled.div`
  border: 1.5px solid #e3e3e3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 19px 28px 14px;
`;

const StyledBoxMobile = styled.div`
  margin-top: 12px;
  border: 1.5px solid #e3e3e3;
  border-radius: 8px;
`;

const StepText = styled.div`
  font-family: Proxima Nova;

  h4,
  p {
    margin-bottom: 0;
  }

  h4 {
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #393939;
    font-size: 17px;
    font-weight: 600;
  }

  p {
    color: #63666a;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 6px;
  }
`;

const StepImg = styled.div`
  img {
    width: 32px;
    height: 32px;
  }
`;
