import { center } from "components";
import React, { Fragment } from "react";
import { callHeader } from "static/svg";
import styled from "styled-components";

const Footer = () => {
  return (
    <Fragment>
      <FooterContainer>
        <div className="phone">
          <img src={callHeader} alt="express_logo" />{" "}
          <div className="phoneText">132</div>
        </div>
      </FooterContainer>
    </Fragment>
  );
};

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Proxima Nova";
  .info {
    ${center}
    gap: 16px;
    .infoText {
      font-size: 20px;
      font-weight: 500;
      color: #393939;
    }
  }
  .phone {
    ${center}
    gap: 12px;
    .phoneText {
      font-weight: 700;
      font-size: 24px;
      color: #393939;
    }
  }
`;
