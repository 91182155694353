
export const payments = [
    
    {
        key: 22,
        name: 'Budget',
        to: '/payments/budget',
        permissions: 'budget'
    },
    {
        key: 23,
        name: 'State_treasury',
        to: '/payments/vat',
        permissions: 'stateTreasury'
    },
    // {
    //     key: 25,
    //     name: 'Government Payment Portal',
    //     to: '/payments/gpp'
    // },
    {
        key: 24,
        name: 'Salary',
        to: '/payments/salary',
        permissions: "salary"
    },
]


export const products =[
    {
        key: 11,
        name: 'Accounts',
        to: '/product/accounts',
        permissions: 'accounts'

    },
    {
        key: 13,
        name: 'Cards',
        to: '/product/cards',
        permissions: 'cards'
    },
    {
        key: 12,
        name: 'Loans',
        to: '/product/loans',
        permissions: 'loans'
    },
    {
        key: 14,
        name: 'Deposits',
        to: '/product/deposits',
        permissions: 'deposits'
    },
    {
        key: 15,
        name: 'Guarantees',
        to: '/product/guarantees',
        permissions: 'guarantees'
    },
]

export const transfer = [
    {
        key: 41,
        name: 'inside_country',
        to: '/payments/inside-country',
        permissions: 'insideCountry'
    },
    {
        key: 42,
        name: 'within_bank',
        to: '/payments/within-bank',
        permissions: 'withinBank'
    },
    {
        key: 43,
        name: 'between_my_account',
        to: '/payments/between-my-accounts',
        permissions: 'betweenMyAccounts'
    },
    {
        key: 44,
        name: 'International',
        to: '/payments/international',
        permissions: 'international'
    },
    {
        key: 45,
        name: 'AniPay',
        to: '/payments/anipay',
        permissions: 'aniPay'
    },
]