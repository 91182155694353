import { createSlice } from '@reduxjs/toolkit'
import { GetAllBalance, GetTotalBalance } from './api_action';

export { GetAllBalance , GetTotalBalance }


const initialState = {
    allBalanceData: null,
    totalBalanceData: null,
    error: null,
  }

const GetBalanceSlice = createSlice({
    name: 'getBalance',
    initialState,
    extraReducers: {
        [GetAllBalance.fulfilled]: (state, action) => {
            state.allBalanceData = action.payload.data
        },
        [GetTotalBalance.fulfilled]: (state, action) => {
            state.totalBalanceData = action.payload.data
        },
        [GetAllBalance.rejected]: (state, action) => {
            state.error = action.payload
            state.allBalanceData = null
        },
        [GetTotalBalance.rejected]: (state, action) => {
            state.error = action.payload
            state.totalBalanceData = null
        }
    }
});

export const getBalanceReducer = GetBalanceSlice.reducer;

