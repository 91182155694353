import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

//Inside Country Propses
export const StateTreasuryProses = createAsyncThunk(
  "StateTreasuryProses",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: "/state-treasury-payment-ms/api/v1/process",
        data: JSON.stringify(body),
        method: "POST",
        showSuccessToast: true,
        showErrorToast: true,
        errorMessage: "Tam qeyd olunmayin",
        succesMessage: "paymentSuccess",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

//Inside Country
export const StateTreasuryValidate = createAsyncThunk(
  "StateTreasuryValidate",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: "/state-treasury-payment-ms/api/v1/validate",
        data: JSON.stringify(body),
        method: "POST",
        // showSuccessToast: true,
        showErrorToast: true,
        errorMessage: "Tam qeyd olunmayin",
        // succesMessage: "Successfully",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

