import { createSlice } from '@reduxjs/toolkit'
import { GetBankInfoByCode } from './api_action';


const GetBankInfoByCodeSlice = createSlice({
    name: 'getBankInfoByCode',
    initialState: {
        bankInfo: null,
        error: null
    },
    extraReducers: {
        [GetBankInfoByCode.fulfilled]: (state, action) => {
            state.bankInfo = action.payload.data
        },
        [GetBankInfoByCode.rejected]: (state, action) => {
            state.error = action.payload
            state.bankInfo = null
        }
    }
});

export { GetBankInfoByCode }

export const getBankInfoByCodeReducer = GetBankInfoByCodeSlice.reducer;
