import { createSlice } from "@reduxjs/toolkit";
import { LanguageDictonary } from "./api_action";

const langSlice = createSlice({
  name: "lang",
  initialState: {
    lngdata: [],
  },
  extraReducers: {
    [LanguageDictonary.fulfilled]: (state, action) => {
      state.lngdata = action.payload.data
      // console.log('action',action)
    }
  }
});

export { LanguageDictonary };

export default langSlice.reducer;

//Logout
const languageSlice = createSlice({
  name: 'languageSlice',
  initialState: {
    lang: ''
  },
  reducers: {
    setLanguage: (state, action) => {
      state.lang = action.payload
      localStorage.setItem('lang', state.lang)
      // const languageInterceptor = {
      //     processRequest: (request) => {
      //       const language = localStorage.getItem('lang');
      //       if (language) {
      //         request.headers.set('Accept-Language', language);
      //       }
      //       return request;
      //     }
      //   }

      //   // Add the interceptor to your API client
      //   const apiClient = client();
      //   apiClient.addInterceptor(languageInterceptor);
    },
    getLanguage: (state) => {
      state.lang = localStorage.getItem('lang') || 'az'
    },
  }
});

export const languageReducer = languageSlice.reducer;
export const { setLanguage, getLanguage } = languageSlice.actions;
