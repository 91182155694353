import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { asan_signature } from 'static/svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AsanSign, resetError } from 'store/auth';
import { Modal } from 'components';
import { responsive } from 'utils/responsiveUI';
import AsanCompanyList from '../CompanyList/AsanCompanyList';
import { openNotificationWithIcon } from 'client';
import { ErrorMessage } from 'utils/errorMessage';
import { setVoen } from '../../../../../store/authInfo';

const AsanSignature = ({ setCheckSms }) => {
  //translation
  const { t } = useTranslation();
  //routing
  const history = useHistory();
  //for services
  const dispatch = useDispatch();
  //modal
  const [modalVisible, setModalVisible] = useState(false);
  const [timer, setTimer] = useState(180); // 180 sec - 3 minutes
  const [seconds, setSeconds] = useState(0);
  const [seenError, setSeenError] = useState(false);
  const tick = useRef();
  const asanInfo = useSelector((state) => state.asanAuthenticate.asanInfo);
  const signInfo = useSelector((state) => state.asanSign.asanSignData);
  const { error } = useSelector((state) => state.asanSign);

  useEffect(() => {
    if (signInfo) {
      localStorage.setItem('tokenInfo', signInfo?.accessToken?.accessToken);
      localStorage.setItem(
        'remainingTimeInSeconds',
        signInfo?.refreshToken?.remainingTimeInSeconds
      );
      localStorage.setItem('refreshToken', signInfo?.refreshToken?.token);
      localStorage.setItem(
        'refreshTokenExpiryDate',
        signInfo?.refreshToken?.expiryDate
      );
      // localStorage.setItem('infoModal',true)
      if (signInfo?.voenList?.length <= 1) {
        setModalVisible(false);
        localStorage.setItem('voen', signInfo?.voenList[0]);

        const replacedUrl = window.location.href.replace('/login', '/');
        window.location.href = replacedUrl;
        // localStorage.setItem('kobModal',true)
      } else {
        setModalVisible(true);
      }
    }
  }, [signInfo]);

  useEffect(() => {
    if (timer !== 0) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [timer]);

  useEffect(() => {
    let interval;
    let hasError =
      error?.data?.message === 'USER_CANCEL' ||
      error?.data?.message === 'SIM_ERROR' ||
      error?.data?.message === 'INTERNAL_ERROR';
    if (!modalVisible && !hasError) {
      interval = setInterval(() => {
        if (asanInfo) {
          dispatch(
            AsanSign({
              sessionId: {
                sessionId: asanInfo?.sessionId,
              },
              history,
            })
          );
        }

        if (timer !== 0) {
          setSeconds((seconds) => seconds + 1);
        } else {
          setCheckSms(false);
          dispatch(resetError());
        }
      }, 2500);
    }

    if (hasError && !seenError) {
      setSeenError(true);
      clearInterval(interval);
      openNotificationWithIcon('error', ErrorMessage(error?.data));
      setTimeout(() => {
        setCheckSms(false);
        dispatch(resetError());
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [modalVisible, seconds, error, dispatch]);

  // popup props
  const props = {
    modalVisible,
    setModalVisible,
  };

  const onClose = () => {
    localStorage.removeItem('loginStatus');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiryDate');
    localStorage.removeItem('tokenInfo');
    setModalVisible(false);
    window.location.reload();
  };

  return (
    <StyledSignature>
      <img src={asan_signature} alt="asan icon" />
      <h4>{t('asanSignatureText1')}</h4>
      <p>{t('asanSignatureText2')}</p>
      <span>{asanInfo?.verificationCode}</span>

      <Modal
        open={modalVisible}
        cancel={onClose}
        title={t('chooseCompany')}
        headerColor="#fff"
        width="524px"
      >
        <AsanCompanyList {...props} />
      </Modal>
    </StyledSignature>
  );
};

export default AsanSignature;

const StyledSignature = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;

  h4 {
    font-weight: 600;
    font-size: 22px;
    color: #1c1c1c;
  }

  p {
    color: #63666a;
    font-size: 20px;
  }

  span {
    width: 160px;
    background: #fcfcfc;
    text-align: center;
    padding: 16px 48px;
    border: 1px solid #ffa300;
    border-radius: 16px;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #ffa300;
  }

  img {
    width: 160px;
    margin-bottom: 32px;
  }

  @media ${responsive.laptop} {
    margin-top: 0;
    h4 {
      font-size: 20px;
    }

    p {
      font-size: 18px;
    }

    span {
      padding: 10px;
    }
  }
`;
