import { Col } from 'antd';
import React from 'react';
import { download, excellError, trash } from 'static/svg';
import { FileImgType } from 'constant/consts';
import styled from 'styled-components';
import { StyledFlexBetween, Tooltip } from 'components';
import { useTranslation } from 'react-i18next';

const DesktopVersion = ({
  col,
  fileList,
  handleDelete,
  handleDownloadUploadFile,
  salaryuploadStatus,
  uploadDataBatchId,
  salaryDownloadCheck,
  swift,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {fileList?.map((item, index) => (
        <Col span={col}>
          <StyledCol salaryuploadStatus={salaryuploadStatus}>
            <div className="containerName">
              <img
                src={
                  swift
                    ? FileImgType[item?.type]?.icon
                    : !salaryuploadStatus
                    ? excellError
                    : FileImgType[item?.type]?.icon
                }
                alt={'uploadFile'}
              />

              <div className="fileFullName">
                <span className="FileName">{item?.name}</span>
                <span className="fileSize">
                  {(item?.size / 1024 / 1024).toFixed(2)} MB
                </span>
              </div>
            </div>

            {salaryuploadStatus || swift ? (
              <StyledUploadText
                onClick={() => {
                  handleDelete(item);
                }}
              >
                <img src={trash} alt={'delete'} className="" id={index} />
                <span className="">{t('Delete')}</span>
              </StyledUploadText>
            ) : (
              <StyledFlexBetween>
                <StyledUploadText
                  onClick={() => {
                    handleDelete(item);
                  }}
                >
                  <img
                    src={trash}
                    alt={'delete'}
                    className="trashError"
                    id={index}
                  />
                  <span className="trashErrorTxt">{t('Delete')}</span>
                </StyledUploadText>
                {!(salaryDownloadCheck && !uploadDataBatchId) && (
                  <StyledUploadText
                    onClick={() => {
                      handleDownloadUploadFile();
                    }}
                  >
                    <img src={download} alt="download" />
                    <span>{t('Download')}</span>
                  </StyledUploadText>
                )}
              </StyledFlexBetween>
            )}
          </StyledCol>
        </Col>
      ))}
    </>
  );
};

const StyledCol = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1700px) {
    display: block;
    margin-top: ${({ salaryuploadStatus }) => (salaryuploadStatus ? '0' : '20px')};
  }
  .containerName {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .fileFullName {
    display: flex;
    flex-direction: column;
  }
  .FileName {
    color: #555555;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  .fileSize {
    font-weight: 400;
    color: #8e8e8e;
    font-size: 13px;
  }
`;

const StyledUploadText = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
  }
  .trashError {
    filter: invert(46%) sepia(25%) saturate(6483%) hue-rotate(333deg)
      brightness(110%) contrast(116%);
  }
  .trashErrorTxt {
    color: #ff5252;
  }
`;
export default DesktopVersion;
