import {createAsyncThunk} from '@reduxjs/toolkit';
import {client} from 'client';


const voen = localStorage.getItem("voen")

//all cards
export const GetCards = createAsyncThunk(
    "GetCards",
    // 1501323621
    async ( thunkAPI) => {
        try {
            const data = await client({
                url: `/card-ms/api/v1/card/list/${voen}`,
                showErrorToast: true,
                token: false,
            })
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
);

export const GetCardsInfo = createAsyncThunk(
    'GetCardsInfo',
    async (cardNo, thunkAPI) => {
        try {
            const data = await client({
                url: `/card-ms/api/v1/card/details/${cardNo}`,
                showErrorToast: true,
                token: false
            });
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);



//rename card
export const RenameCard = createAsyncThunk(
    "RenameCard",
    async (cardInfo, thunkAPI) => {
        const {setModalFunc} = cardInfo;
        try {
            const data = await client({
                url: `/card-ms/api/v1/card/rename-title`,
                data: cardInfo,
                method: 'POST',
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "Card name was changed successfully",
                token: false,
                successCallback: () => setModalFunc({visible: false})
            })
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

//block the card
export const ChangeCardStatus = createAsyncThunk(
    "ChangeCardStatus",
    async (cardInfo, thunkAPI) => {
        try {
            const data = await client({
                url: `/card-ms/api/v1/card/change-status`,
                method: 'POST',
                data: cardInfo,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "Card status was updated",
                token: false,

            });
            return data;
        } catch (err) {
            thunkAPI.rejectWithValue(err.response);
        }
    }
);

//statement card
export const StatementCard = createAsyncThunk(
    "StatementCard",
    async (cardInfo, thunkAPI) => {
        const {page = 0, size = 5} = cardInfo;
        try {
            const data = await client({
                url: `/card-ms/api/v1/card/statement?page=${page}&size=${size}`,
                data: JSON.stringify(cardInfo),
                showSuccessToast: false,
                showErrorToast: true,
                token: false
            })
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
)
