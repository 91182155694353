import { createSlice } from '@reduxjs/toolkit';
import { AutoComPaymentType } from './api_action';


const AutoComPaymentTypeSlice = createSlice({
    name: 'autoComPaymentType',
    initialState: {
        autoComAllPayment: null,
        error: null
    },
    extraReducers: {
        [AutoComPaymentType.fulfilled]: (state, action) => {
            state.autoComAllPayment = action.payload.data
        },
        [AutoComPaymentType.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export { AutoComPaymentType }

export const autoComPaymentTyprReducer = AutoComPaymentTypeSlice.reducer;