import {createSlice} from '@reduxjs/toolkit'
import {
    GetAccounts,
    GetAccountDetails,
    RenameAccount,
    StatementAccount,   
    GetRequisites,
    GetAccountsPayments
} from './api_action';

export {
    GetAccounts,
    GetAccountDetails,
    RenameAccount,
    StatementAccount,
    GetRequisites
    
}


const  initialState= {
    accounts: null,
    accountsPayments: null,
    error: null
}
// GET ACCOUNTS
 const GetAccountsSlice = createSlice({
    name: 'getAccounts',
    initialState,
    reducers: {
        reset:()=>initialState
    },
    extraReducers: {
        [GetAccounts.fulfilled]: (state, action) => {
            const {data, aznAccounts, modifiedData} = action.payload
            const allAccounts = modifiedData
            const allData=data?.data
            state.accounts = {allData, aznAccounts, allAccounts}
        },
        [GetAccounts.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});


export const getAccountsReducer = GetAccountsSlice.reducer;

const GetAccountsPaymentsSlice = createSlice({
    name: 'getAccountsPayments',
    initialState,
    reducers: {
        reset:()=>initialState
    },
    extraReducers: {
        [GetAccountsPayments.fulfilled]: (state, action) => {
            const {data, aznAccounts, modifiedData} = action.payload
            const allAccounts = modifiedData
            const allData=data?.data
            state.accountsPayments = {allData, aznAccounts, allAccounts}
        },
        [GetAccountsPayments.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});


export const getAccountsPaymentsReducer = GetAccountsPaymentsSlice.reducer;

//GET ACCOUNT NAME
const GetAccountDetailsSlice = createSlice({
    name: 'getAccountDetails',
    initialState: {
        accountDetails: null,
        error: null
    },
    extraReducers: {
        [GetAccountDetails.fulfilled]: (state, action) => {
            state.accountDetails = action.payload.data
        },
        [GetAccountDetails.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const getAccountNameReducer = GetAccountDetailsSlice.reducer;

//rename account
const RenameAccountSlice = createSlice({
    name: 'renameAccount',
    initialState: {
        accountName: null,
        error: null
    },
    extraReducers: {
        [RenameAccount.fulfilled]: (state, action) => {
            state.accountName = action.payload.config
            state.error = null
        },
        [RenameAccount.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const renameAccountReducer = RenameAccountSlice.reducer;

//statement account
const StatementAccountSlice = createSlice({
    name: 'statementAccount',
    initialState: {
        statementAccountInfo: null,
        error: null
    },
    extraReducers: {
        [StatementAccount.fulfilled]: (state, action) => {
            state.statementAccountInfo = action.payload.data
            state.error = null
        },
        [StatementAccount.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const statementAccountReducer = StatementAccountSlice.reducer;

//Get Requisites
const GetRequisitesSlice = createSlice({
    name: 'GetRequisitesS',
    initialState: {
        requisites: null,
        error: null
    },
    extraReducers: {
        [GetRequisites.fulfilled]: (state, action) => {
            state.requisites = action.payload.data
            state.error = null
        },
        [GetRequisites.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const GetRequisitesReducer = GetRequisitesSlice.reducer;
