/**
 * i18n (multilang) library config
 */
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
//  import {en, az, ru} from './locales';
import { aza } from './Locales/Az';
import { ena } from './Locales/En';
import { rua } from './Locales/Ru';
const lang = JSON.parse(localStorage.getItem('LangStorage'));
 
const resources = {
    az: {
        translation: {
            ...aza.translation,
            ...lang?.AZ
        }
    },
    en: {
        translation: {
            ...ena.translation,
            ...lang?.EN
        }
    },
    ru: {
        translation: {
            ...rua.translation,
            ...lang?.RU
        }
    }
};
     
// const detectionOptions = {
//   // order and from where user language should be detected
//   order: ['cookie', 'localStorage', 'path'],

//   // keys or params to lookup language from
//   lookupCookie: 'language',
//   lookupLocalStorage: 'language',
//   lookupFromPathIndex: 0,

//   // cache user language on
//   caches: ['cookie', 'localStorage'],

//   // optional expire and domain for set cookie
//   cookieMinutes: 100000,
//   // cookieDomain: '.sahibkarlarklubu.loc',
// };

i18next
    //   .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'az',
        fallbackLng: 'az',
        // load: 'languageOnly',
        // whitelist: ['az', 'en', 'ru'],
        // keySeparator: false,
        // detection: detectionOptions,
        interpolation: {
            escapeValue: false,
        },
    });


    const choosenLanguage = localStorage.getItem('lang');
    i18next.changeLanguage(choosenLanguage);
    
export default i18next;