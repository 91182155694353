import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

//ALL AutoComplite Payment Type
export const GetPaymentList = createAsyncThunk(
  "PaymentList",
  async (type, thunkAPI) => {

    try {
      const data = await client({
        url: `/users/v1/operator/payments`
      })
      return data;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)