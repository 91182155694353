import { createSlice } from "@reduxjs/toolkit";
import { OrdersList, OrdersTotalDebt } from "./api_action";


const initialState = {
  OrdersList: null,
  error: null,
  OrdersTotalDebt:null
}

const OrdersSlice = createSlice({
  name: 'orders',
  // initialState: {
  //     OrdersList: [],
  //     error:null
  //   },
  initialState,
  extraReducers: {
    [OrdersList.fulfilled]: (state, action) => {
      state.OrdersList = action.payload.data
    },
    [OrdersTotalDebt.fulfilled]:(state, action)=>{
      state.OrdersTotalDebt = action.payload.data
    },
    [OrdersList.rejected]: (state, action) => {
      state.error = action.payload
    },
    [OrdersList.rejected]: (state, action)=>{
      state.error = action.payload
    }
  },
  reducers: {
    setList: (state, action) => {
      state.push(action.payload);
    }
  }
});

export const OrderReducer = OrdersSlice.reducer;