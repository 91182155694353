import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { responsive } from 'utils/responsiveUI';
import { checkIcon, successIcon } from 'static/svg';
import { LoginButton, LoginLayout } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { client } from 'client';
import language from 'store/language';

const EmailConfirmation = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const [data, setData] = useState();

  const fetchEmailConfirmation = async () => {
    try {
      await client({
        url: `/users/v1/operator/confirmation`,
        data: JSON.stringify({ token }),
        method: 'POST',
        showSuccessToast: false,
        showErrorToast: false,
      });
      setData(null);
    } catch (err) {
      if (err?.response?.status === 404) {
        setData('404 not found');
      } else {
        setData('The link is already used');
      }
    }
  };

  useEffect(() => {
    fetchEmailConfirmation();
  }, [language]);

  const to = () => {
    history.push('/login');
  };

  return (
    <LoginLayout>
      <Container>
        <StyledCheckContnet>
          {data ? (
            <>
              <StyledErrorBox>
                <div className="error_box">
                  <img src={checkIcon} alt="checkIcon" />
                  <span className="error-message">{t(data)}</span>
                </div>
              </StyledErrorBox>
              <LoginButton onClick={to} title={t('Go to Login')} />
            </>
          ) : (
            <>
              <img src={successIcon} alt="checkIcon" />
              <h4>{t('Your email is verified')}</h4>
              <LoginButton onClick={to} title={t('Go to Login')} />
            </>
          )}
        </StyledCheckContnet>
      </Container>
    </LoginLayout>
  );
};

export default EmailConfirmation;

const Container = styled.div`
  margin-top: 76px;
  height: 100%;
  background-color: #fcfcfc;
  text-align: left;
  @media ${responsive.laptop} {
    margin-top: 59px;
    text-align: center;
  }
`;

const StyledCheckContnet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  h4 {
    font-weight: 700;
    font-size: 28px;
    color: #1c1c1c;
  }
  img {
    max-width: 156px;
  }

  @media ${responsive.laptop} {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    h4 {
      font-size: 32px;
    }
  }
`;

const StyledErrorBox = styled.div`
  .error_box {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  /* img {
    max-width: 156px;
  } */
  .error-message {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
`;
