import React from "react";

import { DetailDots, DetailItems } from "components";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const ThreeDotsPopup = ({ isHover, component, list  }) => {
  const detailItems = (key, ...rest) => {
    return <DetailItems  {...rest[0]}  {...{key}} />
  }

  const menuList = list?.map(({ path, ...rest}, key) => {
   return path ? <Link to={path} {...{ key }} > {detailItems(null, rest)} </Link> : detailItems(key, rest) 
  })

  return  <StyledPopup {...{ isHover }} >
            <DetailDots {...{ component }}>
              {menuList}
            </DetailDots>
          </StyledPopup>
}

const hovers = `
  // padding: 8px;
  border-radius: 50%;
  height: 36px;
  &:hover {
    background: #f8f8f8;
  }
`;
const StyledPopup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: "pointer";
  ${(props) => props.isHover && hovers}
  a {
    &:hover {
      color: #555555;
    }
    span {
      width: max-content;
    }
  }
`;
