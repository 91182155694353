import React, { useState, useEffect, useRef } from 'react';
import { Loading, LoginButton } from 'components';
import OtpInput from 'react-otp-input';
import { responsive } from 'utils/responsiveUI';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { OtpVerification, OtpForgotPasswordVerification, OtpLoginSMS, OtpForgotPassword } from 'store/auth';
import { useHistory } from 'react-router-dom';
import { blockGoBackBtn, renderTimer } from 'utils/shortFunctions';
import { useTranslation } from 'react-i18next';
import { clearOtpData } from 'store/auth';

export const OtpSms = ({type, closeOtp}) => {
        //loading
        const allLoading = useSelector(state => state.loadings);
        const { otpData } = useSelector(state => state.authInfo) ?? {};
        //for services
        const dispatch = useDispatch();
        //for routing
        const history = useHistory();
        const [otpPhoneNumber, setMobileNumber] = useState('');
        const [code, setCode] = useState('');
        const [timer, setTimer] = useState(180); // 180 sec - 3 minutes
        const tick = useRef();
        //for translation
        const {t} = useTranslation();

        blockGoBackBtn();

        useEffect(() => {
            // if a value is already store
            setMobileNumber(otpData?.otpPhoneNumber);
        }, [otpData]);

        const isOtpLogin = type === 'otpLogin';

        const handleSend = (otp) => {
            setCode('');
            // otpData.verificationCode = otp;
            const data = {
                otpInfo: {
                    verificationCode: otp,
                    otpPhoneNumber: otpPhoneNumber?.toString(),
                    password: otpData.password,
                    pin: otpData.pin
                },
                otpData,
                history
            }
            const forgotPasswordData = {
              otpInfo: {
                  verificationCode: otp,
                  mobileNumber: otpData?.otpPhoneNumber,
                  pin: otpData.pin
              },
              otpData,
          }
          isOtpLogin ? dispatch(OtpVerification(data)).then((res)=>{
            if(res?.payload?.status === 403 || res?.payload?.status === 404){
              dispatch(clearOtpData())
            }
          }) : dispatch(OtpForgotPasswordVerification(forgotPasswordData)).then((res)=>{
            if(res?.payload?.status === 403 || res?.payload?.status === 404){
              closeOtp(false)
              history.push('/login')
            }
          })
        }

    const handleChange = (code) => {
      setCode(code);
      if ([code.split('')][0].length === 6) {
        handleSend(code);
      }
    };

    const handleResend = () => {
      setTimer(180); // 180 sec - 3 minutes
      const data = isOtpLogin ? {
        otpInfo: {
          otpPhoneNumber: otpData?.otpPhoneNumber,
          password: otpData?.password,
          pin: otpData.pin,
        },
        history: history,
      } : {
        password: otpData.password,
        pin: otpData.pin,
      };

      dispatch(isOtpLogin ? OtpLoginSMS(data) : OtpForgotPassword(data));
    };

    useEffect(() => {
      if (timer !== 0) {
        tick.current = setInterval(() => {
          setTimer((timer) => timer - 1);
        }, 1000);
      } else {
        clearInterval(tick.current);
      }

      return () => clearInterval(tick.current);
    }, [timer]);

        return (
            <OtpInputContainer>
                <Loading loading={
                    allLoading?.isLoading
                }/>
                <StyeldText>
                    <h4>{t('enterVCode')}</h4>
                    <p>
                        {t('The verification code has been sent to {{otpPhoneNumber}}', {otpPhoneNumber})}
                    </p>
                </StyeldText>
                <OtpInput
                    value={code}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span style={{width: "15px"}}></span>}
                    isInputNum
                    shouldAutoFocus={true}
                    inputStyle={{
                        border: "1px solid #D0D0CE",
                        borderRadius: "8px",
                        width: "100%",
                        height: "46px",
                        padding: "2px 8px",
                        fontSize: "30px",
                        color: "#000",
                        fontWeight: "500",
                        caretColor: "#FFA300",
                        fontFamily: "Proxima Nova",
                    }}
                    focusStyle={{
                        border: "1px solid #FFA300",
                        outline: "1px solid #FFA300",
                    }}
                />
                <StyledTimer>
                    <p>{t('timerVCode')} <span>{renderTimer(timer)}</span></p>
                </StyledTimer>
                <StyledResend>
                    <LoginButton
                        disabled={renderTimer(timer) !== '00:00'}
                        onClick={handleResend}
                        title={t('resend')}

                    />
                </StyledResend>
            </OtpInputContainer>
        );
    }
;

const OtpInputContainer = styled.div`
  display: block;
  @media ${responsive.laptop} {
    margin-bottom: 90px;
  }

  div {
    justify-content: center;

    &:nth-child(3) {
      margin-right: 35px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 100%;
        width: 20px;
        height: 2px;
        background: #d0d0ce;
      }
    }

    &:nth-child(6) {
      display: block !important;

      input {
        width: 83% !important;
      }
    }

    @media ${responsive.laptop} {
      input {
        /* font-size: 16px !important; */
        height: 50px !important;
      }
    }
  }

  h5 {
    /* margin-top: 1em; */
    font-weight: 400;
    color: #63666a;
    font-size: 16px;

    span {
      font-weight: 600;
      color: #ffa300;
    }
  }
`;
const StyeldText = styled.div`
  h4 {
    font-weight: 600;
    font-size: 24px;
    color: #1c1c1c;
  }

  p {
    font-weight: 400;
    color: #63666a;
    font-size: 16px;
    margin-bottom: 25px;

    span {
      font-weight: 600;
    }
  }
`;
const StyledResend = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    width: 100%;
  }
`;
const StyledTimer = styled.span`
  display: flex;
  align-items: center;
  margin-top: 24px;

  span, p {
    width: 100%;
    font-size: 16px;
    color: #63666A;
  }

  span {
    font-weight: 600;
    color: #FFA300;
  }

  p {
    font-weight: 400;
  }

  @media ${responsive.laptop} {
    span, p {
      font-size: 14px;
    }
  }
`;
