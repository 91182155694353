import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";

const { TabPane } = Tabs;

export const MapTabsCard = ({ tabsData, onChange, ...rest }) => {

  
  return (
    <Container>
      <MapAntTabs onChange={onChange} {...rest}>
        {tabsData.map((item, index) => {
          return (
            <TabPane tab={item.title} key={index + 1}>
              {item.component}
            </TabPane>
          );
        })}
      </MapAntTabs>
    </Container>
  );
};

const Container = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
`;
const MapAntTabs = styled(Tabs)`
  width: 100%;
  .ant-tabs-nav {
    width: 60%;
  }
  .ant-tabs-tab,
  .ant-tabs-nav-container {
    font-family: Proxima Nova;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .ant-tabs-ink-bar {
    height: 100% !important;
    z-index: -10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #393939;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  div.ant-tabs-nav::before {
    border-bottom: 1px solid transparent;
  }

  .ant-tabs-nav {
    height: 50px;
    background-color: #ebebeb;
    border-radius: 10px;
    padding: 6px;
    color: #8e8e8e;
    margin-bottom: 28px;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
  }
  .ant-tabs-tab-btn {
    &:active {
      color: #8e8e8e;
    }
  }
  .ant-tabs-tab {
    width: 50%;
    display: flex;
    margin: 0;
    justify-content: center;

    &:hover {
      color: #616173;
    }
  }
  @media ${responsive.laptop}{
    .ant-tabs-nav {
      width: 100%;
      min-height: 60px;

        .items-count{
            display: block;
            text-align:center;
        }
    }
  }
`;
