import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from 'client';


export const GetExchangeInfo = createAsyncThunk(
    "GetExchangeInfo",

    async (config, thunkAPI) => {
        try {
            const data = await client({
                url: `/currency-ms/api/v1/currency/detail`,
                // showSuccessToast: true,
                showErrorToast: true,
                // succesMessage: "Get exchange info",
                token: false,
            })
            return data
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)
