import React from 'react';
import { currencyInfos } from 'constant';
import { card_icon } from 'static/svg';

import styled from 'styled-components';
import { responsive } from 'utils/responsiveUI';
import { cardFormat } from 'utils/shortFunctions';
import { numberFormat } from 'utils';

export const CustomSelectBody = ({
  accountNo,
  balance,
  currency,
  category,
  cardNo,
  activeKeyTab,
  isSelectedAmount = false,
}) => {
  return (
    <>
      <StyledAccount className="account">
        {activeKeyTab ? (
          activeKeyTab === 1 ? (
            <span>{accountNo}</span>
          ) : (
            <span>{cardFormat(cardNo)}</span>
          )
        ) : (
          <span>{accountNo}</span>
        )}
        {category === 'CARD' ? (
          <span className="card_icon">
            <img src={card_icon} alt="card_icon" />{' '}
          </span>
        ) : null}
      </StyledAccount>
      <StyledCurrency
        className={`amonut ${isSelectedAmount ? 'isSelectedAmount' : ''}`}
      >
        <div className="list_icon">
          <img src={card_icon} alt="card_icon" />{' '}
        </div>
        {balance !== null
          ? `${numberFormat(balance) || '-'} ${
              currencyInfos[currency]?.symbol || ''
            }`
          : null}
      </StyledCurrency>
    </>
  );
};

const StyledAccount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .card_icon {
    margin-top: -8px !important;
  }
  img {
    width: 20px;
  }
  @media ${responsive.tablet} {
    img {
      width: 18px;
    }
  }
  @media ${responsive.tablet} {
    .card_icon {
      display: none;
    }
  }
`;
const StyledCurrency = styled.span`
  width: 100%;
  text-align: end;
  padding-right: 10px;
  /* display: block !important; */
  .list_icon {
    display: none;
  }
  @media ${responsive.tablet} {
    .list_icon {
      display: flex;
      width: 45px;
      /* justify-content: space-between; */
    }
  }
  @media ${responsive.tablet} {
    display: flex;
    flex-direction: row !important;
  }
`;
