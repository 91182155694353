import React, {
  Fragment, useEffect, useLayoutEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Form } from 'antd';
import { ActionSubButtons, Loading, RadioButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { setOtpData, setVoen } from '../../../../../store/authInfo';
import { useTranslation } from 'react-i18next';

const OtpCompanyList = ({ modalVisible, setModalVisible }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  //global store
  const allLoading = useSelector((state) => state.loadings);
  const userInfo = useSelector((state) => state.otpVerification.userInfo);
  const { companyList } = useSelector((state) => state.getCompanyList);
  //hooks
  const [companyListCustom, setCompanyListCustom] = useState([]);
  const [companyRadio, setCompanyRadio] = useState(0);
  const [selectedVoen, setSelectedVoen] = useState();
  const randomColors = ['#BA68C8', '#FF8A65', '#F06292', '#FFD54F', '#64B5F6'];


  useEffect(() => {
    if (selectedVoen) {
      dispatch(setOtpData(null));
      switch (userInfo?.userStatus) {
        case 'LOGIN_WAITING':
          history.push('/login/set-password');

          break;
        case 'ACTIVE':
          localStorage.setItem('voen', selectedVoen);
          const replacedUrl = window.location.href.replace('/login', '/');
          window.location.href = replacedUrl;
          break;
        default:
          break;
      }
    }
  }, [selectedVoen, dispatch]);

  useEffect(() => {
    if (companyList?.length > 0) {
      const listData = companyList?.map(({ companyName, voen, positionDescription }, index) => {
        const colorIndex = index % randomColors.length;

        return {
          index: voen,
          value: voen,
          title: companyName,
          text: t(`user_position_${positionDescription}`),
          render: (
            <StyleImg style={{ backgroundColor: randomColors[colorIndex], maxWidth: '40px' }} />
          ),
        };
      });
      setCompanyListCustom(listData);
    }

  }, [companyList]);


  const modalTime = 3 * 60 * 1000; //3 minutes
  const closeModal = () => {
    setModalVisible(false);
    localStorage.clear();


    const replacedUrl = window.location.href.replace('/login', '/');
    window.location.href = replacedUrl;
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      closeModal();
    }, modalTime);
  }, []);


  const onChangeCompanyList = (e) => {
    setCompanyRadio(e.target.value);
  };

  const onFinish = () => {
    setSelectedVoen(companyRadio);

    dispatch(setVoen({ voen: companyRadio }));
  };


  return (<Fragment>
      <Loading loading={allLoading?.isLoading} />
      <FormContainer
        name='Auth_Asan'
        layout='vertical'
        initialValues={{ remember: true }}
        form={form}
        onFinish={onFinish}
        scrollToFirstError={true}
        hideRequiredMark={true}

      >
        <RadioButton
          data={companyListCustom}
          onChange={onChangeCompanyList}
          value={companyRadio}
          // disabled={checkPaymentValue}
          isStandard
        />
        <StyledFormItem>

          <ActionSubButtons
            isBg={true}
            isRed={false}
            noPadding
            cancelOnClick={closeModal}
            blockBtnNameCancel={'Clear'}
            blockBtnName={'Continue'}
          />
        </StyledFormItem>
      </FormContainer>
    </Fragment>);
};
export default OtpCompanyList;
const FormContainer = styled(Form)`
  padding: 20px 25px 24px;
`;
const StyledFormItem = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyleImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;

  img {
    width: 100%;
    height: 100%;
  }

  font-family: 'Proxima Nova';
  font-size: 16px;
`;
