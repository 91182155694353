import React from "react";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";

export const MainButton = ({ notCenter, text, modal, isCount,loading=false, icon, ...rest }) => {
  return (
    // <ButtonText {...rest} text={text}>  if there is a problem {...rest} this deleted
    <Container notCenter={notCenter}>
      <StyledButton modal={modal} {...rest} text={text}>
      {loading ? <Spin className="customSpin" spinning={loading} /> : null}
        <ButtonText text={text}>
          {icon && <img src={icon} alt="" />}
          <span>{text}</span>
          {isCount && <span>{`(${isCount})`}</span>}
        </ButtonText>
      </StyledButton>
    </Container>
  );
};

export const SubMainButton = ({ notCenter, text, modal, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Container notCenter={notCenter}>
      <StyledSubButton modal={modal} {...rest}>
        {t(text)}
      </StyledSubButton>
    </Container>
  );
};

export const MainButtonIcon = ({
  notCenter,
  text,
  icon,
  loading = false,
  ...rest
}) => {
  return (
    <Container notCenter={notCenter}>
      <StyledButtonIcon {...rest}>
        {loading ? <Spin spinning={loading} /> : null}
        <img src={icon} alt="" />
        <span>{text}</span>
      </StyledButtonIcon>
    </Container>
  );
};

export const BorderButton = ({
  title,
  icon,
  modal,
  isFilterCount,
  loading = false,
  count,
  ...rest
}) => {
  return (
    <StyledBorderButton modal={modal} isFilterCount={isFilterCount} {...rest}>
     { loading ?  <Spin spinning={loading}/> : null}
      {icon && <img src={icon} alt="" />}
      <ButtonTitle modal={modal}>{title}</ButtonTitle>
    </StyledBorderButton>
  );
};

export const BorderLessButton = ({ title, icon, ...rest }) => {
  return (
    <StyledBorderlessButton {...rest}>
      {icon && <img src={icon} alt="" />}
      <div className="borderlessBtnText">{title}</div>
    </StyledBorderlessButton>
  );
};

export const Button = ({ title, template, notCenter, ...rest }) => {
  return (
    <Container notCenter={notCenter}>
      <StyledTransparentButton template={template} {...rest}>
        <span>{title}</span>
      </StyledTransparentButton>
    </Container>
  );
};

export const LoginButton = ({ title, info, disabled, ...rest }) => {
  const props = {
    title,
    info,
    disabled,
    ...rest,
  };
  return (
    <StyledLoginButton {...props}>
      <span>{title}</span>
    </StyledLoginButton>
  );
};

MainButton.propTypes = {
  text: PropTypes.string,
};
SubMainButton.propTypes = {
  text: PropTypes.string,
};

const center = `
    display:flex;
    justify-content:center;
    align-items:center;
`;
const right = `
    display:flex;
    justify-content:end;
    align-items:end;
`;
const modalButton = `
    // min-width: 100px;
    height: 44px;
    border-radius:8px;
    // padding:5px;
`;

//MainButton
const Container = styled.div`
  ${(props) => (!props.notCenter ? center : right)}
  @media ${responsive.laptop} {
    font-size: 16px;
    /* width: 100%; */
  }
`;

const StyledButton = styled.button`
  /* width: 132px; */
  background: ${(props) =>
    props.text === "Reject"
      ? "#FF857B"
      : props.disabled
      ? "#DADAE5"
      : props.isBg
      ? "#393939"
      : props.white
      ? "#fff"
      : props.grey
      ? "#F0F1F3"
      : props.isRed
      ? "#FF5252"
      : "#FF857B"};
  border: ${(props) =>
    props.text === "Reject"
      ? "1.5px solid #FF857B"
      : props.isWhiteBtn
      ? "1.5px solid #FF857B"
      : "none"};
  border-radius: 8px;
  padding: 10px 20px;
  text-decoration: none;
  ${(props) => (!props.notCenter ? center : right)}
  ${(props) => props.modal && modalButton}
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.4s;
  margin-top: ${(props) => (props.isTop ? "20px" : "none")};
  color: ${({ text }) => (text === "Reject" ? "#ffff" : "")};
  /* &:hover {
    background: ${(props) =>
      props.text === "Reject"
        ? "#FF857B"
        : props.disabled
        ? "#DADAE5"
        : props.isBg
        ? "#393939"
        : props.white
        ? "#fff"
        : props.grey
        ? "#F0F1F3"
        : props.isRed
        ? "#FF5252"
        : "#393939"};
  } */

  &:focus {
    /* background: #F58220; */
    outline: 0;
  }
  span {
    color: ${(props) => (props.grey ? "#393939" : "#FFFFF")};
  }

  @media ${responsive.laptop} {
    width: 100%;
  }
`;

// SubMainButton

const StyledSubButton = styled.button`
  background: ${(props) =>
    props.disabled ? "#E3E3E3" : props.isActivWhite ? " #F0F1F3" : "#393939"};
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  text-decoration: none;
  ${(props) => (!props.notCenter ? center : right)}
  ${(props) => props.modal && modalButton}
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.4s;
  &:hover {
    ${(props) => (props.disabled ? "#E3E3E3" : "#393939")};
  }

  &:focus {
    /* background: #F58220; */
    outline: 0;
  }

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.isActivWhite ? "#393939" : "#fff")};
`;
const ButtonText = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 500;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.text === "Reject"
      ? "#fff"
      : props.white || props.grey
      ? "#393939"
      : "#fff"};
`;

//MainButtonIcon
const StyledButtonIcon = styled.button`
  min-width: ${(props) =>
    props.customStyle ? "225px" : props.smallWidth ? "154px" : "auto"};
  background: ${(props) => (props.isBg ? "#393939" : " #FFA300")};
  border: none;
  /* padding: ${(props) => (props.isPadding ? "13px 20px" : "22px 30px")}; */
  padding: 10px;
  text-decoration: none;
  border-radius: 8px;
  ${center};
  cursor: pointer;
  transition: all 0.4s;

  img {
    padding-right: 10px;
  }

  span {
    font-family: Proxima Nova;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
  }

  &:hover {
    background: "#393939";
  }

  &:focus {
    background: "#393939";
    outline: 0;
  }
`;

//BorderButton
const StyledBorderButton = styled.button`
  background: ${(props) => (props.isFilterCount ? "#F2F2F2" : props.disabled ? "#dadae587" :  "#fff")};
  border: 1.5px solid #393939; 
  border-radius: 8px;
  padding: 10px 18px;
  text-decoration: none;
  ${center};
  ${(props) => props.modal && modalButton};
  flex-direction: row;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.4s;
  &:focus {
    outline: 0;
  }

  img {
    margin-right: 10px;
  }
  @media ${responsive.laptop} {
    font-size: 14px;
  }
`;
const ButtonTitle = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.modal ? "16px" : "13px")};
  line-height: 8px;
  color: #393939;
  @media ${responsive.laptop} {
    font-size: 16px;
  }
`;
//BorderlessButton
const StyledBorderlessButton = styled.div`
  padding: 10px;
  flex-direction: row;
  white-space: nowrap;
  ${center};
  cursor: pointer;

  .borderlessBtnText {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #5f6369;
    padding-left: 10px;
  }
`;
//Button
const StyledTransparentButton = styled.button`
  background: transparent;
  border: 1px solid #f9c20a;
  border-radius: 8px;
  padding: ${(props) => (props.template ? "24px 48px" : "14px 33px")};
  text-decoration: none;
  ${center};
  cursor: pointer;
  transition: all 0.4s;

  span {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: ${(props) => (props.template ? "normal" : "600")};
    font-size: 18px;
    line-height: ${(props) => (props.template ? "87%" : "26px")};
    text-align: center;
    color: #000;
  }

  &:hover {
    background: #f9c20a;
  }

  &:focus {
    background: #393939;
    outline: 0;
  }
`;

//LoginButton
const StyledLoginButton = styled.button`
  padding: 20.5px;
  border-radius: 8px;
  width: 100%;
  background: ${(props) =>
    props.info ? "#F2F2F2" : props.disabled ? "#D1D1D1" : "#393939"};
  border: ${(props) => (props.disabled ? "1px solid #D1D1D1" : "none")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${center}
  &:hover {
    background: ${(props) =>
      props.info ? "#F2F2F2" : props.disabled ? "#D1D1D1" : "#393939"};
  }

  &:focus {
    background: ${(props) =>
      props.info ? "#F2F2F2" : props.disabled ? "#D1D1D1" : "#393939"};
    outline: 0;
  }

  span {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    color: ${(props) => (props.disabled ? "#818181" : "#FCFCFC")};
  }
`;
