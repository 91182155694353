import React from "react";
import styled from "styled-components";
import {  useHistory } from "react-router-dom";
import { Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  FormItem,
  LoginButton,
  Loading,
  CustomInput,
  LoginLayout,
  ErrorMessage,
} from "components";
import { responsive } from "utils/responsiveUI";
import {
  requiredRule,
  whitespaceRule,
} from "utils/checkValidations";
import { blockGoBackBtn } from "utils/shortFunctions";
import { useValidate } from "hooks";
import { passwordValidation } from "utils";
import { SetNewPasswordForgetPassword } from "store/auth/api_actions";

const SetNewPasswordForgot = () => {
  const [form] = Form.useForm();

  const allLoading = useSelector((state) => state.loadings);
  const {checkInfo} = useSelector((state) => state.forgotPasswordCheckAnswer);
  const methodInfo = JSON.parse(localStorage.getItem("methodInfo"));


  const token= checkInfo?.token || methodInfo?.token
  const { handleBeginningEmptyInput } = useValidate(form);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const history = useHistory();

  blockGoBackBtn();

  const onFinish = (values) => {

      dispatch(SetNewPasswordForgetPassword({body: {password: values.password, token }, history}))

  };


  const checkConfPass = () => {
    let passVal = form.getFieldValue("password");
    let confPassVal = form.getFieldValue("confirmPassword");
    if (!confPassVal || passVal === confPassVal) {
      return Promise.resolve();
    }
    return Promise.reject(<ErrorMessage text={"passwordMatchError"} />);
  };


  const ForgotPasswordSetNewPassForm = () => {
   return (
      <FormContainer>
        <div className="title_style">{t("newPass")}</div>
        <Form
          name="Set-New-Password"
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values)}
          initialValues={{ remember: true }}
          scrollToFirstError={true}
          hideRequiredMark={true}
        >
          <Col span={24} className="general">
            <FormItem
              autoheight
              name="password"
              rules={[
                requiredRule,
                whitespaceRule,
                ...[
                  {

                    validator: (rule, value, callback) => {
                      if (value) {
                        const valueLength = value.split("");
                        if (
                          !passwordValidation(value) ||
                          valueLength.length < 8
                        ) {
                          callback(true);
                          return Promise.reject(
                            <ErrorMessage text="pass_info" />
                          );
                        } else {
                          callback();
                          return Promise.resolve();
                        }
                      }else{
                        callback(true);
                      }
                    },
                  },

                ]
              ]}
            >
              <CustomInput password label={t("password")} maxLength={30}
               onChange={(e) => {
                  handleBeginningEmptyInput(e, "password");
                  form.setFieldsValue({
                    confirmPassword: undefined,
                  });
                }} />
            </FormItem>
          </Col>

          <Col span={24} className="general">
            <FormItem
            autoheight
              name="confirmPassword"
              rules={[
                requiredRule,
                whitespaceRule,
                ...[
                  {
                    validator: checkConfPass,
                    message: <ErrorMessage text={"passwordMatchError"} />,
                  },
                ],
              ]}
            >
              <CustomInput label={t("confirmPassword")} password maxLength={30}/>
            </FormItem>
          </Col>

          <FormItem>
            <LoginButton title={t("save")} htmlType="submit" />
          </FormItem>
        </Form>
      </FormContainer>
    );
  };

  return (
    <>
      <Loading loading={allLoading?.isLoading} />

      <LoginLayout>
        { ForgotPasswordSetNewPassForm()}
      </LoginLayout>
    </>
  );
};

export default SetNewPasswordForgot;

const FormContainer = styled.div`
  height: 100%;
  margin-top: 76px;
  overflow: auto;
  @media ${responsive.laptop} {
    margin-top: 59px;
  }
  .title_style {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
    @media ${responsive.laptop} {
      font-size: 20px;
    }
  }
`;
