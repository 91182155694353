import React from "react";
import { stepCheck } from "static/svg";
import styled from "styled-components";

export const StepCard = ({ value, step, bgColor }) => {
  return (
    <>
      <StyledCards bgColor={bgColor}>
        <StyledCardsContent bgColor={bgColor}>
          {step ? <span>{step}</span>  :  <img src={stepCheck} alt="stepCheck"/>}
         
          <h3>{value}</h3>
        </StyledCardsContent>
      </StyledCards>

      <StepCardLine>
        <StyleLine />
      </StepCardLine>
    </>
  );
};

const center = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCardsContent = styled.div`
  height: 100%;
  ${center};
  flex-direction: column;
  span {
    background-color: ${(props) => (props.bgColor ? " #393939" : "#AAA")};
    padding: 3px 10px;
    border-radius: 20px;
    color: #fff;
  }
  span, img{
    margin-bottom: 15px;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    color: #393939;
    margin-bottom: 0;
  }
`;
const StyledCards = styled.div`
  height: 98px;
  width: 188px;
  background: ${(props) => (props.bgColor ? " #f8f8f8" : "#fff")};
  border-radius: 8px;
  margin: 10px;
`;
const StepCardLine = styled.div`
  margin: 20px;
  &:nth-last-child(1) {
    display: none;
  }
  ${center}
`;
const StyleLine = styled.div`
  margin-bottom: 40px;
  width: 62px;
  height: 1px;
  background-color: #e3e3e3;
`;
