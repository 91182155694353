import React, { useState } from "react";
import { Menu, Dropdown } from "antd";

import {
  selectArrow,
} from "static/svg";
import styled from "styled-components";
import Flags from "shared/Components/Flags";

export const DropdownInput = ({ spaceBetween }) => {

  const data = [
    {
      id: 0,
      name: "AZN",
      currency: 944,
    },
    {
      id: 1,
      name: "USD",
      currency: 944,
    },
    {
      id: 2,
      name: "EUR ",
      currency: 944,
    },
  ];
  const [defaultSelected, setDefaultSelected] = useState({
    id: data[0].id,
    currency: data[0].currency,
    companyName: data[0].companyName,
    name: data[0].name,
  });

  const handleItemSelectModal = ({ key }) => {
    for (let k in data) {
      if (k === key) {
        setDefaultSelected({
          id: data[key].id,
          currency:
            data[key].currency ||
            `${data[key]?.name.split(" ")[0].split("")[0]}${
              data[key]?.name.split(" ")[1].split("")[0]
            }`,
          companyName: data[key].companyName,
          name: data[key].name,
        });
      }
    }
  };

  const menu = (
    <Menu selectable onClick={handleItemSelectModal}>
      {data.map((item) => (
        <Menu.Item key={item.id}>
          <StyleImg><Flags currencyId={item?.currency}/></StyleImg>
          <StyledText>{item?.name}</StyledText>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottom"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <StyledMobile className="ant-dropdown-link" spaceBetween={spaceBetween}>
        <ForSpaceBetween>
          <StyleImg><Flags currencyId={defaultSelected?.currency}/></StyleImg>
          <StyledText>{defaultSelected?.name}</StyledText>
        </ForSpaceBetween>
        <img src={selectArrow} className="down" alt="down" />
      </StyledMobile>
    </Dropdown>
  );
};

const ForSpaceBetween = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "center"};
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 10px;
`;
const StyleImg = styled.div`
  img {
    width: 28px;
  }
`;
const StyledText = styled.span`
  margin: 10px;
`;