import { createGlobalStyle } from 'styled-components';
import { input_Success, input_Error, check } from 'static/svg';
import { responsive } from 'utils/responsiveUI';

const center = `
    display:flex;
    justify-content:center;
    align-items:center;
`;

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  *{
    ::selection{
      background: #ADD7FD !important;
    } 
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* PAGE DON'T SELECTED */
    /* -webkit-user-select: none;
     -moz-user-select: -moz-none;
      -ms-user-select: none;
          user-select: none; */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  /* Remove automatic color in input */
  /* input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  } */

  /* Form inner width */
  .form_center {
    width: 645px;

  }

  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }

  .ant-select-multiple .ant-select-selection-item-remove > * {
      color: red ;
    }

    .ant-select-selection-item{
      border: 1px solid transparent;
      /* background-color: red; */
    }

    .ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected{
      display: flex;
      justify-content: space-between;
    }

    .ant-select-selection-overflow-item{
      padding: 0;
    }

    .ant-select-multiple .ant-select-selection-item{
      border: 1px solid transparent;
    }

    .ant-select-multiple.ant-select-lg .ant-select-selection-item{
      height: 32px;
    }
    
   .ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected{
      background-color: transparent;
   }
   .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:transparent !important;
   }

   .anticon .anticon-check{
    position: relative;
    left: 4rem;
   }
    .ant-select-selection-item-content{
      border: none;
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: flex-end ;
      /* padding: 2px 10px; */  
      font-size: 12px;
      border-radius: 6px;
      /* color: #F44336 !important; */
      font-weight: 500;
      flex: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:none;
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      /* padding: 6px 10px; */
      font-size: 14px;
      /* background: #FEE8E7 !important; */
      border-radius: 6px;
      /* color: #F44336 !important; */
      font-weight: 500;
      /* flex: none; */
      overflow: hidden;
      white-space: nowrap;
      text-overflow:none;
    }

    .ant-select-item-option-state{
     
    }

    .ant-select-item-option-selected{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){  
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      background-color: #BEBED0;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled){
      background:transparent;
    }


  /* AccountSelect balance */
    .simpleSelect .ant-select-selection-item{
       span:nth-child(2){
        margin-top: -4px;
       }
      .isSelectedAmount{
        display: none;
      }
    }

    
  /* Ant Checkbox icon */
  .ant-checkbox-checked .ant-checkbox-inner::after {
    background: url(${check}) center no-repeat;
    border: 0;
    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    opacity: 1;
      
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: transparent;
  }

  .ant-checkbox-checked::after{
    border-color: #393939 !important;  
    border-radius: 5px;
    /* animation: 0.36s ease-in-out;    */
  }
  .ant-checkbox-inner::after {
    left: 50%;
    width: 17px;
    height: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #393939;
    border-color: #393939 !important;
    &:active{
      border-color: #393939 !important;
    }
  }

  .ant-checkbox-inner{
    border-color: #63666A !important;
    &:active{
      border-color: #63666A !important;
    }
  }
  .ant-checkbox-wrapper-disabled .ant-checkbox-inner{
    background-color:  #8E8E8E !important;
    border-color: #8E8E8E !important;
    &:active{
      border-color: #8E8E8E !important;
    }
  }
  /* Ant tabs height */
  .ant-table-tbody > tr > td {
    height: 70px
  }
.isHoverTable{
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #F8F8F8 !important;
  }
  .ant-table-cell.ant-table-selection-column{
    padding: 0 23px;
  }
}
.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: transparent !important;
  }
  
  .dashboardAccountsTable{
    .ant-table-tbody {
    height: auto;
  }
    .ant-table-content{
    height: 306px;
  }
  .ant-table-cell:nth-child(1) {
    padding: 16px 16px;
  }
  }
  /* Ant notification */
.ant-notification{
  top: 126px!important;
}

  .ant-notification-notice-close,
  .ant-notification-notice-icon {
  }
  
  .ant-notification-notice-close {
    top: 50%;
    transform: translate(25%, -55%);
  }

  .ant-notification-notice-with-icon {
    display: grid;
    align-items: center;
    grid-template-columns: 32px 1fr;
    grid-gap: 15px;
  }
  
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: 5px;
    line-height: 22px;
    color: #1C1C1C;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-left: 30px;
  }

  .customNotify{
    .ant-notification-notice-with-icon {
      grid-template-columns: 0;
      display: flex;
      flex-direction: row;
      gap: 16px;
      grid-gap: 0;
    }
    .ant-notification-notice {
      padding: 18px;
    }
    .ant-notification-notice-with-icon {
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      align-items: flex-start;
    }
    .ant-notification-notice-icon {
      position: static;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-left: 18px;
      color: #393939;
      font-weight: 600;
    }
    .ant-notification-notice-close {
      top: 0;
      -webkit-transform: translate(25%, 80%);
      -ms-transform: translate(25%, 80%);
      transform: translate(25%, 80%);
    }
    .ant-notification-hook-holder,
    .ant-notification-notice {
      height: 120px;
}
  }
  /*Ant pagination */
  .ant-pagination-item {
    background: transparent;
    border: none;
    ${center};
  }

  .ant-pagination-item-active a {
    width: 40px;
    height: 40px;
    background: #F2F2F2;
    color: #A9ABAC !important;
    border-radius: 46px;
    ${center};
  }

  .ant-pagination {
    height: 50px;
    ${center};
    /* margin-top: 20px !important; */

    li {
      background: #FFFFFF;
      height: 50px;
      ${center};
      margin: 0;
      border-radius: 0;
      min-width: 50px;

      :first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }

      :last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }
  }

  /* Input horizontal label */
  .input_horizontal_label {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Proxima Nova;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #616173;
  }

  /*Ant input validation icon */
  /* success */
  .ant-form-item-has-success .anticon-check-circle svg {
    display: none;
  }

  .ant-form-item-has-success .anticon-check-circle::before {
    content: " ";
    display: inline-block;
    background: transparent url(${input_Success}) center;
    width: 13px;
    height: 12px;
  }

  /* error */
  .ant-form-item-has-error .anticon-close-circle svg {
    display: none;
  }

  .ant-form-item-has-error .anticon-close-circle::before {
    content: " ";
    display: inline-block;
    background: transparent url(${input_Error}) center;
    width: 14px;
    height: 14px;
  }

  .general{
    .ant-form-item-explain.ant-form-item-explain-error {
      margin-top: -8.5px;
    }
    .ant-input, .ant-input-password{
      box-shadow: none;
      border-color: #dcdcdc;
      padding-bottom: 0px;
    }
    .ant-input-password{
      
      background-color: transparent !important;
      #settings_new_password, 
      #settings_repeat_password, 
      #settings_current_password, 
      #Auth_Asan_userId, 
      #Auth_OTP_pin, 
      #Auth_OTP_password, 
      #Set-New-Password_password,
      #Set-New-Password_confirmPassword{
        background-color: transparent !important; 
      

      }
    }
    .ant-input-affix-wrapper{
      padding: 0 10px !important;
      height: 55px !important;
      &:focus{
       background-color: transparent !important;
      }
    }
    .ant-picker{
      box-shadow: none !important;
      /* border-color: #dcdcdc !important; */
      &:focus{
        box-shadow: none !important;
        border-color: #dcdcdc !important;
      }
    }
    .ant-picker input{
      padding-top: 10px;
    }
    .ant-picker.ant-picker-disabled, .ant-select-disabled{
      background-color: #ebebeb;
      border-radius: 8px;
    }
    
    .ant-select-selector, .ant-input-textarea textarea{
      box-shadow: none !important;
      border-color: #dcdcdc !important;
      &:focus{
        box-shadow: none !important;
        border-color: #dcdcdc !important;
      }
    }
    .ant-form-item-has-error {
      .ant-input, .ant-select-selector, .ant-input-password, .ant-input-textarea textarea{
      box-shadow: none;
      border-color: #FF5252 !important;
      background: rgba(255, 82, 82, 0.12) !important;
      &:hover{
        background: rgba(255, 82, 82, 0.12)
      }
      &:focus{
        box-shadow: none;
      }
    }
    .ant-select-disabled{
       background-color: transparent !important;
    }
    .as-label , .as-placeholder{
      color:  #FF5252;
    }
    .ant-picker{
      background-color: #ffeaea;
      &:hover{
        background-color: #ffeaea;
      }
    }
.ant-input-affix-wrapper .ant-input-suffix{
  /* background-color: rgba(255, 82, 82, 0.12) !important; */
  border-radius:  0 7px 7px 0;
  margin-left: 0;
}
.ant-input-password .ant-input{
  border-radius:  7px 0 0 7px ;
}
  }
   .ant-select-selection-item{
    display: flex;
    justify-content: space-between;
    }
  }
  
 #international_swift_receiverCountry,
 #budget_budgetLevel,
 #budget_budgetType{
  font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #393939;
    margin-top: 4px;
 }
  

  /* warning */
  .ant-form-item-has-warning .anticon-exclamation-circle svg {
    display: none;
  }

  .ant-form-item-has-warning .anticon-exclamation-circle::before {
    content: " ";
    display: inline-block;
    background: #fff url("https://cdn.jsdelivr.net/emojione/assets/4.0/png/128/26a0.png") center /
    14px 14px no-repeat;
    width: 14px;
    height: 14px;
  }

  .ant-select .ant-select-arrow {
	  transition: transform .2s ease-in;
  }

  .ant-select.ant-select-open .ant-select-arrow {
    transform: rotate(180deg);
  }

  /* sidebar hamburger menu */
  .trigger img{
	  transition: transform .2s ease-in;
    transform: rotate(90deg);
  }
  .trigger.active img{
    transform: rotate(270deg);
  }
  

  /* Dropdown menu */
  .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical{
    padding: 10px 0;
    .ant-dropdown-menu-item{
      margin: 0 10px 10px;
      padding: 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      border-radius: 8px;
      &:hover{
        background-color: #F2F2F2;
      }
    }
    
  }

  .accountDrop {
    
      max-height: 240px;
      overflow: auto;
      ::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 5px;
      cursor: pointer;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: #F5F5F5;
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #D7D8D6;
      cursor: pointer;
    }
    
  }
  /* Sidebar menu  */
  .ant-menu-submenu{
    .ant-menu.ant-menu-sub.ant-menu-vertical{
      .ant-menu-item{
        height: 100%;
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
    }
    .active{
      &:hover{
         background-color: #F2F2F2 !important;
      }
    }
    .ant-menu-item-selected, 
    .ant-menu-item-active{
      .active{
        background-color: #F2F2F2 !important;
        color: #393939;
        &:hover{
         background-color: #F2F2F2 !important;
      }
      &:active{
        background-color: #F2F2F2 !important
      }
      }
    }
    .ant-menu.ant-menu-sub.ant-menu-vertical{
      padding: 12px 0;
    }
    .ant-menu-inline .ant-menu-item-selected,
    .ant-menu-vertical .ant-menu-item-selected {
    &:after {
      right: 98%  !important;
      border-right: 10px solid #393939;;
      border-radius: 0px 3px 3px 0px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 10px;
      transform: scaleY(1);
      opacity: 1;
    }
  }
  .ant-menu-item:active{
    background-color: transparent;
  }
  }
  .ant-dropdown-menu-item-selected{
    background-color: #FFF7E3;
  }

  /* Sidebar menu Tooltip */
  .ant-menu-inline-collapsed-tooltip { display: none; }

  .ant-modal-close{
    top: 5px;
  }
  .ant-modal-header{
    padding: 16px 24px 0px;
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 0 16px 24px;
  }

  /* antd Popover */
  .ant-popover-inner{
    box-shadow: none;
  }
  .ant-popover-content .ant-popover-arrow{
    display: none;
  }
  .ant-layout{
    background-color: transparent;
  }




  /* upload */
.ant-upload-list-item-file{
  position: relative;
  display: flex;
  &::after{
    content: '';
    position: absolute;
    background-image : url("/static/media/excel_icon.a4af8e17.svg") !important;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
  }
  svg{
    display: none;
  }
}
.ant-upload-list-item{
  border: none !important;
}
.ant-upload-list-item-name{
  text-align: left;
}

/* Dahboard table convert hover effect*/
.dashboardAccountsTable {
  .ant-table-row:hover .convertIcon {
     opacity: 1;
  }
}

.ant-picker-input>input::placeholder {
   color: #aaa;
}
@media ${responsive.tablet} {
  .ant-select-selection-item .account{
   font-size: 13px;
}
.ant-select-selection-item .amount{
  display: none !important;
}
  }


  

  //  .ant-select-dropdown{
  //   .rc-virtual-list{
  //     .rc-virtual-list-holder{
  //       max-height: 300px!important;
  //     }
  //   }
  // }


  
  .customSpin{ 
  .ant-spin-dot-item{
    background-color: #fff;
  }
  .ant-spin-dot.ant-spin-dot-spin{
    margin-bottom: -5px;
    margin-right: 5px;
  }
  }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important; /* Farklı bir renk de verebilirsiniz */
}

.ant-form-item-has-error {
  .as-label{
    background: transparent!important;
  }
  .label{
    background: transparent!important;
  }
}

`;
