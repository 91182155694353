const lightTheme = {
    colors: {

        backgroundColor: '#F6F6FA',
        leftBackgroundColor: '#F1F1F5',
        mainColor: '#FAA61A',
        dividerColor: 'rgba(0,0,0,0.1)',
        inputBoxShadov: '#DADAE5',
        inputFocusBorder:'#BFC5D2',

        //Auth
        LngBtnColor: '#000000',
        LngBtnActiveColor: '#bbbaba',
        AuthFooterTxtColor: '#616173',
        LogInBackground: '#FFFFFF',
        LogInLeftBackground: '#3E4758',
        LeftBtn: '#616173',
        LeftText: '#FFFFFF',
        title: '#000000',
        forgotpasswordLink: '#3B57E3',
        tabText: '#616173',
        tabTextActive: '#000000',
        tabTextHover: '#000000',
        carouselBtn: '#3E4758',

        //mainButton
        mainBtn: '#F9C20A',
        mainBtnHover: '#FAA61A',
        mainBtnFocus: '#393939',
        mainBtnText:'#231F20',

        //FormItem
        formItemLabel:'#616173',

        textColor: 'black',

    },
    fontFamily: 'Proxima Nova',
}

const darkTheme = {
    colors: {
        backgroundColor: 'black',
        textColor: 'orange'
    }
}

export {
    lightTheme,
    darkTheme
}