import { createSlice } from "@reduxjs/toolkit";
import {
  GetOperationHistoryList,
  GetOperationHistoryDetails,

} from "./api_action";

export {
  GetOperationHistoryList,
  GetOperationHistoryDetails,
};

const initialState = {
  operations: [],
  details: {},
  queryParams: "",
  error: "",
}

const OperationsHistorySlice = createSlice({
  name: "OperationsHistorySlice",
  initialState,
  reducers: {
    resetOperationHistoryDetails: (state)=> {
      state.details = {}
    }
  },
  extraReducers: {
    [GetOperationHistoryList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const  {url} = action.payload.config;
      state.operations = data;
      state.queryParams= url;
    },
    [GetOperationHistoryList.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [GetOperationHistoryDetails.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.details = data;
    },
    [GetOperationHistoryDetails.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const operationHistoryReducer = OperationsHistorySlice.reducer;
export const { resetOperationHistoryDetails } = OperationsHistorySlice.actions
