import React from 'react';
import {Select} from 'antd';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {selectArrow} from 'static/svg';

const { Option } = Select;

const LngSelect = ({handleButton, lang}) => {

    return (
        <LngSeelctContainer>
            <Select suffixIcon={<img src={selectArrow} alt={selectArrow}/>} onChange={handleButton} defaultValue={lang}>
                <Option value="az">AZ</Option>
                <Option value="ru">RU</Option>
                <Option value="en">EN</Option>
            </Select>
        </LngSeelctContainer>
    )
}

export default LngSelect;

LngSelect.propTypes = {
    handleButton: PropTypes.func,
    lang: PropTypes.string,
}

const LngSeelctContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .ant-select-selector{
    color: #63666A;
    background-color: #fff !important;
    border: 1px solid #fff !important;
    box-shadow: 0px 0px 4px -2px rgba(24, 39, 75, 0.12), 0px 1px 4px -2px rgba(24, 39, 75, 0.08);
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-arrow{
    color: #63666A;
    top: 50% !important;
  }
`