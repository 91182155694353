import React from 'react';
import styled from 'styled-components';
import { master_logo } from 'static/svg';

export const ModalSecondTitle = ({ image, title, secondTitle }) => {
    return (
        <Container>
            {image && <img src={image} alt='' />}
            <span className='card_number'>{title}</span>
            {secondTitle && <div className='dot'></div>}
            <span className='card_date'>{secondTitle}</span>
        </Container>
    )
};

const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    font-family: Proxima Nova;
    font-weight:normal;
    font-size: 14px;
    line-height: 17px;
    color: #231F20;
    margin-top:10px;
    img{
        padding-right:10px
    }
    .dot{
        margin:0 10px;
        background-color:orange;
        width:4px;
        height:4px;
        border-radius:4px;
    }
`