import React from "react";
import styled from "styled-components";

export const ContentSub = ({title, children}) => {
  return (
    <StyleContent>
      <span className="title">{title}</span>
      {children}
    </StyleContent>
  );
};



const StyleContent = styled.div`
 padding: 15px 0;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 24px 0;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: #1c1c1c;

  }
`;