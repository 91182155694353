import { createSlice } from '@reduxjs/toolkit'
import { GetAniPayTypeInfo } from './api_action';


export const GetAniPayTypeInfoSlice = createSlice({
    name: 'getAniPayTypeInfo',
    initialState: {
        aniPayTypeInfo: null,
        error: null
    },
    reducers: {
        removeState: (state) => { state.aniPayTypeInfo = null }
    },
    extraReducers: {
        [GetAniPayTypeInfo.fulfilled]: (state, action) => {
            state.aniPayTypeInfo = action.payload.data
        },
        [GetAniPayTypeInfo.rejected]: (state, action) => {
            state.error = action.payload
            state.aniPayTypeInfo = null
        }
    }
});

export { GetAniPayTypeInfo }

export const getAniPayTypeInfoReducer = GetAniPayTypeInfoSlice.reducer;
