export const ErrorMessage = (error) => {

  const lang=localStorage.getItem("lang")

  let errorMessage = [
    {
      messageBack: "Unexpected error occurred during matching INN",
      messageFront: {
        az: "INN uyğunluğu zamanı gözlənilməz xəta baş verdi",
        en: "English-INN xeta",
        ru: "Rus- INN xeta",
      },
    },
    {
      messageBack: "Bank Local Info Not Found!",
      messageFront: {
        az: "Bank kodu mövcud deyil",
        en: "Bank code is not found",
        ru: "Код банка не найден",
      },
    },
    {
      messageBack: "Excel file upload failed!",
      messageFront: {
        az: "Excel fayl yüklənmədi",
        en: "Excel file upload failed",
        ru: "Не удалось загрузить  Excel  файл",
      },
    },
    {
      messageBack: "SIM_ERROR",
      messageFront: {
        az: "Sorğu üçün təyin olunmuş müddət bitdi",
        en: "Request has expired",
        ru: "Срок действия запроса истек",
      },
    },
    {
      messageBack: "USER_CANCEL",
      messageFront: {
        az: "Sorğuya imtina edilmişdir",
        en: "Request was rejected",
        ru: "Запрос был отклонен",
      },
    },
    {
      messageBack: "INTERNAL_ERROR",
      messageFront: {
        az: "Xəta baş verdi",
        en: "An error has occurred",
        ru: "Произошла ошибка",
      },
    },
    {
      messageBack: "Internal server error statement",
      messageFront: {
        az: "Kart bloklaşdırılıb",
        en: "Card blocked",
        ru: "Карта заблокирована",
      },
    },
    {
      messageBack: "The currency of the accounts must be the same",
      messageFront: {
        az: "Hesablar üzrə valyuta eyni olmalıdır",
        en: "The currency of the accounts must be the same",
        ru: "Валюта счетов должна быть одинаковой",
      },
    },
    {
      messageBack: "The entered template name already exists.",
      messageFront: {
        az: "Daxil edilmiş şablon adı artıq mövcuddur",
        en: "The entered template name already exists",
        ru: "Введенное имя шаблона уже существует",
      },
    },
    {
      messageBack: "inputted-data-incorrect",
      messageFront: {
        az: "Daxil etdiyiniz cari şifrə yanlışdır",
        en: "The current password you entered is incorrect",
        ru: "Введенный текущий пароль неверный",
      },
    },
  ];

  const matchedError = errorMessage.find(
    (item) => (item.messageBack === (error.message || error.result))
  );

  if (matchedError) {
    return lang === "az"
      ? matchedError.messageFront.az
      : lang === "en"
      ? matchedError.messageFront.en
      : matchedError.messageFront.ru;
  } else {
    return  error.message;
  }
};
