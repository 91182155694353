import { createSlice } from '@reduxjs/toolkit';
import {
  AsanAuthenticate,
  AsanSign,
  OtpVerification,
  OtpLoginSMS,
  OtpSign,
  OtpConfirmation,
  OtpForgotPassword,
  OtpForgotPasswordVerification,
  GetQuestions,
  GetCompanyList,
  ForgotPasswordCheckAnswer,
  ForgotPasswordEmail,
  ValidationToken,
  RefreshToken,
  GetPermissionsList,
} from './api_actions';

export {
  AsanAuthenticate,
  AsanSign,
  OtpVerification,
  OtpLoginSMS,
  OtpSign,
  OtpConfirmation,
  OtpForgotPassword,
  OtpForgotPasswordVerification,
  GetQuestions,
  GetCompanyList,
  ForgotPasswordCheckAnswer,
  ForgotPasswordEmail,
  ValidationToken,
  RefreshToken,
  GetPermissionsList,
};

//Asan Authenticate
export const asanAuthenticateSlice = createSlice({
  name: 'asanAuthenticate',
  initialState: {
    asanInfo: null,
    isClicked: false,
    error: null,
  },
  reducers: {
    resend: (state, action) => {
      state.isClicked = action.payload;
      state.asanInfo = null;
      state.error = null;
    },
    clearAsanData: (state) => {
      state.asanInfo = null;
      state.isClicked = false;
      state.error = null;
    },
  },
  extraReducers: {
    [AsanAuthenticate.fulfilled]: (state, action) => {
      state.asanInfo = action.payload.data;
      state.error = null;
    },
    [AsanAuthenticate.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const asanAuthenticateReducer = asanAuthenticateSlice.reducer;
export const { resend, clearAsanData } = asanAuthenticateSlice.actions;

//Asan Sign
export const asanSignSlice = createSlice({
  name: 'asanSign',
  initialState: {
    asanSignData: null,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },

  extraReducers: {
    [AsanSign.fulfilled]: (state, action) => {
      state.asanSignData = action.payload.data;
      state.error = null;
    },
    [AsanSign.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});
export const { resetError } = asanSignSlice.actions;
export const asanSignReducer = asanSignSlice.reducer;

//OTP Login SMS
export const otpLoginSMSSlice = createSlice({
  name: 'otpLoginSMS',
  initialState: {
    userInfo: null,
    error: null,
  },
  reducers: {
    clearOtpData: (state) => {
      state.userInfo = null;
      state.error = null;
    },
  },

  extraReducers: {
    [OtpLoginSMS.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
      state.error = null;
    },
    [OtpLoginSMS.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const otpLoginSMSReducer = otpLoginSMSSlice.reducer;
export const { clearOtpData } = otpLoginSMSSlice.actions;

//OTP Verification
export const otpVerificationSlice = createSlice({
  name: 'otpVerification',
  initialState: {
    userInfo: null,
    error: null,
  },

  extraReducers: {
    [OtpVerification.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
      state.error = null;
    },
    [OtpVerification.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const otpVerificationReducer = otpVerificationSlice.reducer;

//OTP Sign
export const otpSignSlice = createSlice({
  name: 'otpSign',
  initialState: {
    // asanSignData: {},
    asanSignData: null,
    error: null,
  },

  extraReducers: {
    [OtpSign.fulfilled]: (state, action) => {
      state.asanSignData = action.payload.data;
      state.error = null;
    },
    [OtpSign.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const otpSignReducer = otpSignSlice.reducer;

//OTP confirmation
export const otpConfirmationSlice = createSlice({
  name: 'otpConfirmation',
  initialState: {
    userInfo: null,
    error: null,
  },
  extraReducers: {
    [OtpConfirmation.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
      state.error = null;
    },
    [OtpConfirmation.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const otpConfirmationReducer = otpConfirmationSlice.reducer;

//Get questions
export const getQuestionsSlice = createSlice({
  name: 'getQuestions',
  initialState: {
    questions: [],
    error: null,
  },
  extraReducers: {
    [GetQuestions.fulfilled]: (state, action) => {
      state.questions = action.payload.data;
      state.error = null;
    },
    [GetQuestions.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const getQuestionsReducer = getQuestionsSlice.reducer;

//Get companyList
export const getCompanyListSlice = createSlice({
  name: 'getCompanyList',
  initialState: {
    companyList: null,
    error: null,
  },
  extraReducers: {
    [GetCompanyList.fulfilled]: (state, action) => {
      state.companyList = action.payload.data;
      state.error = null;
    },
    [GetCompanyList.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const getCompanyListReducer = getCompanyListSlice.reducer;

//OTP forgot password
export const otpForgotPasswordSlice = createSlice({
  name: 'otpForgotPasswordSlice',
  initialState: {
    otpInfo: null,
    error: null,
  },

  extraReducers: {
    [OtpForgotPassword.fulfilled]: (state, action) => {
      state.otpInfo = action.payload;
      state.error = null;
    },
    [OtpForgotPassword.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const otpForgotPasswordReducer = otpForgotPasswordSlice.reducer;

//OTP forgot password Verification
export const otpForgotPasswordVerificationSlice = createSlice({
  name: 'otpForgotPasswordVerification',
  initialState: {
    isEmailActive: null,
    error: null,
  },

  extraReducers: {
    [OtpForgotPasswordVerification.fulfilled]: (state, action) => {
      state.isEmailActive = action.payload.data;
      state.error = null;
    },
    [OtpForgotPasswordVerification.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const otpForgotPasswordVerificationReducer =
  otpForgotPasswordVerificationSlice.reducer;

//Forgot password Check answer
export const forgotPasswordCheckAnswer = createSlice({
  name: 'forgotPasswordCheckAnswer',
  initialState: {
    checkInfo: {},
    error: null,
  },
  extraReducers: {
    [ForgotPasswordCheckAnswer.fulfilled]: (state, action) => {
      state.checkInfo = action.payload.data;
      state.error = null;
      localStorage.setItem('methodInfo', JSON.stringify(state.checkInfo));
    },
    [ForgotPasswordCheckAnswer.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const forgotPasswordCheckAnswerReducer = forgotPasswordCheckAnswer.reducer;

//Forgot password Email
export const forgotPasswordEmail = createSlice({
  name: 'forgotPasswordEmail',
  initialState: {
    emailInfo: null,
    isSuccess: false,
    error: null,
  },
  extraReducers: {
    [ForgotPasswordEmail.fulfilled]: (state, action) => {
      state.emailInfo = action.payload.data;
      state.isSuccess = true;
      state.error = null;
      localStorage.setItem('methodInfo', JSON.stringify(state.emailInfo));
    },
    [ForgotPasswordEmail.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const forgotPasswordEmailReducer = forgotPasswordEmail.reducer;

//Validation token
export const validationTokenSlice = createSlice({
  name: 'validationTokenSlice',
  initialState: {
    validationToken: null,
    isValid: false,
    error: null,
  },

  extraReducers: {
    [ValidationToken.fulfilled]: (state, action) => {
      state.validationToken = action.payload;
      state.isValid = true;
      state.error = null;
    },
    [ValidationToken.rejected]: (state, action) => {
      state.isValid = false;
      state.error = action.payload;
    },
  },
});

export const validationTokenReducer = validationTokenSlice.reducer;

//Refresh token
export const refreshTokenSlice = createSlice({
  name: 'refreshTokenSlice',
  initialState: {
    refreshToken: null,
    isValid: false,
    error: null,
  },

  extraReducers: {
    [RefreshToken.fulfilled]: (state, action) => {
      state.refreshToken = action.payload;
      state.isValid = true;
      state.error = null;
      // ! token set local storage
      localStorage.setItem('tokenInfo', JSON.stringify(state.refreshToken.data));
    },
    [RefreshToken.rejected]: (state, action) => {
      state.isValid = false;
      state.error = action.payload;
    },
  },
});

export const refreshTokenReducer = refreshTokenSlice.reducer;

//Logout
export const logOutSlice = createSlice({
  name: 'logOutSlice',
  initialState: {
    isLogout: false,
  },
  reducers: {
    logout: (state, action) => {
      state.isLogout = true;
      localStorage.clear();
    },
  },
});

export const logOutReducer = logOutSlice.reducer;

//Permissions
export const GetPermissionsListSlice = createSlice({
  name: 'GetPermissionsList',
  initialState: {
    permissions: null,
    error: null,
  },
  extraReducers: {
    [GetPermissionsList.fulfilled]: (state, action) => {
      state.permissions = action.payload.data;
      state.error = null;
    },
    [GetPermissionsList.rejected]: (state, action) => {
      state.error = action.payload;
      state.permissions = null;
    },
  },
});

export const GetPermissionsListReducer = GetPermissionsListSlice.reducer;

export const { logout } = logOutSlice.actions;
