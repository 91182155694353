import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

export  const GetAllBranch = createAsyncThunk(
  "GetAllBranch",
  async (body, thunkApi) => {
    try {
      const data = await client({
        url: "/branch-ms/branch/list",
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch(err) {
        return thunkApi.rejectWithValue(err.response);
    }
  }
);  

export  const GetAllTerminal = createAsyncThunk(
  "GetAllTerminal",
  async (body, thunkApi) => {
    try {
      const data = await client({
        url: "/branch-ms/terminal/list",
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch(err) {
        return thunkApi.rejectWithValue(err.response);
    }
  }
);  
export  const GetAllAtm = createAsyncThunk(
  "GetAllAtm",
  async (body, thunkApi) => {
    try {
      const data = await client({
        url: "/branch-ms/atm/list",
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch(err) {
        return thunkApi.rejectWithValue(err.response);
    }
  }
);  

export const getBranchById = createAsyncThunk(
  "getBranchById",
  async (body, thunkApi) => {
    try{
      const data = await client({
        url:`/branch-ms/branch/${body}`,
        showErrorToast: true,
        token: false,
      })
      return data;
    }catch(err){
      return thunkApi.rejectWithValue(err.response);

    }
  }
)

export const getTerminalById = createAsyncThunk(
  "getTerminalById",
  async (body, thunkApi)=>{
    try{
      const data = await client({
        url:`/branch-ms/terminal/${body}`,
        showErrorToast: true,
        token: false,
      })
      return data;
    }catch(err){
      return thunkApi.rejectWithValue(err.response);
    }
  }
)

export const getAtmById = createAsyncThunk(
  "getAtmById",
  async (body, thunkApi)=>{
    try{
      const data = await client({
        url:`/branch-ms/atm/${body}`,
        showErrorToast: true,
        token: false,
      })
      return data;
    }catch(err){
      return thunkApi.rejectWithValue(err.response);
    }
  }
)