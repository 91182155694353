import React from "react";
import { double_arrow } from "static/svg";
import { SingleButton } from "components";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";

export const ButtonRightIcon = ({ text, onClick , isMargin}) => {
  return (
    <StyledButton isMargin={isMargin}>
       <SingleButton
            isGrey
            text={text}
            icon={double_arrow}
            onClick={onClick}
           />
    </StyledButton>
  );
};

const StyledButton = styled.div`

  margin: ${props=> props.isMargin ? "0px 24px 0" : '0'};
  border-radius: 12px;
  button {
    width: 100%;
    height: 72px;
    background: #f8f8f8;
    justify-content: left;
    &:hover,
    &:focus {
      background: #f8f8f8;
    }
  }
  span {
    color: #393939;
    font-size: 15px;
    font-weight: 700;
    padding-left:14px;
  }
  img {
    order: 1;
    padding-left: 4px;
  }
  @media ${responsive.laptop}{
    div{
      width:100% !important;
    }
  }
  
`;
