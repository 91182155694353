import {createSlice} from '@reduxjs/toolkit';
import {
    GetCards,
    GetCardsInfo,
    RenameCard,
    ChangeCardStatus,
    StatementCard
} from './api_actions';

export {
    GetCards,
    GetCardsInfo,
    RenameCard,
    ChangeCardStatus,
    StatementCard
}

//get all cards
const initialState = {
    error: "",
    cards: [],
    cardDetails: {},
  };
  
  const GetCardsSlice = createSlice({
    name: "getCards",
    initialState,
    reducers: {},
    extraReducers: {
      [GetCards.fulfilled]: (state, action) => {
        const { data } = action.payload;
        state.cards = data;
      },
      [GetCards.rejected]: (state, action) => {
        const error = action.payload;
        state.error = error;
      },
      [GetCardsInfo.fulfilled]: (state, action) => {
        const { data } = action.payload;
        state.cardDetails = data;
      },
      [GetCardsInfo.rejected]: (state, action) => {
        const error = action.payload;
        state.error = error;
      },
    },
  });
  
  export const getCardsReducer = GetCardsSlice.reducer;


//rename card
const RenameCardSlice = createSlice({
    name: 'renameCard',
    initialState: {
        cardName: '',
        error: null
    },
    extraReducers: {
        [RenameCard.fulfilled]: (state, action) => {
            state.cardName = action.payload
            state.error = null
        },
        [RenameCard.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
})

export const renameCardReducer = RenameCardSlice.reducer;

//block the card
const ChangeCardStatusSlice = createSlice({
    name: 'changeCardStatus',
    initialState: {
        cardStatus: null,
        error: null
    },
    extraReducers: {
        [ChangeCardStatus.fulfilled]: (state, action) => {
            state.cardStatus = action.payload
            state.error = null
        },
        [ChangeCardStatus.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const changeCardStatusReducer = ChangeCardStatusSlice.reducer;

//statement card
const StatementCardSlice = createSlice({
    name: 'statementCards',
    initialState: {
        statementCardInfo: null,
        error: null
    },
    extraReducers: {
        [StatementCard.fulfilled]: (state, action) => {
            state.statementCardInfo = action.payload.data
            state.error = null
        },
        [StatementCard.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export const statementCardReducer = StatementCardSlice.reducer