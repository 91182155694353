import React from "react";
import { Row } from "antd";
import styled from "styled-components";
import DesktopVersion from "./DesktopVersion";
import { responsive } from "utils/responsiveUI";

const Files = (props) => {

  const {singleUpload} =props;
 
  return (
    <>
      <StyleRow gutter={24} singleUpload={singleUpload}>
        <DesktopVersion {...props} />
      </StyleRow>
    </>
  );
};

const StyleRow = styled(Row)`
    height:  ${({ singleUpload }) =>
    singleUpload ? "none" : "150px"};
    padding:   ${({ singleUpload }) =>
    singleUpload ? "none" : " 20px 30px"};
    row-gap: 5px !important;
  @media ${responsive.tablet} {
    flex-direction: column;
    .ant-col {
      max-width: 100%;
    }
  }
`;
export default Files;
