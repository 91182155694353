import React from "react";
import styled from "styled-components";
import { flexBtw } from "components";
import { BorderSelect } from "components/BorderSelect";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export const Caption = ({
  title,
  to,
  children,
  selectData,
  seeAll,
  onChange,
  noPadding,
  isMargin,
}) => {
  const { t } = useTranslation();
  return (
    <CaptionContainer noPadding={noPadding}>
      <StyledContent isMargin={isMargin}>
        <TitleContainer>{t(title)}</TitleContainer>

        {selectData && (
          <BorderSelect data={selectData} onChange={onChange} translate />
        )}
       {seeAll && (
          <Link to={to} style={{color: "#555", fontSize: "14px", fontWeight: "600"}}>
            {t("see all")}
          </Link>
        )}

      </StyledContent>
      {children}
    </CaptionContainer>
  );
};

const CaptionContainer = styled.div`
  padding: ${({ noPadding }) => (noPadding ? "10px 0 0  " : "50px 0 0")};
  .ant-select-dropdown{
    right: 0px!important;
    left: auto !important;
  }  
`;
const StyledContent = styled.div`
  ${flexBtw}
  margin: ${({ isMargin }) => (isMargin ? "8px 0 28px" : "0 0 20px")};
`;
const TitleContainer = styled.div`
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  color: #393939;
`;
