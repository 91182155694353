import React from "react";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";
import { SubMainButton } from "components";
import { successCheck } from "static/svg";

export const SuccessModal = ({cancelOnClick}) => {
  return (
    <StyledSignature>
      <StyleContent>
      <img src={successCheck} alt="successCheck" />
      <h4>
         The payment has been signed successfully. Waiting bank confirmation.
      </h4>
      </StyleContent>
     <StyleButton>
          <SubMainButton
            onClick={cancelOnClick}
            text={'Close'}
            isActivWhite
          />
     </StyleButton>
    </StyledSignature>
  );
};

const StyledSignature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  h4 {
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #63666a;
  }

  @media ${responsive.laptop} {
    margin-top: 0;

  }
`;

const StyleButton = styled.div`
 display: flex;
 justify-content: end;
 align-items: center;
 width: 100%;
 padding: 0 30px ;
 button{
    width: 132px;
    margin-top: 16px;
 }
`
const StyleContent = styled.div`
  width: 340px;
  display: flex;
`
