import { createSlice } from '@reduxjs/toolkit';
import { GetPaymentList } from './api_action';


const PaymentListSlice = createSlice({
    name: 'paymentList',
    initialState: {
        paymentList: [],
        error: null
    },
    extraReducers: {
        [GetPaymentList.fulfilled]: (state, action) => {
            state.paymentList = action.payload.data;
        },
        [GetPaymentList.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});

export { PaymentListSlice }

export const paymentListReducer = PaymentListSlice.reducer;