import { GroupButtons } from 'components/CustomButtons/GroupButtons';
import { Modal } from 'components/Modal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { renderTimer } from 'utils/shortFunctions';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth';
import { GetDocumentToSignCount } from 'store/documentToBeSign/api_action';
import { useTranslation } from 'react-i18next';
import { RefreshTokenRemainingTime } from 'store/auth/api_actions';

const RefreshModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [closedModal, setClosedModal] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  const remainingTimeInSeconds = localStorage.getItem('remainingTimeInSeconds')
  const refreshTokenExpiryDate = localStorage.getItem('refreshTokenExpiryDate')
  const handleRefresh = () => {
    GetDocumentToSignCount(()=>{
      setShowModal(false);  
      setClosedModal(false);
    })
  };

  useEffect(() => {
    const remainingTime = parseInt(
      remainingTimeInSeconds || '0',
      10
    );
    if (!isNaN(remainingTime) && remainingTime > 0) {
      setSecondsRemaining(remainingTime);
    }
  }, [remainingTimeInSeconds, refreshTokenExpiryDate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((prev) => {
        if (prev === null || prev <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    RefreshTokenRemainingTime()
  }, [])

  useEffect(() => {
    if (secondsRemaining !== null) {
      if (secondsRemaining <= 0) {
        dispatch(logout());
        window.location.reload();
      }
      if (!closedModal && secondsRemaining <= 180) {
        setShowModal(true);
        setClosedModal(true);
      }
    }
  }, [secondsRemaining]);
  
  return (
    <Modal open={showModal} cancel={() => setShowModal(false)} title={'information'}>
      <Container>
        <StyeldText>
          <p>{t('refreshInfo')}</p>
        </StyeldText>
        <StyledTimer>
          {renderTimer(secondsRemaining >= 0 ? secondsRemaining : 0)}
        </StyledTimer>

        <GroupButtons
          firstProps={{
            text: 'Cancel',
            isGrey: true,
            onClick: () => setShowModal(false),
          }}
          secondProps={{ text: 'refresh', onClick: () => handleRefresh() }}
        />
      </Container>
    </Modal>
  );
};

export default RefreshModal;

const Container = styled.div`
  padding: 25px;
`;

const StyeldText = styled.div`
  p {
    font-weight: 400;
    color: #63666a;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

const StyledTimer = styled.span`
  color: #e69300;
  font-size: 44px;
  margin-bottom: 16px;
  font-weight: 500;
`;
