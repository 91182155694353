import { createSlice } from '@reduxjs/toolkit';
import { GetBudgetCodeAndOrgList } from './api_action';

const GetBudgetCodeAndOrgListSlice = createSlice({
    name: "getBudgetCodeAndOrgList",
    initialState: {
        budgetCodeAndOrgList: null,
        error: null
    },
    extraReducers: {
        [GetBudgetCodeAndOrgList.fulfilled]: (state, action) => {
            const { budgetCodeList, budgetOrgList } = action.payload
            state.budgetCodeAndOrgList = { budgetCodeList: budgetCodeList.data, budgetOrgList: budgetOrgList.data }
            state.error = null
        },
        [GetBudgetCodeAndOrgList.rejected]: (state, action) => {
            state.error = action.payload
        }
    }
});
export { GetBudgetCodeAndOrgList }

export const GetBudgetCodeAndOrgListReducer = GetBudgetCodeAndOrgListSlice.reducer;