import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

export const PermissionsRouter = ({ component, path, exact = false, name }) => {
  const { permissions } = useSelector((state) => state.GetPermissionsList);

  const [values, setValues] = useState([]);

  useEffect(() => {
    const extractValues = (permissions) => {
      const newValues = [];
      for (var property in permissions) {
        if (typeof permissions[property] === "object") {
          for (var innerProperty in permissions[property]) {
            const value = permissions[property][innerProperty];
            newValues.push({ [innerProperty]: value });
          }
        } else {
          const value = permissions[property];
          newValues.push({ [property]: value });
        }
      }
      return newValues;
    };
    setValues(extractValues(permissions));
  }, [permissions]);

  return (
    <Fragment>
      {values.map((item, index) => {
        const itemName = Object.keys(item)[0];
        const itemValue = Object.values(item)[0];

        if (itemName === name && itemValue) {
          return (
            <Route
              key={index}
              exact={exact}
              path={path}
              component={component}
            />
          );
        }  else {
          return null
        }
      })}
 
    </Fragment>
  );
};
export default Permissions;
