import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'client';

export const getCustomerCheck = createAsyncThunk(
  'getCustomerCheck',
  async (thunkAPI) => {
    try {
      const data = await client({
        url: `/anipay/api/v1/customer`,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getCustomersData = createAsyncThunk(
  'getCustomersData',
  async (customerId, thunkAPI) => {
    try {
      const data = await client({
        url: `/anipay/api/v1/customer/${customerId}`,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Registr

export const customersRegistr = createAsyncThunk(
  'customersRegistr',
  async (customData, thunkAPI) => {
    try {
      const data = await client({
        url: `/anipay/api/v1/customer`,
        method: 'POST',
        data: customData,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const customerOtp = createAsyncThunk(
  'customerOtp',
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: `/anipay/api/v1/customer/verification`,
        method: 'POST',
        data: JSON.stringify(body),
        showErrorToast: true,
        showSuccessToast: true,
        succesMessage: 'Registration completed successfully',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Add account
export const customersAddAccount = createAsyncThunk(
  'customersAddAccount',
  async (data, thunkAPI) => {
    const { customerId, aliasId, accountId } = data;

    try {
      const data = await client({
        url: `anipay/api/v1/customer/${customerId}/aliases/${aliasId}`,
        method: 'POST',
        data: {
          iban: accountId,
        },
        showErrorToast: true,
        showSuccessToast: true,
        succesMessage: 'Account successfully added',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// delete Account

export const customersRemoveAccount = createAsyncThunk(
  'customersRemoveAccount',
  async (data, thunkAPI) => {
    const { accountId } = data;
    try {
      const data = await client({
        url: `anipay/api/v1/customer/accounts/${accountId}`,
        method: 'DELETE',
        showErrorToast: true,
        showSuccessToast: true,
        succesMessage: 'Account successfully deleted',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Add Alias
export const customersAddAliases = createAsyncThunk(
  'customersAddAliases',
  async (data, thunkAPI) => {
    const { customerId, type, value, setModalFunc } = data;

    try {
      const data = await client({
        url: `anipay/api/v1/customer/${customerId}/aliases`,
        method: 'POST',
        data: {
          type: type,
          value: value,
        },

        showErrorToast: true,
        token: false,
        successCallback: () => {
          setModalFunc(true);
        },
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Add Alias Verification
export const customersAliasesVerification = createAsyncThunk(
  'customersAliasesVerification',
  async ({ body, customerId }, thunkAPI) => {
    try {
      const data = await client({
        url: `anipay/api/v1/customer/${customerId}/aliases/activation`,
        method: 'POST',
        data: body,
        showErrorToast: true,
        succesMessage: 'Alias successfully added',
        showSuccessToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// Add fovarite

export const customersAccountFovarite = createAsyncThunk(
  'customersAccountFovarite',
  async (data, thunkAPI) => {
    const { accountId, aliasId } = data;
    try {
      const data = await client({
        url: `anipay/api/v1/customer/accounts/${accountId}/aliases/${aliasId}`,
        method: 'POST',
        showErrorToast: true,
        token: false,
        succesMessage: 'starredSuccess',
        showSuccessToast: true,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

// delete Aliase

export const customersRemoveAlias = createAsyncThunk(
  'customersRemoveAlias',
  async (data, thunkAPI) => {
    const { aliasId } = data;
    try {
      const data = await client({
        url: `anipay/api/v1/customer/aliases/${aliasId}`,
        method: 'DELETE',
        showErrorToast: true,
        token: false,
        succesMessage: 'Alias successfully deleted',
        showSuccessToast: true,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
