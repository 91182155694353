//logo
import E_logo from "./svgs/E_logo.svg";
import ExpressBankLogo from "./svgs/ExpressBankLogo.svg";
import ChatLogo from "./svgs/ChatLogo.svg";
import small_logo from "./svgs/small_logo.svg";
import whiteLogo from "./svgs/whiteLogo.svg";
//Edit
import edit from "./svgs/edit.svg";

// sidebar
import close from "./svgs/Close.svg";
import menu from "./svgs/menu.svg";
//Map
import mapMarkerPNG from "./svgs/mapMarker.png";
import mapMarkerSVG from "./svgs/mapMarker.svg";

//Login
import info from "./svgs/info.svg";
import question from "./svgs/question.svg";
import toWebSite from "./svgs/toWebSite.svg";
import logInNumInfo from "./svgs/logInNumInfo.svg";
import asan_signature from "./svgs/asan_signature.svg";
import phone from "./svgs/phone.svg";
import iconPhone from "./svgs/icon-phone.svg";

//Login InfoCard
import computer from "./svgs/computer.svg";
import desktop from "./svgs/desktop.svg";
import percent from "./svgs/percent.svg";
import time from "./svgs/time.svg";
import security from "./svgs/security.svg";
import circle from "./svgs/circle.svg";

//Auth_Carousel
import left_CarouselBtn from "./svgs/left_CarouselBtn.svg";
import right_CarouselBtn from "./svgs/right_CarouselBtn.svg";

//Input
import input_Success from "./svgs/input_Success.svg";
import input_Error from "./svgs/input_Error.svg";

//Header
import express_logo from "./svgs/express_logo.svg";
import express_logo_snow from "./svgs/express_logo_snow.svg";
import expressSmall from "./svgs/expressSmall.svg";
import finance from "./svgs/finance.svg";
import userRol from "./svgs/userRol.svg";
import message from "./svgs/message.svg";
import notification from "./svgs/notification.svg";
import search from "./svgs/search.svg";
import setting from "./svgs/setting.svg";
import tariff from "./svgs/tariff.svg";
import tax from "./svgs/tax.svg";
import logOut from "./svgs/logOut.svg";
import selectArrow from "./svgs/selectArrow.svg";

import header_drop_down from "./svgs/header_drop_down_icon.svg";
import notiyIcon from "./svgs/notiyIcon.svg";
import callHeader from "./svgs/callHeader.svg";

//Left menu
import dashboard_Nav from "./svgs/dashboard_Nav.svg";
import payments_Nav from "./svgs/payments_Nav.svg";
import payroll_Nav from "./svgs/payroll_Nav.svg";
import templates_Nav from "./svgs/templates_Nav.svg";
import history_Nav from "./svgs/history_Nav.svg";
import product_Nav from "./svgs/product_Nav.svg";
import express_Nav from "./svgs/express_Nav.svg";
import anipay from "./svgs/anipay.svg";
import map from "./svgs/map.svg";
import templates from "./svgs/templates.svg";

//Messgae icon
import message_icon from "./svgs/message_icon.svg";

//Falback Logo
import fallback_logo from "./svgs/fallback_logo.svg";

//currency
import flag_uk from "./svgs/flag_uk.svg";
import flag_eur from "./svgs/flag_eur.svg";
import flag_usd from "./svgs/flag_usd.svg";
import flag_tr from "./svgs/flag_tr.svg";
import flag_rus from "./svgs/flag_rus.svg";
import up_currency from "./svgs/up_currency.svg";
import down_currency from "./svgs/down_currency.svg";
import dot from "./svgs/dot.svg";
import currency_btn from "./svgs/currency_btn.svg";

//Service Order
import service_btn from "./svgs/service_btn.svg";

//Table
import tableMenu from "./svgs/tableMenu.svg";

//Calendar
import calendar from "./svgs/calendar.svg";

//Check
import check from "./svgs/check.svg";

//Swift
import upload from "./svgs/upload.svg";

//Bulk Payment && common svg
import backIcon from "./svgs/backIcon.svg";
import copy from "./svgs/copy.svg";
import cancel from "./svgs/cancel.svg";
import down from "./svgs/down.svg";
import downloadBlack from "./svgs/downloadBlack.svg";
import downloadWhite from "./svgs/downloadWhite.svg";
import downloadList from "./svgs/downloadList.svg";
import messageBlue from "./svgs/messageBlue.svg";
import next from "./svgs/next.svg";
import no from "./svgs/no.svg";
import noMenu from "./svgs/noMenu.svg";
import previous from "./svgs/previous.svg";
import print from "./svgs/print.svg";
import save from "./svgs/save.svg";
import saveGreen from "./svgs/saveGreen.svg";
import wrongInfo from "./svgs/wrongInfo.svg";

//map
import branch from "./svgs/branch.svg";
import terminal from "./svgs/terminal.svg";
import atm from "./svgs/atm.svg";
import right_row from "./svgs/right_row.svg";
import phone_icon from "./svgs/phone-icon.svg";
import clock_icon from "./svgs/clock-icon.svg";
import clock from "./svgs/clock.svg";
import location_icon from "./svgs/location-icon.svg";
import polygon_icon from "./svgs/polygon-icon.svg";

//payroll employees
import add_new from "./svgs/add_new.svg";
import download_grey from "./svgs/download_grey.svg";
import master_logo from "./svgs/master_logo.svg";
import table_clock from "./svgs/table_clock.svg";
import table_th_action from "./svgs/table_th_action.svg";
import visa_logo from "./svgs/visa_logo.svg";

//Modal
import modalCancel from "./svgs/modalCancel.svg";

//Payroll History
import printBlack from "./svgs/printBlack.svg";

//Payroll Report
import percentDown from "./svgs/percentDown.svg";
import percentHigh from "./svgs/percentHigh.svg";

//Products
import caruselArrow from "./svgs/caruselArrow.svg";
import blackprint from "./svgs/blackprint.svg";
import blackmessage from "./svgs/blackmessage.svg";

//Express POS
import blackadd from "./svgs/blackadd.svg";
import blackcancel from "./svgs/blackcancel.svg";
import teker from "./svgs/teker.svg";
import infoblack from "./svgs/infoblack.svg";

//Settings
import validateWrong from "./svgs/validateWrong.svg";
import validateSuccess from "./svgs/validateSuccess.svg";

//User roles
import userRoles from "./svgs/user-roles.svg";

//alerts
import alert_success from "./svgs/alert_success_icon.svg";
import alert_error from "./svgs/alert_error_icon.svg";

//close
import close_success from "./svgs/close_success.svg";
import close_error from "./svgs/close_error.svg";

//profile
import profile_img from "./svgs/profile_img.svg";
import undefined_profile_img from "./svgs/undefined_profile_img.svg";

// check Email
import checkIcon from "./svgs/checkIcon.svg";
import successIcon from "./svgs/successIcon.svg";
import successCheckIcon from "./svgs/successCheckIcon.svg";

// Dashboard
import Dashboard from "./dashboardSvg/Dashboard.svg";
import Call from "./dashboardSvg/Call.svg";
import Exit from "./dashboardSvg/Exit.svg";
import History from "./dashboardSvg/History.svg";
import Map from "./dashboardSvg/Map.svg";
import Payments from "./dashboardSvg/Payments.svg";
import Products from "./dashboardSvg/Products.svg";
import Settings from "./dashboardSvg/Settings.svg";
import User1 from "./dashboardSvg/User1.svg";
import User2 from "./dashboardSvg/User2.svg";
import AniPayCabinet from "./dashboardSvg/AniPayCabinet.svg";
import Transfer from "./dashboardSvg/Transfer.svg";
import Conversion from "./dashboardSvg/Conversion.svg";
import conversionFrame from "./svgs/conversionFrame.svg";
import CovensionWhite from "./dashboardSvg/CovensionWhite.svg";
import Order from "./dashboardSvg/Order.svg";
import card_map from "./dashboardSvg/card_map.svg";
import lock from "./dashboardSvg/Lock.svg";
import unlock from "./dashboardSvg/unlock.svg";
import visaCard from "./dashboardSvg/visaCard.svg";

// Flags
import AZ from "./flags/az.svg";
import TR from "./flags/tr.svg";
import EUR from "./flags/eur.svg";
import UK from "./flags/uk.svg";
import US from "./flags/us.svg";
import RU from "./flags/ru.svg";
import JP from "./flags/jp.svg";
import CN from "./flags/cn.svg";
import CHF from "./flags/chf.svg";

// Payments
import icon_bank from "./paymentsSvg/icon_bank.svg";
import icon_budget from "./paymentsSvg/icon_budget.svg";
import icon_map from "./paymentsSvg/icon_map.svg";
import icon_tax from "./paymentsSvg/icon_tax.svg";
import icon_salary from "./paymentsSvg/icon_salary.svg";
import icon_internal from "./paymentsSvg/icon_internal.svg";
import icon_accounts from "./paymentsSvg/icon_accounts.svg";
import icon_conversion from "./paymentsSvg/icon_conversion.svg";
import icon_gpp from "./svgs/gpp.png";

import icon_anipay from "./paymentsSvg/icon_anipay.svg";
import icon_house from "./paymentsSvg/icon_house.svg";
import icon_money from "./paymentsSvg/icon_money.svg";
import icon_money_bag from "./paymentsSvg/icon_money_bag.svg";
import seeAll from "./paymentsSvg/seeAll.svg";

// Next prev
import arrow_right from "./svgs/arrow_right.svg";
import arrow_disabled from "./svgs/arrow_disabled.svg";
import left_rigth_arrow from "./svgs/left_rigth_arrow.svg";
import left_arrow from "./svgs/left_arrow.svg"
import double_arrow from "./svgs/double_arrow.svg";
import double_arrow_up from "./svgs/double_arrow_up.svg";
import arrow_right_yellow from "./svgs/arrow-right-yellow.svg";

// popover icons
import PageIcon from "./svgs/PageIcon.svg";
import PageIconWhite from "./svgs/PageIconWhite.svg";
import statementIconWhite from "./svgs/statementIconWhite.svg";


import payIcon from "./svgs/payIcon.svg";
import PenIcon from "./svgs/PenIcon.svg";
import more_search from "./svgs/more_search.svg";
import pen_one from "./svgs/pen_one.svg";

// Exchange rates
import downArrow from "./svgs/downArrow.svg";
import upArrow from "./svgs/upArrow.svg";
import oneDot from "./svgs/oneDot.svg";

// Figures
import bank from "./figures/bank.svg";
import romb from "./figures/romb.svg";
import square from "./figures/square.svg";

import triangle from "./figures/triangle.svg";
import bank_d from "./figures/bank_d.svg";

import accounter_black from "./figures/accounter_black.svg";
import creator_black from "./figures/creator_black.svg";
import deputy_black from "./figures/deputy_black.svg";
import bank_black from "./figures/bank_black.svg";
import director_black from "./figures/director_black.svg";

import successCheck from "./figures/successCheck.svg";

import Plus from "./svgs/Plus.svg";
import plusBlack from "./svgs/plusBlack.svg";
import pdf from "./svgs/pdf.svg";
import pdfIcon from './svgs/pdfIcon.svg';
import searchPdf from "./svgs/searchPdf.svg";

import expressBank from "./svgs/expressBank.png";

import errorField from "./svgs/errorField.svg";
import editIcon from "./transferSvg/edit.svg";
import saveIcon from "./transferSvg/save.svg";
import deleteIcon from "./svgs/deleteIcon.svg";
import userActive from './svgs/userActive.svg'; 

import clearIcon from "./svgs/clearIcon.svg";
import stepCheck from "./svgs/stepCheck.svg";
import error_icon from "./svgs/error_icon.svg";
import fin_icon from "./svgs/fin_icon.svg";
import oldIdCard from "./svgs/oldIdCard.svg";
import newIdCard from "./svgs/newIdCard.svg";

import yellowWarning from "./svgs/yellowWarning.svg";
import excel_icon from "./svgs/excel_icon.svg";
import statement from "./svgs/statement.svg";
import filter from "./svgs/filter.svg";

import gov from "./gov/gov.svg";
import municipality from "./gov/municipality.svg";
import taxs from "./gov/tax.svg";
import customs from "./gov/customs.svg";
import DIN from "./gov/DIN.svg";
import yashat from "./gov/yashat.svg";
import insurance from "./gov/insurance.svg";
import BNA from "./gov/BNA.svg";
import kharabag from "./gov/kharabag.svg";
import loan from "./gov/loan.svg";
import army from "./gov/army.svg";
import covid from "./gov/covid.svg";
import asan from "./gov/asan.svg";

// loan
import loan_azn from "./products/loan_azn.svg";
import loan_eur from "./products/loan_eur.svg";
import loan_usd from "./products/loan_usd.svg";
import loan_rur from "./products/loan_rur.svg";
import loan_gbp from "./products/loan_gbp.svg";

//Guarantees
import gdarkGray_azn from "./products/Guarantees/azn/dark_gray.svg";
import gdarkLight_azn from "./products/Guarantees/azn/light_gray.svg";
import gdarkGray_euro from "./products/Guarantees/euro/dark_gray.svg";
import gdarkLight_euro from "./products/Guarantees/euro/light_gray.svg";
import gdarkGray_gbp from "./products/Guarantees/gbp/dark_gray.svg";
import gdarkLight_gbp from "./products/Guarantees/gbp/light_gray.svg";
import gdarkGray_rub from "./products/Guarantees/rub/dark_gray.svg";
import gdarkLight_rub from "./products/Guarantees/rub/light_gray.svg";
import gdarkGray_usd from "./products/Guarantees/usd/dark_gray.svg";
import gdarkLight_usd from "./products/Guarantees/usd/light_gray.svg";

// Deposit
import deposit_dark_azn from "./products/Deposit/azn/deposit_dark_azn.svg";
import deposit_light_azn from "./products/Deposit/azn/deposit_light_azn.svg";
import deposit_dark_eur from "./products/Deposit/euro/deposit_dark_eur.svg";
import deposit_light_eur from "./products/Deposit/euro/deposit_light_eur.svg";
import deposit_dark_rur from "./products/Deposit/rub/deposit_dark_rur.svg";
import deposit_light_rur from "./products/Deposit/rub/deposit_light_rur.svg";
import deposit_dark_gbp from "./products/Deposit/gbp/deposit_dark_gbp.svg";
import deposit_light_gbp from "./products/Deposit/gbp/deposit_light_gbp.svg";
import deposit_dark_usd from "./products/Deposit/usd/deposit_dark_usd.svg";
import deposit_light_usd from "./products/Deposit/usd/deposit_light_usd.svg";

//product logo with expressbank icon
import loan_with_logo from "./products/product_logo/loan.svg";
import guarantee_with_logo from "./products/product_logo/guarantee.svg";
import deposit_with_logo from "./products/product_logo/deposit.svg";

// Cards
import master from "./products/Card/master.svg";
import visa from "./products/Card/visa.svg";

import warning from "./svgs/warning.svg";

import stateIcon from "./svgs/stateIcon.svg";

import statementforthreedot from "./svgs/statementforthreedot.svg";

////////////TRANSFER AND PAYMENTS///////////////////////
import within_bank from "./payment/WITHIN_BANK.svg";
import between_my_account from "./payment/BETWEEN_MY_ACCOUNT.svg";
import inside_country from "./payment/INSIDE_COUNTRY.svg";

/////currency symbols////
import manat from "./svgs/manat.svg";
import dollar from "./svgs/dollar.svg";
import frank from "./svgs/frank.svg";
import euro from "./svgs/euro.svg";
import united from "./svgs/united.svg";
import rubl from "./svgs/rubl.svg";
import yen from "./svgs/yen.svg";

import card_icon from "./svgs/card_icon.svg";
import masterCart from "./svgs/masterCart.svg";
import visaCart from "./svgs/visaCart.svg";

import convertIcon from './svgs/convertIcon.svg';
import convertIconDeactive from './svgs/convertIconDeactive.svg';
import export_icon from './svgs/export_icon.svg';
import maps from './svgs/maps.svg';
import mapnavigation from './svgs/mapnavigation.svg';
import excellError from './svgs/excellError.svg';
import excellSuccess from './svgs/excellSuccess.svg';
import jpegUpload from './svgs/jpeg.svg';
import pdfUpload from './svgs/pdfUpload.svg';
import documentUpload from './svgs/document.svg';
import download from './svgs/download.svg';
import trash from './svgs/trash.svg';
import AniPayTIN from './payment/AnipayTin.svg';
import AniPayMOBILE from './payment/AniPayMobile.svg';
import AniPayEMAIL from './payment/AniPayEmail.svg';
import star_full from './svgs/star_full.svg';
import star from './svgs/star.svg';


export {
  star_full,
  star,
  AniPayEMAIL,
  AniPayTIN,
  AniPayMOBILE,
  loan_with_logo,
  guarantee_with_logo,
  deposit_with_logo,
  export_icon,
  card_icon,
  masterCart,
  visaCart,
  convertIcon,
  convertIconDeactive,
  warning,
  loan_azn,
  loan_eur,
  loan_usd,
  loan_rur,
  loan_gbp,
  deposit_dark_azn,
  deposit_light_azn,
  deposit_dark_eur,
  deposit_light_eur,
  deposit_dark_rur,
  deposit_light_rur,
  deposit_dark_usd,
  deposit_light_usd,
  deposit_dark_gbp,
  deposit_light_gbp,
  gov,
  asan,
  municipality,
  taxs,
  customs,
  DIN,
  yashat,
  insurance,
  BNA,
  kharabag,
  loan,
  army,
  covid,
  filter,
  pen_one,
  statement,
  excel_icon,
  yellowWarning,
  oldIdCard,
  newIdCard,
  fin_icon,
  error_icon,
  stepCheck,
  clearIcon,
  // Treansfer / Within bank
  editIcon,
  saveIcon,
  more_search,
  errorField,
  expressBank,
  Plus,
  plusBlack,
  pdf,
  pdfIcon,
  searchPdf,
  // Figures
  bank,
  romb,
  square,
  triangle,
  bank_d,
  accounter_black,
  creator_black,
  deputy_black,
  bank_black,
  director_black,
  successCheck,
  downArrow,
  upArrow,
  oneDot,
  // flags
  AZ,
  TR,
  EUR,
  UK,
  US,
  RU,
  JP,
  CN,
  CHF,
  // payments
  icon_bank,
  icon_budget,
  icon_map,
  icon_tax,
  icon_salary,
  icon_internal,
  icon_accounts,
  icon_anipay,
  icon_house,
  icon_conversion,
  icon_money,
  icon_money_bag,
  seeAll,
  icon_gpp,

  // next prev
  arrow_right,
  arrow_disabled,
  left_rigth_arrow,
  double_arrow,
  double_arrow_up,
  arrow_right_yellow,
  left_arrow,

  // Dashboard
  Dashboard,
  Call,
  Exit,
  History,
  Map,
  Payments,
  Products,
  Settings,
  User1,
  User2,
  AniPayCabinet,
  Transfer,
  Conversion,
  CovensionWhite,
  conversionFrame,
  Order,
  card_map,
  lock,
  unlock,
  visaCard,
  // popover icons
  PageIcon,
  PageIconWhite,
  statementIconWhite,
  PenIcon,
  payIcon,
  //  success password
  successIcon,
  successCheckIcon,
  // check Email
  checkIcon,
  //profile
  profile_img,
  undefined_profile_img,

  //alert
  alert_success,
  alert_error,

  //close
  close_success,
  close_error,

  //logo
  E_logo,
  ExpressBankLogo,
  ChatLogo,
  small_logo,
  whiteLogo,

  //Edit
  edit,

  //Mao
  mapMarkerPNG,
  mapMarkerSVG,

  //User roles
  userRoles,

  //Login
  info,
  question,
  toWebSite,
  logInNumInfo,
  phone,
  iconPhone,
  asan_signature,

  //Login Info Card
  computer,
  desktop,
  percent,
  time,
  security,
  circle,

  //Auth_Carousel
  left_CarouselBtn,
  right_CarouselBtn,

  //Input
  input_Success,
  input_Error,

  //Header
  express_logo,
  express_logo_snow,
  expressSmall,
  finance,
  userRol,
  message,
  notification,
  search,
  setting,
  tariff,
  tax,
  logOut,
  selectArrow,
  header_drop_down,
  notiyIcon,
  callHeader,

  // sidebar
  close,
  menu,

  //Left Menu
  dashboard_Nav,
  payments_Nav,
  payroll_Nav,
  templates_Nav,
  history_Nav,
  product_Nav,
  express_Nav,
  anipay,
  map,
  templates,

  //Message icon
  message_icon,

  //Fallback Logo
  fallback_logo,

  //currency
  flag_uk,
  flag_eur,
  flag_usd,
  flag_tr,
  flag_rus,
  dot,
  up_currency,
  down_currency,
  currency_btn,

  //Service order
  service_btn,

  //Table
  tableMenu,

  //Calendar
  calendar,

  //Checkbox
  check,

  // SWIFT
  upload,

  //Bulk Payment && common svg
  backIcon,
  copy,
  cancel,
  deleteIcon,
  userActive,
  
  down,
  downloadBlack,
  downloadWhite,
  downloadList,
  messageBlue,
  next,
  no,
  noMenu,
  previous,
  print,
  save,
  saveGreen,
  wrongInfo,

  //map
  branch,
  terminal,
  atm,
  phone_icon,
  clock_icon,
  clock,
  location_icon,
  right_row,
  polygon_icon,

  //payroll employees
  add_new,
  download_grey,
  master_logo,
  table_clock,
  table_th_action,
  visa_logo,

  //Modal
  modalCancel,

  //Payroll History
  printBlack,

  //Payroll report
  percentDown,
  percentHigh,

  //Products
  caruselArrow,
  blackprint,
  blackmessage,

  //Express POS
  blackadd,
  blackcancel,
  teker,
  infoblack,

  //Settings
  validateWrong,
  validateSuccess,

  //TRANSFER AND PAYMENTS
  within_bank,
  between_my_account,
  inside_country,

  //currency symbolss////
  manat,
  dollar,
  frank,
  euro,
  united,
  rubl,
  yen,
  stateIcon,
  statementforthreedot,

  //guarantee
  gdarkGray_azn,
  gdarkLight_azn,
  gdarkGray_euro,
  gdarkLight_euro,
  gdarkGray_gbp,
  gdarkLight_gbp,
  gdarkGray_rub,
  gdarkLight_rub,
  gdarkGray_usd,
  gdarkLight_usd,

  ////map
  maps,
  mapnavigation,
  // cards
  master,
  visa,
  ///salary
  excellError,
  download,
  excellSuccess,
  jpegUpload,
  pdfUpload,
  documentUpload,
  trash
};
