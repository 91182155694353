import React from 'react';
import {useTranslation} from "react-i18next";
import styled from 'styled-components';

import {
    computer,
    desktop,
    percent,
    time,
    security,
    circle
} from 'static/svg';

const InfoCard = () => {
    //for translation
    const {t} = useTranslation();

    return (
        <StyledDiv >
            <SubStyledDiv img={computer}>
                <p>{t('opportunities')}</p>

                <ul>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('controlManagement')}</span>
                    </li>
                    {/*<li>*/}
                    {/*    <img src={circle} alt=""/>*/}
                    {/*    <span>{t('intraBankTransfers')}</span>*/}
                    {/*</li>*/}
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('intraBankTransfers')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('swift')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('conversion')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('accountStatements')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('loanInfo')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('paymentSalary')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('trackingStatus')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('recurring')}</span>
                    </li>
                    <li>
                        <img src={circle} alt=""/>
                        <span>{t('exchangeRates')}</span>
                    </li>
                </ul>
            </SubStyledDiv>

            <div className="advantages">
                <p>{t('advantages')}</p>

                <ul>
                    <li>
                        <img src={desktop} alt=""/>
                        <span>{t('clearInterface')}</span>
                    </li>

                    <li>
                        <img src={percent} alt=""/>
                        <span>{t('noCommission')}</span>
                    </li>

                    <li>
                        <img src={time} alt=""/>
                        <span>{t('accountControl')}</span>
                    </li>

                    <li>
                        <img src={security} alt=""/>
                        <span>{t('securePayments')}</span>
                    </li>
                </ul>
            </div>
        </StyledDiv>
    );
}

export default InfoCard;

const SubStyledDiv = styled.div`
 background-image: url(${props => props.img});
 background-repeat: no-repeat;
 background-position: 100%;
 padding: 85px;
` 
const StyledDiv = styled.div`
  background: #fff url(${props => props.img}) no-repeat;
  background-position: 100% -25%;
  border-radius: 10px;
  // box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  width: 976px;
  height: 817px;
  margin: 50px auto auto;

  p {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #231F20;
    letter-spacing: 0.05em;
  }

  ul {
    padding-left: 0;
    list-style-type: none;

    li {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.05em;
      color: #231F20;
      margin: 12px 0;
    }
  }

  div {
    &:first-child {
      border-bottom: 1px solid #D7D8D6;
      padding: 85px 25px;
      /* padding: 25px 25px 30px; parent div-den height: 817px; sildikde */
    }

    ul {
      li {
        img {
          margin-right: 10px;
        }
      }
    }
  }

  .advantages {
    padding: 30px;
    // margin-top: 40px;
    /*padding-bottom: 40px; parent div-den height: 817px; sildikde */

    p {
      text-align: center;
      // margin-bottom: 35px;
    }

    ul {
      display: flex;
      justify-content: center;
      text-align: center;
      // margin-top: 60px;

      li {
        display: inline-block;
        padding: 0 30px;

        //&:not(:last-child) {
        //  padding-right: 70px;
        //}

        span {
          width: 150px;
          display: block;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: 0.05em;
          color: #231F20;
        }

        img {
          margin: auto auto 25px;
          display: block;
        }
      }
    }
  }
`;
