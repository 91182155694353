import { createSlice } from "@reduxjs/toolkit";
import { InsideCountryValidate , InsideCountryProses, GetBankCode} from "./api_action";

export { InsideCountryValidate , InsideCountryProses, GetBankCode};

//insideCountry
const initialState = {
  validate: null,
  process:null,
  error: null,
}
export const InsideCountrySlice = createSlice({
  name: "insideCountry",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [InsideCountryValidate.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.validate = data
    },
    [InsideCountryProses.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.process = data
    },
    [InsideCountryValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [InsideCountryProses.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const insideCountryReducer = InsideCountrySlice.reducer;


//Beneficiary Bank Code
const GetBankCodeSlice = createSlice({
  name: "getBankCode",
  initialState: {
    bankCodeData: null,
    error: null,
  },
  reducers: {
    resetBankCode: (state, action) => {
      state.bankCodeData = null
      state.error = null
    }
  },
  extraReducers: {
    [GetBankCode.fulfilled]: (state, action) => {
      const data = action.payload;
      state.bankCodeData = data.data;
      state.error = null;
    },
    [GetBankCode.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { resetBankCode } = GetBankCodeSlice.actions;

export const getBankCodeReducer = GetBankCodeSlice.reducer;