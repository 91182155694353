import { createSlice } from "@reduxjs/toolkit";
import {
  StatementAccountList,
  StatementCardList,
  DeleteAccountElement,
  DeleteCardElement,
  GenerateAccountStatement,
  GenerateCardStatement,
} from "./api_action";
export {
  StatementAccountList,
  StatementCardList,
  DeleteAccountElement,
  DeleteCardElement,
  GenerateAccountStatement,
  GenerateCardStatement,
};
const initialState = {
  statamentList: [],
  statamentCardList: [],
  error: null,
  deletedAccount: null,
  deletedCard: null,
  generatedAccountStatement: null,
  generateCardStatement: null,
};

const StatementSlice = createSlice({
  name: "statement",
  initialState: initialState,
  extraReducers: {
    [StatementAccountList.fulfilled]: (state, action) => {
      state.statamentList = action.payload;
    },
    [StatementCardList.fulfilled]: (state, action) => {
      state.statamentCardList = action.payload;
    },
    [StatementAccountList.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [StatementCardList.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [DeleteAccountElement.fulfilled]: (state, action) => {
      state.deletedAccount = action.payload;
    },
    [DeleteAccountElement.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [DeleteCardElement.fulfilled]: (state, action) => {
      state.deletedCard = action.payload;
    },
    [DeleteCardElement.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [GenerateAccountStatement.fulfilled]: (state, action) => {
      state.generatedAccountStatement = action.payload;
    },
    [GenerateAccountStatement.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [GenerateCardStatement.fulfilled]: (state, action) => {
      state.generateCardStatement = action.payload;
    },
    [GenerateCardStatement.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const StatementReducer = StatementSlice.reducer;
