import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom'
import {Tooltip} from 'components'


export const LinkBtn = ({text, img, href = '#', count, backcolor, handleClick}) => {

    const renderedForLink = () => (
        <Link to={href} className='btnWrapper'>
            {renderedChildren}
        </Link>
    )

    const renderedForEvent = () => (
        <div onClick={handleClick} className='btnWrapper'>
            {renderedChildren}
        </div>
    )

    const renderedChildren = (
        <Fragment>
            {count && <div className='badge_container'>{count}</div>}
            <img src={img} alt='link'/>
        </Fragment>
    )

    return (
        <StyledContainer backcolor={backcolor}>
            <Tooltip text={text}>
                {handleClick ? renderedForEvent() : renderedForLink()}
            </Tooltip>
        </StyledContainer>
    )
};

const StyledContainer = styled.div`
  .btnWrapper {
    background-color: ${props => props.backcolor ? '#FCFCFC' : 'transparent'};
    height: 45px;
    width: 45px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }
  }

  .badge_container {
    background-color: #FAA61A;
    position: absolute;
    top: 0;
    left: 27px;
    height: 16px;
    border-radius: 10px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.03em;
    color: #474C54;
  }
`