import React from 'react';
import styled from 'styled-components';

//paddingForm - Card-ın yuxarıdan və aşağıdan padding-ni vermək üçündür.Verməsək default dəyəri götürəcək
//dividerless - title altındakı xəttin olub olmaması
//form - form tipində olacaq enliliyi 645 px və aşağıda footerdə component yerləşdirə bilərik(əsasən button)
//footer - form tipi aktiv olduqda footere komponent veririk.
//shadow- default olaraq trudur əyər false olsa shadow olmayacaq.

export const Card = ({ title, rightComponent, children, form = false, footer, shadow = true, dividerless = false, ...rest }) => {

    return (
        <Container title={title} shadow={shadow}  {...rest}>
            {title && <CardHeader dividerless={dividerless}>
                <span className='cardTitle' >{title}</span>
                <div>{rightComponent}</div>
            </CardHeader>
            }
            {form ?
                <div className='form_container'>
                    <div className='form_center'>
                        {children}
                    </div>
                    <CardFooter>
                        <ButtonContainer>
                            {footer}
                        </ButtonContainer>
                    </CardFooter>
                </div>
                :
                <div className='formless_container'>
                    {children}
                </div>

            }

        </Container>
    )
}

const Container = styled.div`
    background: #FFFFFF;
    /* shadow false olsa kğlgə olmayacaq əyər kolgə olsa title-ın olub olmadığını yoxlayıb ona uyğun kölgəni tətbiq edəcək */
    box-shadow:${props => props.shadow ? props.title ? '0px 4px 16px' : '0px 1px 2px' : '0 0 0'}  rgba(0, 0, 0, 0.08);   
    border-radius: 10px;
    display:flex;
    flex-direction:column;
    margin:${({ title }) => title ? '0' : '2px'};
    margin-top:${({ margintop }) => margintop || '0'};
    .formless_container{
        padding:${({ paddingForm }) => paddingForm}
    }
    .form_container{
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        display:flex;
        flex-direction:column;
    }
    .form_center{
        width:${({ width }) => width || '645px'};
        padding:${({ paddingForm }) => paddingForm || '40px 0 5px'}
    }
`
const ButtonContainer = styled.div`
    width:645px;
`
const CardFooter = styled.div`
    width:100%;
    border-top:1px solid #E5E5E5;
    padding:35px 0;
    display:flex;
    justify-content:center;
`
const CardHeader = styled.div`
    height:72px;
    border-bottom: ${props => props.dividerless ? '0' : '1px'} solid #E5E5E5;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 30px;
    .cardTitle{
        font-family: Proxima Nova;
        font-size: 24px;
        line-height: 29px;
        font-weight:700;
        letter-spacing: 0.03em;
        color: #231F20;
    }
`