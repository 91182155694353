import { createSlice } from "@reduxjs/toolkit";
import {
  StateTreasuryValidate,
  StateTreasuryProses,
} from "./api_action";

export {
  StateTreasuryValidate,
  StateTreasuryProses,
};

//budget
const initialState = {
  validate: null,
  process: null,
  error: null,
};
export const StateTreasurySlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [StateTreasuryValidate.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.validate = data;
    },
    [StateTreasuryProses.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.process = data;
    },
    [StateTreasuryValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [StateTreasuryProses.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const StateTreasuryReducer = StateTreasurySlice.reducer;
