import React, { useEffect } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import styled, { keyframes } from "styled-components";
import { dot } from "static/svg";

export const Notify = ({
  handlerPage,
  handler,
  show,
  isButtons,
  icon,
  setShow,
  duration,
  template,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setInterval(() => {
      setShow(false);
    }, duration);
  }, []);

  return (
    <>
      {show && (
        <NotifContainer show={show}>
          <InfoSection>
            <img src={icon} alt="stepCheck" />
            <div>
              {template
                ? t("TemplateUpdated")
                : t("Template successfully created")}
            </div>
            <div onClick={handler}>
              <Icon />
            </div>
          </InfoSection>
          <>
            {isButtons && (
              <OffersList>
                <li onClick={handlerPage}>{t("Go to page")}</li>
                <li>
                  <img src={dot} alt="middle_dot" />
                </li>
                <li onClick={handler}>{t("No,Thanks")}</li>
              </OffersList>
            )}
          </>
        </NotifContainer>
      )}
    </>
  );
};

const Icon = () => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.375 1.625L1.625 10.375"
        stroke="#555555"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.625 1.625L10.375 10.375"
        stroke="#555555"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AntNotificationBtn = ({ handler }) => {
  return (
    <>
      <Button type="primary" onClick={handler}>
        Open the notification box
      </Button>
    </>
  );
};

const OffersList = styled.ul`
  display: flex;
  align-items: center;
  gap: 18px;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  margin: 5px 0 13px 1rem;
  cursor: pointer;
  & li:first-child {
    color: #12b76a;
  }
  li {
    list-style: none;
  }
`;
const InfoSection = styled.div`
  display: flex;
  gap: 18px;
  padding: 10px;
  color: #393939;
  font-weight: 600;
  font-size: 16px;

  img {
    margin-bottom: 0.8rem;
  }
`;

const animationNotif = keyframes`
  from{
    right: -380px;
    top: 1.4rem;
  }
  to{
    right: 1.7rem;
    top: 1.4rem;
  }
`;

const NotifContainer = styled.div`
  // width: 372px;
  position: absolute;
  background: #ffffff;
  right: 1.7rem;
  @media (max-width: 450px){
    right:0px;
    left: 30px;
  }
  top: 0rem;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 6px;
  z-index: 10000;
  padding: 7px 11px;
  animation: ${animationNotif} 0.2s ease-in-out forwards;
`;
