import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from 'client';


export const GetBankInfoByCode = createAsyncThunk(
    "GetBankInfoByCode",

    async (bankCode, thunkAPI) => {
        try {
            const data = await client({
                url: `/payment/standard/bank-info/${bankCode}`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "Get Bank info",
                token: false,
            })
            return data
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)