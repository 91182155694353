import React from 'react';
import styled from 'styled-components';
import {
  location_icon,
  phone_icon,
  polygon_icon
} from "static/svg";

export const MapTooltip = (props) => {
  return (
    <StyledTooltip>
      <div className="box">
        <img className="box-icon" src={polygon_icon} alt="Icon" />
        <div className="title">
          <div className="title-address">
            <h3>{props?.item?.nameAz ? props?.item?.nameAz : props?.item?.name}</h3>
            {/*<span>3.5 km</span>*/}
          </div>
          <div className="title-info">
            <img className="title-info--icon" src={location_icon} alt="Icon" />
            <p className="title-info--text">{props?.item?.addressAz ? props?.item?.addressAz : props?.item?.address}</p>
          </div>
        </div>
        <div className="body">
          {props?.item?.fax && (
            <div className="body-wrapper">
              <img className="body-icon" src={phone_icon} alt="Icon" />
              <div className="body-container">
                <p className="body-title">Telefon nömrələri:</p>
                <p className="body-info">{props?.item?.fax}</p>
              </div>
            </div>
          )
          }
        </div>
      </div>
    </StyledTooltip>
  )
};

const StyledTooltip = styled.div`
  position: absolute;
  z-index: 1;
  top: 45px;
  min-width: 280px;
  background: #2D3546;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-family: Proxima Nova;
  color: #fff;

  .box {
    padding: 15px 20px;

    .box-icon {
      position: absolute;
      top: -11px;
      left: 47%;
    }

    .title {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 110px;

      .title-address {
        display: flex;
        justify-content: space-between;

        h3 {
          color: #fff;
          font-size: 20px;
          letter-spacing: 0.05em;
        }

        span {
          width: 20px;
          font-size: 14px;
          letter-spacing: 0.05em;
          text-decoration-line: underline;
        }
      }

      .title-info {
        display: flex;
        justify-content: space-between;

        .title-info--icon {
          width: 16px;
          margin-right: 10px;
        }

        .title-info--text {
          font-size: 14px;
          letter-spacing: 0.05em;
          margin: 0;
        }
      }
    }

    .body {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .body-line {
        width: 100%;
        height: 1px;
        margin-top: 13px;
        background-color: #fff;
        border-radius: 2px;
        opacity: 0.2;
      }

      .body-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 15px;

        .body-icon {
          width: 16px;
          margin-top: 3px;
          margin-right: 10px;

        }

        .body-container {

          .body-title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.05em;
            margin: 0;
          }

          .body-info {
            font-size: 14px;
            letter-spacing: 0.05em;
            margin: 0;
          }
        }
      }
    }
  }
`
