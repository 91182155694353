import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  Dashboard,
  User2,
  AniPayCabinet,
  Transfer,
  Payments,
  Products,
  History,
  Exit,
  Conversion,
  Order,
  editIcon,
  stateIcon,
  templates,
  Map,
  Settings,
} from 'static/svg';
import { payments, products, transfer } from './subMenuLink';
import Icon from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'components/Tooltip';
import { client } from 'client';
import { GetDocumentToSignCount } from 'store/documentToBeSign/api_action';

export const Menus = ({ collapsed, toggle, setToggle }) => {
  const { SubMenu } = Menu;
  const location = useLocation();
  const { t } = useTranslation();
  const { permissions } = useSelector((state) => state.GetPermissionsList);
  const [count, setCount] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    GetDocumentToSignCount(setCount);
  }, [pathname]);

  const permissionsTransfers = permissions?.transfers;
  const permissionsProducts = permissions?.products;
  const permissionsPayments = permissions?.payments;

  const permissionsList = (item, permissions) => (
    <Menu.Item key={item?.key}>
      <NavLink to={permissions && permissions[item?.permissions] ? item?.to : '#'}>
        <StyledItem
          className={permissions && permissions[item?.permissions] ? 'active' : ''}
          changes={item?.to === location.pathname}
          collapsed={collapsed}
          toggle={toggle}
          onClick={() => setToggle(!toggle)}
        >
          <Tooltip
            text={
              permissions && permissions[item?.permissions]
                ? null
                : t('permissionsText')
            }
          >
            <span
              style={
                permissions && permissions[item?.permissions]
                  ? null
                  : { opacity: 0.4 }
              }
            >
              {t(item?.name)}
            </span>
          </Tooltip>
        </StyledItem>
      </NavLink>
    </Menu.Item>
  );

  const permissionsCard = (permissions, name, path, img, count) => (
    <NavLink to={permissions ? path : '#'}>
      <StyledItem
        className={permissions ? 'active' : ''}
        collapsed={collapsed}
        changes={path === location.pathname}
        toggle={toggle}
        onClick={() => setToggle(!toggle)}
      >
        <Icon component={() => <img src={img} alt={name} />} />
        {!collapsed ? (
          <Tooltip text={permissions ? null : t('permissionsText')}>
            <span style={{ opacity: permissions ? 1 : 0.4, textWrap: 'wrap' }}>
              {t(name)}{' '}
              {count ? (
                <span style={{ color: '#ffb500' }}>{`(${count})`}</span>
              ) : null}{' '}
            </span>
          </Tooltip>
        ) : null}
      </StyledItem>
    </NavLink>
  );

  return (
    <Menu defaultSelectedKeys={['1']} mode="inline">
      <Menu.Item key="1" title=" ">
        <NavLink to={'/'}>
          <StyledItem
            className="active"
            collapsed={collapsed}
            changes={('/' || '') === location.pathname}
            toggle={toggle}
            onClick={() => setToggle(!toggle)}
          >
            <Icon component={() => <img src={Dashboard} alt="Dashboard" />} />
            <span>{t('Main page')}</span>
          </StyledItem>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="12" title=" ">
        {permissionsCard(
          permissions?.anipay,
          'anipaycabinet',
          '/anipay-cabinet',
          AniPayCabinet
        )}
      </Menu.Item>

      <Menu.Item key="2">
        {permissionsCard(permissions?.users, 'Users', '/users', User2)}
      </Menu.Item>

      <SubMenu
        key="sub1"
        title={
          <StyledItem className="active" collapsed={collapsed}>
            <Icon component={() => <img src={Transfer} alt="Transfer" />} />
            <span>{t('Transfers')}</span>
          </StyledItem>
        }
      >
        {transfer.map((payment) => permissionsList(payment, permissionsTransfers))}
      </SubMenu>

      <SubMenu
        key="sub2"
        title={
          <StyledItem className="active" collapsed={collapsed}>
            <Icon component={() => <img src={Payments} alt="Payments" />} />
            <span>{t('Payments')}</span>
          </StyledItem>
        }
      >
        {payments.map((payment) => permissionsList(payment, permissionsPayments))}
      </SubMenu>

      <SubMenu
        key="sub3"
        title={
          <StyledItem className="active" collapsed={collapsed}>
            <Icon component={() => <img src={Products} alt="Products" />} />
            <span>{t('Products')}</span>
          </StyledItem>
        }
      >
        {products.map((product) => permissionsList(product, permissionsProducts))}
      </SubMenu>

      <Menu.Item key="201">
        {permissionsCard(
          permissions?.templates,
          'Templates',
          '/templates',
          templates
        )}
      </Menu.Item>

      <Menu.Item key="203">
        {permissionsCard(
          permissions?.conversion,
          'Conversion',
          '/conversion',
          Conversion
        )}
      </Menu.Item>

      <Menu.Item key="2055">
        {permissionsCard(
          permissions?.documentsToSign,
          'Documents to be sign',
          '/document-sign',
          editIcon,
          count
        )}
      </Menu.Item>

      <Menu.Item key="2057">
        {permissionsCard(
          permissions?.statements,
          'Statements',
          '/statements/id',
          stateIcon
        )}
      </Menu.Item>

      <Menu.Item key="204">
        {permissionsCard(permissions?.taxOrders, 'Orders', '/orders', Order)}
      </Menu.Item>

      <Menu.Item key="202">
        {permissionsCard(
          permissions?.operationHistory,
          'operationHistory',
          '/history',
          History
        )}
      </Menu.Item>

      <StyleLine collapsed={collapsed} />

      <Menu.Item key="333">
        {permissionsCard(permissions?.map, 'Map', '/maps', Map)}
      </Menu.Item>

      <Menu.Item key="31">
        {permissionsCard(permissions?.settings, 'Settings', '/settings', Settings)}
      </Menu.Item>
    </Menu>
  );
};

export default Menus;

const StyledItem = styled.div`
  height: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 500;
  position: relative;
  padding: 10px;
  &::after {
    content: '';
    position: absolute;
    background: ${({ changes }) => (changes ? '#393939' : 'transparent')} !important;
    width: 10px;
    height: 35px;
    right: 108%;
    border-radius: 0px 3px 3px 0px;
    display: ${(props) => (props.collapsed ? 'none' : 'block')};
  }
  a {
    border-radius: 8px;
    width: 100%;
    position: relative;
    font-weight: 500;
    &:hover {
      color: #393939;
    }
  }
  background: ${({ changes }) => (changes ? '#F2F2F2' : 'transparent')} !important;
  .ant-menu-item::after &:nth-child(1) {
    padding: 10px 15px;
  }
`;

const StyleLine = styled.div`
  border-bottom: 1px solid #e3e3e3;
  margin: 16px 32px;
`;
