import { Spin } from 'antd';
import { center } from 'components/StyleComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { responsive } from 'utils/responsiveUI';

export const SingleButton = ({
  text,
  onClick,
  isBorder,
  isGrey,
  isRed,
  icon,
  prevIcon,
  isCount,
  style,
  loading = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <StyledContent {...{ isBorder, isGrey, isRed, onClick, disabled, style }}>
      <Spin spinning={loading}>
        {icon && <img src={icon} alt="" />}
        {text ? t(text) : null}
        {prevIcon && <img src={prevIcon} alt="" />}
        {isCount && <span>{`(${isCount})`}</span>}
      </Spin>
    </StyledContent>
  );
};

const StyledContent = styled.button`
  padding: 13.5px 20px;
  background-color: ${(props) =>
    props.isBorder
      ? '#fff'
      : props.isGrey
      ? '#F0F1F3'
      : props.isRed
      ? '#FF857B'
      : '#383838'};
  color: ${(props) => (props.isBorder || props.isGrey ? '#383838' : '#fff')};
  border: 1px solid ${(props) => (props.isBorder ? '#383838' : 'transparent')};
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  ${center}
  min-width: 140px;
  .ant-spin-container {
    display: flex;
    gap: 10px;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media ${responsive.laptop} {
    padding: 10px 15px;
    font-size: 14px;
  }
`;
