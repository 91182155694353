export const changePaymentTypeIds = (data, key) => {
     
    if (data && data[key]) {
        const hasCommaInPaymentTypeIds = data[key].some(item => item.includes(','));

        if (hasCommaInPaymentTypeIds) {
        const modifiedPaymentTypeIds = [];
        data[key].forEach(item => {
            const values = item.split(', ').filter(Boolean);
            modifiedPaymentTypeIds.push(...values);
        });

        data = {
            ...data,
            [key]: modifiedPaymentTypeIds
        };
        }
    }

    return data;
}

export const changePaymentTypeIdsAndStatuses = (data, key1, key2) => {
    if (!data) return data;
    if (data[key1]) {
      const hasCommaInPaymentTypeIds = data[key1].some(item => item.includes(','));
      if (hasCommaInPaymentTypeIds) {
        const modifiedPaymentTypeIds = [];
        data[key1].forEach(item => {
          const values = item.split(', ').filter(Boolean);
          modifiedPaymentTypeIds.push(...values);
        });
        data[key1] = modifiedPaymentTypeIds;
      }
    }
    if (data[key2]) {
      const hasCommaInStatuses = data[key2].some(item => item.includes(','));
      if (hasCommaInStatuses) {
        const modifiedStatuses = [];
        data[key2].forEach(item => {
          const values = item.split(', ').filter(Boolean);
          modifiedStatuses.push(...values);
        });
        data[key2] = modifiedStatuses;
      }
    }
    return data;
  }