import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

export const BetweenMyAccountsValidate = createAsyncThunk(
  "BetweenMyAccountsValidate",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: "/between-payment-ms/payment/validate",
        data: JSON.stringify(body),
        showErrorToast: true,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const BetweenMyAccountsProcess = createAsyncThunk(
  "BetweenMyAccountsProcess",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: "/between-payment-ms/payment/process",
        data: JSON.stringify(body),
        method: "POST",
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "paymentSuccess",
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
