import React from "react";
import { Layout } from "antd";
import { notiyIcon, menu as hamburgerMenu, callHeader } from "static/svg";
import { responsive } from "utils/responsiveUI";
import styled from "styled-components";
import { DropdownMenu } from "components/DropdownMenu";

import { LanguageSelect } from "components/LanguageSelect";
const { Header } = Layout;


export const HeaderIB = ({ collapsed, toggle, setToggle, setCollapsed }) => {


  return (
    <>
      <StyledHeader collapsed={collapsed}>
        <Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
          }}
        >
          <StyledHeaderContent>
            <StylesHeaderLeft>
              <span
                className="hamburgerMenu"
                onClick={() => setToggle(!toggle)}
              >
                <img src={hamburgerMenu} alt="hamburgerMenu" />
              </span>
              <p>
                <img src={callHeader} alt="express_logo" /> <span>132</span>
              </p>
              <LanguageSelect/>
              
            </StylesHeaderLeft>

            <StylesHeaderRight>
              {/* <ThreeDotsPopup
                list={threeDotsPopupList}
                component={
                  <StyledNotify style={{ cursor: "pointer" }}>
                    <img src={notiyIcon} alt="notification" />
                    <sub>32</sub>
                  </StyledNotify>
                }
              /> */}

              {/*DropdownMenu component */}
              <DropdownMenu />
            </StylesHeaderRight>
          </StyledHeaderContent>
        </Header>
      </StyledHeader>
    </>
  );
};

const StyledHeader = styled.div`
  transition: 0.2s ease;
  @media ${responsive.laptop} {
    z-index: 11;
    position: relative;
    transition: all 1s ease-in-out;
  }

  .ant-layout-header {
    background-color: #fff;
    height: 97px;
    padding: 16px 24px;
    gap: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
`;
const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px 0 0;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-dropdown-trigger.ant-dropdown-link.ant-dropdown-open .down {
    transform: rotate(180deg);
  }
  .hamburgerMenu {
    display: none;
  }
  @media ${responsive.laptop} {
    padding: 0 20px;
    .hamburgerMenu {
      display: block;
      cursor: pointer;
    }
  }
`;
const StylesHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 22px;
      color: #323232;
      margin-left: 5px;
      letter-spacing:-2px;
    font-family: 'Proxima Nova';
    }
   
  }
  @media ${responsive.laptop}{
    p{
      display:none;
    }
    .simpleSelect{
      display:none;
    }
  }
`;
const StylesHeaderRight = styled.div`
  display: flex;
  align-items: center;
  @media ${responsive.laptop}{
    /* width:100%; */
    justify-content:space-between;
  }
`;
// Notification
const StyledNotify = styled.div`
padding:17px;
  sub {
    bottom: 9px;
    background: #f45552;
    padding: 4px;
    right: 20px;
    border-radius: 8px;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    font-family: 'Proxima Nova';
  }
`;