import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'client';
import axios from 'axios';

export const InternationalValidate = createAsyncThunk(
  'InternationalValidate',
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: '/swift-transfers/v1/transfer/validate',
        data: JSON.stringify(body),
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "paymentSuccess",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const InternationalProcess = createAsyncThunk(
  'InternationalProcess',
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url: '/swift-transfers/v1/transfer/process',
        data: JSON.stringify(body),
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: 'paymentSuccess',
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const InternaionalFileUpload = createAsyncThunk(
  'InternaionalFileUpload',
  async (body, thunkAPI) => {
    const { fileState, process } = body;
    try {
      const data = await client({
        url: '/swift-transfers/v1/transfer/files',
        data: fileState,
        method: 'POST',
        showErrorToast: true,
        errorMessage: 'ERROR',
        process: process,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getInternaionalFileUpload = createAsyncThunk(
  'getInternaionalFileUpload',
  async ({ paymentId }, thunkAPI) => {
    try {
      const response = await client({
        url: `/swift-transfers/v1/transfer/files/${paymentId}`,
        responseType: 'blob', // Ensure the response is treated as a blob
        showErrorToast: true,
        errorMessage: 'ERROR',
        token: false,
      });

      // Assuming the response contains the file data as a blob
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'international-file.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return response.data; // You can return whatever you want from the API response
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
export const getSwiftList = createAsyncThunk(
  'getSwiftList',
  async ({ bic }, thunkAPI) => {
    try {
      const data = await client({
        url: `/swift-transfers/v1/swift/bic?bic=${bic}`,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "paymentSuccess",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
