import { createSlice } from "@reduxjs/toolkit";
import { ConversionValidate, ConversionProsess } from "./api_action";

export { ConversionValidate , ConversionProsess};

//conversion
const initialState = {
  validate: null,
  process:null,
  error: null,
}
export const ConversionSlice = createSlice({
  name: "conversion",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [ConversionValidate.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.validate = data
    },
    [ConversionProsess.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.process = data
    },
    [ConversionValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [ConversionProsess.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const conversionReducer = ConversionSlice.reducer;
