import React from 'react';
import { useTranslation } from 'react-i18next';
import { errorField } from 'static/svg';
import styled from 'styled-components';

export const ErrorMessage = ({ text }) => {
  const { t } = useTranslation();

  return (
    <StyledField>
      <img src={errorField} alt="error" />
      {t(text)}
    </StyledField>
  );
};

const StyledField = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ff5252;
  margin-top: 9px;
  display: inline-flex;
  align-items: center;
`;
