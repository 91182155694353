import { createAsyncThunk } from "@reduxjs/toolkit";
import { client} from 'client'

export const ConversionProsess = createAsyncThunk(
  "ConversionProsess", 
  async (body, thunkAPI) =>  {
    try {
      const data = await client({
        url :'/sell-buy-ms/sell-buy/process',
        data:body,
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "paymentSuccess",
        token: false   
      })
      return data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

export const ConversionValidate = createAsyncThunk(
  "ConversionValidate",
  async (body, thunkAPI) => {
    try {
      const data = await client({
        url :'/sell-buy-ms/sell-buy/validate',
        data:body,
        // showSuccessToast: true,
        showErrorToast: true,
        // succesMessage: "paymentSuccess",
        token: false  
      })
      
      return data
    }catch(err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)