import React, { useState } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import { ScrollStyle } from "components/ScrollStyle";
import { HeaderIB, SidebarIB } from "components";
import { responsive } from "utils/responsiveUI";
import RefreshModal from "components/RefreshModal";

const { Content } = Layout;

export const LayoutIB = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggle, setToggle] = useState(false);

  return (
    <StyledLayout>
      <Layout style={{ height: "100vh", overflow: "hidden" }}>
        <RefreshModal />
        <SidebarIB
          collapsed={collapsed}
          toggle={toggle}
          setToggle={setToggle}
          setCollapsed={setCollapsed}
        />
        <Layout className="site-layout">
          <HeaderIB
            collapsed={collapsed}
            toggle={toggle}
            setToggle={setToggle}
          />
          <Content>
            <FixOverflow id="layoutFixOverflow">
              <ScrolledArea>{children}</ScrolledArea>
            </FixOverflow>
          </Content>
        </Layout>
      </Layout>
    </StyledLayout>
  );
};

const FixOverflow = styled.div`
  position: relative;
  height: 100%;
`;
const StyledLayout = styled.div`
  .ant-layout-content {
  }
`;


const ScrolledArea = styled.div`
  height: 100%;
  overflow-y: scroll;
  
  ${ScrollStyle}
`;


