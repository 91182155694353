import React from 'react';
import styled from 'styled-components';
import { SingleButton } from '../SingleButton';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useCanceled } from 'hooks';
import { responsive } from 'utils/responsiveUI';

export const GroupButtons = ({ firstProps, secondProps, thirdProps, onClick, mobilColumnBtn=false }) => {

  const history= useHistory()

  const { handleCancel } = useCanceled()

  const {templateId} = useParams()

  const templateHandle = ()=>{
     history.push('/templates')
  }

  const combinedOnClick = templateId ? templateHandle : (firstProps.onClick || handleCancel);


  const updatedFirstProps = { ...firstProps, onClick: combinedOnClick };


  return (
    <StyledBtnsWrapper>
      <StyledContainer {...{mobilColumnBtn}}>
        {firstProps && <SingleButton {...updatedFirstProps} />}
        {secondProps && <SingleButton {...secondProps}  />}
        {thirdProps && <SingleButton {...thirdProps} />}
      </StyledContainer>
    </StyledBtnsWrapper>
  );
};

const StyledBtnsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const StyledContainer = styled.div`
  display: flex;
  max-width: 100%;
  gap: 10px;  
  @media ${responsive.laptop} {
    max-width: 100%;
    flex: 1;
    flex-direction: ${(props) => (props.mobilColumnBtn ? "column-reverse" : "row")};
  }
  
`;
