import { useState } from 'react';
import {
  amountToFixedVal,
  onlyEnglishAndRussianSymbolOnlyDot,
  onlyEnglishSymbol,
  onlyEnglishNotSymbol,
  onlyEnglishSymbolOnlyDot,
} from 'utils';
import {
  checkStringWithEmpty,
  checkValueIsOnlyAzSymbolAndOtherSymbols,
  isValidNumber,
  onlySymbolForPin,
  passwordOnlyNumber,
  regexEmailCharacter,
  regexMax10,
  regexMax6,
} from 'utils/inputValidations';

export function useValidate(form) {
  const [amount, setAmount] = useState('');
  const [amountConverter, setAmountConverter] = useState('');
  const [receiverAccount, setReceiverAccount] = useState('');
  const [receiverCountry, setReceiverCountry] = useState('');
  const [details, setDetails] = useState('');
  const [valueField, setValueField] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [receiverTAXID, setReceiverTaxId] = useState('');
  const [receiverBankCode, setReceiverBankCode] = useState('');
  const [anyPayTypeValue, setAnyPayTypeValue] = useState('');
  const [paymentPurpose, setPaymentPurposeValidate] = useState('');

  const [intermediaryBank, setIntermediaryBank] = useState('');
  const [intermediaryBankSwiftCode, setIntermediaryBankSwiftCode] = useState('');
  const [receiverSwiftCode, setReceiverSwiftCodeValidate] = useState('');
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [userPatronymic, setUserPatronymic] = useState('');
  const [userPin, setUserPin] = useState('');

  const resetStatesByCurrency = () => {
    setReceiverName('');
    setReceiverAddress('');
    setReceiverSwiftCodeValidate('');
    setDetails('');
    setPaymentPurposeValidate('');
  };

  const handleBeginningEmptyInput = (e, fieldName) => {
    // a value that does not start with a blank
    let temproryValue = '';
    const value = e.target.value;
    if (checkStringWithEmpty(value)) {
      temproryValue = value;
    } else {
      form.setFieldsValue({
        [fieldName]: temproryValue,
      });
    }
  };

  const handleBlockSpacePattern = (e, fieldName) => {
    const value = e.target.value.replace(/\s/g, '');
    if (value) {
      form.setFieldsValue({
        [fieldName]: value,
      });
    }
  };
  

  const currencySwiftValidation = (value, accountCurrency, swift) => {
    if (swift) {
      return (
        (accountCurrency === 'RUB'
          ? onlyEnglishAndRussianSymbolOnlyDot(value)
          : onlyEnglishNotSymbol(value)) || value === ''
      );
    } else {
      return onlyEnglishSymbol(value) || value === '';
    }
  };

  const receiverNameValidation = (value, paymentType, accountCurrency) => {
    // Swift
    if (paymentType === 'International') {
      return (
        (accountCurrency === 'RUB'
          ? onlyEnglishAndRussianSymbolOnlyDot(value)
          : onlyEnglishSymbolOnlyDot(value)) || value === ''
      );
    } else if (paymentType === 'State treasury') {
      // State treasury
      return onlyEnglishSymbol(value) || value === '';
    } else {
      // Other Payment
      return onlyEnglishNotSymbol(value) || value === '';
    }
  };

  const swiftValidation = (value, swift) => {
    const isEmpty = value === '';
    const isValid = swift
      ? onlyEnglishNotSymbol(value) || isEmpty
      : onlyEnglishSymbol(value) || isEmpty;
  
    return isValid && !value.includes(' ');
  };
  
  const handleChange = (e) => {
    const value = e.target.value;

    if (amountToFixedVal(value) || value === '') {
      setAmount(value);
    } else {
      form.setFieldsValue({
        amount: amount,
      });
    }
  };

  const handleChangeAmountConverter = (e) => {
    const value = e.target.value;

    if (amountToFixedVal(value) || value === '') {
      setAmountConverter(value);
    } else {
      form.setFieldsValue({
        amountConverter: amountConverter,
      });
    }
  };

  const receiverTaxIDValidate = (e) => {
    const value = e.target.value;

    if (regexMax10(value)) {
      setReceiverTaxId(value);
    } else {
      form.setFieldsValue({
        receiverVoen: receiverTAXID,
      });
    }
  };
  const receiverBankCodeValidate = (e) => {
    const value = e.target.value;

    if (regexMax6(value)) {
      setReceiverBankCode(value);
    } else {
      form.setFieldsValue({
        receiverBankCode: receiverBankCode,
      });
    }
  };

  const anyPayTypeValueValidate = (e, typeValue) => {
    const value = e.target.value;

    if (typeValue === 2) {
      if (regexEmailCharacter(value)) {
        setAnyPayTypeValue(value);
      } else {
        form.setFieldsValue({
          alias: anyPayTypeValue,
        });
      }
    } else if (typeValue === 4) {
      if (isValidNumber(value)) {
        setAnyPayTypeValue(value);
      } else {
        form.setFieldsValue({
          alias: anyPayTypeValue,
        });
      }
    } else {
      if (onlyEnglishNotSymbol(value)) {
        setAnyPayTypeValue(value);
      } else {
        form.setFieldsValue({
          alias: anyPayTypeValue,
        });
      }
    }
  };

  const receiverAccountValidate = (e, swift) => {
    const value = e.target.value
    if (swiftValidation(value, swift)) {
      setReceiverAccount(value);
    } else {
      form.setFieldsValue({
        receiverIban: receiverAccount,
      });
    }
  };

  const additionDetailsValidate = (e, accountCurrency, swift) => {
    const value = e.target.value;

    if (currencySwiftValidation(value, accountCurrency, swift)) {
      setDetails(value);
    } else {
      form.setFieldsValue({
        additionalDetails: details,
      });
    }
  };

  const valueFieldValidate = (e, placeholder) => {
    const value = e.target.value;

    if (onlyEnglishSymbol(value) || value === '') {
      setValueField(value);
    } else {
      form.setFieldsValue({
        [placeholder]: valueField,
      });
    }
  };

  const recieverNameValidate = (e, swift, accountCurrency) => {
    const value = e.target.value;
    if (receiverNameValidation(value, swift, accountCurrency)) {
      setReceiverName(value);
    } else {
      form.setFieldsValue({
        receiverName: receiverName,
      });
    }
  };

  const receiverCountryValidate = (e, swift) => {
    const value = e.target.value;
    if (
      swift
        ? onlyEnglishSymbolOnlyDot(value) && value?.length <= 35
        : (onlyEnglishSymbol(value) || value === '') && value?.length <= 35
    ) {
      setReceiverCountry(value);
    } else {
      form.setFieldsValue({
        receiverCountry: receiverCountry,
      });
    }
  };

  const receiverAddressValidate = (e, accountCurrency, swift) => {
    const value = e.target.value;

    if (currencySwiftValidation(value, accountCurrency, swift)) {
      setReceiverAddress(value);
    } else {
      form.setFieldsValue({
        receiverAddress: receiverAddress,
      });
    }
  };

  const paymentPurposeValidate = (e, accountCurrency, swift) => {
    const value = e.target.value;

    if (currencySwiftValidation(value, accountCurrency, swift)) {
      setPaymentPurposeValidate(value);
      form.setFieldsValue({
        paymentPurpose: value,
      });
    } else {
      form.setFieldsValue({
        paymentPurpose: paymentPurpose,
      });
    }
  };

  const intermediaryBankValidate = (e, fieldName) => {
    const value = e.target.value;

    if (onlyEnglishSymbolOnlyDot(value)) {
      setIntermediaryBank(value);
    } else {
      form.setFieldsValue({
        [fieldName]: intermediaryBank,
      });
    }
  };

  const intermediaryBankSwiftCodeValidate = (
    e,
    fieldName,
  ) => {
    const value = e.target.value;

    if (onlyEnglishNotSymbol(value)) {
      setIntermediaryBankSwiftCode(value);
    } else {
      form.setFieldsValue({
        [fieldName]: intermediaryBankSwiftCode,
      });
    }
  };

  const receiverSwiftCodeValidate = (e, accountCurrency, swift) => {
    let value = e.target.value;
    const label = accountCurrency === 'RUB' ? 'receiverBank' : 'receiverSwiftCode';
    if (accountCurrency !== 'RUB') value = value?.toUpperCase();
    if (currencySwiftValidation(value, accountCurrency, swift)) {
      setReceiverSwiftCodeValidate(value);
      form.setFieldsValue({
        [label]: value,
      });
    } else {
      form.setFieldsValue({
        [label]: receiverSwiftCode,
      });
    }
  };

  const userNameValidation = (e) => {
    const value = e.target.value;

    if (checkValueIsOnlyAzSymbolAndOtherSymbols(value) || value === '') {
      setUserName(value);
    } else {
      form.setFieldsValue({
        name: userName,
      });
    }
  };

  const userSurnameValidation = (e) => {
    const value = e.target.value;

    if (checkValueIsOnlyAzSymbolAndOtherSymbols(value) || value === '') {
      setUserSurname(value);
    } else {
      form.setFieldsValue({
        surname: userSurname,
      });
    }
  };

  const userPinValidate = (e, fieldName) => {
    const value = e.target.value;

    if (onlySymbolForPin(value) || value === '') {
      setUserPin(value?.toUpperCase());
      let object = { pin: value?.toUpperCase() };
      if (fieldName) object = { [fieldName]: value?.toUpperCase() };
      form.setFieldsValue({
        ...object,
      });
    } else {
      let object = { pin: userPin?.toUpperCase() };
      if (fieldName) object = { [fieldName]: userPin?.toUpperCase() };
      form.setFieldsValue({
        ...object,
      });
    }
  };

  const userPatronymicValidation = (e) => {
    const value = e.target.value;

    if (checkValueIsOnlyAzSymbolAndOtherSymbols(value) || value === '') {
      setUserPatronymic(value);
    } else {
      form.setFieldsValue({
        patronymic: userPatronymic,
      });
    }
  };

  const [userId, setUserId] = useState('');
  const handlePasswordOnlyNumber = (e) => {
    const value = e.target.value;

    if (passwordOnlyNumber(value) || value === '') {
      setUserId(value);
    } else {
      form.setFieldsValue({
        userId,
      });
    }
  };

  const clearSlashN = (e, fieldName) => {
    const value = e.target.value;

    const cleanedText = value.replace(/\r?\n|\r/g, ' ');
    form.setFieldsValue({
      [fieldName]: cleanedText,
    });
  };

  return {
    handleChange,
    additionDetailsValidate,
    valueFieldValidate,
    recieverNameValidate,
    receiverAddressValidate,
    paymentPurposeValidate,
    receiverAccountValidate,
    receiverCountryValidate,
    intermediaryBankValidate,
    intermediaryBankSwiftCodeValidate,
    receiverSwiftCodeValidate,
    anyPayTypeValueValidate,
    receiverTaxIDValidate,
    receiverBankCodeValidate,
    handleChangeAmountConverter,
    form,
    resetStatesByCurrency,
    handleBeginningEmptyInput,
    userNameValidation,
    userSurnameValidation,
    userPatronymicValidation,
    userPinValidate,
    handlePasswordOnlyNumber,
    clearSlashN,
    handleBlockSpacePattern
  };
}
