import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import {responsive} from "utils/responsiveUI";
import LoginContainer from "./LoginContainer";
import AsanSignature from "./LoginContainer/AsanSignature";
import OtpMessage from "./LoginContainer/OtpMessage";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearAsanData, clearOtpData } from 'store/auth';

const Login = () => {
    const [showOtpMessage, setShowOtpMessage] = useState(false);
    const [checkSms, setCheckSms] = useState(false);
    const userInfo = useSelector((state) => state.otpLoginSMS.userInfo);
    const asanInfo = useSelector((state) => state.asanAuthenticate.asanInfo);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        userInfo ? setShowOtpMessage(true) : setShowOtpMessage(false);
    }, [userInfo]);

    // checkStatusSms
    useEffect(() => {
        asanInfo ? setCheckSms(true) : setCheckSms(false);
    }, [asanInfo]);

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                dispatch(clearAsanData())
                dispatch(clearOtpData())
            }
        });

        return () => {
            unlisten();
        };
    }, [history]);


    return (
        <LoginStyled>
            <Header isMobileLogin/>
            {showOtpMessage ? (
                <OtpMessage/>
            ) : !checkSms ? (
                <LoginContainer/>
            ) : (
                <AsanSignature setCheckSms={setCheckSms}/>
            )}
            <Footer/>
        </LoginStyled>
    );
};

export default Login;

const LoginStyled = styled.div`
  position: relative;
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 100vh;
  @media ${responsive.laptop} {
    padding: 33px 18px;
  }
`;
