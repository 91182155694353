import merge from 'lodash.merge';
import { ErrorMessage } from '../utils/errorMessage';
import { openNotificationWithIcon } from './index';

function request(customConfig, _axiosInstance) {
  const token = localStorage.getItem('tokenInfo');
  const refreshToken = localStorage.getItem('refreshToken');

  const {
    showSuccessToast,
    showErrorToast = true,
    hasErrorsHiddenToast = false,
    succesMessage = '',
    method = null,
    token: configToken = true,
    paymentId = '',
    stateInToken = '',
    batchId = '',
    setTokenFromStorage = true,
    process = null,
    successCallback = () => {},
    errorCallback = () => {},
    responseType = '',
    contentType,
    hasChannel,
    hasRefreshToken,
    ...restConfig
  } = customConfig;

  const isRefreshTokenRequest = restConfig.url?.endsWith('refresh-token');

  // merge recursively defaults with customConfig, then call api
  const config = merge(_axiosInstance.defaults, {
    method: method ?? (customConfig.data ? 'post' : 'get'),
    ...restConfig,
  });

  if (token && setTokenFromStorage) {
    if (!isRefreshTokenRequest) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }

  if(hasRefreshToken) {
    if (!isRefreshTokenRequest) {
      config.headers.common['X-Session-Id'] = refreshToken;
    }
  } else {
    delete config.headers.common['X-Session-Id'];
  }

  if (stateInToken) {
    if (!isRefreshTokenRequest) {
      config.headers.common['Authorization'] = `Bearer ${stateInToken}`;
    }
  }

  if (!setTokenFromStorage) {
    delete config.headers.common['Authorization'];
  }

  if (batchId) {
    config.headers.common['batch-id'] = `${batchId}`;
  }

  // This code for swift upload file
  if (process) {
    config.headers.common['payment-type-id'] = `${process?.paymentTypeId}`;
    config.headers.common['customer-id'] = `${process?.senderCustomerId}`;
    config.headers.common['branch-code'] = `${process?.senderBankCode}`;
    config.headers.common['voen'] = `${process?.senderVoen}`;
  } else {
    delete config.headers.common['payment-type-id'];
    delete config.headers.common['payment-id'];
  }

  // Operation History for Details
  if (paymentId || process?.paymentId) {
    const newpPaymentId = paymentId || process?.paymentId;
    config.headers.common['payment-id'] = `${newpPaymentId}`;
  } else {
    delete config.headers.common['payment-id'];
  }

  if (hasChannel) {
    config.headers.common.channel = 'WEB ';
  }

  // for file download, default empty string, "blob" otherwise
  config['responseType'] = `${responseType}`;

  if (contentType) {
    config.headers = {
      ...config.headers,
      ['Content-Type']: contentType,
    };
  } else {
    config.headers = {
      ...config.headers,
      ['Content-Type']: 'application/json',
    };
  }

  function resetDefaults() {
    _axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    config.data = null;
  }

  function successHandler(response) {
    successCallback();
    resetDefaults()
    if (showSuccessToast) {
      openNotificationWithIcon('success', succesMessage);
    }
    return response;
  }

  function errorHandler(error) {
    if (error.response?.data?.status !== 401) {
      errorCallback();

      if (showErrorToast) {
        let hiddenToast = false;
        if (hasErrorsHiddenToast && error?.response?.data?.errors?.length)
          hiddenToast = true;
        if (!hiddenToast) {
          if (error.response) {
            openNotificationWithIcon(
              'error',
              !error.response.data?.errorCode && !error.response.data?.message
                ? 'Sistemdə xəta baş vermişdir. Banka müraciət edin.'
                : ErrorMessage(error.response.data)
            );
          } else {
            openNotificationWithIcon(
              'error',
              error.message ||
                (error.response.data
                  ? ErrorMessage(error.response.data)
                  : 'Sistemdə xəta baş vermişdir. Banka müraciət edin.')
            );
          }
        }
      }
    }

    return Promise.reject(error);
  }

  return _axiosInstance.request(config).then(successHandler).catch(errorHandler);
}

export default request;
