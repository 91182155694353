import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";


const voen = localStorage.getItem("voen")

export const UploadFileSalary = createAsyncThunk(
  "UploadFileSalary",
  async (files, thunkAPI) => {

    try {
      const data = await client({
        url: `/salary-ms/salary/${voen}/payroll`,
        data: files,
        showErrorToast: true,
        errorMessage: "ERROR",
        token: false,

      })
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)


export const ValidationSalary = createAsyncThunk(
  "ValidationSalary",
  async (processInfo, thunkAPI) => {
    const { body, batchId } = processInfo;

    try {

      const data = await client({
        url: '/salary-ms/payment/validate',
        data: JSON.stringify(body),
        showErrorToast: true,
        errorMessage: "ERROR",
        token: false,
        batchId: batchId
      })
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)


export const SalaryProcess = createAsyncThunk(
  "SalaryProcess",
  async (processInfo, thunkAPI) => {
    const { body, batchId } = processInfo;
    try {
      const data = await client({
        url: '/salary-ms/payment/process',
        data: JSON.stringify(body),
        showSuccessToast: true,
        showErrorToast: true,
        errorMessage: "ERROR",
        succesMessage: 'paymentSuccess',
        token: false,
        batchId: batchId

      })
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
)
