import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'client';

export const GetAllBalance = createAsyncThunk(
  'GetAllBalance',

  async (config, thunkAPI) => {
    try {
      const data = await client({
        url: `/balance/api/v2/all-balance`,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const GetTotalBalance = createAsyncThunk(
  'GetTotalBalance',

  async (config, thunkAPI) => {
    try {
      const data = await client({
        url: `/balance/api/v2/total-balance`,
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
