import styled from 'styled-components';
import { responsive } from 'utils/responsiveUI';

export const center = `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const left = `
  display: flex;
  justify-content: left;
  align-items: center;
`;
export const flexBtw = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledJustifyLeft = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const StyledFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
// DASHBOARD / Templates card - Start
export const StyledCards = styled.div`
  width: auto;
  background-color: #fff;
  border-radius: 12px;
  display: block;
  cursor: pointer;
  padding: 20px 24px;
  min-height: 177px;
  &:hover {
    box-shadow: 0px 8px 10px rgba(108, 107, 81, 0.16);
  }
`;
export const StyledCardsSecond = styled(StyledCards)`
  border: 1px solid #c6c6c6;
  &:hover {
    box-shadow: none;
  }
`;
export const StyledIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledText = styled.div`
  color: #393939;
  font-weight: 600;
  font-size: 17px;
  padding-top: 16px;
  color: #393939;
  word-wrap: break-word;
`;
export const StyledTextSub = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #8e8e8e;
  word-wrap: break-word;
`;
// DASHBOARD / Templates card - end

// TRANSFER/ Details - START
export const StyledContainer = styled.div`
  background-color: #fff;
  padding: 30px 60px;

  @media ${responsive.laptop} {
    padding: 30px 20px;
    .ant-row {
      flex-direction: ${(props) => (props.responsive ? 'column' : 'row')};
    }
  }
`;

export const StyledCard = styled.div`
  ${center}
  span {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #393939;
    line-height: 15px;
  }
  button {
    border: 1.5px solid #393939;
    border-radius: 8px;
    &:hover {
      border: 1.5px solid #393939;
      border-radius: 8px;
    }
    :nth-child(1) {
      margin-right: 20px;
      @media ${responsive.mobileL} {
        width: 100%;
        margin-right: 15px;
      }
    }
    :nth-child(2) {
      // margin-right: 20px;
    }
  }
  @media ${responsive.mobileL} {
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin-bottom: 15px;
  }
  @media ${responsive.laptop} {
    display: inline-flex;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${(props) => (props.isPadding ? '0 0 29px 0' : 'none')};
  p {
    margin-bottom: 0;
    ${center};
    font-weight: 700;
    font-size: 18px;
    color: #393939;
    span {
      font-weight: 500;
      font-size: 19px;
      line-height: 28px;
      color: #8e8e8e;
    }
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #1c1c1c;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  img {
    margin-right: 20px;
  }

  @media ${responsive.tablet} {
    display: flex;
    width: 100%;
  }
  @media (max-width: 390px) {
    display: block;
    width: 100%;
  }
`;

export const StyledLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
`;
// TRANSFER/ Details - END

// TABLE and Colum style - START
export const TableContainer = styled.div`
  .ant-table-cell {
    // padding-left: 30px !important;
    // padding:18px 32px !important;
    padding-left: 15px !important;
    @media ${responsive.mobileL} {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
  .withoutPadding {
    .ant-table-cell {
      padding-left: 0px !important;
    }
    @media ${responsive.tablet} {
      .ant-table-cell {
        padding: 10px 3vw !important;
      }
    }
  }
`;

export const StyledImg = styled.div`
  margin-right: 10px;
`;
export const StyledTextMain = styled.div`
  p,
  h4 {
    margin-bottom: 3px;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    text-transform: uppercase;
    color: #9e9e9e;
  }
  h4 {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: #393939;
  }
`;

export const StyledTextColumn = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #393939;
  margin: 0;
  word-break: break-all;
  p {
    margin-bottom: 0;
  }
  h6 {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 0;
  }
  span {
    font-style: italic;
    color: #8e8e8e;
  }
  @media ${responsive.laptop} {
    p {
      font-size: 12px;
    }
    h6 {
      font-size: 14px;
    }
  }
`;
// TABLE and Colum style - END

export const StyledItallic = styled.span`
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #63666a;
`;

// TABLE EMPTY SECTION
export const StyledCardsContainerEmpty = styled.div`
  background-color: #fff;
  height: 396px;
  border-radius: 16px;
  padding: 20px;
  width: 100%;
`;

export const StyledOrderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px dashed #e3e3e3;
  border-radius: 16px;
`;

export const StyledContent = styled.div`
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #8e8e8e;
    margin-bottom: 25px;
  }
`;

// All Products Details Header name

export const HeadName = styled.div`
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 19px;
  display: flex;
  justify-content: space-between;
  /* text-transform: uppercase; */
  padding: 9px 0px 15.5px 0px;
  margin-bottom: 36px;
  color: #393939;
  border-bottom: 1px solid #e3e3e3;
  @media ${responsive.tablet} {
    flex-direction: column;
    gap: 10px;
  }
  & div:first-child {
    display: flex;
    /* gap: 25px; */
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
  button {
    flex: none !important;
  }
`;

export const PaymentName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #1c1c1c;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
`;

// products  mobile select
export const StyledResponsiveSelect = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;

  p {
    font-family: Halter;
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  h6 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
    color: #393939;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
`;

//login
export const LoginContainer = styled.div`
  width: 100%;
  background-color: #fcfcfc;
  position: relative;
`;
export const AuthLayout = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  .carousel_container {
    width: 65%;
    height: 100%;
    background-color: #f6f6fa;
  }

  @media ${responsive.laptop} {
    display: grid;
    width: 100%;
    .carousel_container {
      /* width: 100vw;
      height: 160%;
      position: relative;
      overflow: hidden; */
      .ant-carousel {
        display: none;
      }
    }
  }
`;

export const StyledSorteDate = styled.div`
  color: #555;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin-bottom: 14px;
`;

export const StyleOverdraf = styled.div`
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: 500;
  color: #383838;
`;

export const StyleOverdrafModal = styled.div`
  font-size: 16px;
  p {
    font-weight: 400;
    margin-bottom: 0;
    color: #63666a;
  }
  span {
    font-weight: 600;
    color: #383838;
  }
`;
