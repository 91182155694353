import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import './index.css';

export const ScrollToTop = ({ children }) => {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [pathname, setPathname] = useState(history.location.pathname)

  const ScrollToTop = () => {
    // eslint-disable-next-line no-unused-expressions
    scrollRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      ScrollToTop();
      setPathname(history.location.pathname)
    });
    return () => unlisten();
  }, [history]);

  return (
    <div ref={scrollRef} className={`${history.location.pathname !== "/" ? 'scrollContainer' : ''}`} style={{ overflow: "auto", height: '100%'  }}>
      {children}
    </div>
  );
};
