import { createSlice } from "@reduxjs/toolkit";
import { GetAllAtm, GetAllBranch, GetAllTerminal, getBranchById, getAtmById, getTerminalById } from "./api_action";
export { GetAllBranch, GetAllAtm, GetAllTerminal, getBranchById, getAtmById, getTerminalById };
export const MapSliceReducer = createSlice({
  name: "MapSliceReducer",
  initialState: {
    branch: null,
    terminal: null,
    atm: null,
    branchID:null,
    terminalId:null,
    atmId:null,
    error: null,
  },
  extraReducers: {
    [GetAllBranch.fulfilled]: (state, action) => {
      state.branch = action.payload.data;
    },
    [GetAllBranch.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [GetAllTerminal.fulfilled]: (state, action) => {
      state.terminal = action.payload.data;
    },
    [GetAllTerminal.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [GetAllAtm.fulfilled]: (state, action) => {
      state.atm = action.payload.data;
    },
    [GetAllAtm.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getBranchById.fulfilled]:(state, action)=>{
      state.branchID=action.payload.data;
    },
    [getBranchById.rejected]:(state, action)=>{
      state.error=action.payload.data;
    },
    [getTerminalById.fulfilled]:(state, action)=>{
      state.terminalId=action.payload.data;
    },
    [getTerminalById.rejected]:(state, action)=>{
      state.error = action.payload;
    },
    [getAtmById.fulfilled]:(state, action)=>{
      state.atmId=action.payload.data;
    },
    [getAtmById.rejected]:(state, action)=>{
      state.error = action.payload;

    }
  },
});

export const mapBranch = MapSliceReducer.reducer;
