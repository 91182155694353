import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from 'client';


export const GetAniPayTypeInfo = createAsyncThunk(
    "GetAniPayTypeInfo",

    async (allData, thunkAPI) => {
        const { type, alias } = allData
        try {
            const data = await client({
                url: `/payment/ips/alias-info?type=${type}&alias=${alias}`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "Get AniPay type info",
                token: false,
            })
            return data
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)