import {createGlobalStyle} from 'styled-components'

export const ScrollStyle = createGlobalStyle`
  ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 5px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #D7D8D6;
  }
`
