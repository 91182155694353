import {createAsyncThunk} from '@reduxjs/toolkit';
import {client} from 'client';

const voen =  localStorage.getItem("voen")

//all deposits
export const GetDeposits = createAsyncThunk(
    'GetDeposits',
    async (config, thunkAPI) => {
        try {
            const data = await client({
                url: `/deposit-ms/api/v1/deposit/list/${voen}`,
                showErrorToast: true,
                token: false
            });
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

//get deposit info
export const GetDepositInfo = createAsyncThunk(
    'GetDepositInfo',
    async (dealId, thunkAPI) => {
        try {
            const data = await client({
                url: `/deposit-ms/api/v1/deposit/detail/${dealId}`,
                showErrorToast: true,
                token: false
            });
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

//deposit payment schedule
export const PaymentScheduleDeposit = createAsyncThunk(
    'PaymentScheduleDeposit',
    async (contractNumber, thunkAPI) => {
        try {
            const data = await client({
                url: `/deposit-ms/api/v1/deposit/schedule/${contractNumber}`,
                // showSuccessToast: true,
                showErrorToast: true,
                // succesMessage: "Deposit payment schedule successfully loaded",
                token: false
            });
            return data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);
