import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'components';
import InfoCard from '../InfoCard';
import { useTranslation } from "react-i18next";


const BankingInfo = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Fragment>
      <FooterContainer>
      </FooterContainer>
      <Modal
        open={modalVisible}
        cancel={() => setModalVisible(false)}
        title={t('infoExpressBusiness')}
        secondTitle={null}
        headerColor='#fff'
        width='976px'
        scale='0.7, 0.7'
      >
        <InfoCard />
      </Modal>
    </Fragment>
  )
}

export default BankingInfo;

const FooterContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .info_btn {
    margin-bottom: 16px;
  }

  .to_site_btn {
    margin-bottom: 40px;
  }
`
