import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { AsanAuthenticate } from "store/auth";
import {
  FormItem,
  LoginButton,
  Input,
  Modal,
  Loading,
  AsanSignTimer,
  CustomInput, ErrorMessage,
} from "components";
import { mobileNumberParser } from "utils/shortFunctions";
import OtpCompanyList from "../CompanyList/OtpCompanyList";
import {
  whitespaceRule,
  mobilePrefixRule,
} from "utils/checkValidations";
import { useValidate } from "hooks";

const AsanForm = () => {
  const [form] = Form.useForm();
  const allLoading = useSelector((state) => state.loadings);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const asanInfo = useSelector((state) => state.asanAuthenticate.asanInfo);

  const { handlePasswordOnlyNumber, handleBeginningEmptyInput } = useValidate(form);

  // Modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAsanVisible, setModalAsanVisible] = useState(false);
  // Verification code
  const [verificationCode, setVerificationCode] = useState("");
  // Phone number
  const [phoneNumber, setMobileNumber] = useState("");
  // For phone prefix
  const [isEmptyString, setIsEmptyString] = useState(false);
  // For form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = (values) => {
    setIsSubmitting(true); 

    const data = {
      ...values,
      phoneNumber: mobileNumberParser(values?.phoneNumber),
    };
    dispatch(AsanAuthenticate(data)).finally(() => {
      setIsSubmitting(false);
    });

    localStorage.setItem("loginStatus", 'ASAN');
  };

  const onValuesChange = (changedValues, allValues) => {
    !allValues?.phoneNumber?.trim()?.startsWith("(__)")
      ? setIsEmptyString(true)
      : setIsEmptyString(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  // Popup props
  const props = {
    phoneNumber,
    asanInfo,
  };

  return (
    <Fragment>
      <Loading loading={allLoading?.isLoading} />

      <Form
        form={form}
        name="Auth_Asan"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        hideRequiredMark={true}
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <Col span={24} className="general">
          <FormItem
            autoComplete="off"
            name="phoneNumber"
            autoheight
            rules={[isEmptyString, {
              required: true,
              validator: (rule, value, callback) => {
                if (value) {
                  const prefix = value.split('(').join("").split(')').join("").split(" ")[1];

                  if (mobilePrefixRule(prefix) || prefix === "__") {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(<ErrorMessage text="mustBeCorrectMobilePrefix" />);

                  }
                }

                return Promise.reject(<ErrorMessage text="mandatoryField" />);
              },
            }]}
          >
            <CustomInput label={t("asanPhone")} autoComplete="off" inputmask />
          </FormItem>
        </Col>

        <Col span={24} className="general">
          <FormItem
            autoComplete="off"
            autoheight
            name="userId"
            rules={[whitespaceRule, {
              required: true,
              validator: (rule, value) => {
                if (value) {
                  const valueLength = value.split("");

                  if (valueLength.length !== 6) {
                    return Promise.reject(<ErrorMessage text="Asan İD must be 6 characters long" />);
                  }
                  return Promise.resolve();
                }

                return Promise.reject(<ErrorMessage text="mandatoryField" />);
              },
            }]}
          >
            <CustomInput label={t("asanId")} maxLength={6} autoComplete="new-password" onChange={
              (e) => {
                handlePasswordOnlyNumber(e);
                handleBeginningEmptyInput(e, "asanId");
              }} />
          </FormItem>
        </Col>

        <FormItem>
          <LoginButton title={t("login")} htmlType="submit" disabled={isSubmitting} />
        </FormItem>
      </Form>

      <Modal
        open={modalVisible}
        cancel={() => setModalVisible(false)}
        title="ASAN IMZA"
        secondTitle={<StyledModalSpan>{phoneNumber}</StyledModalSpan>}
        width="500px"
      >
        <OtpCompanyList {...props} />
      </Modal>

      <Modal
        open={modalAsanVisible}
        cancel={() => setModalAsanVisible(false)}
        title="ASAN IMZA"
        secondTitle={<StyledModalSpan>{phoneNumber}</StyledModalSpan>}
        width="500px"
      >
        <AsanSignTimer
          code={verificationCode}
          duration={180}
          // duration={5}
          // resendEvent={handleAsanLogin}
        />
      </Modal>
    </Fragment>
  );
};

export default AsanForm;

const StyledModalSpan = styled.span`
  font-size: 14px;
  color: #231f20;
  font-style: normal;
  font-weight: normal;
`;

const MaskInputStyle = styled(Input)`
  .ant-input-group-addon {
    background: transparent;
  }

  .ant-input {
    padding-bottom: 15px;
    background: transparent;
    border-left: 0;

    &:focus {
      border-color: #dcdcdc;
    }
  }

  .ant-input.ant-form-item-has-error {
    &:focus {
      border-color: #ff5252;
    }
  }
`;
