import { createAsyncThunk } from "@reduxjs/toolkit";
import {client} from "client";

export const GetOperatorsList = createAsyncThunk(
    "GetOperatorsList",
    async (body, thunkApi) => {
        const { page, size } = body;
        try {
        const data = await client({
            url: `/users/v1/operator?page=${page}&size=${size}`,
            showErrorToast: true,
            token: false,
        });
        return data;
        } catch (err) {
        return thunkApi.rejectWithValue(err.response);
        }
    }
);

export const GetAuthorityUsersList = createAsyncThunk(
    "GetAuthorityUsersList",
    async (body, thunkApi) => {
        const { page, size } = body;
        try {
        const data = await client({
            url: `/users/v1/signatory?page=${page}&size=${size}`,
            showErrorToast: true,
            token: false,
        });
        return data;
    } catch (err) {
        return thunkApi.rejectWithValue(err.response);
    }
    }
);

export const CreateUser = createAsyncThunk(
  "CreateUser",
  async (body, thunkAPI)=>{
      try{
          const data = await client({
              url:'/users/v1/operator',
              data: JSON.stringify(body),
              showSuccessToast: true,
              showErrorToast: true,
              errorMessage: "ERROR",
              succesMessage: "user successfully created",
              method: "POST",
          })
          return data;
      }catch(err){
          return thunkAPI.rejectWithValue(err.response);
      }
  }
);

export const UpdateUser = createAsyncThunk(
    "UpdateUser",
    async ({body, userId}, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}`,
                data: JSON.stringify(body),
                showSuccessToast: true,
                showErrorToast: true,
                errorMessage: "ERROR",
                succesMessage: "Changes has been saved",
                method: "PUT",
            })
            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
  );

export const GetUserDetails = createAsyncThunk(
    "GetUserDetails",
    async (userId, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}`,
                showSuccessToast: false,
                showErrorToast: true,
                method: "GET",
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
  );

export const SendVerifyOtpUser = createAsyncThunk(
    "SendVerifyOtpUser",
    async (userId, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}/verify/send-otp`,
                showSuccessToast: false,
                showErrorToast: true,
                method: "POST",
                data: JSON.stringify({}),
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

export const BlockUser = createAsyncThunk(
    "BlockUser",
    async (userId, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}/block`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "User has been blocked",
                method: "PUT",
                data: JSON.stringify({}),
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

export const UnblockUser = createAsyncThunk(
    "UnblockUser",
    async (userId, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}/unblock`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "User has been activated",
                method: "PUT",
                data: JSON.stringify({}),
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);

// Authority unblock
const voen = localStorage.getItem("voen")

export const UnblockUserAuthority = createAsyncThunk(
    "UnblockUser",
    async (pin, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/signatory/${pin}/unblock?voen=${voen}`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "User has been activated",
                method: "PUT",
                data: JSON.stringify({}),
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);


export const DeleteUser = createAsyncThunk(
    "DeleteUser",
    async (userId, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}`,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "User has been deleted",
                method: "DELETE",
                data: JSON.stringify({}),
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);


export const VerifyOtpUser = createAsyncThunk(
    "VerifyOtpUser",
    async ({body, userId}, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}/verify`,
                showSuccessToast: true,
                showErrorToast: true,
                method: "PUT",
                data: JSON.stringify(body),
                succesMessage: "Password has been sent to user",
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);


export const SendPasswordAgainAction = createAsyncThunk(
    "SendPasswordAgainAction",
    async (userId, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/operator/${userId}/resend-password`,
                showSuccessToast: true,
                showErrorToast: true,
                method: "POST",
                succesMessage: "Password has been sent to user",
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);


export const GetUserSettingsInfo = createAsyncThunk("GetUserSettingsInfo", async (userId, thunkAPI) => {
  try {
    const data = await client({
      url: `/users/v1/otp-user/settings/user-info`,
      showSuccessToast: false,
      showErrorToast: true,
      method: "GET",
    });

    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response);
  }
});


export const EmailChange = createAsyncThunk("EmailChange", async (data, thunkAPI) => {
    try {
      const response = await client({
        url: `/users/v1/otp-user/settings/email`,
        showSuccessToast: true,
        showErrorToast: true,
        method: "PUT",
        data: JSON.stringify(data),
        succesMessage: "E-mail was successfully changed",
      });
  
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
});

export const PasswordChange = createAsyncThunk("PasswordChange", async (data, thunkAPI) => {
    try {
      const response = await client({
        url: `/users/v1/otp-user/settings/password`,
        showSuccessToast: true,
        showErrorToast: true,
        method: "PUT",
        data: JSON.stringify(data),
        succesMessage: "Password was successfully changed",
      });
  
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
});

export const QuestionAnswerChange = createAsyncThunk("QuestionAnswerChange", async (data, thunkAPI) => {
    try {
        const response = await client({
            url: `/users/v1/otp-user/settings/question-answer`,
            showSuccessToast: true,
            showErrorToast: true,
            method: "PUT",
            data: JSON.stringify(data),
            succesMessage: "Security section information was successfully changed",
        });

        return response;
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response);
    }
});


export const resendPasswordUser = createAsyncThunk(
    "resendPasswordUser",
    async (body, thunkAPI)=>{
        try{
            const data = await client({
                url: `/users/v1/signatory/resend-password`,
                showSuccessToast: true,
                showErrorToast: true,
                method: "POST",
                data: JSON.stringify(body),
                succesMessage: "Password sent successfully",
            })

            return data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.response);
        }
    }
);