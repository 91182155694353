import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

export const StatementAccountList = createAsyncThunk(
  "StatementAccountList",
  async (body, thunkApi) => {
    const { page, size } = body;
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/statement/files?page=${page}&size=${size}`,
        data: JSON.stringify(body),
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);
export const StatementCardList = createAsyncThunk(
  "StatementCardList",
  async (body, thunkApi) => {
    const { page, size } = body;
    try {
      const data = await client({
        url: `/card-ms/api/v1/card/statement/files?page=${page}&size=${size}`,
        data: JSON.stringify(body),
        showErrorToast: true,
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const DeleteAccountElement = createAsyncThunk(
  "DeleteAccountElement",
  async (body, thunkApi) => {
    const { id } = body;
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/statement/files/${id}`,
        method: "DELETE",
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "statementDelete",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const DeleteCardElement = createAsyncThunk(
  "DeleteCardElement",
  async (body, thunkApi) => {
    const { id } = body;
    try {
      const data = await client({
        url: `/card-ms/api/v1/card/statement/files/${id}`,
        method: "DELETE",
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "statementDelete",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const GenerateAccountStatement = createAsyncThunk(
  "GenerateAccountStatement",
  async (body, thunkApi) => {
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/statement/files/generate`,
        data: body,
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "statementGeneratePreparing",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const GenerateCardStatement = createAsyncThunk(
  "GenerateCardStatement",
  async (body, thunkApi) => {
    try {
      const data = await client({
        url: `/card-ms/api/v1/card/statement/files/generate`,
        data: body,
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "statementGeneratePreparing",
        token: false,
      });
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);
