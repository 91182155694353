import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { OtpLoginSMS } from "store/auth";
import { FormItem, LoginButton, Loading, CustomInput, Modal, FinModal, ErrorMessage } from "components";
import {
  whitespaceRule,
  mobileRules,
  requiredRule,
} from "utils/checkValidations";
import { mobileNumberParser } from "utils/shortFunctions";
import { fin_icon } from "static/svg";
import { useValidate } from "hooks";
import { setOtpData } from '../../../../../store/authInfo';

const OtpForm = () => {
  const [form] = Form.useForm();

  //loading
  const allLoading = useSelector((state) => state.loadings);
  //for translation
  const { t } = useTranslation();
  //services
  const dispatch = useDispatch();
  //for routing
  const history = useHistory();

  const { handleBeginningEmptyInput, userPinValidate } = useValidate(form);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalState, setModalState] = useState({
    visible: false,
    isSuccess: false,
    title: null,
    secondTitle: null,
    width: "406px",
    component: null,
  });

  const onFinish = (values) => {
    setIsSubmitting(true);

    const data = {
      otpInfo: {
        ...values,
        otpPhoneNumber: mobileNumberParser(values?.otpPhoneNumber),
        pin: values.pin?.toUpperCase()
      },
      history: history,
    };

    dispatch(OtpLoginSMS(data)).finally(() => {
      setIsSubmitting(false);
    });

    let localOTPStorage = {
      otpPhoneNumber: data?.otpInfo?.otpPhoneNumber,
      password: data?.otpInfo?.password,
      pin: data?.otpInfo?.pin,
      verificationCode: "",
    };

    values?.otpPhoneNumber &&
    dispatch(setOtpData(localOTPStorage))
    localStorage.setItem("loginStatus", 'OTP');
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Loading loading={allLoading?.isLoading} />

      <Form
        form={form}
        name="Auth_OTP"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        hideRequiredMark={true}
        autoComplete="off"
      >
        <Col span={24} className="general">
          <FormItem
            autoComplete="off"
            name="otpPhoneNumber"
            autoheight
            rules={[mobileRules]}
          >
            <CustomInput label={t("mobile")} inputmask autoComplete="off" />
          </FormItem>
        </Col>

        <Col span={24} className="general">
          <FormItem
            name="pin"
            autoheight
            autoComplete="off"
            rules={[
              {
                required: true,
                validator: (rule, value) => {
                  if (value) {
                    const valueLength = value.split("");
                    if (valueLength.length < 5) {
                      return Promise.reject(<ErrorMessage text={"min5max7"} />);
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject(<ErrorMessage text={"mandatoryField"} />);
                  }
                },
              },
            ]}
          >
            <CustomInput
              label={t("Pin")}
              isSuffix
              onSuffixClick={() =>
                setModalState({
                  visible: true,
                  title: t("Pin"),
                  width: "406px",
                  component: <FinModal />,
                })
              }
              maxLength={7}
              suffixIcon={<img src={fin_icon} alt="fin_icon" />}
              onChange={(e) => {
                handleBeginningEmptyInput(e, "pin")
                userPinValidate(e);
              }}
            />
          </FormItem>
        </Col>

        <Col span={24} className="general">
          <FormItem
            autoComplete="off"
            name="password"
            autoheight
            rules={[
              requiredRule,
              whitespaceRule,
            ]}
          >
            <CustomInput password label={t("password")} maxLength={30} onChange={(e) => handleBeginningEmptyInput(e, "password")} />
          </FormItem>
        </Col>

        <FormItem>
          <LoginButton title={t("login")} htmlType="submit" disabled={isSubmitting} />
        </FormItem>

        <ForgotPasswordWrap>
          <Link to="/login/forgot-password">{t("forgotPassword")}</Link>
        </ForgotPasswordWrap>
      </Form>

      <Modal
        open={modalState.visible}
        cancel={() => setModalState({ visible: false })}
        title={modalState.title}
        secondTitle={modalState.secondTitle}
        width={modalState.width}
        isSuccess={modalState.isSuccess}
      >
        {modalState.component}
      </Modal>
    </Fragment>
  );
};

export default OtpForm;

const ForgotPasswordWrap = styled.div`
  display: flex;
  justify-content: end;

  a {
    font-family: Proxima Nova;
    font-weight: 600;
    font-size: 16px;
    color: #FFA300;
  }
`;
