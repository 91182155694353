import { checkSpaceinValue } from "./inputValidations";
import { Translation } from "react-i18next";
import React from "react";
import { ErrorMessage } from "components";

export const requiredRule = {
  required: true,
  message: <ErrorMessage text={"mandatoryField"}/>
};

export const emailRule = {
  type: "email",
  length: 50,
  message: <ErrorMessage text={"shouldBeCorrectlyEmailAddress"}/>
};

export const whitespaceRule = {
  whitespace: true,
  message: <ErrorMessage text={"shouldBeNoSpaces"} />,
  validator: (_rule, value, callback) => {
    if (checkSpaceinValue(value)) {
      callback(true);
    } else {
      callback();
    }
  },
};

export const min6CharactersRule = {
  min: 6,
  message: <ErrorMessage text={"mustBeAtLeastCharacters"}/>
};

export const max6CharactersRule = {
  max: 6,
  message: (
    <Translation>
      {(t) => <div>{t("mustBeNoMoreThanCharacters")}</div>}
    </Translation>
  ),
  // message: i18n.t('mustBeNoMoreThanCharacters'),
};

export const passwordMin8Rule = {
  min: 8,
  message: <ErrorMessage text={"mustBeAtLeast8Characters"}/>
};

export const passwordMax30Rule = {
  max: 30,
  message: <ErrorMessage text={"mustBeNoMoreThan30Characters"} />
};

export const minUppercaseLetterRule = {
  pattern: /^(?=.*?[A-Z])$/,
  message: <ErrorMessage text={"mustBeMinUppercaseLetter"}/>
  // message: i18n.t('mustBeMinUppercaseLetter'),
};

export const minLowercaseLetterRule = {
  pattern: /^(?=.*?[a-z])$/,
  message: <ErrorMessage text={"mustBeMinLowercaseLetter"}/>
};

export const minOneDigitRule = {
  pattern: /^(?=.*?[0-9])$/,
  message: (
    <Translation>{(t) => <div>{t("mustBeMinOneDigit")}</div>}</Translation>
  ),
  // message: i18n.t('mustBeMinOneDigit'),
};

export const minOneSpecialCharacterRule = {
  pattern: /^(?=.*?[#?!@$%^&*-])$/,
  message: (
    <Translation>
      {(t) => <div>{t("mustBeMinOneSpecialCharacter")}</div>}
    </Translation>
  ),
  // message: i18n.t('mustBeMinOneSpecialCharacter'),
};

export const mobileRules = {
  required: true,
  validator: (rule, value) => {
    if (value) {
      const regex = /\(([^)]+)\)/;
      const match = value.match(regex);

      const prefix = match[1];

      if (prefix && !prefix?.includes("_")) {
        const prefixes = ["99", "10", "50", "51", "55", "70", "77"];

        if(!prefixes.includes(prefix)){
          return Promise.reject(<ErrorMessage text="mustBeCorrectMobilePrefix" />)
        }
      }

      if(value[10] === "1" || value[10] === "0"){
        return Promise.reject(<ErrorMessage text="WrongFormat" /> )
      }

      if(value.includes("_")){
        return Promise.reject();
      }

      return Promise.resolve();
    }
    return Promise.reject(<ErrorMessage text="mandatoryField" />);
  },
};


export const mobilePrefixRule = (value) =>
/(99|10|50|51|55|70|77).*$/.test(value);



export const generalPassRule = {
  pattern: /^\s*(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$/,
  message: <ErrorMessage text={"generalPassError"} />

  // message: i18n.t('mustBeMinOneSpecialCharacter'),
};

export const blockInvalidChar = (e) => {
  return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
};

export function isObjectEqual(object1, object2) {
  const keysArray = Object.keys(object2);
  let result = true;
  keysArray.map((item) => {
    if (object1[item] !== object2[item] && result) {
      result = false;
    }
  });
  return result;
}

export const blockZeroBeginningNumber = {
  pattern: /^(?!.*[+\-])^(0(\.0+)?|0\.|0\.\d+|[1-9]\d*(\.\d+)?)$/,
  message: <ErrorMessage text={"enterRightAmount"}/>
  };

export const checkUrlPath = (url) =>{
  return /(vat|budget|salary|government)/.test(url)
}
export const checkUrlPathUsers = (url) =>{
  return /(add-user|user-details|edit-user)/.test(url)
}

export const validateNumber = (number)=>{
  let formattedNumber = number ? number.replace(/^(\+\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5') : '';
  return formattedNumber;
}
