import { createSlice } from "@reduxjs/toolkit";
import { AnyPayProcess, AnyPayValidate, PaymentAlias } from "./api_action";

export { AnyPayProcess , AnyPayValidate, PaymentAlias};

//withinBank
const initialState = {
  alias:null,
  validate: null,
  process:null,
  error: null,
}
export const AnyPaySlice = createSlice({
  name: "anypay",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [AnyPayValidate.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.validate = data
    },
    [AnyPayProcess.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.process = data
    },
    [PaymentAlias.fulfilled]: (state, action) => {
      state.alias = action.payload
    },
    [AnyPayValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [AnyPayProcess.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [PaymentAlias.rejected]: (state, action) => {
      state.error = action.payload
    },
  },
});

export const anyPayActions = AnyPaySlice.actions;
export const anyPayReducer = AnyPaySlice.reducer;
