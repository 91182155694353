import React from "react";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";
import { Carousel } from "antd";
import { useRef } from "react";
import CarouselComponents from "../Carousel/carouselComponent";
import { arrow_right } from "static/svg";

const VideoContent = () => {
  const ref = useRef();
  const settings = {
    dots: true,
    infinite: true,
    draggable: true,
    autoplaySpeed: 5000,
  };

  return (
    <CarouselWrapper>
      <Carousel autoplay {...settings} ref={ref}>
        {[
          {
            id: "1",
            text: '"İnternet Bankçılıq"da',
            spanText: "baş roldasınız!",
          },
          {
            id: "2",
            text: 'İmza ardıcıllığını',
            spanText: "rahat izləmək şansı",
          },
          {
            id: "3", text: 'Əməliyyatlar üçün icazələr',
            spanText: "bir platformada!"
          },
        ].map((v) => (
          <div key={v.id}>
            <ContentStyle>
              <div className="overlay"></div>
              <CarouselComponents
                item={v.id}
                title={v.text}
                spanText={v.spanText}
              />
            </ContentStyle>
          </div>
        ))}
      </Carousel>

      <StyledNextPrev>
        <span className="prev" onClick={() => ref.current.prev()}>
          <img src={arrow_right} alt="arrow" />
        </span>
        <span onClick={() => ref.current.next()}>
          <img src={arrow_right} alt="arrow" />
        </span>
      </StyledNextPrev>
    </CarouselWrapper>
  );
};

export default VideoContent;

const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .ant-carousel {
    .slick-dots li button {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }

    .slick-dots li.slick-active button {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #fff;
    }

    .slick-dots li.slick-active {
      width: 16px;
    }

    .slick-dots-bottom {
      bottom: 63px;
    }
  }

  .mobileHeader {
    display: none;
  }

  @media ${responsive.laptop} {
    .mobileHeader {
      display: block;
    }

    video {
      width: 100vh;
    }
  }
`;

const StyledNextPrev = styled.div`
  position: absolute;
  bottom: 63px;
  left: 97px;
  z-index: 2;

  span {
    cursor: pointer;
    padding: 10px 16px;
    border: 1px solid;
    margin-left: 12px;
    border-radius: 50%;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(12deg) brightness(160%) contrast(103%);
    /* border-color: red; */
  }

  span img {
    margin-bottom: 4px;
  }

  .prev img {
    transform: rotate(180deg);
  }
`;

const ContentStyle = styled.div`
  /* height: 100vh; */
  position: relative;
  /* .overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  } */
`;
