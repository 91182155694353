import React from "react";
import styled from "styled-components";

import { oldIdCard, newIdCard } from "static/svg";
import { useTranslation } from "react-i18next";
import { responsive } from "utils/responsiveUI";

export const FinModal = (props) => {

  const { t } = useTranslation();

  return (
    <Container>
      <StyleContent>
        <IdImageCard>
          <img src={oldIdCard} alt={"oldIdCard"} />
          <p>{t("Old ID Cards")}</p>
        </IdImageCard>
        <IdImageCard>
          <img src={newIdCard} alt={"newIdCard"} />
          <p>{t("New ID Cards")}</p>
        </IdImageCard>
      </StyleContent>
    
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 0px;
`;

const StyleContent = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px 32px 32px 32px;
  overflow: auto;
  h4 {
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #63666a;
    span {
      font-weight: 700;
    }
  }
`;

const IdImageCard = styled.div`
  display:flex;
  flex-direction:column;
  text-align:center;
  & p{
    font-weight: 600;
    font-size: 14px;
    color:#393939;
    position:relative;
    top:14px;
  }

  img{
    width:250px;
  }
  @media ${responsive.laptop} {
    img{
      width: 160px;
  }
  }
`;