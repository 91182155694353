import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";
import { filterQueryResolver } from "utils";
import { changePaymentTypeIdsAndStatuses } from "utils/changePaymentTypeIds";

export const GetOperationHistoryList = createAsyncThunk(
  "GetOperationHistoryList",
  async (filters, thunkAPI) => {

    const query = filterQueryResolver(changePaymentTypeIdsAndStatuses(filters, "payment-type-ids", "status-codes"));
    try {
      const data = await client({
        url: `/operation-ms/v1/operation/filter?${query}`,
        showSuccessToast: false,
        showErrorToast: false,
        succesMessage: "All Operation History Was Inserted",
        token: false
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);



export const GetOperationHistoryDetails = createAsyncThunk(
  "GetOperationHistoryDetails",
  async ({paymentId}, thunkAPI) => {
    try {
      const data = await client({
        url: `/operation-ms/v1/operation/detail`,
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: "Operations Status List Was Added",
        token: false,
        paymentId: paymentId
      });

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);



