import { createSlice } from '@reduxjs/toolkit'
import { CheckPayrollFile } from './api_action';


export const CheckPayrollFileSlice = createSlice({
    name: 'checkPayrollFile',
    initialState: {
        payrollFileInfo: null,
        error: null
    },
    reducers: {
        removeState: (state) => { state.payrollFileInfo = null }
    },
    extraReducers: {
        [CheckPayrollFile.fulfilled]: (state, action) => {
            state.payrollFileInfo = action.payload.data
        },
        [CheckPayrollFile.rejected]: (state, action) => {
            state.error = action.payload
            state.payrollFileInfo = null
        }
    }
});

export { CheckPayrollFile }

export const checkPayrollFileReducer = CheckPayrollFileSlice.reducer;
