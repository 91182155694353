import { createSlice } from "@reduxjs/toolkit";
import { InternationalValidate, InternationalProcess, InternaionalFileUpload, getInternaionalFileUpload } from "./api_action";

export { InternationalValidate, InternationalProcess, InternaionalFileUpload, getInternaionalFileUpload };

const initialState = {
  error: null,
  validate: null,
  process: null,
  // allfiles: [],
  values: {},
  filesData: []
};

export const InternationalSlice = createSlice({
  name: "International",
  initialState,
  reducers: {
    addFiles(state, action) {
      const fileList = action.payload;
      state.filesData = [...fileList];
    },
    saveValues(state, action) {
      const formValues = action.payload;
      state.values = formValues;
    },
    reset: () => initialState
  },
  extraReducers: {
    [InternationalValidate.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.validate = data;
    },
    [InternationalValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [InternationalProcess.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.process = data;
    },
    [InternationalProcess.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getInternaionalFileUpload.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.filesData = data;
    },
    [getInternaionalFileUpload.rejected]: (state, action) => {
      state.error = action.payload;
    },
    
    
  },
});

export const internationalReducer = InternationalSlice.reducer;
