import React from 'react';
import styled from 'styled-components';
import { Tooltip as AntTooltip } from 'antd'

export const Tooltip = ({ children, text, placement = "bottom" }) => {
  return (
    <StyleContainer>
      <AntTooltip
        // this code creates problems for the sidebar that's why removed
        // getPopupContainer={trigger => trigger.parentNode}
        placement={placement}
        title={text}
      >
        {children}
      </AntTooltip>
    </StyleContainer>

    // <StyledTooltip>
    //   <div className="tooltip">
    //     {children}
    //     <span className="tooltiptext">{text}</span>
    //   </div>

    // </StyledTooltip>
  )
}

const StyleContainer = styled.div`
  .ant-tooltip-inner{
    background-color:#2D3546;
    padding:14px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    color: #FFFFFF;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    white-space:nowrap;
    letter-spacing: 0.05em;
    word-break: break-all;
    padding: 10px;
  }
  .ant-tooltip-arrow-content{
    width:11px;
    height:11px;
    top:2px;
  }
`

const StyledTooltip = styled.div`
  
/* Tooltip container */
.tooltip {
  position: relative;
  display:flex;
  justify-content:center;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  color: #FFFFFF;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  white-space:nowrap;
  letter-spacing: 0.05em;
  padding: 13px 0;
  border-radius: 7px;
  background: #2D3546;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  width:130px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -65px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}


/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  /* border color for arrow position */
  border-color: transparent transparent #2D3546 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
`