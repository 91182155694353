import React from "react";
import styled from "styled-components";

export const CardCenterDivider = ({
  firstTitle,
  rPadding,
  firstComponent,
  secondTitle,
  secondComponent,
}) => {
  return (
    <Container rPadding={rPadding}>
      <FormComponent>
        <div>{firstComponent}</div>
      </FormComponent>
      <FormComponent>
        <div>{secondComponent}</div>
      </FormComponent>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  padding: ${({ rPadding }) => (rPadding ? " 0 12px 0" : "30px 36px 0")};
`;
const FormComponent = styled.div`
  .ant-col {
    padding-left: 0px !important;
    /* padding-right: 0px !important; */
  }
`;