import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Form,
} from 'antd';
import {
  GroupButtons,
  Loading,
  RadioButton,
} from "components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setVoen } from "../../../../../store/authInfo";

const AsanCompanyList = ({
                           modalVisible,
                           setModalVisible,
                         }) => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const allLoading = useSelector(state => state.loadings);
  const signInfo = useSelector(state => state.asanSign.asanSignData);
  const [companyList, setCompanyList] = useState([]);
  const [companyRadio, setCompanyRadio] = useState(0);
  const [selectedVoen, setSelectedVoen] = useState();

  const randomColors = [
    "#BA68C8",
    "#FF8A65",
    "#F06292",
    "#FFD54F",
    "#64B5F6",
  ];

  useEffect(() => {
    if (selectedVoen) {
      const replacedUrl = window.location.href.replace("/login", "/");
      window.location.href = replacedUrl;


    }
  }, [selectedVoen]);

  useEffect(() => {
    const listData = signInfo?.voenList?.map((item, index) => {
      const colorIndex = index % randomColors.length;

      return {
        index: item,
        value: item,
        title: `${"Company Name"}`,
        text: item,
        render: (<StyleImg style={{ backgroundColor: randomColors[colorIndex] }}>
          {/* {item?.img ||
                `${item.split(" ")[0].split("")[0]}${
                  item?.name.split(" ")[1].split("")[0]
                }`} */}
        </StyleImg>)

        // (
        //   <img src={profile_img || undefined_profile_img} alt="profile img" />
        // ),
      };
    });
    setCompanyList(listData);
  }, [signInfo]);


  const modalTime = 3 * 60 * 1000; //3 minutes

  const closeModal = () => {
    setModalVisible(false);
    localStorage.clear();
    const replacedUrl = window.location.href.replace("/login", "/");
    window.location.href = replacedUrl;
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      closeModal();
    }, modalTime);
  }, [])

  const onChangeCompanyList = (e) => {
    setCompanyRadio(e.target.value)
  }

  const onFinish = () => {
    setSelectedVoen(companyRadio);
    dispatch(setVoen({ voen: companyRadio }))
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Fragment>
      <Loading loading={
        allLoading?.isLoading
      } />
      <FormContainer
        name="Auth_Asan"
        layout="vertical"
        initialValues={{ remember: true, }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        hideRequiredMark={true}
        // onValuesChange={(changedValues, allValues) => console.log('change in form', changedValues, allValues)}
      >
        <RadioButton
          data={companyList}
          onChange={onChangeCompanyList}
          value={companyRadio}
          // disabled={checkPaymentValue}
          isStandard
        />

        <StyledFormItem>
          <GroupButtons
            firstProps={{text:"Cancel", isBorder: true, onClick: closeModal}}
            secondProps={{text: 'confirm' }}
          />
        </StyledFormItem>
      </FormContainer>
    </Fragment>
  )

}

export default AsanCompanyList;

const FormContainer = styled(Form)`
  padding: 20px 25px 24px;
`

const StyledFormItem = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyleImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;

  img {
    width: 100%;
    height: 100%;
  }

  font-family: 'Proxima Nova';
  font-size: 16px;
`;
