import React from "react";
import Login from "./Login";

import VideoContent from "./VideoContent";
import { AuthLayout, LoginContainer } from "components";

const AuthContainer = () => {
    return (
        <AuthLayout>
            <div className="carousel_container">
                <VideoContent/>
            </div>
            <LoginContainer>
                <Login/>
            </LoginContainer>
           
        </AuthLayout>
    );
};

export default AuthContainer;
