import React from "react";
import styled from "styled-components";
import { responsive } from "utils/responsiveUI";

export const ConstToast = ({title, loanText, loanValue, icon, greyColor, defaultColor, marginTop, marginBottom}) => {
  return (
    <ConstToastContainet >
      <ConstToastContent {...{greyColor, defaultColor, marginTop, marginBottom}}  >
        <img src={icon} alt="warning info" />
        <span>
          {loanText ? `${loanText}: ${loanValue}` : title}
        </span>
      </ConstToastContent>
    </ConstToastContainet>
  );
};
const ConstToastContent = styled.div`
  margin:  ${(props)=> props.marginTop ? '0 0 42px 0' : props.marginBottom ? '24px 0 24px 0' : null};
  padding: 15px;
  background: ${(props)=> props.greyColor ? 'rgba(105, 118, 150, 0.12)' : '#FFF4E0'} ;
  border: ${(props)=> props.greyColor ? '1px solid #697696' : '1px solid #FFA000'};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12.5px;
  @media ${responsive.laptop} {
   gap: 10px;
   padding: 5px;
  }
  span {
    color: ${(props)=> props.greyColor ? ' #697696' : '#FFA000'};
    font-weight: 500;
    font-size: 15px;
  }
  img{
    filter: ${(props)=> props.greyColor || props.defaultColor ? null : "invert(68%) sepia(16%) saturate(3000%) hue-rotate(358deg) brightness(127%) contrast(168%)" };
  }
`;


const ConstToastContainet = styled.div``;
