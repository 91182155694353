
import React from "react";
import { currencyInfos } from "constant";
import styled from "styled-components";
import { center } from "components/StyleComponent";

export const FlagSelect = ({value, id }) => {
  return (
    <>
     
      <StyledCurrency>
        <img src={`${currencyInfos[id]?.flag}`} alt="lang"/>
        <h3>{value}</h3>
      </StyledCurrency>
    </>
  );
};

const StyledCurrency = styled.span`
   width: auto !important;
   text-align: left;
   height: 40px!important;
   ${center}
    img{
       margin-right:10px;
       width: 28px;
       height: auto;
    }
    h3{
      width: 28px;
      font-family: "Proxima Nova";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.045px;
      text-transform: uppercase;
      margin: 0!important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    
`