import React from "react";
import {useHistory, useParams } from "react-router-dom";

import styled from "styled-components";
import {
  MainButtonIcon,
  MainButton,
  BorderButton,
  SubMainButton,
} from "components";
import {
  downloadWhite,
} from "static/svg";
import { useTranslation } from "react-i18next";
import { useCanceled } from "hooks";
import { responsive } from "utils/responsiveUI";



export const ActionSubButtons = ({
  mainOnClick,
  cancelOnClick,
  blockBtnName,
  blockBtnNameCancel,
  mainButtonType = "submit",
  isBg,
  isRed,
  isPadding,
  noPadding,
  disabled = false,
  loading
}) => {
  const { t } = useTranslation();

  
  return (
    <ActionButtonsStyle isPadding={isPadding} noPadding={noPadding}>
      <SubMainButton
        isActivWhite
        onClick={cancelOnClick}
        text={t(blockBtnNameCancel)}
      />
      <MainButton
        isBg={isBg}
        isRed={isRed}
        modal
        text={t(blockBtnName)} //Yadda saxlamaq
        onClick={mainOnClick}
        type={mainButtonType}
        disabled={disabled}
        loading={loading}
      />
    </ActionButtonsStyle>
  );
};

export const ActionButtonsStyle = styled.div`
  width: 100%;
  padding: ${(props) => (props.isPadding ? "30px 0" : props.noPadding ? "0" : " 0 30px")};
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  & > :first-child {
    margin-right: 10px;
  }
  @media ${responsive.laptop} {
      gap: 12px;
      button {
      padding: 10px;
      }
      & > :first-child {
      margin-right: 0;
      margin-top: 0;
  }
  }
`;
