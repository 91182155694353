import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import {Col, Form, Result, Spin} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    FormItem,
    LoginButton,
    Loading,
    CustomInput,
    LoginLayout,
    ErrorMessage,
} from "components";
import { responsive } from "utils/responsiveUI";
import {
    requiredRule,
    whitespaceRule,
} from "utils/checkValidations";
import { useValidate } from "hooks";
import { passwordValidation } from "utils";
import {CheckToken, SetNewPasswordForgetPassword} from "../../../../store/auth/api_actions";


const SetNewPassword = () => {

    const [form] = Form.useForm();
    const [error, setError] = useState(null);
    const allLoading = useSelector((state) => state.loadings);

    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const { handleBeginningEmptyInput } = useValidate(form);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        dispatch(CheckToken(token))
            .then(response => {
                if(response?.payload?.status === 200){
                    setError(null)
                } else {
                    setError(response?.error?.message);
                }
            })

    }, []);

    const checkConfPass = () => {
        let passVal = form.getFieldValue("password");
        let confPassVal = form.getFieldValue("confirmPassword");
        if (!confPassVal || passVal === confPassVal) {
            return Promise.resolve();
        }
        return Promise.reject(<ErrorMessage text={'passwordMatchError'} />);
    };


    const onFinish = (values) => {
        dispatch(SetNewPasswordForgetPassword({body: {password: values.password, token}, history}))
    };

    const renderedForm = () => {
        return (
            <FormContainer>
                <div className="title_style">{t("setPass")}</div>
                <Form
                    name="Set-New-Password"
                    layout="vertical"
                    form={form}
                    onFinish={(values) => onFinish(values)}
                    initialValues={{ remember: true }}
                    scrollToFirstError={true}
                    hideRequiredMark={true}
                >
                    <Col span={24} className="general">
                        <FormItem
                            name="password"
                            autoheight
                            rules={[
                                requiredRule,
                                whitespaceRule,
                                ...[
                                  {
                  
                                    validator: (rule, value, callback) => {
                                      if (value) {
                                        const valueLength = value.split("");
                                        if (
                                          !passwordValidation(value) ||
                                          valueLength.length < 8
                                        ) {
                                          callback(true);
                                          return Promise.reject(
                                            <ErrorMessage text="pass_info" />
                                          );
                                        } else {
                                          callback();
                                          return Promise.resolve();
                                        }
                                      }else{
                                        callback(true);
                                      }
                                    },
                                  },
                        
                                ]
                              ]}
                        >
                            <CustomInput password label={t("password")} maxLength={30} onChange={(e)=> handleBeginningEmptyInput(e, "password")}
                            />
                        </FormItem>
                    </Col>

                    <Col span={24} className="general">
                        <FormItem
                            autoheight
                            name="confirmPassword"
                            rules={[
                                requiredRule,
                                whitespaceRule,
                                ...[
                                    {
                                        validator: checkConfPass,
                                        message: <ErrorMessage text={'passwordMatchError'} />
                                    },
                                ],
                            ]}
                            
                        >
                            <CustomInput
                                password

                                label={t("confirmPassword")}
                                onPaste={(e) => {
                                    e.preventDefault();
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </FormItem>
                    </Col>


                    <Form.Item>
                        <LoginButton title={t("save")} htmlType="submit" />
                    </Form.Item>
                </Form>
            </FormContainer>
        );
    };

    return (
        <>
            <Loading loading={allLoading?.isLoading} />

            <LoginLayout>
                {
                    error
                        ? <Result
                            subTitle={t(error)}
                        />
                        : renderedForm()
                }
            </LoginLayout>
        </>
    );
};

export default SetNewPassword;

const FormContainer = styled.div`
  height: 100%;
  margin-top: 76px;
  overflow: auto;
  @media ${responsive.laptop} {
      margin-top: 59px;
    }
  .title_style {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
    @media ${responsive.laptop} {
      font-size: 20px;
    }
  }
`;
