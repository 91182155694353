import React from 'react';
import Routes from 'routes';
import { LayoutIB } from 'components';

const AuthorizedApp = () => {
  return (
    <LayoutIB>
      <Routes />
    </LayoutIB>
  )
}

export default AuthorizedApp;
