import { Dropdown } from 'antd';
import AutoCompleteInput from 'components/AutoCompleteInput';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import searchIcon from '../../static/svg/svgs/mapSearchIcon.svg';
import { responsive } from 'utils/responsiveUI';
import { SingleButton } from 'components/CustomButtons/SingleButton';
import { left_arrow } from 'static/svg';

export const Content = ({
  title,
  second_component,
  children,
  marginBottom,
  icon,
  url,
  bgColor,
  noPadding,
  isPadding,
  button,
  onClick,
  btnText,
  btnIcon,
  className,
  menu = '',
  autoComplete,
  acitveKey,
  titleUnderText,
  underText,
  loading,
}) => {
  const { t } = useTranslation();
  const [showSearchInput, setShowSearchInput] = useState(false);

  let openInput = () => {
    setShowSearchInput(true);
  };
  let closeInput = () => {
    setShowSearchInput(false);
  };

  return (
    <ContentContainer
      noPadding={noPadding}
      bgColor={bgColor}
      isPadding={isPadding}
      className={className}
    >
      <TitleContainer marginBottom={marginBottom}>
        <HeaderContanier>
          {titleUnderText ? (
            <div>
              <span className="title">{t(title)}</span>
              <>{underText}</>
            </div>
          ) : (
            <span className="title">{t(title)}</span>
          )}
          <div className="second_component">{second_component} </div>
          {autoComplete && (
            <SearchContainer>
              <div className="search_container_icon" onClick={openInput}>
                <img src={searchIcon} alt="search_icon" />
              </div>
              <div
                className={`search_container_input ${showSearchInput ? 'show' : ''}`}
              >
                <div className="left_arrow" onClick={closeInput}>
                  <img src={left_arrow} alt="left_arrow" />
                </div>
                <AutoCompleteInput
                  autoComplete={autoComplete}
                  acitveKey={acitveKey}
                  allowClear
                  newClearIcon
                />
              </div>
            </SearchContainer>
          )}
        </HeaderContanier>
        {icon && (
          <div className="img">
            <Link to={url}>
              <img src={icon} alt={'icon'} />
            </Link>
          </div>
        )}
        {button && (
          <Dropdown overlay={menu} trigger={['click']}>
            <SingleButton
              isBorder
              text={btnText}
              onClick={onClick}
              icon={btnIcon}
              loading={loading}
            />
          </Dropdown>
        )}
      </TitleContainer>
      {children}
    </ContentContainer>
  );
};

const HeaderContanier = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
`;

const ContentContainer = styled.div`
  padding: ${(props) =>
    props.noPadding ? '0' : props.isPadding ? '30px 36px' : '30px 36px'};
  background-color: ${(props) => (props.bgColor ? '#F2F2F2' : '#fff')};
  //min-height: 100vh;
  @media ${responsive.laptop} {
    padding: ${(props) => (props.noPadding ? '0' : '24px 18px')};
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  margin-bottom: ${(props) => props.marginBottom || '30px'};
  .title {
    font-family: Proxima Nova;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    color: #1c1c1c;
    @media ${responsive.laptop} {
      font-size: 18px;
    }
  }
  .img,
  .title {
    /* margin-bottom: 30px; */
  }
  button {
    flex: none;
    margin-left: 10px !important;
  }
`;
const SearchContainer = styled.div`
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    border-bottom: 2px solid #383838 !important;
    box-shadow: 0 0 0 2px rgba(247, 163, 15, 0) !important;
  }

  .ant-select-selector {
    background-color: #fff !important;
    border: none !important;
    border-bottom: 2px solid #aaa !important;
    border-radius: 0 !important;
    padding-left: 40px !important;
    position: relative;
    :before {
      content: url(${searchIcon});
      position: absolute;
      left: 0;
      top: 12px;
      @media ${responsive.laptop} {
        z-index: -1;
      }
    }

    .ant-select-selection-search {
      left: 40px;
    }
  }
  .search_container_icon {
    display: none;
    @media ${responsive.laptop} {
      display: flex;
    }
  }
  .search_container_input {
    @media ${responsive.laptop} {
      position: absolute;
      right: -1000px;
      top: -15px;
      transition: all 0.5s;
    }
  }
  .search_container_input.show {
    right: 0;
    @media (max-width: 390px) {
      width: 100%;
      background-color: #fff;
    }
  }
  .left_arrow {
    display: none;
    @media ${responsive.laptop} {
      position: absolute;
      display: flex;
      width: 23px;
      height: 22px;
      top: 12px;
      z-index: 1;
    }
  }
`;
