import React, { useEffect }  from "react";
import { Col, Spin } from "antd";
import { upload } from "static/svg";
import styled from "styled-components";
import Dragger from "antd/lib/upload/Dragger";
import Files from "shared/Components/UploadFiles";
import { responsive } from "utils/responsiveUI";
import { useTranslation } from "react-i18next";


export const UploadMulti = ({
  span = 12,
  onChange,
  multiple,
  fileList,
  setFileList,
  acceptFile,
  setFileFormat,
  setSizeStatus,
  fileExist,
  fileFormat,
  sizeStatus,
}) => {
  const { t } = useTranslation();

  const handleDelete = (file) => {
    setFileList(fileList.filter((item)=>item !== file));
  };



  useEffect(() => {
    
    const maxSize = 100 * 1024 * 1024
    const totalSize = fileList.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > maxSize) {
      setSizeStatus(true);
    }else{
      setSizeStatus(false);
    }
  }, [fileList])
  
  const allowedFileTypes = [
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const dragProps = {
    name: "files",
    multiple,
    listType: "text",
    maxCount: 4,
    // accept: acceptFile,
    progress: { strokeWidth: 2, showInfo: true },
    onChange: onChange,
    onRemove: handleDelete,
    beforeUpload: (file) => {
      if (allowedFileTypes.includes(file.type)) {
        setFileFormat(false);
        setFileList((prevstate) => [...prevstate, file]);
      } else {
        setFileFormat(true);
        return false;
      }
 

      
      return false;
    },
    fileList,
  };



  const getTotalFileSize = (fileList) => {
    const totalSizeInBytes = fileList.reduce((total, file) => total + file.size, 0);
    const totalSizeInMB = totalSizeInBytes / (1024 * 1024);
    return totalSizeInMB.toFixed(2);
  };
  
  const totalSizeInMB = getTotalFileSize(fileList);

  return (
    <Col {...{ span }}>
      <Spin spinning={false}>
        <StyledContainer {...{fileExist, fileFormat, sizeStatus, fileList}}>
          <StyledFileName>
            <Styledaa fileList={fileList}>
              <Files
                col={fileList.length === 1 ? 24 : 12}
                fileList={fileList}
                handleDelete={handleDelete}
                swift={true}
              />
            </Styledaa>
          </StyledFileName>
          <Dragger {...dragProps}>
            <StyledUploadContent fileList={fileList}>
              <StyledUpload>
                <img src={upload} alt="" />
                <span className="uploadTitle">
                  {t("dropFileHereOrClickToUpload")}
                </span>
                <span className="uploadTitleSub">
                  {acceptFile
                    ? t("supportedFormatSwift")
                    : t("supportedFormat")}
                </span>
              </StyledUpload>
           
            </StyledUploadContent>
          </Dragger>
          <StyledSizeText>    
          {totalSizeInMB} MB / 100 MB
          </StyledSizeText>
        </StyledContainer>
      </Spin>
    </Col>
  );
};

const Styledaa = styled.div`
  /* max-height: 150px; */
  /* margin: 20px 30px; */
  overflow-y: scroll;
  overflow-x: hidden;
  display: ${({ fileList }) => (fileList.length > 0 ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  /* height: 100vh; */
  .general {
    background-color: yellow;
  }
`;
const StyledUploadContent = styled.div`
  position: relative;
  padding: 20px 8px;
  width: 100%;
  background-color: ${({ fileList }) =>
    fileList.length > 0 ? "#f8f8f8" : "#fff"};
  margin: 20px 30px;
  border-radius: 6px;

  display: ${({ salaryUpload, fileList }) =>
    salaryUpload && fileList.length !== 0 ? "none !important" : ""};
`;
const StyledUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 20px;
  background-color: transparent;

  span:first-child {
    width: 100%;
  }
  .uploadTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #555555;
    margin-top: 5px;
  }
  .uploadTitleSub {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #8e8e8e;
    margin-top: 5px;
  }
`;

const StyledFileName = styled.div`
  width: 100%;
  position: absolute;
  z-index: 100;
  @media ${responsive.tablet} {
    /* top: 300px; */
  }
`;
const StyledContainer = styled.div`
  position: relative;
  background-color: #fff;
  border: 2px dashed #C6C6C6;
  border-color: ${(({ fileExist, fileFormat, sizeStatus, fileList }) =>
  fileExist ||
  fileFormat ||
  sizeStatus ? "#FF5252" : fileList.length === 0 ? " #C6C6C6" : "#008739")};

  height: 326px;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: ${({ fileList }) =>
      fileList.length !== 0 ? "end" : "center"};
  }
  .ant-upload.ant-upload-btn {
    padding: 15px 0;
  }
  .ant-upload.ant-upload-drag {
    background-color: transparent;
    height: 100%;
    &:hover {
      box-sizing: border-box;

      border-color: grey;
    }
    &:focus {
      box-sizing: border-box;
    }
  }
  .ant-upload-list.ant-upload-list-picture {
    max-height: 160px;
    overflow: auto;
    padding: 0 20px 20px 20px;
    border: none !important;
    .ant-upload-list-picture.ant-upload-list-item {
      border: none !important;
    }
  }
  .ant-upload-list-item {
    display: none !important;
  }
`;

const StyledSizeText = styled.div`
   display: flex;
   justify-content: end;
   font-weight: 400;
   color: #8e8e8e;
   font-size: 13px;
`;
