import { createSlice } from "@reduxjs/toolkit";
import { WithinBankValidate, WithinBankProcess } from "./api_action";

export { WithinBankValidate , WithinBankProcess};

//withinBank
const initialState = {
  validate: null,
  process:null,
  error: null,
}
export const WithinBankSlice = createSlice({
  name: "withinBank",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [WithinBankValidate.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.validate = data
    },
    [WithinBankProcess.fulfilled]: (state, action) => {
      const {data} = action.payload;
      state.process = data
    },
    [WithinBankValidate.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [WithinBankProcess.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const withinBankReducer = WithinBankSlice.reducer;
