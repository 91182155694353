import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from 'client';


export const CheckPayrollFile = createAsyncThunk(
    "CheckPayrollFile",

    async (allValues, thunkAPI) => {

        console.log('Action value: ', allValues)
        try {
            const data = await client({
                url: `/payment/salary/check-payroll`,
                data: allValues,
                showSuccessToast: true,
                showErrorToast: true,
                succesMessage: "checked payroll file",
                token: false,
            })
            return data
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response)
        }
    }
)