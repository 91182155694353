import { Col, Form } from 'antd';
import {
  CustomInput,
  CustomSelectBody,
  ErrorMessage,
  FormItem,
  GroupButtons,
} from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  customersAddAccount,
  getCustomersData,
} from 'store/anipayCabinet/api_action';
import styled from 'styled-components';

const AddAccountModal = ({
  collapseId,
  setModalVisibility,
  form,
  accountsListCollapse,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accounts } = useSelector((state) => state.getAccounts);
  const { customerCheckId } = useSelector((state) => state.anipayCabinet);

  const accountsData = accounts?.aznAccounts;

  const onFinish = async () => {
    const accountId = form.getFieldValue('accounts');
    const customerId = customerCheckId.recordId;

    if (accountId) {
      await dispatch(
        customersAddAccount({ customerId, aliasId: collapseId, accountId })
      );

      await dispatch(getCustomersData(customerId));
      setModalVisibility({});

      await form.resetFields();
    }
  };

  const filterAccounts = [];

  accountsData &&
    accountsData !== undefined &&
    accountsData.forEach((account) => {
      const found = accountsListCollapse.find(
        (acc) => acc?.iban === account?.accountNo
      );

      if (!found) {
        filterAccounts.push(account);
      }
    });

  return (
    <StyledModalBody>
      <Form
        form={form}
        name="filter"
        layout="vertical"
        initialValues={{ remember: true, transferToOwnAccount: true }}
        onFinish={onFinish}
      >
        <Col className="general">
          <FormItem
            name="accounts"
            rules={[
              {
                required: true,
                message: <ErrorMessage text={'mandatoryField'} />,
              },
            ]}
          >
            <CustomInput
              select
              label={t('Accounts')}
              placeholder=""
              data={filterAccounts?.map(
                ({ accountNo, balance, currency, category }) => ({
                  id: accountNo,
                  accountNo: (
                    <CustomSelectBody
                      {...{
                        accountNo,
                        balance,
                        currency,
                        category,
                      }}
                      isSelectedAmount
                    />
                  ),
                })
              )}
              keys={['accountNo']}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <GroupButtons firstProps={false} secondProps={{ text: 'Save' }} />
        </Col>
      </Form>
    </StyledModalBody>
  );
};

export default AddAccountModal;

const StyledModalBody = styled.div`
  padding: 10px;
`;
