import { useEffect, useState } from 'react';

export const useScrollToError = (allFieldError, modulName) => {
  useEffect(() => {
    if (allFieldError?.errorFields && allFieldError?.errorFields?.length !== 0) {
      const errorInput = document.querySelector(
        `#${modulName}_${allFieldError?.errorFields[0]?.name[0]}`
      );

      if (errorInput) {
        setTimeout(() => {
          errorInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    }
  }, [allFieldError]);
};
