import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import {
  express_logo,
  setting,
  logOut
} from 'static/svg';
import { Row, Col } from 'antd';
import { LinkBtn } from './linkButton';

import ChangeLanguage from 'containers/Auth/ChangeLang';
import { logout } from "store/auth";
import { useDispatch, useSelector } from "react-redux";

export const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultLang = useSelector(state => state.setLang.lang);

  const handleLogOut = () => {
    dispatch(logout());
    history.push('/');
  }

  return (
    <Container>
      <Logo>
        <Link to='/'><img src={express_logo} alt='logo' /></Link>
      </Logo>
      <BaseSection>
        <Links>
          <div className='logOutContainer'>
            <ChangeLanguage defaultLang={defaultLang} />
            {/*<LinkBtn text='User roles' img={userRoles} href='/user-roles'/>*/}
            <LinkBtn text='Settings' img={setting} href='/setting' />
            <div className='userWrap'>
              <span className='userName'>Ilya Davidoff</span>
              <span className='userStatus'>Apple INC, Admin</span>
            </div>
            <LinkBtn
              handleClick={handleLogOut}
              text='Log Out'
              img={logOut}
              backcolor
            />
          </div>
        </Links>
      </BaseSection>
    </Container>
  )
};

const Container = styled.nav`
  height: 90px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Logo = styled.div`
  position: absolute;
  left: 20px;
  display: flex;
  align-items: center;
`
const BaseSection = styled(Row)`
  width: 1090px;
  height: 100%;
  margin-left: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 1300px) {
    padding-left: 60px;
  }
  @media (max-width: 1200px) {
    padding-left: 0px;
  }
`

const Links = styled(Col)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .userWrap {
    display: flex;
    flex-direction: column;
    padding: 0 30px;

    .userName {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.03em;
      color: #231F20;
    }

    .userStatus {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.03em;
      color: #616173;
    }
  }

  .logOutContainer {
    display: flex;
    flex-direction: row;
  }

  .dvider {
    border: 1px solid #E6E6E6;
    height: 50px;
  }

`
