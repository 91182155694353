import { Loading } from 'components';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, OtpSms } from 'components';
import OtpCompanyList from '../CompanyList/OtpCompanyList';
import { GetCompanyList } from 'store/auth';

const OtpMessage = () => {
  //loading
  const allLoading = useSelector((state) => state.loadings);
  //global states
  const userInfo = useSelector((state) => state.otpVerification.userInfo);

  const { companyList } = useSelector((state) => state.getCompanyList);

  //popup
  const [modalVisible, setModalVisible] = useState(false);
  //translation
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo && userInfo?.userStatus === 'ACTIVE') {
      dispatch(GetCompanyList(userInfo?.accessToken?.accessToken));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('tokenInfo', userInfo?.accessToken?.accessToken);
      localStorage.setItem('refreshToken', userInfo?.refreshToken?.token);
      localStorage.setItem(
        'refreshTokenExpiryDate',
        userInfo?.refreshToken?.expiryDate
      );
      localStorage.setItem(
        'remainingTimeInSeconds',
        userInfo?.refreshToken?.remainingTimeInSeconds
      );
      if (userInfo.userStatus === 'LOGIN_WAITING') {
        history.push('/login/set-password');
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (companyList && userInfo?.userStatus === 'ACTIVE') {
      // localStorage.setItem('infoModal',true)
      if (companyList?.length <= 1) {
        setModalVisible(false);
        localStorage.setItem('voen', companyList[0].voen);
        const replacedUrl = window.location.href.replace('/login', '/');
        window.location.href = replacedUrl;
        // localStorage.setItem('kobModal',true)
      } else {
        setModalVisible(true);
      }
    }
  }, [companyList, userInfo]);

  const props = {
    modalVisible,
    setModalVisible,
  };

  const onClose = () => {
    localStorage.removeItem('loginStatus');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiryDate');
    localStorage.removeItem('tokenInfo');
    setModalVisible(false);
    window.location.reload();
  };
  return (
    <Fragment>
      <Loading loading={allLoading?.isLoading} />
      <OtpSms type="otpLogin" />
      <Modal
        open={modalVisible}
        cancel={onClose}
        title={t('chooseCompany')}
        headerColor="#fff"
        width="524px"
      >
        <OtpCompanyList {...props} />
      </Modal>
    </Fragment>
  );
};
export default OtpMessage;
