import React, { createContext, useState } from 'react';
import { ThemeProvider as Theme } from "styled-components";
import { lightTheme, darkTheme } from '../theme/themeBuilder'

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
    const [themeName, setThemeName] = useState("light")

    return (
        <ThemeContext.Provider value={{ themeName, setThemeName }}>
            <Theme theme={themeName === "light" ? lightTheme : darkTheme}>
                {children}
            </Theme>
        </ThemeContext.Provider>
    )
}

export default ThemeProvider;