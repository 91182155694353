import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

export const StyledOption = ({children}) => {
  return (
    <Tooltip placement="bottom" title={children} >
         <StyledOptionContent>{children}</StyledOptionContent>
    </Tooltip>
   
  )
}

const StyledOptionContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`