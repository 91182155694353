import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";

export const Status = ({  status, creator, icon, color }) => {
  return (
    <Tooltip title={creator} placement="bottom">
      <StyledCards {...{color}}>
        <StyledCardsContent>
          <span>
            {icon && <img src={icon} alt={icon} />} {status}
          </span>
        </StyledCardsContent>
      </StyledCards>
    </Tooltip>
  );
};

const StyledCardsContent = styled.div`
  display: flex;
`;
const StyledCards = styled.div`
  span {
    display: flex;
    padding: 8.3px 12.3px;
    border-radius: 6px;
    ${(props) => props.color};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  img {
    margin-right: 6px;
  }
`;