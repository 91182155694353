import React from "react";
import { Select } from "components/Select";
import { Pagination as CstPagination } from "antd";
import { arrow_right } from "static/svg";
import styled from "styled-components";
// import { useTranslation } from "react-i18next";
export const Pagination = ({
  currentPage,
  onChange,
  total,
  isBackground,
  onClick,
  text,
  selectOnChange,
  data,
  selectedValue,
  ...rest
}) => {
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <a
          // href="#/"
          disabled={currentPage === 1}
          // onClick={decrement}
        >
          <img className="prev"
            src={arrow_right}
            alt="prev"
          />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a
          // href="#/"
          disabled={currentPage === 10}
          // onClick={increment}
        >
          <img
            src={arrow_right}
            alt="next"
          />
        </a>
      );
    }
    return  <StyledNextPrev>{originalElement}</StyledNextPrev>;
  }
  // const {t} = useTranslation();x``
  return (
    <StyledPaginantion isBg={isBackground}  className="pagination">
            <StyledLeft>
        <CstPagination
          total={total}
          onChange={onChange}
          itemRender={itemRender}
          current={currentPage}
          {...rest}
        />
      </StyledLeft>
      <StyledRight onClick={onClick}>
        {text || (
          data && <Select
            name="paginationSelect"
            data={data}
            allowClear={false}
            keys={["show"]}
            value={selectedValue}
            onChange={selectOnChange}
          />
        )} 
      </StyledRight>
          </StyledPaginantion>
  );
};

const center = `
  display: flex;
  align-items: center;`;

// Styled Paginantion
const StyledPaginantion = styled.div`
  border-radius: 0 0 16px 16px;
  justify-content: space-between;
  ${center}
  width: 100%;
  padding: 20px 36px 20px 0;
  background-color: ${(props) => (props.isBg ? "#fff" : "transparent")};
  border-top: 1px solid #e4e4e7;
  .prev{
    transform: rotate(180deg);
  }
  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow{
    display: none;
  }
  .ant-pagination-item-ellipsis{
    display: flex !important;
    justify-content: center;
  }
`;
const StyledLeft = styled.div`
  display: flex;
  overflow: auto;
`;
const StyledRight = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #555555;
  cursor: pointer;
  .ant-select-arrow {
    left: auto;
    right: 10px;
  }
`;

const StyledNextPrev = styled.div`
  display: flex;
  a {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #fafafa;
    margin: 0px 4px;
    justify-content: center;
    ${center}
  }
`;
