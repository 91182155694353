import { paymentType, transferAndPaymentType } from "constant/consts";

export const getTransferAndPaymentType = (type) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case paymentType.AZIPS:
    case paymentType.XOHKS:
      return transferAndPaymentType.INSIDE_COUNTRY;
    case paymentType.INTERNAL:
      return transferAndPaymentType.WITHIN_BANK;
    case paymentType.BETWEEN:
      return transferAndPaymentType.BETWEEN_MY_ACCOUNT;
    case paymentType.ANYPAY:
      return transferAndPaymentType.ANYPAY;
    case paymentType.BUDGET:
      return transferAndPaymentType.BUDGET;
    case paymentType.VAT:
      return transferAndPaymentType.VAT;
    case paymentType.SALARY:
      return transferAndPaymentType.SALARY;
    case paymentType["SWIFT.MT103"]:
    case paymentType["SWIFT.MT103.RU"]:
      return transferAndPaymentType["SWIFT.MT103"];
    case paymentType["CCY.BUY"]:
        return transferAndPaymentType["CCY.BUY"];
    case paymentType["CCY.SELL"]:
          return transferAndPaymentType["CCY.SELL"];
  }
};
