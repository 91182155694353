import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ChartComponent = ({chartColor}) => {
    const [state, setState] = useState({

        series: [{
            name: "Item",
            data: [40, 80, 200, 100]
        }],
        options: {
            chart: {
                zoom: {
                    enabled: false
                },
                dropShadow: {     //kölgə effekti
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {     //kiçik yuxardakı menu gorunməyəcək
                    show: false
                  }
            },

            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2,         //xəttin qalınlığı
                curve: 'straight'
            },
            title: {
                text: '',
                align: 'left'
            },
            colors:[chartColor],         //line rəngi
            fill: {                     //gradient yaradır
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  inverseColors: false,
                  opacityFrom: 0.5,
                  opacityTo: 0,
                  stops: [0, 90, 100]
                },
              },
            markers: {      //xətdin üzərindəki yumrular
                size: 2,
                colors: ["white"],
                strokeColors: chartColor,
                strokeWidth: 2,
                hover: {
                  size: 4,
                }
              },
            grid: {
                borderColor: '#E0E7FF',
                row: {
                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },

            xaxis: {
                categories: ['Oct', 'Nov', 'Dec', 'Jan'],
            },
            yaxis:{
                min:0,
                max:300,
                tickAmount: 3,
            }
        },


    })

    return (
        <ReactApexChart options={state.options} series={state.series} type="area"  />
    )
}

export default ChartComponent;