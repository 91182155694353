import { Col, Form } from 'antd';
import { CustomInput, ErrorMessage, FormItem, GroupButtons } from 'components';
import { aliasTypeCabinet } from 'constant';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  customersAddAliases,
  customersAliasesVerification,
  getCustomersData,
} from 'store/anipayCabinet/api_action';
import { mobileRules } from 'utils/checkValidations';
import OtpModal from './aliasVerification/OtpModal';
import { mobileNumberParser } from 'utils/shortFunctions';
import { aliasTypeEnum } from 'constant/consts';

const AddAliasModal = ({
  aliasCkeck,
  setAliasCkeck,
  form,
  setModalVisible,
  changeFormValues,
  setChangeFormValues,
}) => {
  const { t } = useTranslation();
  const { customerCheckId } = useSelector((state) => state.anipayCabinet);

  const [aliasValue, setAliasValue] = useState();
  const [aliasTypes, setAliasType] = useState();

  const dispatch = useDispatch();
  const onValuesChange = (changeValue, { aliasType }) => {
    setChangeFormValues(aliasType);
  };

  const onFinish = (values) => {
    const { aliasType, alias } = values;
    const customerId = customerCheckId.recordId;
    const aliasTypeName = aliasTypeCabinet.find(
      (item) => item.id === aliasType
    )?.name;

    dispatch(
      customersAddAliases({
        customerId,
        type: aliasTypeName,
        value:
          aliasTypeName === aliasTypeEnum.MOBILE ? mobileNumberParser(alias) : alias,
        setModalFunc: () => setAliasCkeck(true),
      })
    );

    setAliasValue(alias);
    setAliasType(aliasType);
  };

  const resendOtp = (type, value) => {
    const customerId = customerCheckId.recordId;
    dispatch(
      customersAddAliases({
        customerId,
        type: type,
        value: type === aliasTypeEnum.MOBILE ? mobileNumberParser(value) : value,
        setModalFunc: () => setAliasCkeck(true),
      })
    );
  };

  const verifyOtp = async (type, code, value) => {
    const customerId = customerCheckId.recordId;

    const body = {
      type: type,
      value: type === aliasTypeEnum.MOBILE ? mobileNumberParser(value) : value,
      confirmCode: code,
    };

    await dispatch(
      customersAliasesVerification({
        body,
        customerId,
      })
    );

    await dispatch(getCustomersData(customerId));
    await form.resetFields();
    await setModalVisible(false);
    await setAliasCkeck(false);
  };

  return aliasCkeck ? (
    <OtpModal
      value={aliasValue}
      type={aliasTypes}
      resendOtp={resendOtp}
      verifyOtp={verifyOtp}
    />
  ) : (
    <Form
      form={form}
      name="aniPay"
      layout="vertical"
      initialValues={{ remember: true, transferToOwnAccount: true }}
      scrollToFirstError={true}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
    >
      <Col span={24} className="general">
        <FormItem
          name="aliasType"
          autoheight
          rules={[
            {
              required: true,
              message: <ErrorMessage text={'mandatoryField'} />,
            },
          ]}
        >
          <CustomInput
            select
            data={aliasTypeCabinet?.map(({ id, name }) => {
              return {
                id,
                name: t(name),
              };
            })}
            label={t('Alias type')}
            onChange={(e) => {
              form.setFieldsValue({
                alias: undefined,
              });
            }}
          />
        </FormItem>
      </Col>
      <Col span={24} className="general">
        <FormItem
          name="alias"
          autoheight
          rules={
            changeFormValues === 1
              ? [mobileRules]
              : [
                  {
                    required: true,
                    message: <ErrorMessage text={'mandatoryField'} />,
                  },
                ]
          }
        >
          {changeFormValues === 1 ? (
            <CustomInput inputmask label={t('mobile')} />
          ) : changeFormValues === 2 ? (
            <CustomInput label={t('mail')} maxLength={30} />
          ) : (
            <CustomInput label={t('select')} disabled />
          )}
        </FormItem>
      </Col>

      <Col span={24}>
        <GroupButtons firstProps={false} secondProps={{ text: 'Save' }} />
      </Col>
    </Form>
  );
};

export default AddAliasModal;
