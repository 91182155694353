import React from 'react';
import { FormItem } from 'components';
import { Col, Spin } from 'antd';
import { upload } from 'static/svg';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Dragger from 'antd/lib/upload/Dragger';

import { SalaryUploadSlicer } from 'store/salary';
import Files from 'shared/Components/UploadFiles';
import { responsive } from 'utils/responsiveUI';
import { useTranslation } from 'react-i18next';

export const Upload = ({
  form,
  span = 12,
  onChange,

  salaryuploadStatus,
  setSalaryUploadStatus,
  selectedFile,
  setSelectedFile,
  multiple,

  handleDownloadUploadFile,
  acceptFile,
  fileList,
  setFileList,

  fileUploadValue,
  SetFileUploadValue,
  sizeStatus,
  setSizeStatus,
  uploadDataBatchId,
  salaryDownloadCheck,
  setSalaryDownloadCheck,
  setUploadData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allLoading = useSelector((state) => state.loadings);
  const resetUpload = SalaryUploadSlicer.actions.reset();

  const singleUpload = true;

  const handleDelete = (file) => {
    setSizeStatus(false);
    setSalaryUploadStatus(false);
    dispatch(resetUpload);
    setSelectedFile();
    setFileList([]);
    form.setFieldsValue({
      files: [],
    });
    setUploadData(null);
  };

  const dragProps = {
    name: 'file',
    listType: 'text',
    maxCount: 4,
    accept: acceptFile,
    progress: { strokeWidth: 2, showInfo: true },
    onChange: onChange,
    onRemove: handleDelete,

    beforeUpload: (file) => {
      setSalaryDownloadCheck(false);
      if (multiple) {
        setFileList((prevstate) => [...prevstate, file]);
      } else {
        setFileList([file]);
      }
      const isLt2M = file.size / 1024 / 1024 <= 5;

      if (!isLt2M) {
        setSizeStatus(true);
      }

      return false;
    },

    fileList,
  };

  return (
    <Col {...{ span }}>
      <FormItem
        name="files"
        autoheight
        rules={[
          {
            required: true,

            validator: (rule, value, callback) => {
              if (value?.length !== 0 && value) {
                SetFileUploadValue(false);
                // if(sizeStatus){
                //   return Promise.reject(<ErrorMessage text="fileLimitSize" />);

                // }
                return Promise.resolve();
              }

              callback();
              SetFileUploadValue(true);
              // return Promise.reject(<ErrorMessage text="uploadFileMandatory" />);
            },
          },
        ]}
      >
        <Spin spinning={!allLoading}>
          <StyledContainer
            {...{ salaryuploadStatus, fileList, fileUploadValue, sizeStatus }}
          >
            <StyledFileName salaryuploadStatus={salaryuploadStatus}>
              <Styledaa>
                <Files
                  col={24}
                  fileList={fileList}
                  handleDelete={handleDelete}
                  handleDownloadUploadFile={handleDownloadUploadFile}
                  salaryuploadStatus={salaryuploadStatus}
                  singleUpload={singleUpload}
                  uploadDataBatchId={uploadDataBatchId}
                  salaryDownloadCheck={salaryDownloadCheck}
                />
              </Styledaa>
            </StyledFileName>
            <Dragger {...dragProps} showUploadList={true}>
              {!salaryuploadStatus ? (
                <StyledUploadContent
                  selectedFile={selectedFile}
                  // salaryUpload={salaryUpload}
                  fileList={fileList}
                >
                  <StyledUpload>
                    <img src={upload} alt="" />
                    <span className="uploadTitle">
                      {t('dropFileHereOrClickToUpload')}
                    </span>
                    <span className="uploadTitleSub">
                      {selectedFile ? null : t('supportedFormat')}
                    </span>
                  </StyledUpload>
                </StyledUploadContent>
              ) : null}
            </Dragger>
          </StyledContainer>
        </Spin>
      </FormItem>
    </Col>
  );
};

const Styledaa = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  .general {
    background-color: yellow;
  }
`;
const StyledUploadContent = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ selectedFile }) => (selectedFile ? '#f8f8f8' : '#fff')};
  margin-bottom: ${({ selectedFile }) => (selectedFile ? '0px' : '0')};
  margin-top: ${({ selectedFile }) => (selectedFile ? '20px' : '0')};
  border-radius: 6px;
  height: 100px;
  @media ${responsive.laptop} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const StyledUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 20px;
  background-color: transparent;
  height: 100%;

  span:first-child {
    width: 100%;
  }
  .uploadTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #555555;
    margin-top: 5px;
    @media ${responsive.laptop} {
      font-size: 10px;
    }
  }
  .uploadTitleSub {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #8e8e8e;
    margin-top: 5px;
  }
`;

const StyledFileName = styled.div`
  width: 100%;
  z-index: 100;
  margin-top: 0;
`;
const StyledContainer = styled.div`
  position: relative;
  background-color: #fff;
  padding: 0 25px;
  width: 100%;
  background-color: transparent;
  display: ${({ salaryuploadStatus }) => (salaryuploadStatus ? 'flex' : 'block')};
  align-items: center;
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: ${({ fileList }) => (fileList.length !== 0 ? 'end' : 'center')};
  }

  .ant-upload.ant-upload-btn {
    padding: 15px 0;
  }
  .ant-upload.ant-upload-drag {
    background-color: transparent;
    height: 100%;
    &:hover {
      box-sizing: border-box;
      border-color: grey;
      border-color: ${({ salaryuploadStatus }) =>
        salaryuploadStatus
          ? 'green'
          : salaryuploadStatus === undefined
          ? 'grey'
          : 'red'};
    }
    &:focus {
      box-sizing: border-box;
    }
  }
  .ant-upload-list.ant-upload-list-picture {
    max-height: 160px;
    overflow: auto;
    padding: 0 20px 20px 20px;
    border: none !important;
    .ant-upload-list-picture.ant-upload-list-item {
      border: none !important;
    }
  }
  .ant-upload-list-item {
    display: none !important;
  }
`;
