import { Col, Collapse, Row } from 'antd';
import {
  DeleteModal,
  Modal,
  ResponsiveComponent,
  SingleButton,
  center,
} from 'components';
import { aliasTypeEnum } from 'constant/consts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  AniPayEMAIL,
  AniPayMOBILE,
  AniPayTIN,
  plusBlack,
  star,
  star_full,
  trash,
} from 'static/svg';
import {
  customersRemoveAlias,
  getCustomersData,
} from 'store/anipayCabinet/api_action';
import styled from 'styled-components';
import { responsive } from 'utils/responsiveUI';

const { Panel } = Collapse;

const Collapses = ({
  type,
  value,
  accounts,
  handleRemoveAccount,
  handleFovarite,
  setModalVisibility,
  collapseId,
}) => {
  const [modalState, setModalState] = useState({
    visible: false,
    isSuccess: false,
    title: null,
    secondTitle: null,
    width: '406px',
    component: null,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customerCheckId } = useSelector((state) => state.anipayCabinet);

  const openModal = (e, collapseId) => {
    e.stopPropagation();
    setModalVisibility({ [collapseId]: true });
  };

  const handleRemoveCollapse = (e, collapseId, aliastype) => {
    const customerId = customerCheckId.recordId;
    e.stopPropagation();
    setModalState({
      visible: true,
      title: t('Delete alias'),
      component: (
        <DeleteModal
          cancelOnClick={() => setModalState({ visible: false })}
          mainOnClick={async () => {
            await dispatch(customersRemoveAlias({ aliasId: collapseId }));
            await dispatch(getCustomersData(customerId));
            setModalVisibility({});

            setModalState({ visible: false });
          }}
          title={
            aliastype === aliasTypeEnum.EMAIL ? t('deleteEmail') : t('deleteMobile')
          }
          noText={t('No')}
          yesText={t('Yes')}
        />
      ),
    });
  };

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} lg={22}>
          <StyledCollapse>
            <Collapse
              expandIconPosition={'right'}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MdKeyboardArrowDown size={40} />
                ) : (
                  <MdKeyboardArrowRight size={40} />
                )
              }
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <StyledContainer>
                    <StyledWrapper>
                      <StyledLeftContent>
                        <img
                          src={
                            type === aliasTypeEnum.EMAIL
                              ? AniPayEMAIL
                              : type === aliasTypeEnum.MOBILE
                              ? AniPayMOBILE
                              : AniPayTIN
                          }
                          alt="icon"
                        />
                        <StyledText>
                          <h3>{t(type)}</h3>
                          <p>{value}</p>
                        </StyledText>
                      </StyledLeftContent>
                    </StyledWrapper>
                    <StyledWrapper>
                      <div style={{ marginTop: '10px' }}>
                        <SingleButton
                          isBorder
                          text={'Add account'}
                          icon={plusBlack}
                          onClick={(e) => openModal(e, collapseId)}
                        />
                      </div>
                      <ResponsiveComponent isMobile>
                        {type === aliasTypeEnum.TIN ? (
                          ''
                        ) : (
                          <StyledCollapseTrash
                            onClick={(e) =>
                              handleRemoveCollapse(e, collapseId, type)
                            }
                          >
                            <img src={trash} alt="filterIcon" />
                          </StyledCollapseTrash>
                        )}
                      </ResponsiveComponent>
                    </StyledWrapper>
                  </StyledContainer>
                }
                key="1"
              >
                <StyledCollapseBody>
                  {accounts.length > 0 ? (
                    accounts.map(({ iban, recordId, isDefault }) => {
                      return (
                        <StyledRow>
                          <div>
                            <h3>{iban}</h3>
                          </div>
                          <div className="actions">
                            <div
                              onClick={(e) =>
                                isDefault
                                  ? e.preventDefault()
                                  : handleFovarite(recordId, Number(collapseId))
                              }
                            >
                              <img src={isDefault ? star_full : star} alt="star" />
                            </div>
                            <div onClick={() => handleRemoveAccount(recordId)}>
                              <img src={trash} alt="trash" />
                            </div>
                          </div>
                        </StyledRow>
                      );
                    })
                  ) : (
                    <EmptySection>
                      <p>{t("You don't have an account")}</p>
                    </EmptySection>
                  )}
                </StyledCollapseBody>
              </Panel>
            </Collapse>
          </StyledCollapse>
        </Col>

        <Col span={1}>
          {type === aliasTypeEnum.TIN ? (
            ''
          ) : (
            <ResponsiveComponent>
              <StyledCollapseTrash
                onClick={(e) => handleRemoveCollapse(e, collapseId, type)}
              >
                <img src={trash} alt="filterIcon" />
              </StyledCollapseTrash>
            </ResponsiveComponent>
          )}
        </Col>
      </Row>

      <Modal
        open={modalState.visible}
        cancel={() => setModalState({ visible: false })}
        title={modalState.title}
        secondTitle={modalState.secondTitle}
        width={modalState.width}
        isSuccess={modalState.isSuccess}
      >
        {modalState.component}
      </Modal>
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${responsive.laptop} {
    flex-direction: column;
    align-items: start;
  }
`;
const StyledCollapse = styled.div`
  margin-bottom: 20px;
`;

const StyledWrapper = styled.div`
  margin: 0 20px;
  @media ${responsive.laptop} {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const StyledLeftContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const StyledText = styled.div`
  h3 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #8e8e8e;
  }
  p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    color: #383838;
  }
`;

const StyledCollapseBody = styled.div``;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  .actions {
    ${center}
    cursor: pointer;
    gap: 10px;
  }
`;

const StyledCollapseTrash = styled.div`
  cursor: pointer;
  border: 1px solid #383838;
  border-radius: 8px;
  padding: 10px 20px;
  ${center}
  margin-top: 10px;
`;

const EmptySection = styled.div`
  padding: 30px;
  ${center}
  p {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 0;
  }
`;
export default Collapses;
