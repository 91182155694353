import React, { useEffect, useState } from 'react';
import Collapses from '../component/accordian';
import { Form, Modal } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AddAccountModal from '../component/addAccountModal';
import { DeleteModal, Modal as ModalComponent } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  customersAccountFovarite,
  customersRemoveAccount,
  getCustomersData,
} from 'store/anipayCabinet/api_action';
import { GetAccounts } from 'store/accounts';

const Registered = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { customersData, customerCheckId } = useSelector(
    (state) => state.anipayCabinet
  );

  const [modalVisibility, setModalVisibility] = useState({});
  const [modalState, setModalState] = useState({
    visible: false,
    isSuccess: false,
    title: null,
    secondTitle: null,
    width: '406px',
    component: null,
  });
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(GetAccounts({}));
  }, [dispatch]);

  const handleRemoveAccount = (iban) => {
    if (iban) {
      setModalState({
        visible: true,
        title: t('Delete account'),
        component: (
          <DeleteModal
            cancelOnClick={() => setModalState({ visible: false })}
            mainOnClick={async () => {
              await dispatch(customersRemoveAccount({ accountId: iban }));
              setModalState({ visible: false });

              const customerId = customerCheckId.recordId;

              await dispatch(getCustomersData(customerId));
            }}
            title={t('Are you sure to account')}
            noText={t('No')}
            yesText={t('Yes')}
          />
        ),
      });
    }
  };

  const handleFovarite = async (accountId, collapseId) => {
    await dispatch(customersAccountFovarite({ accountId, aliasId: collapseId }));
    const customerId = customerCheckId.recordId;
    await dispatch(getCustomersData(customerId));
  };

  const closeModal = () => {
    setModalVisibility({});
    form.resetFields();
  };

  return (
    <>
      {customersData?.map(({ type, value, accounts, recordId }, index) => (
        <div key={recordId}>
          <Collapses
            type={type}
            value={value}
            accounts={accounts}
            handleRemoveAccount={handleRemoveAccount}
            handleFovarite={handleFovarite}
            setModalVisibility={setModalVisibility}
            collapseId={recordId}
            collapseIndex={index}
          />

          <StyledModal
            visible={modalVisibility[recordId]}
            title={t('Add account')}
            onCancel={closeModal}
            centered={true}
            footer={false}
          >
            <AddAccountModal
              collapseId={recordId}
              setModalVisibility={setModalVisibility}
              form={form}
              accountsListCollapse={accounts}
            />
          </StyledModal>
        </div>
      ))}

      <ModalComponent
        open={modalState.visible}
        cancel={() => setModalState({ visible: false })}
        title={modalState.title}
        secondTitle={modalState.secondTitle}
        width={modalState.width}
        isSuccess={modalState.isSuccess}
      >
        {modalState.component}
      </ModalComponent>
    </>
  );
};

export default Registered;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 30px;
  }
`;
