import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "client";

const voen = localStorage.getItem("voen")
//ALL Accounts - DONE
export const GetAccounts = createAsyncThunk(
  "GetAccounts",
  async (accountInfo, thunkAPI) => {
    const { page = 0, size = 3000 } = accountInfo;
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/list/${voen}?page=${page}&size=${size}`,
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: false,
        token: false,
      })

      //Bütün accountlar içərisindən AZN accountları seçirik
      const aznAccounts = await data.data.content?.filter(item => item.currency === 944)
      const modifiedData = await data.data.content.map(item => {
        return {
          ...item,
          key: Math.random(),
        }
      })
      return { data, modifiedData, aznAccounts };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

// Only Payments Account
export const GetAccountsPayments = createAsyncThunk(
  "GetAccounts",
  async (accountInfo, thunkAPI) => {
    const { page = 0, size = 3000 } = accountInfo;
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/payment/list/${voen}?page=${page}&size=${size}`,
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: false,
        token: false,
      })

      //Bütün accountlar içərisindən AZN accountları seçirik
      const aznAccounts = await data.data.content?.filter(item => item.currency === 944)
      const modifiedData = await data.data.content.map(item => {
        return {
          ...item,
          key: Math.random(),
        }
      })
      return { data, modifiedData, aznAccounts };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

//GET ACCOUNT NAME- DONE
export const GetAccountDetails = createAsyncThunk(
  "GetAccountDetails",
  async (accountNo, thunkAPI) => {
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/${accountNo}`,
        showSuccessToast: false,
        showErrorToast: true,
        succesMessage: "Get account info",
        token: false,

      })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)

//Rename account - DONE
export const RenameAccount = createAsyncThunk(
  "RenameAccount",
  async (accountInfo, thunkAPI) => {
    const { setModalFunc, accountNo, accountTitle } = accountInfo;
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/title`,
        data: { accountNo, accountTitle },
        method: 'POST',
        showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "Account name was changed successfully",
        token: false,
        successCallback: () => setModalFunc({ visible: false }),
      })
      return data
    } catch (err) {
      console.log(err)
      return thunkAPI.rejectWithValue(err.response)
    }
  }
);

//Statement account - DONE
export const StatementAccount = createAsyncThunk(
  "StatementAccount",
  async (accountInfo, thunkAPI) => {
    try {
      return await client({
        url: `/account-ms/v1/accounts/statement`,
        data: JSON.stringify(accountInfo),
        // showSuccessToast: true,
        showErrorToast: true,
        succesMessage: "Get statement account information was successfully",
        token: false,
      })
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
);

//Get Requisites
export const GetRequisites = createAsyncThunk(
  "GetRequisites",
  async (accountNo, thunkAPI) => {
    try {
      const data = await client({
        url: `/account-ms/v1/accounts/requisites/${accountNo}`,
        showErrorToast: true,
        token: false,

      })
      return data
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response)
    }
  }
)


