import React, { useState, useEffect, useRef } from 'react';
import { SubMainButton } from 'components';
import OtpInput from 'react-otp-input';
import { responsive } from 'utils/responsiveUI';
import styled from 'styled-components';
import { renderTimer } from 'utils/shortFunctions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const OtpModal = ({ otpPhoneNumber, resendOtp, verifyOtp }) => {
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(180);
  const tick = useRef();
  const { t } = useTranslation();

  const { customersRegistrId } = useSelector((state) => state.anipayCabinet);

  const handleSend = (otp) => {
    setCode('');
    verifyOtp(otp, customersRegistrId?.recordId, otpPhoneNumber);
  };

  const handleChange = (code) => {
    setCode(code);
    if ([code.split('')][0].length === 6) {
      handleSend(code);
    }
  };

  const handleResend = () => {
    setTimer(180); // 180 sec - 3 minutes
    resendOtp();
  };

  useEffect(() => {
    if (timer !== 0) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [timer]);

  return (
    <OtpInputContainer>
      <StyeldText>
        <p>
          {t('The verification code has been sent to {{otpPhoneNumber}}', {
            otpPhoneNumber,
          })}
        </p>
      </StyeldText>
      <OtpInput
        value={code}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{ width: '15px' }}></span>}
        isInputNum
        shouldAutoFocus={true}
        inputStyle={{
          border: '1px solid #D0D0CE',
          borderRadius: '8px',
          width: '100%',
          height: '46px',
          padding: '2px 8px',
          fontSize: '30px',
          color: '#000',
          fontWeight: '500',
          caretColor: '#FFA300',
          fontFamily: 'Proxima Nova',
        }}
        focusStyle={{
          border: '1px solid #FFA300',
          outline: '1px solid #FFA300',
        }}
      />
      <StyledTimer>
        {renderTimer(timer) !== '00:00' ? (
          <p>
            {t('timerVCode')} <span>{renderTimer(timer)}</span>
          </p>
        ) : (
          <p>{t('Didn’t receive an OTP?')}</p>
        )}
      </StyledTimer>
      <StyledResend>
        <SubMainButton
          disabled={renderTimer(timer) !== '00:00'}
          onClick={handleResend}
          text={t('resend')}
        />
      </StyledResend>
    </OtpInputContainer>
  );
};

export default OtpModal;

const OtpInputContainer = styled.div`
  display: block;
  padding: 25px 25px 0;
  width: 400px;
  @media ${responsive.laptop} {
    margin-bottom: 90px;
  }

  div {
    justify-content: end;

    &:nth-child(3) {
      margin-right: 35px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 100%;
        width: 20px;
        height: 2px;
        background: #d0d0ce;
      }
    }

    &:nth-child(6) {
      display: block !important;

      input {
        width: 83% !important;
      }
    }

    @media ${responsive.laptop} {
      input {
        /* font-size: 16px !important; */
        height: 50px !important;
      }
    }
  }

  h5 {
    /* margin-top: 1em; */
    font-weight: 400;
    color: #63666a;
    font-size: 16px;

    span {
      font-weight: 600;
      color: #ffa300;
    }
  }
`;
const StyeldText = styled.div`
  h4 {
    font-weight: 600;
    font-size: 24px;
    color: #1c1c1c;
  }

  p {
    font-weight: 400;
    color: #63666a;
    font-size: 16px;
    margin-bottom: 25px;
    span {
      font-weight: 600;
    }
  }
`;
const StyledResend = styled.div`
  margin: 0px 0 30px 0px;
  width: 100%;
  display: flex;
  justify-content: end;

  button {
    width: 100%;
  }
`;
const StyledTimer = styled.span`
  display: flex;
  align-items: center;
  span,
  p {
    width: 100%;
    font-size: 16px;
    color: #63666a;
  }

  span {
    font-weight: 600;
    color: #ffa300;
  }

  p {
    font-weight: 400;
    margin-top: 20px;
  }

  @media ${responsive.laptop} {
    span,
    p {
      font-size: 14px;
    }
  }
`;
