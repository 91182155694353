import { SubMainButton } from "components/Button";
import { useTranslation } from "react-i18next";
import React from "react";
import { Status } from "components";
import {
  accounter_black,
  bank_black,
  creator_black,
  director_black,
} from "static/svg";

import styled from "styled-components";
import { Col, Row } from "antd";
import { responsive } from "utils/responsiveUI";

export const StatusInfo = ({ cancelOnClick, statuses  }) => {
  const { t } = useTranslation();
  return (
   <StayledWrapper>
     <Row gutter={[24]}>
 
       
        
 <Col  xs={24} xl={12}>
 <h3>{t("positions")}</h3>
     <StyledCardsContent>
    
       <Status icon={creator_black} alt="creator_black" status={t("operator")} />
       <Status icon={accounter_black} alt="accounter_black" status={t("accountant")} />
       <Status icon={director_black} alt="director_black" status={t("Director")} />
       <Status icon={bank_black} alt="bank_black" status={t("Bank")} />
     </StyledCardsContent>
     </Col>
     
   
     <Col  xs={24} xl={12}>
     <h3>{t("operations")}</h3>
     <StatusMeansContent>
    
       {statuses.map((item) => {
         return item.name;
       })}
     </StatusMeansContent>
     </Col>
    
   


 <Col  xs={24}>
   <SubMainButton notCenter onClick={cancelOnClick} text={"Close"} isActivWhite />
 </Col>

</Row>
   </StayledWrapper>
  );
};

const textStyle = `
  margin:  10px 0;
  padding: 9px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const StayledWrapper =styled.div`
  padding: 0 32px 32px;
  h3{
    ${textStyle};
  }
`
const StyledCardsContent = styled.div`
    margin-top: -10px;

  span {
    ${textStyle};
    background: #f2f2f2;
    color: #393939;
  }
  @media ${responsive.laptop} {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    row-gap: 0px;

  }


  
`;

const StatusMeansContent = styled.div`
  span {
    ${textStyle}
  }
`;

