import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NotFound } from 'components'
import Login from 'containers/Auth';
import SuccessPassword from 'containers/Auth/ForgotPassword/SuccessPassword';
import SetPassword from "containers/Auth/SetPassword";
import CheckEmailSuccess from 'containers/Auth/ForgotPassword/CheckEmailSuccess';
import SetNewPassword from "./containers/Auth/ForgotPassword/SetNewPassword";
import EmailConfirmation from "./containers/Auth/EmailConfirmation";
import SetNewPasswordForgot from 'containers/Auth/ForgotPassword/SetNewPasswordForgot';

const ForgotPassword = lazy(() =>
  import('containers/Auth/ForgotPassword')
);

const UnAuthorizedApp = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route
        exact
        path="/login/forgot-password"
      >
        <ForgotPassword />
      </Route>
      <Route
        exact
        path="/forget-password/set-new-password"
      >
        <SetNewPassword />
      </Route>

      <Route
        exact
        path="/forget-password/set-newpassword"
      >
        <SetNewPasswordForgot />
      </Route>

      <Route
        path="/forgetpassword/success"
      >
        <SuccessPassword text="Password was successfully changed" />
      </Route>
      <Route
        path="/login/success"
      >
        <SuccessPassword text="Your email is verified" />
      </Route>

      <Route
        path="/set-new-password/success"
      >
        <CheckEmailSuccess />
      </Route>

      <Route
        exact
        path="/login/set-password"
      >
        <SetPassword />
      </Route>
      <Route
        exact
        path="/login/email-confirmation"
      >
        <EmailConfirmation />
      </Route>
      {/* Accessing URL Parameters*/}
      <Route
        path="/login/set-password/:type"
      >
        <SetPassword />
      </Route>

      <Redirect from="*" to="/login" />
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default UnAuthorizedApp;
