import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFound, Loading, PermissionsRouter } from 'components';
import { ScrollToTop } from 'components/ScrollTo';
import AniPayCabinet from 'containers/AniPayCabinet';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
// Dashboard
const Dashboard = lazy(() =>
  lazyRetry(() => import('containers/Dashboard'), 'Dashboard'),
);

// Users
const Users = lazy(() => lazyRetry(() => import('containers/Users'), 'Users'));

const UserAdd = lazy(() =>
  lazyRetry(() => import('containers/Users/UserAdd'), 'UserAdd'),
);

const UserEdit = lazy(() =>
  lazyRetry(() => import('containers/Users/UserEdit'), 'UserEdit'),
);

const UserDetails = lazy(() =>
  lazyRetry(() => import('containers/Users/DetailsPage'), 'UserDetails'),
);

// Transfers
const TransferWithinBank = lazy(() =>
  lazyRetry(
    () => import('containers/Transfers/WithinBank'),
    'TransferWithinBank',
  ),
);
const TransferInsideCountry = lazy(() =>
  lazyRetry(
    () => import('containers/Transfers/InsideCountry'),
    'TransferInsideCountry',
  ),
);
const TransferAnyPay = lazy(() =>
  lazyRetry(() => import('containers/Transfers/AnyPay'), 'TransferAnyPay'),
);
const TransferBetweenMyAccounts = lazy(() =>
  lazyRetry(
    () => import('containers/Transfers/BetweenMyAccounts'),
    'TransferBetweenMyAccounts',
  ),
);

const TransferInternational = lazy(() =>
  lazyRetry(
    () => import('containers/Transfers/International'),
    'TransferInternational',
  ),
);

// Payments
const PaymentsSalary = lazy(() =>
  lazyRetry(() => import('containers/Payments/SalaryVersion'), 'PaymentsSalary'),
);
const PaymentsBudget = lazy(() =>
  lazyRetry(() => import('containers/Payments/Budget'), 'PaymentsBudget'),
);
const PaymnetsStateTreasury = lazy(() =>
  lazyRetry(
    () => import('containers/Payments/StateTreasury'),
    'PaymnetsStateTreasury',
  ),
);

// Products

const ProductsAccounts = lazy(() =>
  lazyRetry(() => import('containers/Products/Accounts'), 'ProductsAccounts'),
);
const ProductsAccountsStatement = lazy(() =>
  lazyRetry(
    () => import('containers/Products/Accounts/Statement'),
    'ProductsAccountsStatement',
  ),
);

const Statement = lazy(() =>
  lazyRetry(() => import('containers/Statement'), 'Statement'),
);

const ProductLoans = lazy(() =>
  lazyRetry(() => import('containers/Products/Loans'), 'ProductLoans'),
);
const ProductLoansStatement = lazy(() =>
  lazyRetry(
    () => import('containers/Products/Loans/Statement'),
    'ProductLoansStatement',
  ),
);

const ProductCards = lazy(() =>
  lazyRetry(() => import('containers/Products/ProductCard'), 'ProductCards'),
);

const ProductCardsStatement = lazy(() =>
  lazyRetry(
    () => import('containers/Products/ProductCard/Statement'),
    'ProductCardsStatement',
  ),
);

const MobileCardsDetails = lazy(() =>
  lazyRetry(
    () => import('containers/Products/ProductCard/Mobile/MobileDetails'),
    'MobileCardsDetails',
  ),
);
const ProductDeposits = lazy(() =>
  lazyRetry(() => import('containers/Products/Deposits'), 'ProductDeposits'),
);
const ProductDepositsStatement = lazy(() =>
  lazyRetry(
    () => import('containers/Products/Deposits/Statement'),
    'ProductDepositsStatement',
  ),
);

const DetailsPage = lazy(() =>
  lazyRetry(() => import('containers/DetailsPage'), 'DetailsPage'),
);

// Conversion
const Conversion = lazy(() =>
  lazyRetry(() => import('containers/Conversion'), 'Conversion'),
);

// Notification
const DocumentToBeSign = lazy(() =>
  lazyRetry(() => import('containers/DocumentToBeSign'), 'DocumentToBeSign'),
);
const Orders = lazy(() =>
  lazyRetry(() => import('containers/Orders'), 'Orders'),
);

//Templates
const Templates = lazy(() =>
  lazyRetry(() => import('containers/Templates'), 'Templates'),
);

// Government
const Government = lazy(() =>
  lazyRetry(() => import('containers/Government'), 'Government'),
);
const GovernmentPay = lazy(() =>
  lazyRetry(() => import('containers/Government/Pay'), 'GovernmentPay'),
);
//History
const History = lazy(() =>
  lazyRetry(() => import('containers/History'), 'History'),
);

//Maps
const Maps = lazy(() => lazyRetry(() => import('containers/Maps'), 'Maps'));

//SettingsPanel
const SettingsPanel = lazy(() =>
  lazyRetry(() => import('containers/SettingsPanel'), 'SettingsPanel'),
);

const Guarantees = lazy(() =>
  lazyRetry(() => import('containers/Products/Guarantees'), 'Guarantees'),
);

// const AniPay = lazy(() => import("containers/Transfers/AniPay"));
// //Approve
// const Approve = lazy(() => import("containers/Approve"));
// //Map
// const Map = lazy(() => import("containers/Map"));
// //Payroll
// const PayrollPayment = lazy(() => import("containers/Payroll/PayrollPayment"));

//Products
// const Cards = lazy(() => import("containers/Products/Cards"));
// const Accounts = lazy(() => import("containers/Products/Accounts"));
// const Loans = lazy(() => import("containers/Products/Loans"));
// const Deposits = lazy(() => import("containers/Products/Deposits"));

const Routes = () => {
  return (
    <ScrollToTop>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path='/'>
            <Dashboard />
          </Route>

          <PermissionsRouter
            exact
            path='/users'
            component={Users}
            name='users'
          />

          <Route exact path='/anipay-cabinet' component={AniPayCabinet} />

          <PermissionsRouter
            exact
            path='/add-user'
            component={UserAdd}
            name='users'
          />

          <PermissionsRouter
            exact
            path='/edit-user/:userId'
            component={UserEdit}
            name='users'
          />

          <PermissionsRouter
            exact
            path='/user-details/:userId'
            component={UserDetails}
            name='users'
          />

          {/* Transfers */}
          <PermissionsRouter
            exact
            path='/payments/within-bank'
            component={TransferWithinBank}
            name='withinBank'
          />

          <PermissionsRouter
            exact
            path='/payments/within-bank/:templateId'
            component={TransferWithinBank}
            name='withinBank'
          />
          <PermissionsRouter
            exact
            path='/payments/within-bank/edit/:templateId'
            component={TransferWithinBank}
            name='withinBank'
          />

          {/* Inside country */}
          <PermissionsRouter
            exact
            path='/payments/inside-country'
            component={TransferInsideCountry}
            name='insideCountry'
          />

          <PermissionsRouter
            exact
            path='/payments/inside-country/:templateId'
            component={TransferInsideCountry}
            name='insideCountry'
          />

          <PermissionsRouter
            exact
            path='/payments/inside-country/edit/:templateId'
            component={TransferInsideCountry}
            name='insideCountry'
          />

          <PermissionsRouter
            exact
            path='/payments/anipay'
            component={TransferAnyPay}
            name='aniPay'
          />

          <PermissionsRouter
            exact
            path='/payments/International'
            component={TransferInternational}
            name='international'
          />
          <PermissionsRouter
            exact
            path='/payments/International/:templateId'
            component={TransferInternational}
            name='international'
          />

          <PermissionsRouter
            exact
            path='/payments/International/edit/:templateId'
            component={TransferInternational}
            name='international'
          />

          {/* BetweenMyAccount */}
          <PermissionsRouter
            exact
            path='/payments/between-my-accounts'
            component={TransferBetweenMyAccounts}
            name='betweenMyAccounts'
          />

          <PermissionsRouter
            exact
            path='payments/between-my-accounts'
            component={TransferBetweenMyAccounts}
            name='betweenMyAccounts'
          />

          <PermissionsRouter
            exact
            path='/payments/between-my-accounts/:templateId'
            component={TransferBetweenMyAccounts}
            name='betweenMyAccounts'
          />

          <PermissionsRouter
            exact
            path='/payments/between-my-accounts/edit/:templateId'
            component={TransferBetweenMyAccounts}
            name='betweenMyAccounts'
          />

          {/* Payments */}

          <PermissionsRouter
            exact
            path='/payments/salary'
            component={PaymentsSalary}
            name='salary'
          />

          <PermissionsRouter
            exact
            path='/payments/budget'
            component={PaymentsBudget}
            name='budget'
          />

          <PermissionsRouter
            exact
            path='/payments/budget/:templateId'
            component={PaymentsBudget}
            name='budget'
          />

          <PermissionsRouter
            exact
            path='/payments/budget/edit/:templateId'
            component={PaymentsBudget}
            name='budget'
          />

          <PermissionsRouter
            exact
            path='/payments/vat'
            component={PaymnetsStateTreasury}
            name='stateTreasury'
          />

          <PermissionsRouter
            exact
            path='/payments/vat/:templateId'
            component={PaymnetsStateTreasury}
            name='stateTreasury'
          />

          <PermissionsRouter
            exact
            path='/payments/vat/edit/:templateId'
            component={PaymnetsStateTreasury}
            name='stateTreasury'
          />

          {/* Products */}

          <PermissionsRouter
            exact
            path='/product/accounts'
            name='accounts'
            component={ProductsAccounts}
          />

          <PermissionsRouter
            exact
            path='/product/accounts/statement'
            name='accounts'
            component={ProductsAccountsStatement}
          />

          <PermissionsRouter
            exact
            path='/product/loans'
            name='loans'
            component={ProductLoans}
          />

          <PermissionsRouter
            exact
            path='/product/loans/statement'
            name='loans'
            component={ProductLoansStatement}
          />

          <PermissionsRouter
            exact
            path='/product/cards'
            name='cards'
            component={ProductCards}
          />

          <PermissionsRouter
            exact
            path='/product/cards/:sublink'
            name='cards'
            component={MobileCardsDetails}
          />

          <PermissionsRouter
            exact
            path='/product/card/statement'
            name='cards'
            component={ProductCardsStatement}
          />

          <PermissionsRouter
            exact
            path='/product/deposits'
            name='deposits'
            component={ProductDeposits}
          />
          <PermissionsRouter
            exact
            path='/product/deposits/statement/:sublink'
            name='deposits'
            component={ProductDepositsStatement}
          />
          <PermissionsRouter
            exact
            path='/product/guarantees'
            name='guarantees'
            component={Guarantees}
          />

          <PermissionsRouter
            exact
            path='/document-sign'
            name='documentsToSign'
            component={DocumentToBeSign}
          />

          <PermissionsRouter
            exact
            path='/orders'
            name='taxOrders'
            component={Orders}
          />

          <PermissionsRouter
            exact
            path='/history'
            name='operationHistory'
            component={History}
          />

          <PermissionsRouter exact path='/maps' name='map' component={Maps} />

          <PermissionsRouter
            exact
            path='/settings'
            name='settings'
            component={SettingsPanel}
          />

          <PermissionsRouter
            exact
            path='/conversion'
            name='conversion'
            component={Conversion}
          />
          <PermissionsRouter
            exact
            path='/templates'
            name='templates'
            component={Templates}
          />

          {/* Government */}
          {/* <Route exact path="/government">
            <Government />
          </Route> */}

          {/* <Route exact path="/government/:subLink">
            <GovernmentPay />
          </Route> */}

          <Route exact path='/details/' component={DetailsPage} />
          <Route exact path='/history/details' component={DetailsPage} />

          <Route exact path='/statements/:id' component={Statement} />

          {/* Map */}
          {/* <Route exact path="/map">
          <Map />
        </Route> */}

          {/* Products */}
          {/* <Route exact path="/products/cards">
          <Cards />
        </Route>

        <Route exact path="/products/accounts">
          <Accounts />
        </Route>

        <Route exact path="/products/loans">
          <Loans />
        </Route>

        <Route exact path="/products/deposits">
          <Deposits />
        </Route> */}

          {/* Payroll */}
          {/* <Route exact path="/payroll_payment">
          <PayrollPayment />
        </Route> */}

          {/* AniPay */}
          {/* <Route exact path="/ani-pay">
          <AniPay />
        </Route>
         */}
          {/* Approve */}
          {/* <Route exact path="/approve">
          <Approve />
        </Route> */}

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
};

export default Routes;
