import React from "react";
import { Row } from "antd";
import { GeneralCol } from "components";
import styled from "styled-components";

export const Filter = ({
  onFinish,
  data,
  toggleFilter,
  statusData,
  filterInputs,
  date,
  height = "190px",
  rowSize = 24,
  rules,
  children,
  buttons,
  ...rest
}) => {

 
  return (
    <FilterContainer {...{ toggleFilter, height }} {...rest}>
      <Row gutter={[rowSize]}>
        <GeneralCol>
          <DropdownContainer>
           
               {children}
             
          </DropdownContainer>
        </GeneralCol>
        <GeneralCol>
          <FilterBtnContainer>
              {buttons}
          </FilterBtnContainer>
        </GeneralCol>
      </Row>
    </FilterContainer>
  );
};

const DropdownContainer = styled.div`
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #e5e5e5;
  padding: 27px 0 8px 11px;
  display: flex;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  height: ${({ toggleFilter, height }) => (toggleFilter ? 'auto' : "0px")};
  transition: height 300ms ease-in-out;
  margin-bottom: ${({ toggleFilter }) => (toggleFilter ? "20px" : "0px")};
  overflow: ${({ toggleFilter }) => (toggleFilter ? "none" : "hidden")};

  .ant-select-multiple .ant-select-selector {
    padding: 4px 13px;
  }
  .ant-select-selector {
    min-height: 55px !important;
    max-height: 55px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #ffff;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 34px;
  }
`;

const FilterBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px;
  border: 1px solid #e5e5e5;
  border-top: 0;
`;
