import { createSlice } from "@reduxjs/toolkit";
import { GetGuaranteesList, GetGuaranteeInfo } from "./api_action";

export { GetGuaranteesList, GetGuaranteeInfo }


const initialState = {
  error: "",
  guarantees: [],
  guaranteeDetail: {},
};

const GuaranteeSlice = createSlice({
  name: "getGuarantee",
  initialState,
  reducers: {},
  extraReducers: {
    [GetGuaranteesList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.guarantees = data;
    },
    [GetGuaranteesList.rejected]: (state, action) => {
      const error = action.payload;
      state.error = error;
    },
    [GetGuaranteeInfo.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.guaranteeDetail = data;
    },
    [GetGuaranteeInfo.rejected]: (state, action) => {
      const error = action.payload;
      state.error = error;
    },
  },
});

export const guaranteeReducer = GuaranteeSlice.reducer;
