import React from 'react';
import styled from 'styled-components';
import {responsive} from "utils/responsiveUI";
import {Radio} from 'antd';
import {ScrollStyle} from "components";

export const RadioButton = ({isStandard, data, ...rest}) => {
    const count = data && 100 / data.length;
    const RenderedRadioButton = (index, value, title, text, render, isDisable) => (
        <Radio key={index} value={value} disabled={isDisable || false} style={{ flex: 1, maxWidth: "100%" }}>
            {render}
            <div style={{width: '100%'}}> 
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </Radio>
    )
    return (
        <Container count={count}>
            <Radio.Group {...rest} defaultValue={data[0]?.value} buttonStyle="solid">
                {data && data.length > 0 && data.map((item, index) => {
                    const {value, title, text, render, isDisable} = item;
                    return !isStandard ? <Radio.Button key={index}
                                                       value={value}>
                            {title}
                        </Radio.Button>
                        : RenderedRadioButton(index, value, title, text, render, isDisable)
                })}
            </Radio.Group>
        </Container>
    )
};

const Container = styled.div`
  .ant-radio-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin: 0 0 32px 0;
    ${ScrollStyle}
  }

  .ant-radio-wrapper-checked {
    border-color: #FFCC71 !important;
  }

  .ant-radio-wrapper-disabled {
    border-color: #E3E3E3 !important;

    img {
      opacity: 0.4;
    }

    h3, p {
      color: #E3E3E3 !important;
    }
  }

  .ant-radio-wrapper {
    all: unset;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid #E3E3E3; */
    border-radius: 8px;
    .ant-radio{
      width: 16px
    }
    span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;
      font-style: normal;
      padding-left: 0 !important;
      width: 100%;

      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #555555;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8E8E8E;
      }

      h3, p {
        margin-bottom: 0;
      }
    }
    @media ${responsive.laptop} {
      padding: 10px;
    }
   
  }

  .ant-radio-button-wrapper {
    width: ${props => props.count + '%'};
    height: 50px;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;

    span {
      font-family: Proxima Nova;
      font-size: 16px;
      line-height: 19px;
    }
  }

`
